import React, { Fragment, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import * as StellarSdk from "@stellar/stellar-sdk";
import { Button } from "@stellar/design-system";
import { useDispatch } from "react-redux";
import { useRedux } from "hooks/useRedux";

import { useStore } from "../../../hook-store/store";

// import classes from './IssuerFrontend.module.css';


function WideAds() {

    const dispatch = useDispatch();

    const { account, memoRequiredAccounts, settings } = useRedux(
        "account",
        "memoRequiredAccounts",
        "settings",
    );

    // const navigate = useNavigate();
    // const location = useLocation();

    const [hStore, hDispatch] = useStore();
    const { windowValues } = hStore.uiStore;
    // console.log(windowValues);

  
    let wideAdsBody;

    if (windowValues) {

      if (windowValues.width > 768) {
        wideAdsBody = (
          <div style={{display:"flex", justifyContent:"center", paddingBottom:"1.5rem"}}>
              {/* <iframe id="localhost:8506-1675925520615"
                style={{width:"660px", height:"150px", border:"none"}}  
                src="http://localhost:3003?adPlaceId=localhost:8506-1675925520615"
              /> */}
              <iframe id="stellar-nftplace.spaceeight.work-1675841156867"
                  style={{width:"610px", height:"135px", border:"none"}}  
                  src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=stellar-nftplace.spaceeight.work-1675841156867"
              />
          </div>
        ); 
      }
  
      if (windowValues.width <= 768 && windowValues.width > 480) {
        wideAdsBody = (
          <div style={{display:"flex", justifyContent:"center", paddingBottom:"1.5rem"}}>
              <iframe id="stellar-nftplace.spaceeight.work-1675842335427"
                style={{width:"460px", height:"95px", border:"none"}}  
                src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=stellar-nftplace.spaceeight.work-1675842335427"
              />
          </div>
        ); 
      }
  
      if (windowValues.width <= 480) {
        wideAdsBody = (
          <div style={{display:"flex", justifyContent:"center", paddingBottom:"1.5rem"}}>
              <iframe id="stellar-nftplace.spaceeight.work-1675842352452"
                style={{width:"310px", height:"70px", border:"none"}}  
                src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=stellar-nftplace.spaceeight.work-1675842352452"
              />
          </div>
        ); 
      }
    }


    return (
        <Fragment>
          <div>{wideAdsBody}</div>
        </Fragment>
    );
}

export default WideAds;