import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Layout, Identicon, CopyText } from "@stellar/design-system";

import { resetStoreAction } from "config/store";
import { stopAccountWatcherAction } from "ducks/account";
import { stopTxHistoryWatcherAction } from "ducks/txHistory";
import { useRedux } from "hooks/useRedux";
import { getUserThemeSettings } from "helpers/getUserThemeSettings";
import { logEvent } from "helpers/tracking";


import Backdrop from "../Backdrop/Backdrop.jsx";
import ToggleNav from "../Navigation/ToggleNav/ToggleNav/ToggleNav.jsx";
import { useStore } from '../../../hook-store/store';

const IssuerHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { account } = useRedux("account");
  const { isAuthenticated } = account;

  const [hStore, hDispatch] = useStore();
  const isMenuOpen = hStore.uiStore.isMenuOpen;

  const getThemeTrackingParams = (isDarkMode) => {
    const { prefersDarkMode, savedMode } = getUserThemeSettings(isDarkMode);

    return {
      "using system dark mode": Boolean(prefersDarkMode),
      "user set website theme": savedMode ?? "not set",
    };
  };

  useEffect(() => {
    logEvent("theme: initial page load", getThemeTrackingParams());

    trackThemeChange();
  }, []);

  const handleSignOut = () => {
    dispatch(stopAccountWatcherAction());
    dispatch(stopTxHistoryWatcherAction());
    dispatch(resetStoreAction());
    navigate("/");
  };

  const trackThemeChange = (isDarkMode) => {
    logEvent("theme: user changed", getThemeTrackingParams(isDarkMode));
  };

  const menuHandler = () => {
    hDispatch('SET_ISMENUOPEN', !isMenuOpen);
  };

  const isSignedIn = isAuthenticated && account.data;

  let headerContentBody;


  if (isSignedIn || !isSignedIn) {
    headerContentBody = (
      <div>
        {isSignedIn && (
          <div>
            {/* <img
              title={account.data.id}
              style={{height:"20px", width: "20px" }} 
              src={`https://id.lobstr.co/${account.data.id}.png`}
              alt="account id icon"
            /> */}
          </div>
        )}
        
      </div>
    );
  }

  return (
    <Fragment>
      <div
        style={{fontSize:"0.8rem"}}
      >
        <Layout.Header
          // projectTitle="Account Viewer"
          // projectLink="https://stellar.org"
          projectTitle="NFT Place"
          projectLink="/"
          hasDarkModeToggle
          onDarkModeToggleEnd={trackThemeChange}
          onSignOut={isSignedIn ? handleSignOut : undefined}
          // showButtonBorder
          contentCenter={
            isSignedIn ? (
              <div className="Header__account">
                <CopyText textToCopy={account.data.id} showCopyIcon showTooltip>
                  <Identicon publicAddress={account.data.id} shortenAddress />
                </CopyText>
              </div>
            ) : undefined
          }
          // contentRight={(
          //   <div>right-co</div>
          // )}
          menu={{
            isEnabled:true, 
            onOpen: menuHandler,
          }}
        ></Layout.Header>
      </div>


        <ToggleNav />
        
        {isMenuOpen && (
          <Backdrop 
            onCancel={() => {
              hDispatch('SET_ISMENUOPEN', false);
            }}
          />
        )}

    </Fragment>
  );
};

export default IssuerHeader;