import React, { useState, useEffect, Fragment } from 'react';
import { Button, Input, InfoBlock } from '@stellar/design-system';
import { useTranslation } from 'react-i18next';

import { useDispatch } from "react-redux";

import { ActionStatus, AuthType } from "types/types";
import { useRedux } from "hooks/useRedux";
import { fetchAccountAction } from 'ducks/account';


import CreateNftSellOfferSelect from './CreateNftSellOfferSelect.jsx';
import GetNetworkFee from '../../UiComponent/GetNetworkFee.jsx';

// import * as uploadUtils from '../utils/bucket-upload-util';
import { useStore } from '../../../../hook-store/store';
import { txConfig } from '../../transactionConfig.ts';
import { getAccountInfo } from '../../../../utils/nft/offer-util';
import { BACKEND_URL } from '../../../../urlLinks';

import classes from './CreateNftSellOfferInputs.module.css';

function CreateNftSellOfferInputs(props) {
  const { 
    nft,
    buyAssetInput,
    inputXlmAmountChangeHandler,
    sellAmountInput,
    sellAmountInputChangeHandler,
    showCreateOfferHandler,
    setSelectOptionHandler,
    createNftSellOfferHandler,
    priceInput,
    // enoughXLM,
    isLoading,
  } = props;

  const [t] = useTranslation('translation');

  const dispatch = useDispatch();

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    "memoRequiredAccounts",
    "settings",
    "sendTx",
  );
  // const { status, errorString } = sendTx;

  const publicKey = account.data ? account.data.id : '';


  const [hStore, hDispatch] = useStore();
  // const feeStats = hStore.nftStore.feeStats;
  const { feeStats, termAssetList } = hStore.nftStore;

  const [accountData, setAccountData] = useState();
  const [availableXLM, setAvailableXLM] = useState(0);

  useEffect(() => {
    const getAccountDataHandler = async () => {
      try {
        const accountInfo = await getAccountInfo(account.data.id);
        console.log('accountInfo', accountInfo);
        if (accountInfo) {
          setAccountData(accountInfo);
        }
      } catch(err) {
        console.log(err);
      }
    };

    if (account.data) {
      getAccountDataHandler();
    }
  },[account.data]);

  useEffect(() => {
    const fetchAccountActionHandler = async () => {
      const accountDetails = await dispatch(fetchAccountAction(publicKey));
  
      const nativeBalances = accountDetails.payload.data.balances.native;
      const availXLM = 
      nativeBalances.available.minus(nativeBalances.minimumBalance);

      setAvailableXLM(availXLM);
    };
  
    if (publicKey) {
      fetchAccountActionHandler();
    }
  
  },[publicKey]);


  let hasTrust = false;

  if (accountData && buyAssetInput) {

    const hasBuyAssetTrust = accountData.balances.find(balance => (
      balance.asset_code === buyAssetInput.code && 
        balance.asset_issuer === buyAssetInput.issuer
        // Number(balance.balance) > 0
      ));

    if (hasBuyAssetTrust) {
      hasTrust = true;
    }

    if (buyAssetInput.code === "XLM" && !buyAssetInput.issuer) {
      hasTrust = true;
    }
  }

  // console.log('buyAssetInput', buyAssetInput, hasTrust);


  const fee = '100000';
  let useFee = fee;
  let networkFee = 0;
  // let minXLM = 0;
  let opNum = 2;

  let inputFee = fee;


  let enoughXLM = false;
  

  if (buyAssetInput && !hasTrust) {
    opNum = 3;
  }

  if (feeStats) {
    networkFee = Number(feeStats.fee_charged.mode);
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    inputFee = Number(networkFee)*1.1;
    inputFee = Number(networkFee)*10;
    useFee = inputFee*opNum / 10**7 + txConfig.txFee;

  } else {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    useFee = Number(useFee)*opNum / 10**7 + txConfig.txFee;
  }

  if (buyAssetInput && !hasTrust) {
    useFee = useFee + txConfig.createTrustXLM;
  }

  useFee = useFee + txConfig.offerEntryReserve;

  if (availableXLM >= useFee) {
    enoughXLM = true;
  }

  console.log('enoughXLM', enoughXLM, availableXLM, useFee, feeStats, buyAssetInput);
  
  let buyAssetTermData;
  let displayAssetPriceUSD;

  if (buyAssetInput && termAssetList) {
    if (buyAssetInput.code === 'XLM' && !buyAssetInput.issuer) {
      buyAssetTermData = termAssetList.assets.find(asset => {
        return asset.code === 'XLM' && !asset.issuer;
      });
    } else {
      buyAssetTermData = termAssetList.assets.find(asset => {
        return (asset.code === buyAssetInput.code && 
                  asset.issuer === buyAssetInput.issuer);
      });
    }

    if (buyAssetTermData) {
      displayAssetPriceUSD = buyAssetTermData.price_USD;
    }

    // console.log('buyAssetTermData', buyAssetTermData);
  }


  let additionalMessage = (<span>
    ({`${t('singleNFT.22','including transaction fee')} ${txConfig.txFee.toFixed(2)} XLM`})
  </span>);

  if (buyAssetInput && !hasTrust) {
    additionalMessage = (<span>
      ({`${t('singleNFT.22','including transaction fee')} ${txConfig.txFee.toFixed(2)} XLM & ${txConfig.createTrustXLM} XLM for creating trustline to this asset`})
    </span>);
  }

  let createSellOfferInputsBody;

  createSellOfferInputsBody = (
    <div>

      <div className={classes.createSellOfferInput}>
      <label>
        {t('singleNFT.12','select asset')}
      </label>
        <CreateNftSellOfferSelect 
          setSelectOptionHandler={setSelectOptionHandler}
        />
        {buyAssetInput && (
          <div className={classes.createSellOfferBuyAssetInfo}>
            {buyAssetInput.code} 
            {' '}
            {buyAssetInput.issuer && (
              <span>
                ({buyAssetInput.issuer})
              </span>
            )}

            {/* {displayAssetPriceUSD && (
              <div>
                {buyAssetInput.code} price: {displayAssetPriceUSD} USD
              </div>
            )} */}
            
          </div>
        )}
      </div>

      <div className={classes.createSellOfferInput}>
        <label>
          {t('general.text24','price')}
          {' '}
          (0.0000001 ~ 200)
          </label>
        <Input 
          type="text" 
          // onChange={(event) => { priceInputChangeHandler(event); }}
          placeholder='price input'
          onChange={(event) => {
          inputXlmAmountChangeHandler(event); 
          }}
        /> 
        {/* {buyAssetInput && buyAssetInput.code} */}
        {buyAssetInput && (
          <div>
            {(Number(priceInput)*0.0000001).toFixed(7)}
            {' '} 
            {/* {buyAssetInput && buyAssetInput.code}
            {' '} */}
            ({Number(priceInput).toFixed()} {buyAssetInput.code}/{nft.code})
            {' '}
            {displayAssetPriceUSD && (
              <div>
                ( ~ {(displayAssetPriceUSD*Number(priceInput)/10**7).toFixed(2)} USD, 
                  {' '}
                  1 {buyAssetInput.code}: {displayAssetPriceUSD} USD )
              </div>
            )}
          </div> 
        )}
      </div>

      <div className={classes.createSellOfferInput}>
        <label> 
          {t('general.text23','amount')}
          {' '}
          (1 ~ )
          </label>
        <Input 
          type="text" 
          onChange={sellAmountInputChangeHandler}
          placeholder='sell amount'
        />
        {buyAssetInput && (
          <div>
            {sellAmountInput} 
            {' '}
            ({( Number(sellAmountInput)*0.0000001 ).toFixed(7)} {nft.code})
          </div>
        )}
      </div>

      <div className={classes.createSellOfferAvailableXlm}>
        <InfoBlock>
          <div className={classes.createSellOfferAvailableXlm}>
            <div>
              {t('general.text25','available')}
              {' '}
              XLM: {availableXLM && availableXLM.toFixed(7)}
            </div>
            <div>
              {t('general.text26','required')}
              {' '}
              XLM: {useFee.toFixed(7)}
              {' '}
              {additionalMessage}
            </div>
          </div>

          {sellAmountInput && priceInput && 
            settings.authType && settings.authType !== AuthType.PRIVATE_KEY && (
            <div> 
              {t('singleNFT.20','Numbers in confirm screen of wallet will be different from your inputs.')}
              {' '}
              {t('singleNFT.21','This is because to keep indivisible unit of NFT')}
            </div>
          )}
        </InfoBlock>
      </div>
      
      <div>
        <Button 
          disabled={isLoading || !priceInput || 
            Number(priceInput) < 1 || Number(priceInput) > 2 * 10**9 || 
            !sellAmountInput || !enoughXLM
          }
          isLoading={isLoading}
          onClick={() => {
            createNftSellOfferHandler(
              BACKEND_URL, 
              localStorage.getItem('token'),
              publicKey,
              { code: nft.code, issuer: nft.issuer },
              { code: buyAssetInput.code, issuer: buyAssetInput.issuer}, // { code: 'XLM', issuer: ''},
              priceInput,
              ( Number(sellAmountInput)*0.0000001 ).toFixed(7), // '0.0000001',
              (Number(inputFee)).toFixed(),
            );
          }}
        >
          {t('singleNFT.09','create trading sell offer')}
        </Button>
      </div>

    </div>
  );


  return (
    <Fragment>
      {createSellOfferInputsBody}
      <GetNetworkFee />
    </Fragment>
  );
}

export default CreateNftSellOfferInputs;
