/* eslint-disable max-len */
import { Logo } from "@stellar/design-system";
import { ModalType, Wallets } from "types/types";
import i18n from '../i18n';

console.log(i18n);
export const wallets: Wallets = {
  albedo: {
    // title: "Connect with Albedo",
    title: "Albedo",
    logoSvg: <Logo.Albedo />,
    modalType: ModalType.SIGNIN_ALBEDO,
    // infoText: "Albed is a browser wallet."
    infoText: `Albedo is a ${i18n.t('wallet.03', 'browser wallet')}.`,
    // infoLinkText: "Learn more",
    infoLinkText: `${i18n.t('wallet.04', 'Learn more')}`,
    infoLink: "https://albedo.link",
  },
  freighter: {
    // title: "Connect with Freighter",
    title: "Freighter",
    logoSvg: <Logo.Freighter />,
    modalType: ModalType.SIGNIN_FREIGHTER,
    // infoText: "Freighter is a browser extension wallet. Available on Chrome and Firefox.",
    infoText: `Freighter is a ${i18n.t('wallet.05', 'browser extension wallet')}. Available on Chrome and Firefox.`,
    // infoLinkText: "Download",
    infoLinkText: `${i18n.t('wallet.06', 'Download')}`,
    infoLink: "https://freighter.app",
  },
  ledger: {
    // title: "Connect with Ledger",
    title: "Ledger",
    logoSvg: <Logo.Ledger />,
    modalType: ModalType.SIGNIN_LEDGER,
    // infoText: "Ledger is a Stellar-compatible hardware wallet.",
    // infoLinkText: "Learn more",
    infoText:"",
    infoLinkText: "",
    infoLink: "https://www.ledger.com",
  },
  trezor: {
    // title: "Connect with Trezor",
    title: "Trezor",
    logoSvg: <Logo.Trezor />,
    modalType: ModalType.SIGNIN_TREZOR,
    // infoText: "Trezor is a Stellar-compatible hardware wallet.",
    // infoLinkText: "Learn more",
    infoText: "",
    infoLinkText: "",
    infoLink: "https://trezor.io",
  },
};
