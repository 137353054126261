export const getNftComment = async (url, token, nId) => {
  try {

    const result = await fetch(`${url  }/nft-comment?nId=${nId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
      // body: JSON.stringify({
      //   comment: comment,
      //   nId: nId,
      // }),
    });

    const resData = await result.json();
    
    console.log(result, resData);

    if (!result.ok) {
      throw new Error('error-occured');
    }

    return resData;

  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const createNftComment = async (
  url, 
  token, 
  nId,
  comment,
) => {
  try {

    const result = await fetch(`${url  }/nft-comment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
      body: JSON.stringify({
        comment,
        nId,
      }),
    });

    const resData = await result.json();
    
    console.log(result, resData);

    if (!result.ok) {
      throw new Error('error-occured');
    }

    return resData;

  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const deleteNftComment = async (
  url, 
  token, 
  nId,
  commentId,
) => {
  try {

    const result = await fetch(`${url  }/nft-comment?nId=${nId}&commentId=${commentId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
      // body: JSON.stringify({
      //   comment: comment,
      //   nId: nId,
      // }),
    });

    const resData = await result.json();
    
    console.log(result, resData);

    if (!result.ok) {
      throw new Error('error-occured');
    }

    return resData;

  } catch(err) {
    console.log(err);
    throw err;
  }
};