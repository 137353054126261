import React from 'react';
import classes from './Notification.module.css';

import { useStore } from '../../../hook-store/store';

const Notification = (props) => {

  const [hStore, hDispatch] = useStore();
  // console.log('Notification.js-props', props)
  let specialClasses = '';

  if (props.status === 'error') {
    specialClasses = classes.error;
  }
  if (props.status === 'success') {
    specialClasses = classes.success;
  }
  if (!props.status) {
    specialClasses = '';
  }

  const cssClasses = `${classes.notification} ${specialClasses}`;

  return (
    <section className={cssClasses}>
      <h2>{props.title}</h2>
      <p>{props.message}</p>
      <div className={classes.notificationClear}
        onClick={() => {
          hDispatch('CLEAR_NOTIFICATION'); 
        }}
      >
        X
      </div>
    </section>
  );
};

export default Notification;