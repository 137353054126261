import React, { useState, useEffect, Fragment } from 'react';
// import albedo from '@albedo-link/intent';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { useRedux } from "hooks/useRedux";

// import CreateNftBuyOffer from './CreateNftBuyOffer.jsx';
import GetAssetsTomlData from "components/IssuerFrontend/UiComponent/GetAssetsTomlData.jsx";
import CreateNftSellOffer from '../CreateNftSellOffer/CreateNftSellOffer.jsx';
import NftSellOfferListItem from './NftSellOfferListItem.jsx';


import { useStore } from '../../../../hook-store/store';
import { 
  getAccountInfo,
  checkAssetPosition,
} from '../../../../utils/nft/offer-util';
import { addUSDPriceInOffers } from '../../../../utils/nft/nft-data-util.js';

// import { BACKEND_URL, } from '../../../App';

import { marks } from '../../../../images/icons/marks';
import classes from './NftSellOfferList.module.css';

function NftSellOfferList(props) {
  const { 
    nft, 
    assetBuyOffers,
    assetSellOffers,
    getAssetBuyOffersHandler,
    getAssetSellOffersHandler,
    // isLoading
  } = props;

  const [t] = useTranslation('translation');

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    "memoRequiredAccounts",
    "settings",
    "sendTx",
  );
  // const { status, errorString } = sendTx;

  const publicKey = account.data ? account.data.id : '';


  const [hStore, hDispatch] = useStore();
  const {
    termAssetList,
  } = hStore.nftStore;

  const [showSellList, setShowSellList] = useState(true);
  const [accountData, setAccountData] = useState();
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (account.data) {
      getAccountDataHandler();
    }
  },[account.data]);

  const getAccountDataHandler = async () => {
    try {
      const accountInfo = await getAccountInfo(account.data.id);
      console.log('accountInfo', accountInfo);
      if (accountInfo) {
        setAccountData(accountInfo);
      }
    } catch(err) {
      console.log(err);
    }
  };

  // const setUpdateAccountInfo = async (accountId) => {
  //   const accountInfo = await getAccountInfo(accountId);
  //     dispatch('SET_ALBEDO_ACCOUNTINFO', accountInfo);
  // };


  // const buyNftHandler = async (
  //   url, 
  //   token, 
  //   accountId,
  //   sellAsset,
  //   buyAsset,
  //   price,
  //   buyAmount,
  // ) => {
  //   try {
  //     setIsLoading(true);

  //     // const accountInfo = await getAccountInfo(accountId);

  //     // console.log(accountInfo);

  //     // if (accountInfo && accountInfo.balances.length > 0) {
  //     //   const hasSellAsset = accountInfo.balances.find(balance => {
  //     //     return (balance.asset_code === sellAsset.code && 
  //     //       balance.asset_issuer === sellAsset.issuer &&
  //     //       Number(balance.balance) > 0
  //     //     );
  //     //   });

  //     //   console.log(hasSellAsset);

  //     //   if (!hasSellAsset) {
  //     //     throw new Error('not-have-sell-asset');
  //     //   }
  //     // }

  //     // const sellOffers = await getAssetOffers(
  //     //   'sell', 
  //     //   'credit_alphanum12', 
  //     //   sellAsset.code, 
  //     //   sellAsset.issuer,
  //     // );

  //     // console.log(sellOffers, sellOffers._embedded.records);
      
  //     // if (sellOffers && sellOffers._embedded.records.length > 0) {
  //     //   throw new Error('sell-asset-is-already-in-sell-offer');
  //     // }

  //     const resData = await createBuyOfferXdr(
  //       url, 
  //       token, 
  //       accountId,
  //       sellAsset,
  //       buyAsset,
  //       price,
  //       buyAmount,
  //     );

  //     if (resData) {
  //       // console.log(resData);

  //       const albedoResult = await albedo.tx({
  //         xdr: resData.data,
  //         description: `Buy ${buyAsset.code} at ${Number(price*0.0000001).toFixed(2)} ${sellAsset.code}`,
  //         pubkey: accountId,
  //         submit: true,
  //       });

  //       console.log(albedoResult);

        
  //     }

  //     await getAssetBuyOffersHandler('credit_alphanum12', buyAsset.code, buyAsset.issuer);
  //     await getAssetSellOffersHandler('credit_alphanum12', buyAsset.code, buyAsset.issuer);
  //     await setUpdateAccountInfo(accountId);

  //     dispatch('SHOW_NOTIFICATION', {
  //       status: '',
  //       title: '',
  //       message: 'buy offre created',
  //     });

  //     setTimeout(() => {
  //       dispatch('CLEAR_NOTIFICATION')
  //     }, 3000);


  //     setIsLoading(false);
  //   } catch(err) {
  //     console.log(err);
  //     setIsLoading(false);

  //     dispatch('SHOW_NOTIFICATION', {
  //       status: '',
  //       title: '',
  //       message: 'Error: buy offre failed',
  //     });
  //   }
  // };

  let hasAsset = false;
  let hasAmount;
  let hasSellOffer = false;
  let hasBuyOffer = false;
  let userSellOffersAmount = 0;

  /// / check have nft
  if (nft && account.data && accountData) {

    const assetPosition = checkAssetPosition(
        nft.code, 
        nft.issuer, 
        accountData.balances,
      );

    // console.log('assetPosition', assetPosition);

    if (assetPosition) {
      hasAsset = true;
      hasAmount = assetPosition.amount;
    }
  }


  if (assetSellOffers && assetSellOffers.length > 0) {
    const isPublicKeySellOffer = assetSellOffers.find(sOffer => sOffer.seller === publicKey);

    if (isPublicKeySellOffer) {
      hasSellOffer = true;
    }
  }

  if (assetBuyOffers && assetBuyOffers.length > 0) {
    const isPublicKeyBuyOffer = assetBuyOffers.find(bOffer => bOffer.seller === publicKey);

    if (isPublicKeyBuyOffer) {
      hasBuyOffer = true;
    }
  }


  if (hasAsset && account.data && accountData && assetSellOffers && 
    assetSellOffers.length > 0) {
    // const userSellOffers = assetSellOffers.filter(sOffer => (
    //     sOffer.selling.asset_code === nft.code 
    //     && sOffer.selling.asset_issuer === nft.issuer
    //     && sOffer.seller === account.data.id
    //   ));

    assetSellOffers.forEach(sOffer => {
      if (sOffer.seller === publicKey) {
        userSellOffersAmount += Number(sOffer.amount);
      }
    });

    console.log(userSellOffersAmount);
      // console.log(userSellOffers);
  }


  let createSellOfferBody;

  if (!hasBuyOffer && hasAsset) {
    createSellOfferBody = (
      <div>
        <CreateNftSellOffer 
          nft={nft} 
          // offer={offer}
          assetSellOffers={assetSellOffers}
          accountData={accountData}
          hasAsset={hasAsset}
          hasAmount={hasAmount}
          hasSellOffer={hasSellOffer}
          // hasBuyOffer={hasBuyOffer}
          getAssetSellOffersHandler={getAssetSellOffersHandler}
        />
        <div>
          {t('singleNFT.29', 'selling')}: {(userSellOffersAmount*10**7).toFixed()} 
          {' '}
          {t('singleNFT.30', 'you have')}: {(Number(hasAmount)*10**7).toFixed()}
        </div>
      </div>
    );
  }

  if (hasBuyOffer && hasAsset) {
    createSellOfferBody = (
      <div>
        <strong>
        {t('singleNFT.31', 'Cancel trading buy offers for selling')}
        </strong>
      </div>
    );
  }

  if (userSellOffersAmount > 0 && userSellOffersAmount >= Number(hasAmount)) {
    createSellOfferBody = (
      <div>
        <strong>
        {t('singleNFT.32', 'Cancel trading sell offers for selling')}
        </strong>
      </div>
    );
  }


  let nftSellOfflerListBody;

  if (assetSellOffers.length === 0) {
      nftSellOfflerListBody = (
      <span>
        {t('singleNFT.11', 'no trading sell offer exist')}
      </span>
      );
  }

 if (assetSellOffers.length > 0) {
  // console.log('assetSellOffers', assetSellOffers);
    if (!showSellList) {
      nftSellOfflerListBody = null;
    } else {
    
    let displaySellOffers = assetSellOffers;

    if (termAssetList) {
      
      const withUSDPriceSellOffers = addUSDPriceInOffers(
          assetSellOffers, 
          termAssetList.assets,
      );
  
      displaySellOffers = _.orderBy(withUSDPriceSellOffers, 'offerPriceUSD', 'asc');
    }

      nftSellOfflerListBody = (
          <ul>
            {displaySellOffers.map(offer => (
                <div key={offer.id}>
                  <NftSellOfferListItem 
                    nft={nft}
                    offer={offer}
                    // assetBuyOffers={assetBuyOffers}
                    // assetSellOffers={assetSellOffers}
                    // accountData={accountData}
                    // hasAsset={hasAsset}
                    // hasAmount={hasAmount}
                    hasSellOffer={hasSellOffer}
                    hasBuyOffer={hasBuyOffer}
                    // buyCode={buyCode}
                    // buyNftHandler={buyNftHandler}
                    getAssetBuyOffersHandler={getAssetBuyOffersHandler}
                    getAssetSellOffersHandler={getAssetSellOffersHandler}
                    isLoading={isLoading}
                  />
                </div>
              ))}
          </ul>
        );
    }

 }


  return (
    <Fragment>
      <div>
        <div>
          {t('singleNFT.05', 'trading sell offers')}
        </div>

        {assetSellOffers.length > 0 && !showSellList && (
          <span onClick={() => {
            setShowSellList(true); 
            }}
          >
            {assetSellOffers.length} sell-offer  {marks.triangleDown}
          </span>
        )}

        {/* {assetSellOffers.length > 0 && showSellList && (
          <span onClick={() => { setShowSellList(false); }}>
            hide-sell-list {marks.triangle}
          </span>
        )} */}

        {nftSellOfflerListBody}

        <div className={classes.nftSellOfferListCreateButton}>
          {createSellOfferBody}
        </div>

        <GetAssetsTomlData 
          // listForToml={buyInputAssetList} 
        />
      </div>
    </Fragment>
  );
}

export default NftSellOfferList;
