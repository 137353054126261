import React, { useState, useEffect, Fragment } from 'react';
// import axios from 'axios';
// import albedo from '@albedo-link/intent'

// import AlbedoLogin from '../Albedo/AlbedoLogin';

import { useStore } from '../../../hook-store/store';

// import * as fileUtils from '../../../utils/file-util';
// import * as uploadUtils from '../../../utils/bucket-upload-util';
// import * as nftDataUtils from '../../../utils/nft-data-util';


function FileUploadPreview(props) {
  const { 
    filePreviews,
    selectedFiles,
  } = props;

  const [hStore, hDispatch] = useStore();
  // const nftList = store.nftStore.nftList;
  // const albedoPubkeyInfo = store.uiStore.albedoPubkeyInfo;


  let filePreviewBody;

  if (selectedFiles && selectedFiles.length > 0 &&
    filePreviews && filePreviews.length > 0
  ) {
    if (selectedFiles[0].type.split('/')[0] === 'image') {
      filePreviewBody = (
        <img style={{height:"200px"}} 
          src={filePreviews[0]} 
          alt="file preview"
        />
      );
    }

    if (selectedFiles[0].type.split('/')[0] === 'video') {
      filePreviewBody = (
        <video style={{height:"200px"}} 
          src={filePreviews[0]} 
          alt="file preview"
          controls
          muted
        />
      );
    }

    if (selectedFiles[0].type.split('/')[0] === 'audio') {
      filePreviewBody = (
        <audio
          src={filePreviews[0]} 
          alt="file preview"
          controls
        />
      );
    }

  }
  return (
    <Fragment>
      <div>{filePreviewBody}</div>
    </Fragment>
  );
}

export default FileUploadPreview;
