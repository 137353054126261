import React, { Fragment } from "react";
import { CopyText, Identicon } from '@stellar/design-system';

import classes from './StellarAddress.module.css';

function StellarAddress(props) {
    const { 
      stellarId,
      iconSize,
    } = props;

    let stellarAddressBody;

    if (stellarId) {
      stellarAddressBody = (
        <span className={classes.stellarAddressContainer}>
          <span title={stellarId}>
          <Identicon
            publicAddress={stellarId}
            shortenAddress
            size={iconSize || '1.75rem'}
          />
          </span>
          <CopyText
            showTooltip
            textToCopy={stellarId}
            tooltipPosition="top"
          >
            (copy)
          </CopyText>
        </span>
      );
    }
    return (
      <Fragment>
        {stellarAddressBody}
      </Fragment>
    );
}

export default StellarAddress;