import React from "react";
// import { useDispatch } from "react-redux";

// import { PaymentFormData } from "types/types";
// import { resetSendTxAction } from "ducks/sendTx";
// import { lumensFromStroops } from "helpers/stroopConversion";

import CreateTx from './CreateTx.jsx';
// import FailedTx from "../FailedTx.jsx";
import Loader from "../Loader/Loader.jsx";

import classes from './TxFlow.module.css';

const TxFlow = (props) => {
  const { 
    // onCancel, 
    createTxBody, 
    isLoading,
  } = props;

  // const dispatch = useDispatch();

  // const [currentStage, setCurrentStage] = useState(SendState.CREATE);
//   const [formData, setFormData] = useState(initialFormData);
//   const [maxFee, setMaxFee] = useState(
//     lumensFromStroops(BASE_FEE).toString());

  // const handleBack = () => {
  //   setCurrentStage(SendState.CREATE);
  //   dispatch(resetSendTxAction());
  // };

  return (
    <>
          <CreateTx 
            // onEditTransaction={console.log('')}
            // onContinue={() => {
            //   // setCurrentStage(currentStage + 1);
            // }}
            // onCancel={onCancel}
            isLoading={isLoading}
          >
            <div>
              {createTxBody}

              {isLoading && (
                  <div><Loader /></div>
              )}
              
            </div>
          </CreateTx>
      {/* {currentStage === SendState.CREATE && (
        // <CreateTransaction
        //   onContinue={(newFormData) => {
        //     setFormData(newFormData);
        //     setCurrentStage(currentStage + 1);
        //   }}
        //   onCancel={onCancel}
        //   initialFormData={formData}
        //   setMaxFee={setMaxFee}
        //   maxFee={maxFee}
        // />
          <CreateTx 
            onEditTransaction={console.log('')}
            onContinue={() => {
              // setCurrentStage(currentStage + 1);
            }}
            onCancel={onCancel}
          >
            <div>
              {createTxBody}

              {isLoading && (<Loader />)}
            </div>
          </CreateTx>
        // <div>
        //     create-trans-action
        // </div>
      )} */}

      {/* {currentStage === SendState.CONFIRM && (
        // <ConfirmTransaction
        //   onSuccessfulTx={() => {
        //     setCurrentStage(SendState.SUCCESS);
        //   }}
        //   onFailedTx={() => {
        //     setCurrentStage(SendState.ERROR);
        //   }}
        //   onBack={handleBack}
        //   formData={formData}
        //   maxFee={maxFee}
        // />
        <div>confirm-transaction</div>
      )} */}

      {/* {currentStage === SendState.SUCCESS && (
        // <SuccessfulTransaction
        //   onRestartFlow={() => {
        //     setFormData(initialFormData);
        //     setCurrentStage(SendState.CREATE);
        //     dispatch(resetSendTxAction());
        //   }}
        //   onCancel={onCancel}
        // />
        <div>success-tx</div>
      )} */}

      {/* {currentStage === SendState.ERROR && (
        // eslint-disable-next-line max-len
        <FailedTx onEditTransaction={handleBack} onCancel={onCancel} />
        // <div>failed-tx</div>
      )} */}
    </>
  );
};

export default TxFlow;
