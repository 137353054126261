import React, { Fragment, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import * as StellarSdk from "@stellar/stellar-sdk";
import { Button } from "@stellar/design-system";
import { useTranslation } from 'react-i18next';

import { useDispatch } from "react-redux";
import { useRedux } from "hooks/useRedux";

import { logEvent } from "../../helpers/tracking";
import { getNetworkConfig } from "../../helpers/getNetworkConfig";
import { sendTxAction } from "../../ducks/sendTx";

import i18n from '../../i18n';
// import AuthButtons from "./Auth/AuthButtons.jsx";
// import AuthCheck from "./Auth/AuthCheck.jsx";
import FileUpload from "./FileUpload/FileUpload.jsx";
// import GetAccountAssetInfo from "./UiComponent/GetAccountAssetInfo.jsx";
// import GetAuth from "./Auth/GetAuth.jsx";
// import GetBackend from "./UiComponent/GetBackend.jsx";
import NftList from "./NftList/NftList.jsx";
import Notification from "./Notification/Notification.jsx";

import { useStore } from "../../hook-store/store";
import { getUserData } from '../../utils/user';
import { BACKEND_URL, loginPageLink, signupPageLink } from '../../urlLinks';
import UserNftList from "./UserNftList/UserNftList.jsx";
import UserInfo from "./pages/UserInfo/UserInfo.jsx";
// import NftSearchDisplay from "./NftList/NftSearch/NftSearchDisplay.jsx";
import NftSearch from "./NftList/NftSearch/NftSearch.jsx";
import WideAds from "./Ads/WideAds.jsx";
// import GetUsers from "./UiComponent/GetUsers.jsx";

// import ModalSimple from "./Modal/ModalSimple.jsx";

import classes from './IssuerFrontend.module.css';


function IssuerFrontend() {

    const dispatch = useDispatch();

    const [t] = useTranslation('translation');

    const { account, memoRequiredAccounts, settings } = useRedux(
        "account",
        "memoRequiredAccounts",
        "settings",
    );

    // const navigate = useNavigate();
    const location = useLocation();

    const [hStore, hDispatch] = useStore();
    const userData = hStore.uiStore.userData;
    const userList = hStore.uiStore.userList;
    const isAuth = hStore.uiStore.isAuth;

    const [showUserList, setShowUserList] = useState(false);

    let returnPath = '';
    
    if (location.pathname) {
        returnPath = location.pathname.substring(1) + location.search;
    }

    // // show nft lists depend on pathname
    useEffect(() => {
        // console.log('location', location);
        if (location && location.pathname === '/user-uploads' && 
                location.search) {
            setShowUserList(true);
        } else {
            setShowUserList(false);
        }

    },[location]);

    useEffect(() => {
        const getUserDataHandler = async(url, token) => {
            try {
              const result = await getUserData(url, token);
        
              console.log(result);
        
              if (result.data) {
                // setUserData(result.data);
                hDispatch('SET_USERDATA', result.data);
              }
            } catch(err) {
              console.log(err);
            }
          };

        if (isAuth && !userData) {
            getUserDataHandler(BACKEND_URL, localStorage.getItem('token'));
        }
    },[isAuth, userData]);


    useEffect(() => {
        const lsUserSelectLng = localStorage.getItem('userSelectLng');
        if (lsUserSelectLng) {
          // console.log(lsUserSelectLng);
          i18n.changeLanguage(lsUserSelectLng);
        }
    },[]);


    const getTxXdrHandler = async (url, token) => {
        try {
            const result = await fetch(`${url}/token-tx/test`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                // body: JSON.stringify(inputData),
            });

            const resData = await result.json();

            console.log(result, resData);

            if (!result.ok) {
                throw new Error('error-occured');
            }

            const xdrTx = resData.data;

            const transaction = new StellarSdk.Transaction(
                xdrTx,
                getNetworkConfig(settings.isTestnet).network,
            );
            // console.log(transaction);

            dispatch(sendTxAction(transaction));
            logEvent("testTx: testTx transaction", {
                txOperations: transaction._operations,
                // amount: formData.amount.toString(),
                //   "used federation address": !!formData.federationAddress,
                //   "used memo": !!formData.memoContent,
            });

            return resData;
        } catch (err) {
            console.log(err);
            throw err;
        }
    };

    let nftListBody = (
        <div>
            <div>
                <WideAds />
            </div>

            {!showUserList && (
                <div>
                    <NftSearch />
                </div>
            )}
            <div>
                <NftList />
            </div>
        </div>
    );

    let listStyle = {
        fontWeight: "bold",
        textDecoration: 'underline',
    };
    let userListStyle = null;

    if (showUserList) {
        listStyle = null;

        userListStyle = {
            fontWeight:"bold",
            textDecoration: 'underline',
        };
    }

    if (isAuth && userData) {
        nftListBody = (
            <div>
                <div>
                    <WideAds />
                </div>

                {!showUserList && (
                    <div>
                        <NftSearch />
                    </div>
                )}

                <div className={classes.issuerFrontendListButtons}>
                    <Link to='/uploads'>
                        <Button 
                            style={listStyle}
                            variant="tertiary"
                            // size="small"
                            onClick={() => {
                            setShowUserList(false); 
                            }}
                        >
                            {t('NFTlist.01', 'NFT List')}
                        </Button>
                    </Link>
                    <Link to={`/user-uploads?_id=${userData._id}`}>
                        <Button 
                            style={userListStyle}
                            variant="tertiary"
                            // size="small"
                            onClick={() => {
                            setShowUserList(true); 
                            }}
                        >
                            {t('NFTlist.02', 'User NFT List')}
                        </Button>
                    </Link>
                </div>

                {showUserList && (
                    <UserNftList />
                )}

                {!showUserList && (
                    <div>
                    <NftList />
                    </div>
                )}
                
            </div>
        );
    }

    // if (isAuth && userData && showUserList) { 
    //     nftListBody = (
    //         <div>
    //             <div className={classes.issuerFrontendListButton}>
    //                 <Link to='/uploads'>
    //                     <Button 
    //                         variant="tertiary"
    //                         // size="small"
    //                         onClick={() => {
    //                         setShowUserList(false); 
    //                         }}
    //                     >
    //                         NFT List
    //                     </Button>
    //                 </Link>
    //             </div>
    //             <div>
    //                 <UserNftList />
    //             </div>
    //         </div>
    //     );
    // }


    return (
        <Fragment>
            {/* <div>issuer-frontend</div> */}
   
            {/* <AuthButtons /> */}

            <section>
                <FileUpload />
            </section>

            <section>
                {nftListBody}
            </section>

            {hStore.uiStore.notification && (
                <div>
                <Notification 
                    status={hStore.uiStore.notification.status}
                    title={hStore.uiStore.notification.title}
                    message={hStore.uiStore.notification.message}
                />
                </div>
            )}
            
            {/* <AuthCheck />
            <GetAuth /> */}

            {/* <GetAccountAssetInfo /> */}
            <div style={{display: 'none'}}>
                <UserInfo />
            </div>

            {/* <GetUsers /> */}
            {/* <GetBackend /> */}
        </Fragment>
    );
}

export default IssuerFrontend;