import React, { Fragment, useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { Button, Textarea } from "@stellar/design-system";
import { useTranslation } from 'react-i18next';

// import moment from 'moment';

// import ModalSimple from "../Modal/ModalSimple.jsx";
import ImageEditorLink from "../pages/AboutPage/ImageEditorLink.jsx";
import Loader from "../Loader/Loader.jsx";

import { useStore } from "../../../hook-store/store";
import { generateBase64FromImage } from '../../../utils/file-util';
// import { getUserData } from "../../../../utils/user";
import { BACKEND_URL } from "../../../urlLinks";

import classes from './UserImageUploadContent.module.css';

const UserImageUploadContent = (props) => {
  const { 
    userData,
    setShowUploadModal,
    // imageFile,
    // setImageFile,
    userImageUploadHandler,
    deleteUserImageHandler,
    action,
    isLoading,
   } = props;

   const [t] = useTranslation('translation');

  const [hStore, hDispatch] = useStore();
  // const userData = hStore.uiStore.userData;
  // const isAdmin = store.uiStore.isAdmin;

  const [imageFile, setImageFile] = useState();
  const [imagePreview, setImagePreview] = useState();
  // const [userData, setUserData] = useState();

  useEffect(() => {
    // getUserDataHandler(BACKEND_URL, localStorage.getItem('token'));
  }, []);

  const setImageHandler = async (event) => {
    console.log(event.target.files);
    setImageFile(event.target.files[0]);

    const b64 = await generateBase64FromImage(event.target.files[0]);
    setImagePreview(b64);
  };


  let userImageUploadContentBody;

  if (userData && action === 'upload') {
    userImageUploadContentBody = (
        <div>
          <div className={classes.userImageClose}>
            <span
              className={classes.userImageCloseButton}
              onClick={() => {
                setShowUploadModal(false); 
              }}
            >
              X
            </span>
          </div>

          <div className={classes.userImageUploadContent}>
            <strong>
              {t('userinfo.text19', 'Select Image for Update')}
            </strong> 
            <br/>
            <span className={classes.userImageImageEditorLink}>
              ({t('userinfo.text20', 'Maximum 100KB')}, jpeg, png or webp)
            </span>
          </div>

          <div className={classes.userImageUploadContent}>
            <input 
              type='file' 
              accept=".jpeg,.jpg,.png,.webp"
              onChange={setImageHandler}
            />
            <div className={classes.userImageImageEditorLink}>
              <ImageEditorLink />
            </div>
          </div>
          {imagePreview && (
            <div className={classes.userImageUploadContent}>
              <img 
                className={classes.userImagePreview}
                src={imagePreview}
                alt="preview"
              />
            </div>
          )}
          <div>
            <Button
              style={{border: "0.25px solid gray"}}
              variant="tertiary"
              size="small"
              disabled={
                isLoading || !imagePreview || 
                !imageFile || imageFile.size > 10**6
              }
              onClick={() => {
                userImageUploadHandler(imageFile);
              }}
            >
              {t('userinfo.text2', 'update')}
            </Button>
          </div>
        </div>
    );
  }

  if (userData && action === 'delete') {
    userImageUploadContentBody = (
      <div>
        <div className={classes.userImageClose}>
          <span
            className={classes.userImageCloseButton}
            onClick={() => {
              setShowUploadModal(false); 
            }}
          >
            X
          </span>
        </div>

        <div className={classes.userImageUploadContent}>
          {t('userinfo.text21', 'delete image?')}
        </div>
        <div>
          <Button
            style={{border: "0.25px solid gray"}}
            variant="tertiary"
            size="small"
            disabled={isLoading}
            onClick={() => {
              deleteUserImageHandler();
            }}
          >
            {t('userinfo.text22', 'delete')}
          </Button>
        </div>
      </div>
    );
  }
 

  return <Fragment>
    <div>
      {userImageUploadContentBody}
    </div>

    {isLoading && (<Loader />)}
  </Fragment>;
};

export default UserImageUploadContent;
