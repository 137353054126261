import React, { Fragment, useEffect, useState } from "react";

// import { logoutHandler } from "../../../../utils/user/user";
import { logoutHandler, getUserData } from "../../../utils/user";
import { useStore } from '../../../hook-store/store';
import { BACKEND_URL } from "../../../urlLinks";

const AuthCheck = (props) => {
  // const { } = props;

  const [hStore, hDispatch] = useStore();
  const isAuth = hStore.uiStore.isAuth;

  const [authCheckStart, setAuthCheckStart] = useState(false);

  useEffect(() => {
    if (!authCheckStart) {
      setAuthCheckStart(true);

      if (localStorage.getItem('token')) {
        authCheck();
      }
      
      setInterval(() => {
        // console.log('in setInterval')
        // console.log('lsToken', localStorage.getItem('token'));
        if (localStorage.getItem('token')) {
          authCheck();
        }
      }, 1000 * 60 * 60);
    }

  }, []);

  const authCheck = () => {
    //// check auth validity and logout
      fetch(BACKEND_URL + "/auth/auth-check", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          console.log(res);
  
          //// logout when invalid auth
          if (res.status === 401) {

            hDispatch('SET_ISAUTH', false);
            hDispatch('SET_USERDATA', null);
            logoutHandler();
          }
          if (res.status !== 200) {
            throw new Error("Failed to fetch user status.");
          }
          return res.json();
        })
        .then((resData) => {
          console.log(resData);
          hDispatch('SET_ISAUTH', true);
        })
        .catch((err) => {
          console.log(err);
        });
    
  };

  useEffect(() => {
    const getUserDataHandler = async(url, token) => {
      try {
        const result = await getUserData(url, token);
  
        console.log(result);
  
        if (result.data) {
          // setUserData(result.data);
          hDispatch('SET_USERDATA', result.data);
        }
      } catch(err) {
        console.log(err);
      }
    };

    if (isAuth) {
        getUserDataHandler(BACKEND_URL, localStorage.getItem('token'));
    }
  },[isAuth]);

  return <Fragment></Fragment>;
};

export default AuthCheck;
