/* eslint-disable max-len */
// const React = window.React = require('react');
import React, { Fragment } from "react";

import { useTranslation } from "react-i18next";

import ImageEditorLink from "./ImageEditorLink.jsx";
// import "./TermsOfUse.css";
import classes from './AboutPage.module.css';

const AboutPage = (props) => {

  const [t] = useTranslation("translation");

  return <Fragment>
    <div className={classes.aboutPage}>
      <div className={classes.aboutPageTitle}>
        About Stellar NFT Place
      </div>
      <p>
        
        {t('aboutPage.02', 'In this site, you can create, display, and trade NFT.')}
      </p>

      <p>
        {t('aboutPage.03', 'This site is NFT issueing and transaction platform using blockchain based StellarNetwork.')}
      </p>

      <p>
        <ImageEditorLink />
      </p>

      <div>
        <div>
          NFT
        </div>
        <p>
          {t('aboutPage.04', 'The ownership of an NFT is recorded in the blockchain, and can be sold and traded. ')}
          {' '}
          {t('aboutPage.05', 'NFTs can be created by anybody.')}
          {' '}
          
          {t('aboutPage.06', 'NFTs usually contain references to digital files such as photos, videos, and audio.')}
          {' '}
          {t('aboutPage.07', 'NFTs are uniquely identifiable assets.')}
        </p>
      </div>
    </div>
  </Fragment>;
};
export default AboutPage;
