import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRedux } from "hooks/useRedux";

import CreateNftBuyOffer from './CreateNftBuyOffer.jsx';
// import CreateNftSellOffer from '../CreateNftSellOffer/CreateNftSellOffer.jsx';
import CreateNftSellOfferCancel from '../CreateNftSellOffer/CreateNftSellOfferCancel.jsx';

import { useStore } from '../../../../hook-store/store';

import xlmLogo from "../../../../images/stellar-xlm-logo.png";
import classes from './NftSellOfferListItem.module.css';

function NftSellOfferListItem(props) {
  const { 
    nft,
    offer, 
    // assetBuyOffers,
    // assetSellOffers,
    // accountData,
    // hasAsset,
    // hasAmount,
    hasSellOffer,
    hasBuyOffer,
    // buyCode,
    // buyNftHandler,
    getAssetBuyOffersHandler,
    getAssetSellOffersHandler,
    isLoading,
    // isLoading
  } = props;

  // console.log('NftSellOfferListItem-props', props);
  const [t] = useTranslation('translation');

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    "memoRequiredAccounts",
    "settings",
    "sendTx",
  );
  // const { status, errorString } = sendTx;

  const publicKey = account.data ? account.data.id : '';


  const [hStore, hDispatch] = useStore();
  const { 
    forSellOfferAssets,
    termAssetList,
  } = hStore.nftStore;


  // let hasSellOffer = false;
  // let hasBuyOffer = false;


  // if (assetSellOffers && assetSellOffers.length > 0) {
  //   const isPublicKeySellOffer = assetSellOffers.find(sOffer => {
  //     return sOffer.seller === publicKey;
  //   });

  //   if (isPublicKeySellOffer) {
  //     hasSellOffer = true;
  //   }
  // }

  // if (assetBuyOffers && assetBuyOffers.length > 0) {
  //   const isPublicKeyBuyOffer = assetBuyOffers.find(bOffer => {
  //     return bOffer.seller === publicKey;
  //   });

  //   if (isPublicKeyBuyOffer) {
  //     hasBuyOffer = true;
  //   }
  // }


  let cancelSellOfferBody;

  if (hasSellOffer && offer && publicKey === offer.seller) {
      cancelSellOfferBody = (
        <div>
          <CreateNftSellOfferCancel 
            nft={nft}
            offer={offer}
            // createCancelNftSellOfferHandler={createCancelNftSellOfferHandler}
            getAssetSellOffersHandler={getAssetSellOffersHandler}
            isLoading={isLoading}
          />
        </div>
      );
    }


  let nftSellOfferListItemBody;

  if (offer) { 

    let termAssetInfo;
    
    let buyCode;
    let buyLogoUrl;

    if (offer.buying.asset_type === 'native') {
      buyCode = 'XLM';
      buyLogoUrl = xlmLogo;

      if (termAssetList) {
        termAssetInfo = termAssetList.assets.find(ele => {
          return ele.code === 'XLM' && !ele.issuer;
        });
      }

    } else {
      buyCode = offer.buying.asset_code;

      const assetForLogo = forSellOfferAssets.find(asset => {
        return asset.code === offer.buying.asset_code && 
          asset.issuer === offer.buying.asset_issuer; 
      });

      if (assetForLogo && assetForLogo.tomlData) {
        buyLogoUrl = assetForLogo.tomlData.image;
      }


      if (termAssetList) {
        termAssetInfo = termAssetList.assets.find(ele => {
          return ele.code === offer.buying.asset_code && 
          ele.issuer === offer.buying.asset_issuer
        });
      }

    }

    // console.log('offer', offer);
    nftSellOfferListItemBody = (
      <div>
        <div>
          {t('singleNFT.25', 'amount')}: {(offer.amount*10000000).toFixed(0)} ({offer.amount})
          <br/>
          <span className={classes.nftSellOfferItemPrice}>
            <span>
              {t('singleNFT.26', 'price')}: {( Number(offer.price)*0.0000001 ).toFixed(7)} {buyCode} 
            </span>
            <img
              className={classes.nftSellOfferItemLogo} 
              src={buyLogoUrl} 
              alt="asset-logo"
            />
          </span>
          {termAssetInfo && (
            <span>
              ( ~ {(termAssetInfo.price_USD*Number(offer.price)*0.0000001).toFixed(2)} USD)
            </span>
          )}
          {/* <br/>
          <span>{offer.seller.slice(0,4)}....{offer.seller.slice(-4)}</span> */}
        </div>

        {publicKey !== offer.seller && !hasSellOffer && (
          <div>
            <CreateNftBuyOffer 
              nft={nft}
              offer={offer}
              buyCode={buyCode}
              // buyNftHandler={buyNftHandler}
              getAssetBuyOffersHandler={getAssetBuyOffersHandler}
              getAssetSellOffersHandler={getAssetSellOffersHandler}
              isLoading={isLoading}
            />
          </div>
        )}

        {publicKey !== offer.seller && hasSellOffer && (
          <div>
            <strong>
              {t('singleNFT.27', 'Cancel trading sell offers for buying')}
            </strong>
          </div>
        )}
        

        {publicKey === offer.seller && (
          <div>
            <strong>
              {t('singleNFT.28', 'Your trading sell offer')}
            </strong>
          </div>
        )}


        <div>{cancelSellOfferBody}</div>
    
      </div>
    );
  }


  return (
    <Fragment>
      <div className={classes.nftSellOfferItem}>
        {/* {nftOfferBody} */}
        {/* <div>nft-sell-offer-list-item</div> */}
        {nftSellOfferListItemBody}
      </div>
    </Fragment>
  );
}

export default NftSellOfferListItem;
