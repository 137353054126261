import React, { useState, useEffect, Fragment } from 'react';
// import axios from 'axios';
import { useDispatch } from "react-redux";
// import StellarSdk from 'stellar-sdk';
import {
  Button,
  InfoBlock,
} from "@stellar/design-system";
import { useTranslation } from 'react-i18next';

import { useRedux } from "hooks/useRedux";


import ImageEditorLink from '../pages/AboutPage/ImageEditorLink.jsx';

import { useStore } from '../../../hook-store/store';


import classes from './IssueDescription.module.css';

function IssueDescription(props) {

  const [t] = useTranslation('translation');

  const dispatch = useDispatch();

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    "memoRequiredAccounts",
    "settings",
    "sendTx",
  );
  const { status, errorString } = sendTx;

  const publicKey = account.data ? account.data.id : '';


  const [hStore, hDispatch] = useStore();
  // const nftList = hStore.nftStore.nftList;
  // const txXdr = hStore.nftStore.txXdr;
  // const feeStats = hStore.nftStore.feeStats;


  const issueDescriptionBody =(
    <div className={classes.issueDescription}>
      <InfoBlock>
        <br/>
        <div className={classes.issueDescriptionTitle}>
          {t('CreateNFT.08', ' NFT (asset) is created and sent to your wallet after 3 steps.')}
        </div>
        <div className={classes.issueDescriptionContent}>
          1. {t('CreateNFT.09', 'Upload file')}
        </div>
        <div className={classes.issueDescriptionContent}>
          2. {t('CreateNFT.10', 'Create asset issuer account,')}
          {' '}
          and create trustline to the asset (change trust). 
          {' '}
          {t('CreateNFT.11', 'This step requires confirmation by wallet.')}
        </div>
        <div className={classes.issueDescriptionContent}>
          3. {t('CreateNFT.12', 'Send asset from issuer account to your wallet (Automatically start after step 2 finished).')}
        </div>

        <p><ImageEditorLink /></p>
      </InfoBlock>
    </div>
  );


  return (
    <Fragment>
      {issueDescriptionBody}
    </Fragment>
  );
}

export default IssueDescription;
