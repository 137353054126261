import axios from 'axios';
import albedo from '@albedo-link/intent';

import { BACKEND_URL } from '../../urlLinks';

export const s3fileUpload = (
  url, 
  token, 
  selectedFiles, 
  inputData,
  ) => new Promise((resolve, reject) => {
    const data = new FormData(); 

    const file = selectedFiles[0];
    // console.log(selectedFiles);
    // for (const file of selectedFiles) {
    //   data.append('files', file);
    // }

    // data.append('files', selectedFiles);

    data.append('image', file);

    // data.append('storePath', path);
    data.append('name', inputData.name);
    data.append('title', inputData.title);
    data.append('amount', inputData.amount);
    // data.append('creator', inputData.creator);
    data.append('description', inputData.description);
    data.append('homeDomain', inputData.homeDomain);

    data.append('ownerSecret', inputData.ownerSecret);
    // data.append('bucket', bucketName);
    
    // console.log(selectedFiles, inputData);
    // return;


    const sendUrl = createUploadUrl(url, file);

    // console.log('data', data, data.getAll('files'));
    const headers = {
      // 'Content-Type': 'application/json',
      'Authorization': `Bearer ${  token}`,
    };
  
    // axios.post(url + "/bucket/object", data, { 
    axios.post(sendUrl, data, {  
      headers,
      // receive two    parameter endpoint url ,form data
    })
    .then(res => { // then print response status
      // console.log(res);
      resolve(res);
    }).catch(err => {
      console.log(err);
      reject(err);
    });
  });


export const imageCidUrlFileUpload = (
  url, 
  token, 
  selectedFile, 
  inputData,
) => new Promise((resolve, reject) => {
    const data = new FormData(); 

    const file = selectedFile;
    console.log(file);
    
    data.append('image', file);

    // data.append('storePath', path);
    data.append('name', inputData.imageCidName);
    data.append('title', inputData.title);
    data.append('amount', inputData.amount);
    // data.append('creator', inputData.creator);
    data.append('description', inputData.description);
    data.append('homeDomain', inputData.homeDomain);
    data.append('imageCidUrl', inputData.imageCidUrl);

    data.append('ownerSecret', inputData.ownerSecret);
    // data.append('bucket', bucketName);
    
    // console.log(selectedFiles, inputData);
    // return;
    

    const sendUrl = createUploadUrl(url, file);

    // console.log('data', data, data.getAll('files'));
    const headers = {
      // 'Content-Type': 'application/json',
      'Authorization': `Bearer ${  token}`,
    };
  
    // axios.post(url + "/bucket/object", data, { 
    axios.post(sendUrl, data, {  
      headers,
      // receive two    parameter endpoint url ,form data
    })
    .then(res => { // then print response status
      // console.log(res);
      resolve(res);
    }).catch(err => {
      console.log(err);
      reject(err);
    });
  });


const createUploadUrl = (url, file) => {
  let sendUrl = `${url  }/token/issue`;

  if (file && file.type.split('/')[0] === 'image') {
    sendUrl = `${url  }/token/issue-image`;
  }

  if (file && file.type.split('/')[0] === 'video') {
    sendUrl = `${url  }/token/issue-video`;
  }

  if (file && file.type.split('/')[0] === 'audio') {
    sendUrl = `${url  }/token/issue-audio`;
  }

  return sendUrl;
};


export function isUrl(s) {
  // eslint-disable-next-line no-useless-escape
  const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  return regexp.test(s);
}


export async function convertToFile(url, fileName){
  try {
    // console.log('url', url);
    const response = await fetch(url);

    // console.log('response',response);
    if (response.ok) {
      const blob = await response.blob();
      
      return new File([blob], `${fileName}.${blob.type.split('/')[1]}`, {
        type: blob.type,
      });
    } 
      throw new Error('something wrong');
    

  } catch(err) {
    console.log(err);
    throw err;
  }
}


export const albedoNftIssue = async (
  url, 
  token, 
  selectedFiles, 
  inputData, 
  accountId,
) => {

  let imageUrlObj;

  try{

    // console.log(inputData, accountId);
    // throw new Error('error-error');
    // console.log(selectedFiles);

    const data = new FormData(); 

    
    if (!inputData.imageCidUrl) {
      const file = selectedFiles[0];
      data.append('image', file);
      data.append('name', inputData.name);
    }

    if (inputData.imageCidUrl) {
      const file = selectedFiles;
      data.append('image', file);
      data.append('imageCidUrl', inputData.imageCidUrl);
      data.append('name', inputData.imageCidName);
    }

    // data.append('storePath', path);
    // data.append('name', inputData.name);
    data.append('title', inputData.title);
    data.append('amount', inputData.amount);
    // data.append('creator', inputData.creator);
    data.append('description', inputData.description);
    data.append('homeDomain', inputData.homeDomain);

    data.append('ownerSecret', inputData.ownerSecret);
    data.append('code', inputData.code);

    data.append('initOwnId', inputData.initOwnId);

    const resultObj = {
      upload: null,
      create: null,
      send: null,
    };

    const headers = {
      // 'Content-Type': 'application/json',
      'Authorization': `Bearer ${  token}`,
    };
    const uploadResult = await axios.post(`${url  }/token-albedo/store-file`, data, {  
      headers,
      // receive two    parameter endpoint url ,form data
    });

    console.log(uploadResult);

    resultObj.upload = uploadResult;

    const uploadData = uploadResult.data.data;
    // const fileResData = await fileResult.json();
    // console.log(fileResult, fileResData);

    imageUrlObj = uploadData.imageUrlObj;

    // return;
    if (!uploadData) {
      throw new Error('error-occured');
    }

    const result = await fetch(`${url  }/token-albedo/create-fund-trust-xdr`, {
    // const result = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${  token}`,
    },
    body: JSON.stringify({
      accountId,
      data: uploadData.data,
      imageUrlObj: uploadData.imageUrlObj,
    }),
  });

  const resData = await result.json();
  console.log(result, resData);

  resultObj.create = resData;

  if (result.ok) {

    if (resData) {
      // console.log(resData);

      const albedoResult = await albedo.tx({
        xdr: resData.data.xdr,
        description: 'Issue NFT trsansactions',
        pubkey: accountId,
        // network: 'testnet',
        submit: true,
      });

      console.log(albedoResult);

      if (albedoResult) {
        /// / send nft to owner, store issue data in db ipfs
        // const keyPair = resData.data.tokenKeyPair;
        const tokenSecret = resData.data.tokenSecret;
        const tokenCode = resData.data.data.code;
        // const tokenData = resData.data.data;
        // const imageUrlObj = resData.data.imageUrlObj;
        // console.log(keyPair);

        const result2 = await fetch(`${url  }/token-albedo/send-store-nft-data`, {
          // const result = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${  token}`,
          },
          body: JSON.stringify({
            ownerAccountId: accountId,
            // keyPair: keyPair,
            tokenSecret,
            code: tokenCode,
            data: resData.data,
            // imageUrlObj: imageUrlObj,
          }),
        });
    
        const resData2 = await result2.json();
        console.log(result2, resData2);

        resultObj.send = resData2;
      }
    }

    return {
      message: 'token issued',
      results: resultObj,
    };

  } 
    throw new Error('something wrong');


} catch(err) {
  console.log(err);

  /// / delete files
  await deleteFile(
    BACKEND_URL,
    localStorage.getItem('token'),
    imageUrlObj,
  );

  throw err;
}
};


export const deleteFile = async (url, token, imageUrlObj) => {
  try {
    // console.log('imageUrlObj', imageUrlObj);
    const result = await fetch(`${url  }/token-albedo/delete-file?imageUrlObj=${JSON.stringify(imageUrlObj)}`, {
      // const result = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
      // body: JSON.stringify({
      //   accountId: accountId,
      //   data: uploadData.data,
      //   imageUrlObj: uploadData.imageUrlObj,
      // })
    });
  
    const resData = await result.json();
    console.log(result, resData);
  
  } catch(err) {
    console.log(err);
    throw err;
  }
};


export const updateTokenInfo = (
  url, 
  token, 
  selectedFile, 
  inputData,
) => new Promise((resolve, reject) => {

    console.log('inputData', inputData);

    fetch(`${url  }/token/update-token-info`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
      body: JSON.stringify(inputData),
    })
    .then(res => { // then print response status
      // console.log(res);
      if (!res.ok) {
        throw new Error('error occured');
      }
      return res.json();
    })
    .then(resData => {
      resolve(resData);
    }).catch(err => {
      console.log(err);
      reject(err);
    });
  });


  export const postDeleteTokenFiles = (
    url, 
    token, 
    nId, 
    imageUrlObj,
    userAccountId,
  ) => new Promise((resolve, reject) => {
  
      // console.log('inputData', inputData);
  
      fetch(`${url  }/token/delete-token-files`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${  token}`,
        },
        body: JSON.stringify({
          nId,
          imageUrlObj,
          userAccountId,
        }),
      })
      .then(res => { // then print response status
        // console.log(res);
        if (!res.ok) {
          throw new Error('error occured');
        }
        return res.json();
      })
      .then(resData => {
        resolve(resData);
      }).catch(err => {
        console.log(err);
        reject(err);
      });
    });


export const assetIssue = (
  url, 
  token, 
  selectedFiles, 
  inputData,
) => new Promise((resolve, reject) => {
    const data = new FormData(); 

    data.append('image', selectedFiles[0]);

    // data.append('storePath', path);
    data.append('code', inputData.code);
    data.append('issuerPubKey', inputData.issuerPubKey);
    // data.append('name', inputData.name);
    data.append('title', inputData.title);
    data.append('amount', inputData.amount);
    // data.append('creator', inputData.creator);
    data.append('description', inputData.description);
    data.append('homeDomain', inputData.homeDomain);
    // data.append('bucket', bucketName);
    
    // console.log(selectedFiles, inputData);
    // return;


    // console.log('data', data, data.getAll('files'));
    const headers = {
      // 'Content-Type': 'application/json',
      'Authorization': `Bearer ${  token}`,
    };
  
    // axios.post(url + "/bucket/object", data, { 
    axios.post(`${url  }/asset/issue`, data, {  
      headers,
      // receive two    parameter endpoint url ,form data
    })
    .then(res => { // then print response status
      // console.log(res);
      resolve(res);
    }).catch(err => {
      console.log(err);
      reject(err);
    });
  });


export const updateAssetInfo = (
  url, 
  token, 
  selectedFiles, 
  inputData,
) => new Promise((resolve, reject) => {
    const data = new FormData(); 

    data.append('image', selectedFiles[0]);

    // data.append('storePath', path);
    data.append('code', inputData.code);
    data.append('issuerPubKey', inputData.issuerPubKey);
    data.append('name', inputData.name);
    data.append('title', inputData.title);
    data.append('amount', inputData.amount);
    // data.append('creator', inputData.creator);
    data.append('description', inputData.description);
    data.append('homeDomain', inputData.homeDomain);
    // data.append('bucket', bucketName);
    
    // console.log(selectedFiles, inputData);
    // return;


    // console.log('data', data, data.getAll('files'));
    const headers = {
      // 'Content-Type': 'application/json',
      'Authorization': `Bearer ${  token}`,
    };
  
    // axios.post(url + "/bucket/object", data, { 
    axios.post(`${url  }/asset/asset-info`, data, {  
      headers,
      // receive two    parameter endpoint url ,form data
    })
    .then(res => { // then print response status
      // console.log(res);
      resolve(res);
    }).catch(err => {
      console.log(err);
      reject(err);
    });
  });

export const deleteAssetImage = (
  url, 
  token, 
  inputData,
) => new Promise((resolve, reject) => {
    // const data = new FormData(); 

    console.log('inputData', inputData);
    
    const headers = {
      // 'Content-Type': 'application/json',
      'Authorization': `Bearer ${  token}`,
    };
  
    // axios.post(url + "/bucket/object", data, { 
    axios.delete(`${url  }/asset/asset-image?code=${inputData.code}&issuerPubKey=${inputData.issuerPubKey}`, {  
      headers,
      // receive two    parameter endpoint url ,form data
    })
    .then(res => { // then print response status
      // console.log(res);
      resolve(res);
    }).catch(err => {
      console.log(err);
      reject(err);
    });
  });


export const createFolderAddedObjectList = (key, topLevels, currentPrefix) => {
  let addedList;

  if (!currentPrefix) {
    const addObject = {
      Prefix: key,
    };

    addedList = topLevels.concat(addObject);
  } else {
    const addObject = {
      Key: key,
    };

    addedList = topLevels.concat(addObject);
  }

  return addedList;
};


export const getIpfsBackend = async (url, token) => {
  try {
    // console.log('imageUrlObj', imageUrlObj);
    const result = await fetch(`${url  }/token-albedo/ipfs-backend`, {
      // const result = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
      // body: JSON.stringify({
      //   accountId: accountId,
      //   data: uploadData.data,
      //   imageUrlObj: uploadData.imageUrlObj,
      // })
    });
    
    if (!result.ok) {
      throw new Error('error-occured');
    }
    
    const resData = await result.json();
    console.log(result, resData);
    return resData;
    
  } catch(err) {
    console.log(err);
    throw err;
  }
};