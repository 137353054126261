import React, { useState, useEffect } from 'react';

import ToggleNavItems from '../ToggleNavItems/ToggleNavItems.jsx';

import { useStore } from '../../../../../hook-store/store';

import './ToggleNav.css';

const ToggleNav = props => {
  const {} = props;

  const [showToggleNav, setShowTggleNav] = useState(false);
  // console.log('mobileNavigation-props', props)
  // const [t] = useTranslation('translation');

  const [hStore, hDispatch] = useStore();
  const isMenuOpen = hStore.uiStore.isMenuOpen;

  // const showToggleNav = store.uiStore.showToggleNav;
  // const gLoading = store.uiStore.gLoading;

  useEffect(() => {
    if (isMenuOpen) {
      setShowTggleNav(true);
    } else {
      setShowTggleNav(false);
    }
  },[isMenuOpen]); 

  return (
    <nav className={['mobile-nav', showToggleNav ? 'open' : ''].join(' ')}>
      <div>
        <ToggleNavItems />
      </div>
    </nav>
  );
}

export default ToggleNav;
