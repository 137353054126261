import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useRedux } from "hooks/useRedux";

import { useStore } from '../../../../hook-store/store';

import classes from './FullSizeImage.module.css';
// import { marks } from 'images/icons/marks';
import { marks } from '../../../../images/icons/marks';

function FullSizeImage(props) {
  const { 
    nft, 
    setShowFullSize,
    showFullSize,
    isLoading,
  } = props;

  const [t] = useTranslation('translation');

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    // "memoRequiredAccounts",
    "settings",
    // "sendTx",
  );

  // const publicKey = account.data ? account.data.id : '';


  const [hStore, hDispatch] = useStore();
  const albedoPubkeyInfo = hStore.uiStore.albedoPubkeyInfo;
  const albedoAccountInfo = hStore.uiStore.albedoAccountInfo;

  const [fitScreen, setFitScreen] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 480) {
      setFitScreen(true);
    }
  },[]);

  let imgStyle = null;

  if (fitScreen) {
    imgStyle = {
      maxHeight: "80vh",
      maxWidth: "90vw",
    };
  }
  let fullSizeBody;

  if (nft) {
    fullSizeBody = (
      <div>
        {/* <div>
          <button 
            onClick={() => {
              // document.getElementById('image')?.requestFullscreen();
              setShowFullSize(!showFullSize);
            }}
          >
            full-size
          </button>
        </div> */}

        {showFullSize && (
          <div className={classes.fullSizeContainer}>
            <div style={{
              // position:"fixed", top:"1rem", right:"1rem"
              display:"flex", justifyContent:"space-between", padding:"1rem",
            }}
            >
              <div 
                onClick={() => {
                  setFitScreen(!fitScreen);
                }}
              >
                {fitScreen && (
                  <span className={classes.fullSizeImageFit}>
                    {t('singleNFT.02', 'full size')}  {marks.southEastArrow}
                  </span>
                )}
                {!fitScreen && (
                  <span className={classes.fullSizeImageFit}>
                    {t('singleNFT.01', 'fit screen')} {marks.northWestArrow}
                  </span>
                )}
              </div>
              <div className={classes.fullSizeImageClose}
                onClick={() => {
                  // document.getElementById('image')?.requestFullscreen();
                  setShowFullSize(false);
                }}
              >
                X
              </div>
            </div>
            <div className={classes.fullSizeImageContainer}>
              <img  
                style={imgStyle}
                src={nft.fileUrl}
                alt="fullsize media"
              />
            </div>
          </div>
        )}
      </div>
    );
  }


  return (
    <Fragment>
      <div>
        {fullSizeBody}
      </div>
    </Fragment>
  );
}

export default FullSizeImage;
