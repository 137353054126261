// export const languages = ['en', 'de'];
import { translationCodeList } from './locales/translationCodeList';

export const languages = translationCodeList;

// export const languages = [
//   // "en",
//   // "zh",
//   "af",
//   "ar",
//   "az",
//   "be",
//   "bg",
//   "ca",
//   "cs",
//   "da",
//   "de",
//   "dv",
//   "el",
//   "en",
//   "eo",
//   "es",
//   "et",
//   "eu",
//   "fa",
//   "fi",
//   "fo",
//   "fr",
//   "gl",
//   "gu",
//   "he",
//   "hi",
//   "hr",
//   "hu",
//   "hy",
//   "id",
//   "is",
//   "it",
//   "ja",
//   "ka",
//   "kk",
//   "kn",
//   "ko",
//   "kok",
//   "ky",
//   "lt",
//   "lv",
//   "mi",
//   "mk",
//   "mn",
//   "mr",
//   "ms",
//   "mt",
//   "nb",
//   "nl",
//   "ns",
//   "pa",
//   "pl",
//   "ps",
//   "pt",
//   "qu",
//   "ro",
//   "ru",
//   "sa",
//   "se",
//   "sk",
//   "sl",
//   "sq",
//   "sv",
//   "sw",
//   "syr",
//   "ta",
//   "te",
//   "th",
//   "tl",
//   "tn",
//   "tr",
//   "tt",
//   "ts",
//   "uk",
//   "ur",
//   "uz",
//   "vi",
//   "xh",
//   "zh",
//   // "zh-CN",
//   "zh-HK",
//   "zh-TW",
//   "zu",
// ];

export const defaultLanguage = 'en';
