export const getUserFollowNfts = async (url, token) => {
  try {

    const result = await fetch(`${url  }/user-follow-nfts`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } 
      throw new Error('something wrong');
    

  } catch(err) {
    console.log(err);
    throw err;
  }
};