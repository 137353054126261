/* eslint-disable max-len */
// const React = window.React = require('react');
import React, { Fragment } from "react";

import { useTranslation } from "react-i18next";
// import "./TermsOfUse.css";
import classes from './AboutPage.module.css';

const ImageEditorLink = (props) => {

  const [t] = useTranslation("translation");

  const imagePhotoLink =  (
    <a 
      href='https://kura-image-photo.spaceeight.net'
      target="_blank"
      rel="nofollow noopener noreferrer"
    >
      Kura Image Photo
    </a>
  );
  
  return <Fragment>
      <span>
        {t('aboutPage.08', 'When you need to edit your image files before upload, you can use simple image editor')}
        {' '}
        at {imagePhotoLink}
        {' '}
        <br/>
        {imagePhotoLink}
        {' '}
        has various image editing and generation tools.
        (<a 
          href='https://kura-image-photo.spaceeight.net'
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          {t('aboutPage.09', 'Link to image editor site')}
        </a>)

      </span>
  </Fragment>;
};
export default ImageEditorLink;
