import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input } from '@stellar/design-system';
import Img from "react-cool-img";
import { useTranslation } from 'react-i18next';

import { useRedux } from "hooks/useRedux";

import Backdrop from '../Backdrop/Backdrop.jsx';
import DeleteFiles from './DeleteFiles.jsx';
import FileUpdate from './FileUpdate.jsx';
import StellarAddress from '../UiComponent/StellarAddress.jsx';
import ModalSimple from '../Modal/ModalSimple.jsx';

import { useStore } from '../../../hook-store/store';

import { checkAssetPosition } from '../../../utils/nft/offer-util';
// import { checkIsCreator } from '../../../utils/nft/nft-data-util';
// import { BACKEND_URL } from 'urlLinks.js';
// import * as uploadUtils from '../utils/bucket-upload-util';
// import { createShortDisplayId } from '../../../utils/nft/nft-data-util';
// import { copyTextToClipboard } from '../../../utils/file-util';

import { loginPageLink, signupPageLink } from '../../../urlLinks';

import { marks } from '../../../images/icons/marks';

import classes from './UserNftListItem.module.css';

function UserNftListItem(props) {
  // console.log('UserNftListItem.jsx-props', props);
  const { nft, fileType, accountData } = props;

  const [t] = useTranslation('translation');

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    // "memoRequiredAccounts",
    "settings",
    // "sendTx",
  );

  // const { status, errorString } = sendTx;

  const [hStore, hDispatch] = useStore();
  const { isAuth } = hStore.uiStore;
  const { sellOfferNftList } = hStore.nftStore;
  // const albedoPubkeyInfo = hStore.uiStore.albedoPubkeyInfo;

  // const nftList = store.nftStore.nftList;

  const [showUpdate, setShowUpdate] = useState(false);
  const [showFilesDelete, setShowFilesDelete] = useState(false);
  // const [showDetail, setShowDetail] = useState(false);

  // const [isCreator, setIsCreator] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  const url = new URL(window.location.href);
  // const paramUid = url.searchParams.get('_id');

  let isUserUploadPage = false;
  if (url.pathname === '/user-uploads') {
    isUserUploadPage = true;
  }

  // useEffect(() => {
  //   const checkIsCreatorHandler = async (nId) => {
  //     const resData = await checkIsCreator(
  //       BACKEND_URL,
  //       localStorage.getItem('token'),
  //       nId,
  //     );

  //     if (resData.data) {
  //       setIsCreator(resData.data);
  //     }
  //   };

  //   if (nft && isAuth) {
  //     checkIsCreatorHandler(nft.n_id);
  //   }
  // },[nft, isAuth]);
  

  const showUpdateHandler = () => {
    setShowUpdate(!showUpdate);
  };

  const showFilesDeleteHandler = () => {
    setShowFilesDelete(!showFilesDelete);
  };


  let hasAsset = false;
  let hasAmount;
  let isInitOwnId = false;

    /// / check have nft
    if (nft && account.data && accountData) {

      if (nft.initOwnId === account.data.id) {
        isInitOwnId = true;
      }

      // eslint-disable-next-line max-len
      const assetPosition = checkAssetPosition(nft.code, nft.issuer, accountData.balances);
  
      // console.log('assetPosition', assetPosition);
  
      if (assetPosition) {
        hasAsset = true;
        hasAmount = assetPosition.amount;
      }

      // console.log(hasAmount, isInitOwnId);
    }

  let mediaElBody = fileType;

  if (fileType === 'image') {
    mediaElBody = (
      <Img style={{maxWidth: "100%", maxHeight:"200px"}} 
        src={nft.smallImageUrl ? nft.smallImageUrl : nft.fileUrl} 
        // src={`${IPFS_URL}/ipfs/${nft.image_cid}`}
        alt="imagedata"
      />
    );
  }

  if (fileType === 'video') {
    if (isUserUploadPage) {
      mediaElBody = (
        <video style={{maxWidth:"100%", height:"200px"}} 
          src={nft.trimMediaUrl ? nft.trimMediaUrl : nft.fileUrl} 
          // src={`${IPFS_URL}/ipfs/${nft.image_cid}`}
          alt="videodata" muted controls autoPlay={false}
        />
      );
    } else {
      mediaElBody = (
        <video style={{maxWidth:"100%", height:"200px"}} 
          src={nft.trimMediaUrl ? nft.trimMediaUrl : nft.fileUrl} 
          // src={`${IPFS_URL}/ipfs/${nft.image_cid}`}
          alt="videodata" muted 
          // controls 
          autoPlay={true}
          loop
        />
      );
    }
  }

  if (fileType === 'audio') {
    mediaElBody = (
      <audio style={{maxWidth: "100%", marginTop: "20px"}} 
        src={nft.trimMediaUrl ? nft.trimMediaUrl : nft.fileUrl} 
        // src={`${IPFS_URL}/ipfs/${nft.image_cid}`}
        alt="audiodata" controls
      />
    );
  }


  let dataUpdateBody;
  
  if (account && account.data && !hasAsset) {
    dataUpdateBody = (
      <div>
        {t('NFTlist.03', 'You do not have')} {nft.code}
      </div>
    );
  }
  
  if (!isAuth && hasAmount && isInitOwnId) {
    dataUpdateBody = (
      <div className={classes.userNftItemAuthButtons}>
        <div>
          {t('NFTlist.04', 'Login is required to update name and description')}
        </div>
        <div>
          <span>
            <a href={loginPageLink} >
              <Button 
                variant="tertiary"
              >
                {t('general.text18', 'login')}
              </Button>
            </a>
          </span>
        </div>
      </div> 
    );
  }

  if (isAuth && hasAmount && isInitOwnId) {
    dataUpdateBody = (
      <>
        <div>
          {t('NFTlist.05', 'You have ')}
          {' '}
          <strong>
            {(Number(hasAmount)*10000000).toFixed()} {nft.code}
          </strong>
          </div>

        <div className={classes.userNftItemUpdate}>
          <Button 
            style={{border:"1px solid gray"}}
            variant="tertiary"
            size="small"
            onClick={showUpdateHandler}
          >
            {t('NFTlist.06', 'Update Name & Description')}
          </Button>

          {showUpdate && (
            <div>
              <Backdrop 
                onCancel={showUpdateHandler}
              />
              <ModalSimple
                modalStyle={{width: "40rem"}}
              >
              <div className={classes.userNftItemUpdateClose}>
                <span
                  className={classes.userNftItemUpdateCloseButton}
                  onClick={showUpdateHandler}
                >
                  X
                </span>
              </div>
                <FileUpdate 
                  nft={nft}
                  showUpdateHandler={showUpdateHandler}
                />
              </ModalSimple>
            </div>
          )}
        </div>
        
        <div className={classes.userNftItemUpdate}>
          {nft.fileUrl && (
            <Button 
              style={{border:"1px solid gray"}}
              variant="tertiary"
              size="small"
              onClick={showFilesDeleteHandler}
            >
              delete file
            </Button>
          )}

          {showFilesDelete && (
            <div>
              <Backdrop 
                onCancel={showFilesDeleteHandler}
              />
              <ModalSimple
                modalStyle={{width: "40rem"}}
              >
              <div className={classes.userNftItemUpdateClose}>
                <span
                  className={classes.userNftItemUpdateCloseButton}
                  onClick={showFilesDeleteHandler}
                >
                  X
                </span>
              </div>
                <DeleteFiles
                  nft={nft}
                  showFilesDeleteHandler={showFilesDeleteHandler}
                />
              </ModalSimple>
            </div>
          )}
        </div>

        
      </>
    );
  }

  if (isAuth && hasAmount && !isInitOwnId) {
    dataUpdateBody = (
      <>
        <div>
          
          {t('NFTlist.05', 'You have')}
          {' '}
          <strong>
            {(Number(hasAmount)*10000000).toFixed()} {nft.code}
          </strong>
        </div>
        <div>
          {t('NFTlist.07', 'Initial owner which have this asset can update information.')}
        </div>
      </>
    );
  }


  const nftSellOfferData = sellOfferNftList.find(sNft => {
    return sNft.n_id === nft.n_id;
  });

  let lowestSellOffer;
  let lowestSellPrice;
  let lowestSellCode;

  if (nftSellOfferData) {
    const lowestSellOfferData = nftSellOfferData.lowestSellOfferData;

    lowestSellOffer = lowestSellOfferData.lowestSellOffer;
    lowestSellPrice = lowestSellOfferData.lowestSellPrice;
    // console.log('lowestSellOfferData', lowestSellOfferData);


    if (lowestSellOffer.buying.asset_type === 'native') {
      lowestSellCode = 'XLM';
    } else {
      lowestSellCode = lowestSellOffer.buying.asset_code;
    }
  }


  let userNftListItemBody;

  if (nft) {
    userNftListItemBody = (
      <div className={classes.userNftItem}>
        <div className={classes.userNftMediaContainer}>
          {mediaElBody}
        </div>
        <div>nId <strong>{nft.n_id}</strong></div>
        <div>
          {t('general.text1', 'name')}
          {' '}
          <strong>{nft.name}</strong></div>
        <div title={nft.desc}>
          {t('general.text2', 'description')}
          {' '}
          <strong>
            {nft.desc.length > 20 && (<span>{nft.desc.slice(0,20)} ...</span>)}
            {nft.desc.length <= 20 && (<span>{nft.desc}</span>)}
          </strong>
        </div>

        {/* <div>code <strong>{nft.code}</strong></div>

        <div>{dataUpdateBody}</div> */}

        {/* <div>
          detail
        </div> */}
        
        {isUserUploadPage && (
          <div>
            <div>code <strong>{nft.code}</strong></div>

            <div>{dataUpdateBody}</div>

            <div>
              <span>
                {t('general.text5', 'Issuer')}
                {' '}
                ({t('NFTlist.08', 'not accessible')})
                <StellarAddress stellarId={nft.issuer} />
              </span>
            </div>
            <div>
              <span>
                {t('NFTlist.09', 'Initial owner')}
                <StellarAddress stellarId={nft.initOwnId} />
              </span>
            </div>
            {/* <div>mimetype: {nft.mimetype}</div> */}
            <div>
              total visit
              {' '} 
              <strong>{nft.totalVisit}</strong>
              {/* <strong>{new Date(nft.createdAt).toLocaleDateString()}</strong> */}
            </div>
            <div>
              {t('general.text6', 'created time')}
              {' '} 
              <strong>{new Date(nft.createdAt).toLocaleDateString()}</strong>
            </div>
            {/* <div>updatedAt: {nft.updatedAt}</div> */}
    
            {/* <div>userId: {nft.userId}</div> */}
            
            {/* <div>{isInitOwnId && 'using-initial-owner-accountId'}</div> */}
          </div>
        )}

        {nftSellOfferData && (
          <Link to={`/${nft.n_id}`}>
            <div>
              price
              {' '}
              {lowestSellPrice.toFixed(2)} {lowestSellCode}
              {' '}
              ( ~ {(lowestSellOffer.offerPriceUSD).toFixed(2)} USD)
            </div>
          </Link>           
        )}
        
        <div>
          <Link 
            to={`/${nft.n_id}`}
            // target="_blank" rel="noopener noreferrer"
          >
            {t('NFTlist.10', 'Go to NFT Page')}
          </Link>
        </div>

      </div>
    );
  }


  return (
    <Fragment>
      <div>
        {userNftListItemBody}
      </div>
    </Fragment>
  );
}

export default UserNftListItem;
