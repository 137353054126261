export const getUserFollowers = async (url, token) => {
  try {

    const result = await fetch(`${url  }/user-follower/user-followers`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } 
      throw new Error('something wrong');
    

  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const addUserFollower = async (url, token, followUId) => {
  try {

    const result = await fetch(`${url  }/user-follower/add-user-follower`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
      body: JSON.stringify({
        followUId,
      }),
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } 
      throw new Error('something wrong');
    

  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const deleteUserFollower = async (url, token, uId, followUId) => {
  try {

    const result = await fetch(`${url  }/user-follower/delete-user-follower?uId=${uId}&followUId=${followUId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
      // body: JSON.stringify({
      //   nId,
      //   uId,
      // }),
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } 
      throw new Error('something wrong');
    

  } catch(err) {
    console.log(err);
    throw err;
  }
};