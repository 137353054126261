import React from 'react';
import classes from './Backdrop.module.css';

function Backdrop(props) {
  const { zIndex } = props;

  let backdropStyle = null;

  if (zIndex) {
    backdropStyle = {
      zIndex: zIndex,
    };

  }
  return (
    <div className={classes.backdrop}
      style={backdropStyle}
      onClick={props.onCancel}
    >
    </div>
  );
}

export default Backdrop;