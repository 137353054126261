import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";

import { useRedux } from "hooks/useRedux";
import { fetchAccountAction } from "ducks/account";

import { useStore } from '../../../hook-store/store';
// import { fetchNetworkFee } from '../../../utils/nft/offer-util';

function GetDefferredPrompt() {

    const dispatch = useDispatch();

    // const { settings } = useRedux("settings");
    const { account, settings } = useRedux(
        "account",
        // "memoRequiredAccounts",
        "settings",
        // "sendTx",
      );

    const publicKey = account.data ? account.data.id : '';
     

    const [hStore, hDispatch] = useStore();

    
    useEffect(() => {
      console.log('installpromprt');
      window.addEventListener('beforeinstallprompt', (event) => {
        console.log('beforeinstallprompt fired installhandler headermodal');
        event.preventDefault();
        // deferredPrompt = event;
        console.log('in beforeinstallprompt', event);
        // addBtn.style.display = 'block';

        hDispatch('SET_DEFERREDPROMPT', event);
        // setInstallStyle('block');
  
      });
    },[]);

    return (
        <Fragment>
        </Fragment>
    );
}

export default GetDefferredPrompt;