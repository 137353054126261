import React, { useState, useEffect, Fragment } from 'react';
import { Button } from "@stellar/design-system";
import Img from "react-cool-img";
import Linkify from 'react-linkify';
import { SecureLink } from "react-secure-link";
import _ from 'lodash';

import { useRedux } from "hooks/useRedux";

import Loader from '../Loader/Loader.jsx';
import { useStore } from '../../../hook-store/store';

import UserNftListItem from './UserNftListItem.jsx';
import FollowUserButton from './FollowUserButton.jsx';
import Paginator from '../Paginator/Paginator.jsx';
import WideAds from '../Ads/WideAds.jsx';

// import * as uploadUtils from '../utils/bucket-upload-util';
import * as nftDataUtils from '../../../utils/nft/nft-data-util';
import { getAccountInfo } from '../../../utils/nft/offer-util';
import { getUserDescription } from '../../../utils/user';

import { BACKEND_URL } from '../../../urlLinks';

import classes from './UserNftList.module.css';

function UserNftList(props) {
  console.log('UserNftList-props', props);
  const {} = props;

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    // "memoRequiredAccounts",
    "settings",
    // "sendTx",
  );

  const [hStore, hDispatch] = useStore();
  const { nftList, termAssetList } = hStore.nftStore;
  const { userList } = hStore.uiStore;


  const [accountData, setAccountData] = useState();
  const [selectedFileType, setSelectedFileType] = useState('');

  const [creator, setCreator] = useState();
  const [creatorDescription, setCreatorDescription] = useState('');
  const [fileDeletedList, setFileDeletedList] = useState([]);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [audios, setAudios] = useState([]);
  const [other, setOther] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageListNum, setPageListNum] = useState(24);

  const [isLoading, setIsLoading] = useState(false);

  const url = new URL(window.location.href);
  const paramUid = url.searchParams.get('_id');

  let isUserUploadPage = false;

  if (url.pathname === '/user-uploads') {
    isUserUploadPage = true;
  }
  // console.log('url data', url, paramUid);

  useEffect(() => {
    scrollTo(0, 0);
  },[]);

  useEffect(() => {
    console.log('UserNftList useEffect paramUid', paramUid);
    // getNftsHandler();
    // const paramUser = userList.find(user => user._id === paramId);
    // console.log('url data paramUser', paramUser);

    if (paramUid) {
      getUserNftsHandler(paramUid);

      getUserDescriptionHandler(paramUid);
    }
    // getUserNftsHandler(localStorage.getItem('userId'));
  },[]);


  useEffect(() => {
    const getAccountDataHandler = async () => {
      try {
        setIsLoading(true);
        const accountInfo = await getAccountInfo(account.data.id);
        console.log('accountInfo', accountInfo);
        if (accountInfo) {
          setAccountData(accountInfo);
        }

        setIsLoading(false);
      } catch(err) {
        console.log(err);
        setIsLoading(false);
      }
    };

    if (account.data) {
      getAccountDataHandler();
    }
  },[account.data]);


  useEffect(() => {
    if (paramUid && userList.length > 0 && !creator) {
      const creator = userList.find(user => (user._id === paramUid));
      
      if (creator) {
        setCreator(creator);
      }
    }
  },[userList]);


    //// get termAssetList for price
    useEffect(() => {
      getTermAssetListHander();
    },[]);
  
    useEffect(() => {
      if (nftList.length > 0 && termAssetList) {
        const pageStart = (currentPage - 1) * pageListNum;
        const pageEnd = (currentPage) * pageListNum;
        
        const pageNftList = nftList.slice(pageStart, pageEnd);
  
        // createListWithSellOffer(pageNftList, termAssetList.assets);
        createSellOfferNftsHandler(pageNftList, termAssetList.assets);
        // createSellOfferNftsHandler(nftList, termAssetList.assets);
      }
  
    },[nftList, termAssetList, currentPage]);

  // let images = [];
  // let videos = [];
  // let audios = [];
  // let other = [];


  // if (nftList.length > 0) {
  //   images = nftList.filter(nft => nft.mimetype.startsWith('image/'));

  //   videos = nftList.filter(nft => nft.mimetype.startsWith('video/'));
    
  //   audios = nftList.filter(nft => nft.mimetype.startsWith('audio/'));

  //   other = nftList.filter(nft => (!nft.mimetype.startsWith('image/') &&
  //       !nft.mimetype.startsWith('video/') &&
  //       !nft.mimetype.startsWith('audio/')
  //     ));

  //   console.log(images, videos, audios, other);
  // }

  const setListsHandler = (nftList) => {
    if (nftList.length > 0) {
      const images = nftList.filter(nft => nft.mimetype.startsWith('image/'));
  
      const videos = nftList.filter(nft => nft.mimetype.startsWith('video/'));
      
      const audios = nftList.filter(nft => nft.mimetype.startsWith('audio/'));
  
      const other = nftList.filter(nft => (!nft.mimetype.startsWith('image/') &&
          !nft.mimetype.startsWith('video/') &&
          !nft.mimetype.startsWith('audio/')
        ));
      
      setImages(images);
      setVideos(videos);
      setAudios(audios);
      setOther(other);
      console.log(images, videos, audios, other);
    }
  }


  const getUserNftsHandler = async (_id) => {
    try {
      setIsLoading(true);

      const result = await nftDataUtils.getUserNfts(
        BACKEND_URL,
        localStorage.getItem('token'),
        _id,
      );
      

      const notDeletedList = result.data.filter(nft => !nft.fileDeleted);
      hDispatch('SET_NFTLIST', notDeletedList);
      
      const fileDeleted = result.data.filter(nft => nft.fileDeleted);
      setFileDeletedList(fileDeleted);
      // hDispatch('SET_NFTLIST', result.data);
      setListsHandler(notDeletedList);

      // console.log('userList result', result);
      // console.log('userList', userList);

      if (result.creatorUser) {
        const addedList = userList.concat(result.creatorUser);
        const uniqList = _.uniqBy(addedList, '_id');
        hDispatch(
          'SET_USERLIST',
          uniqList,
        );
      }

      setIsLoading(false);
      // console.log(result.data[0].imageCid)
    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  const getUserDescriptionHandler = async (uId) => {
    try {
      // setIsLoading(true);

      const result = await getUserDescription(
        BACKEND_URL,
        localStorage.getItem('token'),
        uId,
      );
      
      // setNftList(result.data);
      // hDispatch('SET_NFTLIST', result.data);
      console.log(result.data);

      if (!result.data) {
        throw new Error('error-occured');
      }
   
      setCreatorDescription(result.data.user.description);
    
      // setIsLoading(false);

    } catch(err) {
      console.log(err);
      // setIsLoading(false);
    }
  };


  const createSellOfferNftsHandler = async (pageNftList, assetsInTermData) => {
    const withSellOfferList = await nftDataUtils.createListWithSellOffer(
      pageNftList, 
      assetsInTermData,
    );
    
    if (withSellOfferList && withSellOfferList.length > 0) {
      // console.log('withSellOfferList', withSellOfferList);
      hDispatch('SET_SELLOFFERNFTLIST', withSellOfferList);
      // setSellOfferNftList(withSellOfferList);
    }
    
  };


  const getTermAssetListHander = async () => {
    try {
      const resData = await nftDataUtils.getStellarTermAssetList();

      if (resData) {
        hDispatch('SET_TERMASSETLIST', resData);
      }
    } catch(err) {
      console.log(err);
    }
  };


  // const getNftImageUrlHandler = async (imageCid) => {
  //   try {
  //     const result = await nftDataUtils.getNftImageUrl(
  //       BACKEND_URL,
  //       localStorage.getItem('token'),
  //       imageCid
  //     );

  //     console.log(result.data);
  //     window.open(result.data , "_blank", 'noreferrer');
  //     // window.open(result.data);
  //     // window.open("http://www.mozilla.org/");
      
  //     // console.log(result.data[0].imageCid)
  //   } catch(err) {
  //     console.log(err);
  //   }
  // };


  let userNftListBody;
  let displayList = [];

  if (nftList.length > 0) {
    displayList = nftList;

    if (isUserUploadPage) {
      displayList = nftList.concat(fileDeletedList);
    }

    if (selectedFileType === 'image') {
      displayList = images;
    }

    if (selectedFileType === 'video') {
      displayList = videos;
    }

    if (selectedFileType === 'audio') {
      displayList = audios;
    }

    if (selectedFileType === 'other') {
      displayList = other;
    }

    const pageStart = (currentPage - 1) * pageListNum;
    const pageEnd = (currentPage) * pageListNum;
  
    userNftListBody = (
      <ul 
        className={classes.userNftListList}
      >
        {displayList.slice(pageStart, pageEnd).map(nft => {
          let fileType = 'other';

          if (nft.mimetype.startsWith('image/')) {
              fileType = 'image';
          }

          if (nft.mimetype.startsWith('video/')) {
            fileType = 'video';
          }

          if (nft.mimetype.startsWith('audio/')) {
            fileType = 'audio';
          }

          return (
            <div key={nft.n_id}
              className={classes.userNftListListContent}
            >
                <UserNftListItem 
                  nft={nft}
                  fileType={fileType}
                  accountData={accountData}
                />
            </div>
          );
        })}
      </ul>
    );
  }

  const activeBtnStyle = {
    fontWeight: 'bold',
    textDecoration: 'underline',
  };

  const selectTypeBody = (
    <div>
      {/* user-uploaded */}
      <div className={classes.userNftListSelectButtons}>
        <Button 
          style={selectedFileType === '' ? activeBtnStyle : null}
          variant="tertiary" size="small"
          onClick={() => {
            setSelectedFileType('');
            setCurrentPage(1);
          }}
        >
          ALL
        </Button>

        {images.length > 0 && (
          <Button
            style={selectedFileType === 'image' ? activeBtnStyle : null}
            variant="tertiary" size="small"
            onClick={() => {
              setSelectedFileType('image');
              setCurrentPage(1);
            }}
          >
            image {images.length}
          </Button>
        )}

        {videos.length > 0 && (
          <Button
            style={selectedFileType === 'video' ? activeBtnStyle : null}
            variant="tertiary" size="small"
            onClick={() => {
              setSelectedFileType('video');
              setCurrentPage(1);
            }}
          >
            video {videos.length}
          </Button>
        )}

        {audios.length > 0 && (
          <Button
            style={selectedFileType === 'audio' ? activeBtnStyle : null}
            variant="tertiary" size="small"
            onClick={() => {
              setSelectedFileType('audio');
              setCurrentPage(1);
            }}
          >
            audio {audios.length}
          </Button>
        )}

        {other.length > 0 && (
          <Button
            style={selectedFileType === 'other' ? activeBtnStyle : null}
            variant="tertiary" size="small"
            onClick={() => {
              setSelectedFileType('other');
              setCurrentPage(1);
            }}
          >
            other {other.length}
          </Button>
        )}
      </div>
    </div>  
  );


  let creatorNameBody;

  if (creator) {
    creatorNameBody = (
      <div>
        <div className={classes.userNftListCreator}>
          {creator.imageUrl && (
            <Img 
              className={classes.userNftListCreatorImage}
              src={creator.imageUrl}
              alt="creator-img"
            />
          )}
          <span>
            {creator.name} NFT List
          </span>
        </div>

        {creatorDescription && (
          <Linkify 
            // eslint-disable-next-line max-len
            componentDecorator={(decoratedHref, decoratedText, key) => {
              // <a target="blank" href={decoratedHref} key={key}>
              //     {decoratedText}
              // </a>
              let showText = decoratedText;

              if (decoratedText.length > 30) {
                showText = `${decoratedText.slice(0, 30)}....`;
              }
              return (
              <SecureLink href={decoratedHref} key={key}>
                {showText}
              </SecureLink>);
            }}
          >
            <div className={classes.userNftListCreatorDescription}>
              {creatorDescription}
            </div>
          </Linkify>
        )}

        <div>
          <FollowUserButton creator={creator}/>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <div>
        {isLoading && <Loader />}
        {!isLoading && (
          <div>
            {!isUserUploadPage && (
              <div className={classes.userNftListTopAd}>
                <WideAds />
              </div>
            )}

            {creatorNameBody}

            {selectTypeBody}
            
            <div>
              {userNftListBody}
            </div>
            
            <Paginator 
              currentPage={currentPage}
              // lastPage={Math.ceil(nftList.length/pageListNum)}
              lastPage={Math.ceil(displayList.length/pageListNum)}
              // lastPage={Math.ceil(nftList.length/pageListNum)+1}
              onPrevious={() => {
                setCurrentPage(currentPage - 1);
                }}
              onNext={() => {
                setCurrentPage(currentPage + 1);
                }}
            />
          </div>
        )}
        </div>
    </Fragment>
  );
}

export default UserNftList;
