/* eslint-disable react/jsx-filename-extension */
import React from 'react';

// //  https://fontawesome.com/v4/icons/
// import classes from './marks.module.css';
const dColor = 'gray';

export const marks = {
  checkMark: <strong style={{color: dColor}} >&#9745;</strong>, // // ☑
  noCheckMark: <span style={{color: dColor}} >&#9744;</span>, // // ☐
	
  fileMark: <i style={{color: dColor}} className="fa fa-file-o"></i>,
  fileFillMark: <i style={{color: dColor}} className="fa fa-file" aria-hidden="true"></i>,
  folderMark: <i style={{color: dColor}} className="fa fa-folder-o"></i>,
  folderOpenMark: <i style={{color: dColor}} className="fa fa-folder-open-o" aria-hidden="true"></i>,
  folderFillMark: <i style={{color: dColor}} className="fa fa-folder-o"></i>,
  folderFillOpenMark: <i style={{color: dColor}} className="fa fa-folder-open" aria-hidden="true"></i>,
  trashMark: <i style={{color: dColor}} className="fa fa-trash" aria-hidden="true"></i>,
  editMark: <i style={{color: dColor}} className="fa fa-pencil-square-o" aria-hidden="true"></i>,

  triangle: <span style={{color: dColor}} >&#9652;</span>,
  triangleDown: <span style={{color: dColor}} >&#9662;</span>,
  arrowCircleUp: <i style={{color: dColor}} className="fa fa-arrow-circle-up"></i>,
  
  spinner: <i style={{color: dColor}}  className="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>,
  
  // closeMark: <i className="fa fa-times" aria-hidden="true"></i>,
  closeMark: <span style={{color: dColor}}>&#x2715;</span>,

  uploadMark: <i style={{color: dColor}} className="fa fa-upload" aria-hidden="true"></i>,
  downloadMark: <i style={{color: dColor}}  className="fa fa-download" aria-hidden="true"></i>,

  signInMrak: <i style={{color: dColor}} className="fa fa-sign-in" aria-hidden="true"></i>,
  signOutMrak: <i style={{color: dColor}} className="fa fa-sign-out" aria-hidden="true"></i>,
  userPlusMrak: <i style={{color: dColor}} className="fa fa-user-plus" aria-hidden="true"></i>,
  userMark: <i style={{color: dColor}} className="fa fa-user" aria-hidden="true"></i>,

  gearMrak: <i style={{color: dColor}} className="fa fa-cog" aria-hidden="true"></i>,
  bellMrak: <i style={{color: dColor}} className="fa fa-bell" aria-hidden="true"></i>,

  copyMark: <i style={{color: dColor}} className="fa fa-files-o" aria-hidden="true"></i>,
  moveMark: <i style={{color: dColor}} className="fa fa-share" aria-hidden="true"></i>,
  replyMark: <i style={{color: dColor}} className="fa fa-reply" aria-hidden="true"></i>,

  listUlMark: <i style={{color: dColor}} className="fa fa-list-ul" aria-hidden="true"></i>,
  tableMark: <i style={{color: dColor}} className="fa fa-table" aria-hidden="true"></i>,

  southEastArrow: <span style={{}}>&#x21F2;</span>,
  northWestArrow: <span style={{}}>&#x21F1;</span>,

  fileVideoOMark: <i style={{color: dColor}} className="fa fa-file-video" aria-hidden="true"></i>,
  fileAudioOMark: <i style={{color: dColor}} className="fa fa-file-audio-o" aria-hidden="true"></i>,

  externalLinkMark: <i style={{color: 'gray'}} className="fa fa-external-link" aria-hidden="true"></i>,

};