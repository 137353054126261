import React, { Fragment, useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { Button } from "@stellar/design-system";
import { useTranslation } from 'react-i18next';

import Backdrop from "../Backdrop/Backdrop.jsx";
import ModalSimple from "../Modal/ModalSimple.jsx";
import UserImageUploadContent from "./UserImageUploadContent.jsx";

import { useStore } from "../../../hook-store/store";
// import { getUserData } from "../../../../utils/user";
import { BACKEND_URL } from "../../../urlLinks";

import classes from './UserImage.module.css';


const UserImage = (props) => {
  const { 
    userData,
    getUserDataHandler,
  } = props;

  const [t] = useTranslation('translation');

  const [hStore, hDispatch] = useStore();
  // const userData = hStore.uiStore.userData;
  // const isAdmin = store.uiStore.isAdmin;

  // const [imageFile, setImageFile] = useState();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [error, setError] = useState('');
  const [action, setAction] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // const [userData, setUserData] = useState();

  useEffect(() => {
    // getUserDataHandler(BACKEND_URL, localStorage.getItem('token'));
  }, []);


  const userImageUploadHandler = imageData => {
    // console.log('in finishupload handler');
    setIsLoading(true);
    setError('');

    console.log(imageData);

    const formData = new FormData();
    // if (!postData.image) {
    //   formData.append('image', postData.imageUrl);
    // } else {
    //   formData.append('image', postData.image);
    // }

    if (imageData) {
      formData.append('image', imageData);
    }

    // console.log(formData);
    const url = `${BACKEND_URL  }/auth/image?userLocation=${localStorage.getItem('userLocation')}`;
    const method = 'POST';

    // if (this.state.editPost) {
    //   url = `${BACKEND_URL  }/auth/image/${  this.state.editPost._id}`;
    //   method = 'put';
    // }

    fetch(url, {
      method,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    })
      .then(res => {
        console.log(res);
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Creating or editing a image failed!');
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);
        setIsLoading(false);
        getUserDataHandler(BACKEND_URL, localStorage.getItem('token'));
      
        setTimeout(() => {
          setShowUploadModal(false);
        }, 3000);
      })
      .catch(err => {
        console.log(err);
        setError('image upload failed');
        setIsLoading(false);
      });
  };

  const deleteUserImageHandler = () => {
    setIsLoading(true);
    setError('');

    fetch(`${BACKEND_URL  }/auth/image?userLocation=${localStorage.getItem('userLocation')}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Deleting a image failed!');
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);
        setIsLoading(false);
        getUserDataHandler(BACKEND_URL, localStorage.getItem('token'));

        setTimeout(() => {
          setShowUploadModal(false);
        }, 3000);
      })
      .catch(err => {
        console.log(err);
        setError('image deletion failed');
        setIsLoading(false);
      });
  };


  let userImageBody;

  if (userData) {
    userImageBody = (
      <div>
        <div className={classes.userImage}>
          {userData && userData.imageUrl && (
            <div>
              <img 
                className={classes.userImageImage}
                src={userData.imageUrl}
              />
            </div>
          )}

          <Button
            style={{border: "0.25px solid gray"}}
            variant="tertiary"
            size="small"
            disabled={isLoading}
            onClick={() => {
              setShowUploadModal(true); 
              setAction('upload');
              setError('');
            }}
          >
            {t('userInfo.text2', 'update')}
          </Button>

          {userData && userData.imageUrl && (
            <div>
              <Button
                  style={{border: "0.25px solid gray"}}
                  variant="tertiary"
                  size="small"
                  disabled={isLoading}
                  onClick={() => {
                    setShowUploadModal(true);
                    setAction('delete');
                    setError('');
                    // deleteUserImageHandler();
                  }}
                >
                  {t('userInfo.text7', 'delete')}
              </Button>
            </div>
          )}
        </div>


      </div>
    );
  }
 

  return <Fragment>

    {showUploadModal && (
      <div>
        <Backdrop  
          onCancel={() => {
            setShowUploadModal(false);
          }}
          zIndex={50}
        />
        <ModalSimple
          modalStyle={{width: "40rem"}}
        >
          <UserImageUploadContent 
            userData={userData}
            setShowUploadModal={setShowUploadModal}
            // imageFile={imageFile}
            // setImageFile={setImageFile}
            userImageUploadHandler={userImageUploadHandler}
            deleteUserImageHandler={deleteUserImageHandler}
            error={error}
            action={action}
            isLoading={isLoading}
          />
        </ModalSimple>
      </div>
    )}

    <div className="pageContentContainer">
      {t('userInfo.text6', 'user image')} (NFT Place)
      {userImageBody}
    </div>
  </Fragment>;
};

export default UserImage;
