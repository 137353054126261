export const getNftSearchResult = async (url, token, input) => {
  try {

    const result = await fetch(`${url  }/nft-search/?input=${input}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
    });

    const resData = await result.json();
    console.log(result, resData);

    if (!result.ok) {
      throw new Error('error occured');
    }

    return resData;

  } catch(err) {
    console.log(err);
    throw err;
  }
};