import React, { useState, useEffect, Fragment } from "react";
// import { useTranslation } from "react-i18next/hooks";

import { useStore } from "../../../hook-store/store";
// import { logoutHandler } from "../../util/user/user";
import { getUserNameData} from '../../../utils/user-name-data/user-name-data-util';

import { BACKEND_URL } from "../../../urlLinks";


const SetUserNameData = (props) => {
  const { } = props;

  // const [t] = useTranslation("translation");

  const [hStore, hDispatch] = useStore();
  const { isAuth, userData } = hStore.uiStore;

  // const lsToken = localStorage.getItem("tokenForCasTGT");

  // useEffect(() => {
  //   let tgtExpireTime;
  //   if (localStorage.getItem("TGTexp")) {
  //     tgtExpireTime = Number(localStorage.getItem("TGTexp")) * 1000;
  //   }
  //   console.log("tgtExpireTime", tgtExpireTime);

  //   if (!tgtExpireTime || tgtExpireTime < Date.now()) {
  //     // deleteCasData();
  //     logoutHandler();
  //     window.location.reload();
  //   }

  //   // if (localStorage.getItem('casTGT') && tgtExpireTime < Date.now()) {
  //   //   getCasTgtStatus(localStorage.getItem('casTGT'));
  //   // }
  // }, []);


  // useEffect(() => {
  //   if (isAuth && lsToken && !userData) {
  //     setUserDataHandler();
  //   }
  // }, [isAuth, userData, lsToken]);


  // setAuthToken();
  // const setUserDataHandler = () => {
  //   if (lsToken) {
  //     const jwtdecoded = jwt.decode(lsToken);
  //     console.log(jwtdecoded);
  //     dispatch("SET_USERDATA", jwtdecoded);

  //     getUserNameDataHandler();
  //   }
  // };

  useEffect(() => {
    if (isAuth) {
      getUserNameDataHandler();
    }
  },[isAuth]);

  const getUserNameDataHandler = async () => {
    try {
      hDispatch('SET_GLOADING', true);
      const resData = await getUserNameData(
        BACKEND_URL,
        localStorage.getItem('token'),
      );

      console.log(resData);

      if (resData.data) {
        hDispatch('SET_USERNAMEDATA', resData.data);  
      }

      hDispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);
      hDispatch('SET_GLOADING', false);
      // throw err;
    }
  };


  return (
    <Fragment></Fragment>
  );
};

export default SetUserNameData;
