import React, { useState, useEffect, Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, InfoBlock } from "@stellar/design-system";
import { useTranslation } from 'react-i18next';

import Loader from 'components/IssuerFrontend/Loader/Loader.jsx';
import InfoIcon from '../../UiComponent/Icons/InfoIcon.jsx';

import { useStore } from '../../../../hook-store/store';


// import * as uploadUtils from '../utils/bucket-upload-util';
import * as nftDataUtils from '../../../../utils/nft/nft-data-util';
import * as nftFollowUtils from '../../../../utils/follow/nft-follow-users-util';
import { getUserData } from '../../../../utils/user';

import { BACKEND_URL } from '../../../../urlLinks';
// import Backdrop from 'components/IssuerFrontend/Backdrop/Backdrop.jsx';

import classes from './FollowNftButton.module.css';

function FollowNftButton(props) {
  // console.log('SingleNft.js-props', props);
  const { nft } = props;

  const params = useParams();

  const [t] = useTranslation('translation');

  // console.log('params', params);

  const [hStore, hDispatch] = useStore();
  const { userData } = hStore.uiStore;
  // const nftList = hStore.nftStore.nftList;
  // const isAuth = hStore.uiStore.isAuth;
  // const userData = hStore.uiStore.userData;

  const { isAuth } = hStore.uiStore;

  // const [nftData, setNftData] = useState();
  // const [creatorUId, setCreatorUId] = useState('');
  const [isFollowUser, setIsFollowUser] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  // console.log(setIsLoading);


  useEffect(() => {
    getNftFollowUsersHandler();
  },[]);
  // const pageListNum = 2;


  // useEffect(() => {
  //   if (isAuth && !userData) {
  //     getUserDataHandler(
  //       BACKEND_URL, 
  //       localStorage.getItem('token'),
  //     );
  //   }
  // },[isAuth, userData]);


  // const getUserDataHandler = async(url, token) => {
  //   try {
  //     const result = await getUserData(url, token);

  //     console.log(result);

  //     if (result.data) {
  //       // setUserData(result.data);
  //       hDispatch('SET_USERDATA', result.data);
  //     }
  //   } catch(err) {
  //     console.log(err);
  //   }
  // };

  const getNftFollowUsersHandler = async () => {
    try {
      setIsLoading(true);

      const resData = await nftFollowUtils.getNftFollowUsers(
        BACKEND_URL,
        localStorage.getItem('token'),
        nft.n_id,
      );

      console.log('follow resData', resData);
      
      if (resData.data) {
        const isInFollowList = resData.data.followUsers.find(user => (
          user.uId === userData._id
        ));

        if (isInFollowList) {
          setIsFollowUser(true);
        }
      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const followNftHandler = async () => {
    try {
      setIsLoading(true);

      const resData = await nftFollowUtils.addNftFollowUser(
        BACKEND_URL,
        localStorage.getItem('token'),
        nft.n_id,
        userData._id,
      );

      if (resData) {
        setIsFollowUser(true);
      }
      // console.log('follow resData', resData);

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const unFollowNftHandler = async () => {
    try {
      setIsLoading(true);

      const resData = await nftFollowUtils.deleteNftFollowUser(
        BACKEND_URL,
        localStorage.getItem('token'),
        nft.n_id,
      );

      if (resData) {
        setIsFollowUser(false);
      }
      // console.log('follow resData', resData);

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  let followNftButtonBody;

  if (isAuth && nft) {
    followNftButtonBody = (
      <div className={classes.followNftButton}
        tilte="After following NFT and enabled notification in user page, you will get notification when offer is created."
      >
        <span
          onClick={() => { 
            setShowInfo(!showInfo); 
          }}
        >
          <InfoIcon />
        </span>
        {isFollowUser && (
          <Button
            style={{border: "0.25px solid gray"}}
            variant="tertiary"
            size="small"
            disabled={isLoading}
            onClick={unFollowNftHandler}
          >
            {t('singleNFT.04', 'stop following')}
          </Button>
        )}

        {!isFollowUser && (
          <Button
            style={{border: "0.25px solid gray"}}
            variant="tertiary"
            size="small"
            disabled={isLoading}
            onClick={followNftHandler}
          >
            {t('singleNFT.03', 'start following this')} NFT
          </Button>
        )}

        {isLoading && <Loader />}
      </div>
    );
  }


  return (
    <Fragment>
      <div>
        {followNftButtonBody}

        {showInfo && (
          <InfoBlock 
            // variant={InfoBlock.variant.error}
          >
            After following NFT and enabled notification in user page, you will get notification when comment was created. 
          </InfoBlock>
        )}
      </div>

    </Fragment>
  );
}

export default FollowNftButton;
