import React, { useState, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as StellarSdk from "@stellar/stellar-sdk";
import { useDispatch } from "react-redux";
import {
  Button,
  InfoBlock,
  Input,
  TextLink,
  Modal,
  Icon,
  Identicon,
} from "@stellar/design-system";
import { useTranslation } from 'react-i18next';

import { useRedux } from "hooks/useRedux";
import { ActionStatus, AuthType } from "types/types";
// import { stroopsFromLumens } from 'helpers/stroopConversion';
// import { FailedTransaction } from '../../../SendTransaction/FailedTransaction';
// import { SuccessfulTransaction } from 'components/SendTransaction/SuccessfulTransaction';
import { fetchAccountAction } from 'ducks/account';
import { logEvent } from 'helpers/tracking';
import { getNetworkConfig } from 'helpers/getNetworkConfig';
import { sendTxAction, resetSendTxAction } from 'ducks/sendTx';

import GetAvailableXlm from '../../UiComponent/GetAvailableXlm.jsx';
import GetNetworkFee from '../../UiComponent/GetNetworkFee.jsx';

import { postOfferPush } from '../../../../utils/pushNotification/push-notify';

// import Backdrop from '../../Backdrop/Backdrop.jsx';
// import Loader from '../../Loader/Loader.jsx';
// import SuccessTx from '../../SuccessTx.jsx';
// import FailedTx from '../../FailedTx.jsx';
// import TxFlow from '../TxFlow.tsx';

import TxFlow from '../../TxFlow/TxFlow.jsx';

import { 
  createBuyOfferXdr,
  getInstructionsMessage,
  fetchNetworkFee,
  getAccountInfo,
  makeBuyOfferXdr,
} from '../../../../utils/nft/offer-util';
import { addNewAssetData } from '../../../../utils/nft/account-data-util';

import { useStore } from '../../../../hook-store/store';

import { txConfig } from '../../transactionConfig.ts';

import { BACKEND_URL } from '../../../../urlLinks';

import classes from './CreateNftBuyOffer.module.css';

function CreateNftBuyOffer(props) {
  // console.log('CreateNftBuyOffer.js-props', props);
  
  const { 
    nft,
    offer, 
    buyCode,
    // buyNftHandler,
    getAssetBuyOffersHandler,
    getAssetSellOffersHandler,
    // isLoading,
  } = props;

  const [t] = useTranslation('translation');

  const dispatch = useDispatch();

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    "memoRequiredAccounts",
    "settings",
    "sendTx",
  );
  const { status, errorString } = sendTx;

  const publicKey = account.data ? account.data.id : '';


  const [hStore, hDispatch] = useStore();
  const feeStats = hStore.nftStore.feeStats;
  // const showTxModal = hStore.nftStore.showTxModal;
  const txXdr = hStore.nftStore.txXdr;
  const availableXLM = hStore.nftStore.availableXLM;


  const [buyPriceInput, setBuyPriceInput] = useState('');
  const [buyAmountInput, setBuyAmountInput] = useState('');
  const [txFeeInput, setTxFeeInput] = useState('');

  const [stateTxXdr, setStateTxXdr] = useState('');
  // const [txSequence, setTxSequence] = useState('');
  const [showBuyModal, setShowBuyModal] = useState(false);
  
  // const [availableXLM, setAvailableXLM] = useState(0);
  const [accountData, setAccountData] = useState();

  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    const fetchNetworkFeeHandler = async () => {
        const getStats = await fetchNetworkFee(settings);

        if (getStats) {
            hDispatch('SET_FEESTATS', getStats);
            setTxFeeInput( 
              (Number(getStats.networkFeeXLM * 1.1) * 10**7).toFixed() );
        }
    };

    if (!feeStats || feeStats.getTime < Date.now() - 1000*60*1 ||
          status === ActionStatus.ERROR) {
      fetchNetworkFeeHandler();
    }
  },[buyPriceInput, buyAmountInput, status]);

  useEffect(() => {
    console.log('status', status, sendTx);
    if (txXdr && stateTxXdr && txXdr === stateTxXdr) {
      if ((sendTx.data && 
          // sendTx.data.source_account_sequence === txSequence && 
          status === ActionStatus.SUCCESS) || 
          status === ActionStatus.ERROR
      ) {

        if (sendTx.data && sendTx.data.envelope_xdr) {
          const txData = new StellarSdk.Transaction(
            sendTx.data.envelope_xdr,
            getNetworkConfig(settings.isTestnet).network,
          );
          
          let txOp = txData._operations[0];

          if (txData._operations.length > 2) {
            txOp = txData._operations[1];
          }

          console.log('txData status', txData);
          buyResultHandler(
            { code: nft.code, issuer: nft.issuer },
            txOp,
            // sendTx.data.offerResults[0].currentOffer,
          );  

        } else {
          buyResultHandler(
            { code: nft.code, issuer: nft.issuer },
            null,
            // sendTx.data.offerResults[0].currentOffer,
          );
        }

      }
    }
  },[status]);

  useEffect(() => {
    if (!status) {
      closeBuyModalHandler();
    }
  },[status]);


  // useEffect(() => {
  //   const fetchAccountActionHandler = async () => {
  //     const accountDetails = await dispatch(fetchAccountAction(publicKey));
  //     // console.log('accountDetails, fetchaccountaction', accountDetails);
  
  //     const nativeBalances = accountDetails.payload.data.balances.native;
  //     const availXLM = 
  //     nativeBalances.available.minus(nativeBalances.minimumBalance);
  //     // nativeBalances.available - nativeBalances.minimumBalance;
  //     // console.log('accountDetails, nativeBalances', nativeBalances);
  //     // console.log('accountDetails availableXLM', availXLM,
  //     //   nativeBalances.available, nativeBalances.minimumBalance,
  //     // ); 
  
  //     setAvailableXLM(availXLM);
  
  //   };
  
  //   if (publicKey && showBuyModal) {
  //     fetchAccountActionHandler();
  //   }
  
  // },[publicKey, showBuyModal]);


  useEffect(() => {
    const getAccountDataHandler = async () => {
      try {
        const accountInfo = await getAccountInfo(account.data.id);
        console.log('accountInfo', accountInfo);
        if (accountInfo) {
          setAccountData(accountInfo);
        }
      } catch(err) {
        console.log(err);
      }
    };

    if (account.data) {
      getAccountDataHandler();
    }
  },[account.data]);


  useEffect(() => {
    if (offer && !buyPriceInput && !buyAmountInput) {
      setBuyPriceInput(Number(offer.price)/10**7);
      setBuyAmountInput(Number(offer.amount)*10**7);
    }

  },[offer, buyPriceInput, buyAmountInput]);


  const buyPriceInputChangeHandler = (event) => {
    setBuyPriceInput(event.target.value);
  };

  const buyAmountInputChangeHandler = (event) => {
    setBuyAmountInput(event.target.value);
  };

  const feeInputChangeHandler = (event) => {
      console.log(event.target.value);
      // const stroopAmount = stroopsFromLumens(event.target.value);
      // setTxFeeInput(stroopAmount.c);
      const stroopAmount = Number(event.target.value) * 10**7;
      
      if (feeStats && 
            stroopAmount >= Number(feeStats.networkFeeXLM) * 10**7
          ) {
        setTxFeeInput(stroopAmount.toFixed());
      }
      if (!feeStats && stroopAmount >= 100) {
        setTxFeeInput(stroopAmount.toFixed());
      }
    
  };

  const openBuyModalHandler = () => {
    setShowBuyModal(true);
    setBuyAmountInput('');
    setBuyPriceInput('');
  };

  const closeBuyModalHandler = () => {
    setShowBuyModal(false);
    // dispatch(resetSendTxAction());
  };

  // const setUpdateAccountInfo = async (accountId) => {
  //   const accountInfo = await getAccountInfo(accountId);
  //     hDispatch('SET_ALBEDO_ACCOUNTINFO', accountInfo);
  // };

  const buyNftHandler = async (
    url, 
    token, 
    accountId,
    sellAsset,
    buyAsset,
    price,
    buyAmount,
    feeInput,
  ) => {
    try {
      setIsLoading(true);

      // const resData = await createBuyOfferXdr(
      //   url, 
      //   token, 
      //   accountId,
      //   sellAsset,
      //   buyAsset,
      //   price,
      //   buyAmount,
      //   feeInput,
      // );

      console.log('feeInput', feeInput);
      const resData = await makeBuyOfferXdr(
        accountId,
        new StellarSdk.Asset(sellAsset.code, sellAsset.issuer),
        new StellarSdk.Asset(buyAsset.code, buyAsset.issuer),
        price,
        buyAmount,
        feeInput,
        settings,
      );

      if (resData) {
        console.log(resData);

        const xdrTx = resData.data;

        const transaction = new StellarSdk.Transaction(
            xdrTx,
            getNetworkConfig(settings.isTestnet).network,
        );
        console.log('transaction', transaction);
        
        // setTxSequence(transaction._sequence);
        setStateTxXdr(xdrTx);
        hDispatch('SET_TXXDR', xdrTx);

        dispatch(sendTxAction(transaction));
        logEvent("createBuyNftOffer: transactions", {
            txOperations: transaction._operations,
            // amount: formData.amount.toString(),
            //   "used federation address": !!formData.federationAddress,
            //   "used memo": !!formData.memoContent,
        });
        
      }

      // await getAssetBuyOffersHandler(
      //   'credit_alphanum12', 
      // buyAsset.code, 
      // buyAsset.issuer
      // );
      // await getAssetSellOffersHandler(
      //   'credit_alphanum12', 
      //   buyAsset.code, 
      //   buyAsset.issuer
      // );
      // // await setUpdateAccountInfo(accountId);

      // setBuyAmountInput('');
      // setBuyPriceInput('');

      // hDispatch('SHOW_NOTIFICATION', {
      //   status: '',
      //   title: '',
      //   message: 'buy offre created',
      // });

      // setTimeout(() => {
      //   hDispatch('CLEAR_NOTIFICATION');
      // }, 3000);


      // setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);

      // if (!err.error.code) {
      //   hDispatch('SHOW_NOTIFICATION', {
      //     status: '',
      //     title: '',
      //     message: `Error: buy offre failed`,
      //   });
      // }
    }
  };

  
  const buyResultHandler = async (buyAsset, offerData) => {
    if (status === ActionStatus.SUCCESS) {
      getAssetBuyOffersHandler('credit_alphanum12', buyAsset.code, buyAsset.issuer);
      getAssetSellOffersHandler('credit_alphanum12', buyAsset.code, buyAsset.issuer);
      // await setUpdateAccountInfo(accountId);
      console.log('status buyResutHandler', status);

      setBuyAmountInput('');
      setBuyPriceInput('');
  
      dispatch(resetSendTxAction());
      // dispatch(fetchAccountAction(publicKey));

      hDispatch('SHOW_NOTIFICATION', {
        status: '',
        title: '',
        message: `${t('singleNFT.06', 'trading buy offer')} ${t('general.text30', 'created')}`,
      });
  
      setTimeout(() => {
        hDispatch('CLEAR_NOTIFICATION');
      }, 5000);

      setIsLoading(false);

      addNewAssetDataHandler(buyAsset.code, buyAsset.issuer);

      // postOfferPush(
      //   BACKEND_URL, 
      //   localStorage.getItem('token'), 
      //   nft.n_id,
      //   null,
      //   offerData,
      // );
    }

    if (status === ActionStatus.ERROR) {
      console.log('errorString', errorString);
      setIsLoading(false);

      // if (!err.error.code) {
      //   hDispatch('SHOW_NOTIFICATION', {
      //     status: '',
      //     title: '',
      //     message: `Error: buy offre failed,
      //       ${sendTx.errorString}`,
      // });
    }
  };

  const addNewAssetDataHandler = async (code, issuer) => {
    const addedList = await addNewAssetData(code, issuer);

    if (addedList) {
      localStorage.setItem('assetDataList', JSON.stringify(addedList));
      hDispatch('SET_ASSETINFOLIST', addedList.assetInfoList);
    }
  };


  let hasTrust = false;
  let buyingAssetType;
  let buyAssetBalance;
  let buyAssetBalanceAmount = 0;
  let noXLMAvailable = 0;
  let enoughNoXLM = false;

  if (offer) {
    buyingAssetType = offer.buying.asset_type;
  } 

  if (nft && accountData) {
    const hasBuyAssetTrust = accountData.balances.find(balance => (
      balance.asset_code === nft.code && 
        balance.asset_issuer === nft.issuer
        // Number(balance.balance) > 0
      ));

    if (hasBuyAssetTrust) {
      hasTrust = true;
    }
  }

  if (offer && accountData) {
    if (offer.buying.asset_type === 'native') {
      buyAssetBalance = accountData.balances.find(balance => {
        return  balance.asset_type === 'native';
      });

      if (buyAssetBalance) {
        buyAssetBalanceAmount = Number(buyAssetBalance.balance);
      }

      enoughNoXLM = true;

    } else {
      buyAssetBalance = accountData.balances.find(balance => {
        return balance.asset_code === offer.buying.asset_code &&
        balance.asset_issuer === offer.buying.asset_issuer 
      });

      if (buyAssetBalance) {
        buyAssetBalanceAmount = Number(buyAssetBalance.balance);
        noXLMAvailable = Number(buyAssetBalance.balance) - Number(buyAssetBalance.selling_liabilities);
      
        if (noXLMAvailable >= (Number(buyPriceInput)) ) {
          enoughNoXLM = true;
        }

        console.log('noXLMAvailable', offer.buying.asset_code, noXLMAvailable, buyAssetBalanceAmount, (Number(buyPriceInput)), enoughNoXLM);
      }

    }
  }

  console.log('buyAssetBalance', buyAssetBalance, offer);
  
  // console.log('feeStats', feeStats);

  const fee = '100000';
  let useFee = fee;
  let networkFee = 0;

  let opNum = 2;

  let minXLM = txConfig.txFee;
  let enoughXLM = false;

  let inputFee = fee;
  

  if (feeStats) {
    networkFee = Number(feeStats.fee_charged.mode);
    
    if (!hasTrust) {
      opNum = 3;
    }

    inputFee = Number(networkFee)*1.1;
    inputFee = Number(networkFee)*10;
    useFee = inputFee*opNum / 10**7;

  } else {
    useFee = Number(useFee)*opNum / 10**7;
  }

  
  if (buyingAssetType === 'native') {

    if (buyPriceInput && buyAmountInput) {
      minXLM = minXLM + Number(buyPriceInput)*Number(buyAmountInput);
    }

    if (!hasTrust) {
      minXLM  = minXLM + useFee + txConfig.createTrustXLM;
    }

    if (hasTrust) {
      minXLM = minXLM + useFee;
    }

  } else {
    if (!hasTrust) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      minXLM = useFee + txConfig.createTrustXLM;
    }
    if (hasTrust) {
      minXLM = useFee;
    }
  }

  minXLM = minXLM + txConfig.offerEntryReserve;
  
  if (availableXLM >= minXLM) {
    enoughXLM = true;
  }

  console.log('minXLM', minXLM, useFee);

  let additionalMessage = (<span>
    ({`${t('singleNFT.22','including transaction fee')} ${txConfig.txFee.toFixed(2)} XLM`})
  </span>);

  if (!hasTrust) {
    additionalMessage = (<span>
      ({`${t('singleNFT.22','including transaction fee')} ${txConfig.txFee.toFixed(2)} XLM & ${txConfig.createTrustXLM} XLM for creating trustline to this asset`})
    </span>);
  }
  

  let createNftBuyOfferBody;

  // eslint-disable-next-line prefer-const
  createNftBuyOfferBody = (
    <div>
      <div className={classes.createBuyOfferInput}>
        <label>
          {t('general.text24', 'price')}
          {' '}
          (0.0000001 ~ 200)
        </label>
        <Input 
          type="text"
          onChange={buyPriceInputChangeHandler}
          value={buyPriceInput}
          placeholder='price'
        />
        {buyPriceInput && (
          <div>
            {buyPriceInput}
            {' '} 
            ({(Number(buyPriceInput)*10000000).toFixed()} {nft.code}/{buyCode})
          </div>
        )}
      </div>

      <div className={classes.createBuyOfferInput}>
        <label>
          {t('general.text23', 'amount')}
          {' '}
          (1 ~)
        </label>
        <Input 
          type="text"
          onChange={buyAmountInputChangeHandler}
          value={buyAmountInput}
          placeholder='amount'
        />
        {buyAmountInput && (
          <div>
            {buyAmountInput} 
            {' '}
            ({(Number(buyAmountInput)*0.0000001).toFixed(7)} {nft.code})
          </div>
        )}
        
      </div>

      <div className={classes.createBuyOfferInput}> 
        <div>{t('singleNFT.33', 'selected trading sell offer')}</div>
        {t('singleNFT.26', 'price')}: {(Number(offer.price)/10**7).toFixed(7)} {buyCode}
        , {t('singleNFT.25', 'amount')}: {(Number(offer.amount)*10**7).toFixed()} 
      </div>

      <div className={classes.createBuyOfferAvailableXlm}>
        <InfoBlock>
          <div className={classes.createBuyOfferAvailableXlm}>
            <div>
              {t('general.text25', 'available')}
              {' '} 
              XLM: {availableXLM && availableXLM.toFixed(7)}</div>
            <div>
              {t('general.text26', 'required')}
              {' '} 
              XLM: {minXLM.toFixed(7)}
              {' '}  
              {additionalMessage}
            </div>


            {buyingAssetType !== 'native' && buyAssetBalance && (
              <div>
                <br/>
                {t('singleNFT.34', 'you have')} {buyAssetBalanceAmount} {buyCode},
                {' '}
                {t('singleNFT.35', 'You are using')} {buyAssetBalance.selling_liabilities} 
                {' '}
                {buyCode} {t('singleNFT.36', 'in trading buy offers,')} 
                {' '}
                {noXLMAvailable.toFixed(7)} {t('singleNFT.37', 'available')}
                {/* <br/>
                {noXLMAvailable} */}
              </div>
            )}

            {buyingAssetType !== 'native' && !buyAssetBalance && (
              <div>
                <br/>
                {t('singleNFT.38', 'you do not have')} {buyCode}
              </div>
            )}
        
          </div>

          {buyAmountInput && buyPriceInput && 
            settings.authType && settings.authType !== AuthType.PRIVATE_KEY && (
            <div>
              {t('singleNFT.20','Numbers in confirm screen of wallet will be different from your inputs.')}
              {' '}
              {t('singleNFT.21','This is because to keep indivisible unit of NFT')}
            </div>
          )}
        </InfoBlock>
      </div>
    
      <Button 
        disabled={isLoading || !buyPriceInput || 
          Number(buyPriceInput) < 0.0000001 || Number(buyPriceInput) > 200 ||
          !buyAmountInput || !enoughXLM || !enoughNoXLM
        }
        isLoading={isLoading}
        onClick={() => {

          let sellAsset = { code: 'XLM', issuer: ''};

          if (offer.buying.asset_type !== 'native') {
            sellAsset = { 
              code: offer.buying.asset_code, 
              issuer: offer.buying.asset_issuer, 
            };
          }

          // console.log('sellAsset', sellAsset)

          buyNftHandler(
            BACKEND_URL, 
            localStorage.getItem('token'),
            publicKey,
            sellAsset,
            { code: nft.code, issuer: nft.issuer },
            (Number(buyPriceInput)*10000000).toFixed(7),
            (Number(buyAmountInput)*0.0000001).toFixed(7),
            (Number(inputFee)).toFixed(), // txFeeInput,
          );
        }}
      >
        {t('singleNFT.07', 'create trading buy offer')}
      </Button>
      
      {status === ActionStatus.PENDING &&
        settings.authType &&
        settings.authType !== AuthType.PRIVATE_KEY && (
          <InfoBlock>{getInstructionsMessage(settings.authType)}</InfoBlock>
      )}
    </div>
  );


  return (
    <Fragment>

      {isLoading && (
        <div>
          {/* <Backdrop /> */}
          {/* <Loader /> */}
        </div>
      )}

      {account && account.data && (
        <Button 
          size="small"
          onClick={() => {
            openBuyModalHandler();
            // hDispatch('SET_SHOWTXMODAL', true);
          }}
        >
          {t('singleNFT.07', 'create trading buy offer')}
        </Button>
      )}

      <Modal
        // visible={isSendTxModalVisible || isReceiveTxModalVisible}
        visible={showBuyModal}
        // onClose={resetModalStates}
        onClose={() => {
            closeBuyModalHandler();
            dispatch(resetSendTxAction());
        }}
      >
        {showBuyModal && (
          <TxFlow
            onCancel={() => {
              // setIsSendTxModalVisible(true);
              // resetModalStates();
              
                closeBuyModalHandler();
                dispatch(resetSendTxAction());
              
            }}
            createTxBody={createNftBuyOfferBody}
            isLoading={isLoading}
          />
        )}
        {/* {isReceiveTxModalVisible && <ReceiveTransaction />} */}
      </Modal>
       

      {/* <Modal 
        // visible={isSendTxModalVisible || isReceiveTxModalVisible}
        // onClose={resetModalStates}
        // visible={showBuyModal}
        visible={false}
        onClose={() => {
          closeBuyModalHandler();
          // dispatch(resetSendTxAction());
          // hDispatch('SET_SHOWTXMODAL', false);
        }}
      >
        {(!status || status === ActionStatus.PENDING) && showBuyModal && (
          <div style={{border: "2px solid green"}}>
            {createNftBuyOfferBody}
          </div>
        )}
    
        {status === ActionStatus.ERROR && (
          <FailedTx 
            onEditTransaction={() => {
              dispatch(resetSendTxAction());
            }}
            onCancel={() => {
              closeBuyModalHandler();
              dispatch(resetSendTxAction());
              // hDispatch('SET_SHOWTXMODAL', false);
            }} 
          />
        )}

        {status === ActionStatus.SUCCESS && (
          <SuccessTx
            // onRestartFlow={() => {
            //   console.log('onRestartFlow');
            // }}
            onCancel={() => {
              closeBuyModalHandler();
              // dispatch(resetSendTxAction());
              // hDispatch('SET_SHOWTXMODAL', false);
            }} 
        />
        )}

        {isLoading && (
        <div>
          <Loader />
        </div>
      )}
      </Modal> */}

      {showBuyModal && (
        <div>
          <GetNetworkFee />
          <GetAvailableXlm />
        </div>
      )}

    </Fragment>
  );
}

export default CreateNftBuyOffer;
