import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "@stellar/design-system";
import Img from "react-cool-img";
import { useTranslation } from 'react-i18next';

import { useStore } from "../../../../hook-store/store";
// import { getUserData } from "../../../../utils/user";

import { BACKEND_URL } from "../../../../urlLinks";

import { marks } from "../../../../images/icons/marks";

import classes from './UserFollowNftsItem.module.css';


const UserFollowNftsItem = (props) => {
  const { nft } = props;

  const [t] = useTranslation('translation');

  const [hStore, hDispatch] = useStore();
  // const userData = hStore.uiStore.userData;
  // const isAdmin = store.uiStore.isAdmin;

  // const [userData, setUserData] = useState();

  // useEffect(() => {}, []);

  const fileType = nft.mimetype.split('/')[0];

  let mediaElBody;

  if (fileType === 'image') {
    mediaElBody = (
      <Img style={{maxHeight:"1.5rem"}} 
        // src={nft.smallImageUrl ? nft.smallImageUrl : nft.fileUrl} 
        src={nft.image_url}
        // src={`${IPFS_URL}/ipfs/${nft.image_cid}`}
        alt="imagedata"
      />
    );
  }

  if (fileType === 'video') {
      mediaElBody = (
        <span>({t('userInfo.text15', 'video')})</span>
        // <video style={{maxWidth:"150px"}} 
        //   src={nft.trimMediaUrl ? nft.trimMediaUrl : nft.fileUrl} 
        //   // src={`${IPFS_URL}/ipfs/${nft.image_cid}`}
        //   alt="videodata" muted 
        //   // controls 
        //   autoPlay={true}
        //   loop
        // />
      );
    }


  if (fileType === 'audio') {
    mediaElBody = (
      <span>({t('userInfo.text14', 'audio')})</span>
      // <audio style={{maxWidth: "100%"}} 
      //   src={nft.trimMediaUrl ? nft.trimMediaUrl : nft.fileUrl} 
      //   // src={`${IPFS_URL}/ipfs/${nft.image_cid}`}
      //   alt="audiodata" controls
      // />
    );
  }


  let userFollowNftsItemBody;

  if (nft) {
    userFollowNftsItemBody = (
      <span className={classes.userFollowNftsItem}>
        <Link to={`/${nft.n_id}`}>
          <span>{nft.name}</span>
        </Link>
        <Link to={`/${nft.n_id}`}>
          <span>{mediaElBody}</span>
        </Link>
      </span>
    );
  }
 

  return <Fragment>
    <div>
      {userFollowNftsItemBody}
    </div>
  </Fragment>;
};

export default UserFollowNftsItem;
