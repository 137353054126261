import React, { Fragment, useEffect } from "react";
// import { useDispatch } from "react-redux";

import { useRedux } from "hooks/useRedux";
// import { fetchAccountAction } from "ducks/account";

import { useStore } from '../../../hook-store/store';
import { fetchNetworkFee } from '../../../utils/nft/offer-util';

function GetNetworkFee() {

    // const dispatch = useDispatch();

    // const { settings } = useRedux("settings");
    const { account, settings } = useRedux(
        "account",
        // "memoRequiredAccounts",
        "settings",
        // "sendTx",
      );

    // const publicKey = account.data ? account.data.id : '';
     

    const [hStore, hDispatch] = useStore();


    useEffect(() => {
        const fetchNetworkFeeHandler = async () => {
            const feeStats = await fetchNetworkFee(settings);

            if (feeStats) {
                hDispatch('SET_FEESTATS', feeStats);
            }
        };

        fetchNetworkFeeHandler();

        const intervalGet = setInterval(() => {
            fetchNetworkFeeHandler();
        }, 1000*30);

        return (() => {
            clearInterval(intervalGet);
        });
    },[]);

    // useEffect(() => {
    //     const fetchAccountActionHandler = async () => {
    //       const accountDetails = await dispatch(fetchAccountAction(publicKey));
      
    //       const nativeBalances = accountDetails.payload.data.balances.native;
    //       const availXLM = 
    //       nativeBalances.available.minus(nativeBalances.minimumBalance);
    
    //       hDispatch('SET_AVAILABLEXLM', availXLM);
    //     //   setAvailableXLM(availXLM);
    //     };
      
    //     let intervalAvailabelGet;
        
    //     if (publicKey) {
    //       fetchAccountActionHandler();

    //       intervalAvailabelGet = setInterval(() => {
    //         fetchAccountActionHandler();
    //       }, 1000*30);
    //     }
      
    //     return (() => {
    //         clearInterval(intervalAvailabelGet);
    //     })
    //   },[publicKey]);

    return (
        <Fragment>
        </Fragment>
    );
}

export default GetNetworkFee;