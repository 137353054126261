
import { 
    // isExpired, 
    decodeToken,
} from "react-jwt";

import { BACKEND_URL } from '../urlLinks';

// eslint-disable-next-line max-len
export const updateUserInfo = (url, token, userId, email, name, firebaseUserData, fierbaseAdditionalUserInfo) => {
  console.log('in updateUserInfo');
  return new Promise((resolve) => {

    fetch(`${url  }/auth/update-user-info`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${  token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId,
        email,
        name,
        firebaseUserData,
        fierbaseAdditionalUserInfo,
        geolocation: localStorage.getItem('userLocation'),
        }),
      })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("Updating user info failed!");
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);
        resolve({ message: 'Update user info success', data: resData.data });
      })
      .catch(err => {
        console.log(err);
        // reject({ message: 'Update user info failed', error: err });
      });

  });
};

export const getAuthInfo = () => new Promise((resolve, reject) => {
    const currentUrl = new URL(window.location.href);
    const queryParams = currentUrl.searchParams;
    // console.log(currentUrl);
  
    if (queryParams.get('tokenForCasTGT') || queryParams.get('casTGT')) {
  
      const token = queryParams.get('tokenForCasTGT');
  
      localStorage.setItem('tokenForCasTGT', token);
      localStorage.setItem('casTGT', queryParams.get('casTGT'));
      localStorage.setItem('TGTexp', queryParams.get('TGTexp'));
      // localStorage.setItem('casUserId', queryParams.get('casUserId'));
  
      // const jwtdecoded = jwt.decode(token);

      const jwtdecoded = decodeToken(token);
      console.log(jwtdecoded);
  
      // throw new Error('error-error');

      const userId = jwtdecoded.userId;
      const email = jwtdecoded.email;
      const name = jwtdecoded.name;
      const firebaseUserData = jwtdecoded.firebaseUserData;
      const fierbaseAdditionalUserInfo = jwtdecoded.fierbaseAdditionalUserInfo;
      // const firebaseProviderId = jwtdecoded.firebaseProviderId;

      updateUserInfo(
        BACKEND_URL, 
        token,
        userId, 
        email,
        name,
        firebaseUserData,
        fierbaseAdditionalUserInfo,
      )
      .then(result => {
        console.log(result);
  
        localStorage.setItem('token', token);
        localStorage.setItem('userId', userId);
        localStorage.setItem('email', email);
        const tokenExpire = new Date(Number(localStorage.getItem('TGTexp')) * 1000);
        console.log('tokenExpire', tokenExpire);
        localStorage.setItem('expiryDate', tokenExpire);
        
        localStorage.setItem('name', result.data.user.name);
  
        // localStorage.removeItem('casTGT');
        // localStorage.removeItem('tokenForCasTGT');
        // localStorage.removeItem('TGTexp');
        
        resolve({ message: 'login success' });
      })
      .catch(err => {
        console.log(err);
  
        localStorage.removeItem('casTGT');
        localStorage.removeItem('tokenForCasTGT');
        localStorage.removeItem('TGTexp');
  
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({ message: 'login failed' });
      });

    } else {
      resolve('no TGTtoken');
    }
  });


export const logoutHandler = () => {

  localStorage.removeItem('token');
  localStorage.removeItem('expiryDate');
  localStorage.removeItem('userId');
  localStorage.removeItem('name');
  localStorage.removeItem('email');
  localStorage.removeItem('userLocation');

  localStorage.removeItem('casTGT');
  localStorage.removeItem('tokenForCasTGT');
  localStorage.removeItem('TGTexp');

  // ////remove stripe user info
  // stripeLogout();
  
  // window.location.reload();
  // this.props.history.push('/');
};


export const getUserData = async (url, token) => {
  try {
    const result = await fetch(`${url  }/auth/userData`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${  token}`,
        'Content-Type': 'application/json',
      },
      // body: ...,
    });

    const resData = await result.json();

    if (!result.ok) {
      throw new Error('something worng');
    }

    console.log(result, resData);

    return resData;
  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const getBackendApi = async (url, token) => {
  try {
    const result = await fetch(`${url  }/example`, {
      method: 'GET',
      headers: {
        // Authorization: `Bearer ${  token}`,
        'Content-Type': 'application/json',
      },
      // body: ...,
    });

    const resData = await result.json();

    if (!result.ok) {
      throw new Error('something worng');
    }

    console.log(result, resData);

    return resData;
  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const getUsers = async (url, token) => {
  try {
    const result = await fetch(`${url  }/auth/getusers`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${  token}`,
        'Content-Type': 'application/json',
      },
      // body: ...,
    });

    const resData = await result.json();

    if (!result.ok) {
      throw new Error('something worng');
    }

    console.log(result, resData);

    return resData;
  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const getUserDescription = async (url, token, uId) => {
  try {
    const result = await fetch(`${url  }/auth/description?uId=${uId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${  token}`,
        'Content-Type': 'application/json',
      },
      // body: ...,
    });

    const resData = await result.json();

    if (!result.ok) {
      throw new Error('something worng');
    }

    console.log(result, resData);

    return resData;
  } catch(err) {
    console.log(err);
    throw err;
  }
};