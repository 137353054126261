import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import { Layout } from "@stellar/design-system";

import { store } from "config/store";
import { Network } from "components/Network";
// import { PrivateRoute } from "components/PrivateRoute";
// import { Header } from "components/Header";

// import { Dashboard } from "pages/Dashboard";
import { Landing } from "pages/Landing";
import { NotFound } from "pages/NotFound";

import AuthCheck from "components/IssuerFrontend/Auth/AuthCheck.jsx";
import GetAccountAssetInfo from "components/IssuerFrontend/UiComponent/GetAccountAssetInfo.jsx";
import GetAuth from "components/IssuerFrontend/Auth/GetAuth.jsx";
import GetDefferredPrompt from "components/IssuerFrontend/UiComponent/GetDeferredPrompt.jsx";
// import GetUsers from "components/IssuerFrontend/UiComponent/GetUsers.jsx";
import GetWindowData from "components/IssuerFrontend/UiComponent/GetWindowData.jsx";
import SetUserNameData from "components/IssuerFrontend/Auth/SetUserNameData.jsx";
import IssuerFrontend from "components/IssuerFrontend/IssuerFrontend.jsx";
import SingleNft from "components/IssuerFrontend/NftList/SingleNft/SingleNft.jsx";
import UserInfo from "components/IssuerFrontend/pages/UserInfo/UserInfo.jsx";
import IssuerHeader from "components/IssuerFrontend/Layout/IssuerHeader.jsx";
import IssuerFooter from "components/IssuerFrontend/Layout/IssuerFooter.jsx";
import UserNftList from "components/IssuerFrontend/UserNftList/UserNftList.jsx";
import UserSocketConnect from "components/IssuerFrontend/SocketConnect/UserSocketConnect.jsx";
import UpdateLsUserList from "components/IssuerFrontend/UiComponent/UpadateLsUserList.jsx";

import AboutPage from "components/IssuerFrontend/pages/AboutPage/AboutPage.jsx";
import PrivacyPolicy from "components/IssuerFrontend/pages/PrivacyPolicy/PrivacyPolicy.jsx";
import TermsOfUse from "components/IssuerFrontend/pages/TermsOfUse/TermsOfUse.jsx";
import UserOffers from "components/IssuerFrontend/pages/UserOffers/UserOffers.jsx";


import "./styles.scss";

import { useStore } from './hook-store/store';


export const App = () => {

  const hStore = useStore()[0];
  console.log('hStore-in-App.tsx', hStore);

  const routes = (
    <Routes>
      <Route path="/wallet-login" element={<Landing />} />
      <Route
        path="/dashboard"
        element={
          <IssuerFrontend />
          // <PrivateRoute>
          //   <Dashboard />
          // </PrivateRoute>
        }
      />
      <Route
        path="/uploads"
        element={<IssuerFrontend />}
      />
      <Route
        path="/user-uploads"
        element={<IssuerFrontend />}
      />
      <Route
        path="/creator-nftlist"
        element={<UserNftList />}
      />
      <Route
        path="/userinfo"
        element={<UserInfo />}
      />
      <Route
        path="/useroffers"
        element={<UserOffers />}
      />
      <Route
        // path="/uploads/:nId"
        path="/:nId"
        element={<SingleNft />}
      />
      <Route
        path="/privacypolicy"
        element={<PrivacyPolicy />}
      />
      <Route
        path="/termsofuse"
        element={<TermsOfUse />}
      />
      <Route
        path="/aboutpage"
        element={<AboutPage />}
      />

      <Route
        path="/"
        element={<IssuerFrontend />}
      />

      <Route path="*" element={<Navigate to="/uploads" />} />

      <Route element={NotFound} />
    </Routes>
  );

  return (
    <Provider store={store}>
      <Router>
        <Network>
          {/* <Header /> */}
          <IssuerHeader />
          <Layout.Content>
            <div className="issuerLayout">
              {routes}
            </div>
            <AuthCheck />
            <GetAuth />
            {/* <GetUsers /> */}
            <GetAccountAssetInfo />
            <GetDefferredPrompt />
            <GetWindowData />
            <SetUserNameData />
            <UserSocketConnect />
            <UpdateLsUserList />
            {/* <Routes>
              <Route path="/" element={<Landing />} />
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route element={NotFound} />
            </Routes> */}
          </Layout.Content>

          {/* <Layout.Footer gitHubLink="https://github.com/stellar/account-viewer-v2" /> */}
          <IssuerFooter />
        </Network>
      </Router>
    </Provider>
  );
};
