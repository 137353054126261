import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from "react-redux";
import * as StellarSdk from '@stellar/stellar-sdk';
import {
  Button,
  InfoBlock,
  Input,
  TextLink,
  Modal,
  Icon,
  Identicon,
} from "@stellar/design-system";
import { useTranslation } from 'react-i18next';

import { useRedux } from "hooks/useRedux";
import { ActionStatus, AuthType } from "types/types";
import { logEvent } from 'helpers/tracking';
import { fetchAccountAction } from 'ducks/account';
import { sendTxAction, resetSendTxAction } from 'ducks/sendTx';
import { getNetworkConfig } from 'helpers/getNetworkConfig';

// import CreateNftSellOfferCancel from './CreateNftSellOfferCancel';
// import CreateNftSellOfferInputs from './CreateNftSellOfferInputs.jsx';
import GetNetworkFee from '../../UiComponent/GetNetworkFee.jsx';
import TxFlow from 'components/IssuerFrontend/TxFlow/TxFlow.jsx';

import { 
  createSellOfferXdr, 
  createCancelSellOfferXdr,
  getAccountInfo,
  getAssetOffers,
  getAccountOffers,
  checkAssetPosition,
  makeCancelSellOfferXdr,
} from '../../../../utils/nft/offer-util';

// import * as uploadUtils from '../utils/bucket-upload-util';
import { useStore } from '../../../../hook-store/store';

import { BACKEND_URL } from '../../../../urlLinks';

import classes from './CreateNftSellOfferCancel.module.css';

function CreateNftSellOfferCancel(props) {
  const { 
    nft,
    offer,
    getAssetSellOffersHandler,
    // buyCode,
    // createCancelNftSellOfferHandler,
    // isLoading
   } = props;

  const dispatch = useDispatch();

  const [t] = useTranslation('translation');

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    "memoRequiredAccounts",
    "settings",
    "sendTx",
  );
  const { status, errorString } = sendTx;

  const publicKey = account.data ? account.data.id : '';
 

  const [hStore, hDispatch] = useStore();
  const txXdr = hStore.nftStore.txXdr;
  const feeStats = hStore.nftStore.feeStats;

  const [stateTxXdr, setStateTxXdr] = useState('');
  const [showCancelSellModal, setShowCancelSellModal] = useState(false);
  const [availableXLM, setAvailableXLM] = useState(0);

  const [isLoading, setIsLoading] = useState(false);



  useEffect(() => {
    console.log('status', status, sendTx);
    if (txXdr && stateTxXdr && txXdr === stateTxXdr) {
      if ((sendTx.data && 
          // sendTx.data.source_account_sequence === txSequence && 
          status === ActionStatus.SUCCESS) || 
          status === ActionStatus.ERROR
      ) {
        cancelSellOfferResultHandler({ code: nft.code, issuer: nft.issuer });
      }
    }
  },[status]);

  useEffect(() => {
    if (!status) {
      setShowCancelSellModal(false);
      // closeBuyModalHandler();
    }
  },[status]);

  useEffect(() => {
    const fetchAccountActionHandler = async () => {
      const accountDetails = await dispatch(fetchAccountAction(publicKey));
  
      const nativeBalances = accountDetails.payload.data.balances.native;
      const availXLM = 
      nativeBalances.available.minus(nativeBalances.minimumBalance);

      setAvailableXLM(availXLM);
    };
  
    if (publicKey && showCancelSellModal) {
      fetchAccountActionHandler();
    }
  
  },[publicKey, showCancelSellModal]);


  const createCancelNftSellOfferHandler = async (
    url, 
    token, 
    accountId,
    sellAsset,
    buyAsset,
    offerId,
  ) => {
    try {
      setIsLoading(true);

      const accountInfo = await getAccountInfo(accountId);

      console.log(accountInfo);

      if (accountInfo && accountInfo.balances.length > 0) {
        // eslint-disable-next-line max-len
        const hasSellAsset = accountInfo.balances.find(balance => (balance.asset_code === sellAsset.code && 
            balance.asset_issuer === sellAsset.issuer &&
            Number(balance.balance) > 0
          ));

        console.log(hasSellAsset);

        if (!hasSellAsset) {
          throw new Error('not-have-sell-asset');
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-shadow
      const sellOffers = await getAssetOffers(
        'sell', 
        'credit_alphanum12', 
        sellAsset.code, 
        sellAsset.issuer,
      );

      console.log(sellOffers, sellOffers._embedded.records);
      
      if (sellOffers && sellOffers._embedded.records.length === 0) {
        throw new Error('sell-offer-not-exist');
      }

      if (sellOffers && sellOffers._embedded.records.length > 0) {
        // eslint-disable-next-line max-len
        const idOfferExist = sellOffers._embedded.records.find(offer => offer.id === offerId);

        if (!idOfferExist) {
          throw new Error('offerId-sell-offer-not-exist');
        }
      }


      // const resData = await createCancelSellOfferXdr(
      //   url, 
      //   token, 
      //   accountId,
      //   sellAsset,
      //   buyAsset,
      //   offerId,
      // );


      const resData = await makeCancelSellOfferXdr(
        accountId,
        new StellarSdk.Asset(sellAsset.code, sellAsset.issuer),
        new StellarSdk.Asset(buyAsset.code, buyAsset.issuer),
        offerId,
        Number(useFee * 10**7).toFixed(),
        settings,
      );

      if (resData) {
        const xdrTx = resData.data;

        const transaction = new StellarSdk.Transaction(
            xdrTx,
            getNetworkConfig(settings.isTestnet).network,
        );
        console.log('transaction', transaction);
        
        // setTxSequence(transaction._sequence);
        setStateTxXdr(xdrTx);
        hDispatch('SET_TXXDR', xdrTx);

        dispatch(sendTxAction(transaction));
        logEvent("createBuyNftOffer: transactions", {
            txOperations: transaction._operations,
            // amount: formData.amount.toString(),
            //   "used federation address": !!formData.federationAddress,
            //   "used memo": !!formData.memoContent,
        });
      }

      // await getAssetSellOffersHandler();
      // showCreateOfferHandler(false);
      // // await setUpdateAccountInfo(accountId);

      // setIsLoading(false);
    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  const cancelSellOfferResultHandler = async () => {
    if (status === ActionStatus.SUCCESS) {
      // console.log('status sellOfferResutHandler', status);

      getAssetSellOffersHandler();
      // showCreateOfferHandler(false);
      // await setUpdateAccountInfo(accountId);

      dispatch(resetSendTxAction());
      setShowCancelSellModal(false);
      // dispatch(fetchAccountAction(publicKey));

      hDispatch('SHOW_NOTIFICATION', {
        status: '',
        title: '',
        message: `${t('singleNFT.05', 'trading sell offers')} ${t('general.text32', 'canceled')}`,
      });
  
      setTimeout(() => {
        hDispatch('CLEAR_NOTIFICATION');
      }, 5000);

      setIsLoading(false);

    }

    if (status === ActionStatus.ERROR) {
      console.log('errorString', errorString);
      setIsLoading(false);

      // if (!err.error.code) {
      //   hDispatch('SHOW_NOTIFICATION', {
      //     status: '',
      //     title: '',
      //     message: `Error: buy offre failed,
      //       ${sendTx.errorString}`,
      // });
    }
  };


  let networkFee = 0;
  // let minXLM = 0;
  let enoughXLM = false;
  let useFee = 0;

  if (feeStats) {
    networkFee = Number(feeStats.fee_charged.mode);
    useFee = Number(networkFee)*1.1 / 10**7;
    useFee = Number(networkFee)*10 / 10**7;

    if (availableXLM >= useFee) {
      enoughXLM = true;
    }
  } 


  let buyCode;
  let buyAssetInfo;

  if (offer.buying.asset_type === 'native') {
    buyCode = 'XLM';
    buyAssetInfo = { code: 'XLM', issuer: ''};

  } else {
    buyCode = offer.buying.asset_code;
    buyAssetInfo = { 
      code: offer.buying.asset_code, 
      issuer: offer.buying.asset_issuer,
    };
  }


  const createNftSellOfferCancelBody = (
    <div>
      {/* <div>user-sell-offers-length: {sellOffers.length}</div> */}
      <div className={classes.sellOfferCancelConetent}>
        <div>OfferId</div>
        <div><strong>{offer.id}</strong></div>
      </div>

      <div className={classes.sellOfferCancelAvailableXlm}>
        <InfoBlock>
          <div className={classes.sellOfferCancelConetent}>
            {t('singleNFT.17', 'Confirm screen of wallet may show amount 0, price 1 with OfferId.')}
          </div>
          <div className={classes.sellOfferCancelConetent}>
            {t('singleNFT.18', 'This is because inpnuts with amount 0 and some price are required to create transaction for canceling offer.')}
          </div>
          <div className={classes.sellOfferCancelConetent}>
            {t('singleNFT.23', 'Please make sure OfferId is same as confirm screen to cancel offer.')}      
          </div>

          <div className={classes.sellOfferCancelConetent}>
            <div>
              {t('general.text25', 'available')}
              {' '} 
              XLM: {availableXLM && availableXLM.toFixed(7)}</div>
            <div>
              {t('general.text26', 'required')}
              {' '}
              XLM: {useFee.toFixed(7)}</div>
          </div>
        </InfoBlock>
      </div>

      <Button 
        disabled={isLoading || !enoughXLM}
        isLoading={isLoading}
        onClick={() => {
          createCancelNftSellOfferHandler(
            BACKEND_URL, 
            localStorage.getItem('token'),
            publicKey,
            { code: nft.code, issuer: nft.issuer },
            buyAssetInfo,
            offer.id,
          );
        }}
      >
        {t('singleNFT.10', 'cancel sell')}
      </Button>
    </div>
  );


  return (
    <Fragment>
      <Button 
        size="small"
        onClick={() => {
          setShowCancelSellModal(true);
        }}
      >
        {t('singleNFT.10', 'cancel sell')}
      </Button>


      <Modal
          visible={showCancelSellModal}
          // onClose={resetModalStates}
          onClose={() => {
            setShowCancelSellModal(false);
            dispatch(resetSendTxAction());
          }}
        >
          {showCancelSellModal && (
            <TxFlow
              onCancel={() => {
                setShowCancelSellModal(false);
                dispatch(resetSendTxAction());
              }}
              createTxBody={createNftSellOfferCancelBody}
              isLoading={isLoading}
            />
          )}
          {/* {isReceiveTxModalVisible && <ReceiveTransaction />} */}
        </Modal>
        
        {showCancelSellModal && (
          <GetNetworkFee />
        )}
    </Fragment>
  );
}

export default CreateNftSellOfferCancel;
