import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from "react-redux";
import * as StellarSdk from '@stellar/stellar-sdk';
import {
  Button,
  Card,
  InfoBlock,
  Input,
  TextLink,
  Modal,
  Icon,
  Identicon,
  DetailsTooltip,
} from "@stellar/design-system";
import { DataProvider, Types } from "@stellar/wallet-sdk";
import { useTranslation } from 'react-i18next';

import { ActionStatus, AuthType } from "types/types";
import { useRedux } from "hooks/useRedux";
import { getNetworkConfig } from 'helpers/getNetworkConfig';
import { sendTxAction, resetSendTxAction } from 'ducks/sendTx';
import { logEvent } from 'helpers/tracking';
import { fetchAccountAction } from 'ducks/account';

// import Backdrop from '../Backdrop/Backdrop.jsx';
// import { fetchAccountDetails } from 'utils/nft/nft-data-util.js';
import FileUploadPreview from './FileUploadPreview.jsx';
import GetNetworkFee from '../UiComponent/GetNetworkFee.jsx'; 
import IssueDescription from './IssueDescription.jsx';
import InfoIcon from '../UiComponent/Icons/InfoIcon.jsx';
// import Loader from '../Loader/Loader.jsx';
import TxFlow from '../TxFlow/TxFlow.jsx';
import WalletConnectDescription from './WalletConnectDescription.jsx';
import ImageEditorLink from '../pages/AboutPage/ImageEditorLink.jsx';

import { useStore } from '../../../hook-store/store';

import * as fileUtils from '../../../utils/file-util';
import * as uploadUtils from '../../../utils/nft/bucket-upload-util';

import { postCreatorUpdatePush } from '../../../utils/pushNotification/push-notify';

import { txConfig } from '../transactionConfig.ts';
// import * as nftDataUtils from '../../../utils/nft-data-util';

// import { logoutHandler } from '../../../utils/user/user';
import { BACKEND_URL, loginPageLink, signupPageLink } from '../../../urlLinks';

import classes from './FileUpload.module.css';

function FileUpload() {

  const dispatch = useDispatch();

  const [t] = useTranslation('translation');

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    "memoRequiredAccounts",
    "settings",
    "sendTx",
  );
  const { status, errorString } = sendTx;

  const publicKey = account.data ? account.data.id : '';


  const [hStore, hDispatch] = useStore();
  const nftList = hStore.nftStore.nftList;
  const txXdr = hStore.nftStore.txXdr;
  const feeStats = hStore.nftStore.feeStats;
  const isAuth = hStore.uiStore.isAuth;


  // const [selectedFile, setSelectedFile] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [nameInput, setNameInput] = useState('');
  const [titleInput, setTitleInput] = useState('');
  const [amountInput, setAmountInput] = useState(1);
  const [creatorInput, setCreatorInput] = useState('');
  const [descriptionInput, setDescriptionInput] = useState('');
  const [homeDomainInput, setHomeDomainInput] = useState('');
  const [codeInput, setCodeInput] = useState('');

  const [cidInput, setCidInput] = useState('');
  const [nIdInput, setNIdInput] = useState('');

  const [ownerSecretInput, setOwnerSecretInput] = useState('');
  // const [filePreview, setFilePreview] = useState();
  const [filePreviews, setFilePreviews] = useState([]);
  // const [imageCidNameInput, setImageCidNameInput] = useState('');
  // const [imageCidUrlInput, setImageCidUrlInput] = useState('');
  // const [imageCidUrlFile, setImageCidUrlFile] = useState();

  const [stateTxXdr, setStateTxXdr] = useState('');
  const [resDataObj, setResDataObj] = useState();
  const [showIssueModal, setShowIssueModal] = useState(false);
  const [availableXLM, setAvailableXLM] = useState(0);

  const [sendResDataObj, setSendResDataObj] = useState();

  const [getBackendError, setGetBackendError] = useState('');
  
  const [showIssueDescription, setShowIssueDescription] = useState(false);
  const [showWalletConnectDescription, setShowWalletConnectDescription] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

 
  const inputData = {
    name: nameInput,
    title: titleInput,
    amount: amountInput,
    description: descriptionInput,
    homeDomain: homeDomainInput,
    cid: cidInput,
    nId: nIdInput,

    ownerSecret: ownerSecretInput,

    code: codeInput,
    // imageCidUrl: imageCidUrlInput,
    // imageCidName: imageCidNameInput,
    // creator: creatorInput,
    initOwnId: publicKey,
  };



useEffect(() => {
  const fetchAccountActionHandler = async () => {
    const accountDetails = await dispatch(fetchAccountAction(publicKey));
    // console.log('accountDetails, fetchaccountaction', accountDetails);

    const nativeBalances = accountDetails.payload.data.balances.native;
    const availXLM = 
    nativeBalances.available.minus(nativeBalances.minimumBalance);
    // nativeBalances.available - nativeBalances.minimumBalance;

    setAvailableXLM(availXLM);
  };

  if (publicKey && showIssueModal) {
    fetchAccountActionHandler();
  }

},[publicKey, showIssueModal]);


  useEffect(() => {
    if (txXdr && stateTxXdr && txXdr === stateTxXdr) {
      if (status === ActionStatus.PENDING) {
        setIsLoading(true);
      }
  
      if (status === ActionStatus.ERROR && 
          resDataObj && resDataObj.imageUrlObj) {
        // console.log('resDataObj', resDataObj, resDataObj.imageUrlObj);
  
        setResDataObj(null);
        setIsLoading(false);

        uploadUtils.deleteFile(
          BACKEND_URL,
          localStorage.getItem('token'),
          resDataObj.imageUrlObj,
        );
  
      }
  
      if (status === ActionStatus.SUCCESS && 
            resDataObj && resDataObj.imageUrlObj) {
        // // store issued token data
        storeNftData(
          BACKEND_URL, 
          localStorage.getItem('token'),
          resDataObj,
          publicKey,
        );

        // // // create send nft to initOwnerXdr
        // createSendNftXdr(
        //   BACKEND_URL, 
        //   localStorage.getItem('token'),
        //   resDataObj,
        //   publicKey,
        // );
      }


      if (status === ActionStatus.ERROR && 
        sendResDataObj && sendResDataObj.imageUrlObj) {
        // console.log('resDataObj', resDataObj, resDataObj.imageUrlObj);

        setSendResDataObj(null);
        setIsLoading(false);

        uploadUtils.deleteFile(
          BACKEND_URL,
          localStorage.getItem('token'),
          sendResDataObj.imageUrlObj,
        );

      }


      if (status === ActionStatus.SUCCESS && 
        sendResDataObj && sendResDataObj.imageUrlObj) {

        // // create send nft to initOwnerXdr
        storeNftData2(
          BACKEND_URL, 
          localStorage.getItem('token'),
          sendResDataObj,
          publicKey,
        );
      }


    }

  },[status]);


  const initInputs = () => {
    setNameInput('');
    setTitleInput('');
    setAmountInput(1);
    setDescriptionInput('');
    setHomeDomainInput('');
    setCodeInput('');
    setFilePreviews([]);
    // setStateTxXdr('');
    // setResDataObj();
    // setShowIssueModal(false);

    // setIsLoading(false);
  };

  const isValidInput = () => {
    if (!codeInput || codeInput.length <= 4) {
      return false;
    }

    if (selectedFiles && selectedFiles.length === 0) {
      return false;
    } 

    if (!(amountInput >= 1)) {
      return false;
    }

    if (nameInput.length > 20) {
      return false;
    }

    if (descriptionInput.length > 500) {
      return false;
    }

    return true;
  };


  const nameInputChangeHandler = (event) => {
    setNameInput(event.target.value); 
  };

  // const titleInputChangeHandler = (event) => {
  //   setTitleInput(event.target.value); 
  // };

  const amountInputChangeHandler = (event) => {
    if (!isNaN(Number(event.target.value)) ) {
      setAmountInput(parseInt(event.target.value));
    }
  };

  const descriptionInputChangeHandler = (event) => {
    setDescriptionInput(event.target.value); 
  };

  // const creatorInputChangeHandler = (event) => {
  //   setCreatorInput(event.target.value); 
  // };

  // const homeDomainInputChangeHandler = (event) => {
  //   setHomeDomainInput(event.target.value);
  // };

  // const cidInputChangeHandler = (event) => {
  //   setCidInput(event.target.value);
  // };

  // const nIdInputChangeHandler = (event) => {
  //   setNIdInput(event.target.value);
  // };

  // const ownerSecretInputChangeHandler = (event) => {
  //   setOwnerSecretInput(event.target.value);
  // };

  const codeInputChangeHandler = (event) => {
    const value = event.target.value;
    const regex = /^[0-9a-zA-Z]+$/; // this will admit letters, numbers and dashes
    if (value.match(regex) || value === "") {
      // this.setState({ inputValue: value });
      setCodeInput(value);
    }

  };


  const onChangeHandler = async (event) => {
    console.log(event.target.files);
    // setSelectedFile(event.target.files[0]);
    setSelectedFiles(event.target.files);

    const b64Images = [];

    for (const file of event.target.files) {
      const b64Image = await fileUtils.generateBase64FromImage(file);
      
      if (b64Image) {
        b64Images.push(b64Image);
      }
    }

    setFilePreviews(b64Images);
  };


  // const onClickHandler = async (files, inputData) => {
  //   try {
  //     setIsLoading(true);

  //     console.log(files, inputData);

  //     let result;

  //     result = await uploadUtils.s3fileUpload(
  //       BACKEND_URL,
  //       localStorage.getItem('token'),
  //       files,
  //       inputData,
  //     );

  //     console.log(result);
      
  //     // setSelectedFiles(null);
      
  //     if (result.data && result.data.data) {

  //       const addedList = [result.data.data].concat(nftList);
        
  //       hDispatch('SET_NFTLIST', addedList);
  //     }

  //     setIsLoading(false);
  //   } catch(err) {
  //     console.log(err);
  //     setIsLoading(false);
  //   }

  // };


  const albedoNftIssueHandler = async (
    url, 
    token, 
    selectedFiles, 
    inputData, 
    accountId,
  ) => {
    try {
      setIsLoading(true);

      // const result = await uploadUtils.albedoNftIssue(
      const result = await nftIssue(
        url, 
        token, 
        selectedFiles, 
        inputData, 
        accountId,
      );

      console.log(result);

      // if (result.results && result.results.send) {

      //   const addedList = [result.results.send.data].concat(nftList);
        
      //   hDispatch('SET_NFTLIST', addedList);
      // }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  const nftIssue = async (
    url, 
    token, 
    selectedFiles, 
    inputData, 
    accountId,
  ) => {
  
    let imageUrlObj;
  
    try{
      
      // setResDataObj(null);
      // console.log(inputData, accountId);
      // throw new Error('error-error');
      // console.log(selectedFiles);

      const data = new FormData(); 
  
      
      if (!inputData.imageCidUrl) {
        const file = selectedFiles[0];
        data.append('image', file);
        data.append('name', inputData.name);
      }
  
      if (inputData.imageCidUrl) {
        const file = selectedFiles;
        data.append('image', file);
        data.append('imageCidUrl', inputData.imageCidUrl);
        data.append('name', inputData.imageCidName);
      }
  
      // data.append('storePath', path);
      // data.append('name', inputData.name);
      data.append('title', inputData.title);
      data.append('amount', inputData.amount);
      // data.append('creator', inputData.creator);
      data.append('description', inputData.description);
      data.append('homeDomain', inputData.homeDomain);
  
      data.append('ownerSecret', inputData.ownerSecret);
      data.append('code', inputData.code);
  
      data.append('initOwnId', inputData.initOwnId);


      const randKeypair = StellarSdk.Keypair.random();
      data.append('tokenSecret', randKeypair.secret());
      data.append('tokenPublicKey', randKeypair.publicKey());

  
      const resultObj = {
        upload: null,
        create: null,
        send: null,
      };
  
      const headers = {
        // 'Content-Type': 'application/json',
        'Authorization': `Bearer ${  token}`,
      };

      
      // // file upload and create issuer account and change trust
      const uploadResult = await axios.post(`${url  }/token-albedo/store-file`, data, {  
        headers,
        // receive two    parameter endpoint url ,form data
      });
  
      console.log(uploadResult);
  
      resultObj.upload = uploadResult;
  
      const uploadData = uploadResult.data.data;
      // const fileResData = await fileResult.json();
      // console.log(fileResult, fileResData);
  
      imageUrlObj = uploadData.imageUrlObj;

      // return;
      if (!uploadData) {
        throw new Error('error-occured');
      }


      const resData = uploadData;
      // console.log('resData',resData, resData.xdr);

      setResDataObj(resData);

      const xdrTx = resData.xdr;
      
      setStateTxXdr(xdrTx);
      hDispatch('SET_TXXDR', xdrTx);

      const transaction = new StellarSdk.Transaction(
          xdrTx,
          getNetworkConfig(settings.isTestnet).network,
      );
      console.log('transaction', transaction);
      
      // setTxSequence(transaction._sequence);

      dispatch(sendTxAction(transaction));
      logEvent("nft-token-issue: transactions", {
          txOperations: transaction._operations,
          // amount: formData.amount.toString(),
          //   "used federation address": !!formData.federationAddress,
          //   "used memo": !!formData.memoContent,
      });

      return {
        message: 'xdr created',
        results: resultObj,
      };

    } catch(err) {
      console.log(err);
    
      setResDataObj(null);

      // // delete files
      // if (imageUrlObj) {
      //   await uploadUtils.deleteFile(
      //     BACKEND_URL,
      //     localStorage.getItem('token'),
      //     imageUrlObj,
      //   );
      // }

      throw err;
    }
  };


  // const createSendNftXdr = async (url, token, resData, accountId) => {
  //   try {
  //     setIsLoading(true);
  //       /// / send nft to owner, store issue data in db ipfs
  //       // const keyPair = resData.data.tokenKeyPair;
  //       const tokenSecret = resData.tokenSecret;
  //       // const tokenPublicKey = resData.data.tokenPublicKey;
  //       const tokenCode = resData.data.code;
  //       // const tokenData = resData.data.data;
  //       // const imageUrlObj = resData.data.imageUrlObj;
  //       // console.log(keyPair);

  //       const result2 = await fetch(`${url  }/token-albedo/create-send-nft-xdr`, {
  //         // const result = await fetch(url, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `Bearer ${  token}`,
  //         },
  //         body: JSON.stringify({
  //           ownerAccountId: accountId,
  //           // keyPair: keyPair,
  //           tokenSecret,
  //           // tokenPublicKey,
  //           code: tokenCode,
  //           data: resData,
  //           // imageUrlObj: imageUrlObj,
  //         }),
  //       });
    
  //       const resData2 = await result2.json();
  //       console.log(result2, resData2);


  //       if (result2.ok && resData2 && resData2.data) {
  //         setSendResDataObj(resData2.data);

  //         const xdrTx = resData2.data.xdr;
          
  //         setStateTxXdr(xdrTx);
  //         hDispatch('SET_TXXDR', xdrTx);
    
  //         const transaction = new StellarSdk.Transaction(
  //             xdrTx,
  //             getNetworkConfig(settings.isTestnet).network,
  //         );
  //         console.log('transaction', transaction);
          
  //         // setTxSequence(transaction._sequence);
    
  //         dispatch(sendTxAction(transaction));
  //         logEvent("nft-token-send: transactions", {
  //             txOperations: transaction._operations,
  //             // amount: formData.amount.toString(),
  //             //   "used federation address": !!formData.federationAddress,
  //             //   "used memo": !!formData.memoContent,
  //         });

  //       } else {
  //         throw new Error('error-occured');
  //       }

  //       // resultObj.send = resData2;
  //       setIsLoading(false);

  //   } catch(err) {
  //     console.log(err);

  //     // await uploadUtils.deleteFile(
  //     //   url,
  //     //   token,
  //     //   resData.imageUrlObj,
  //     // );

  //     setSendResDataObj(null);

  //     setIsLoading(false);
  //   }
  // };


  const storeNftData2 = async (url, token, resData, accountId) => {
    try {
      setIsLoading(true);
        /// / send nft to owner, store issue data in db ipfs
        // const keyPair = resData.data.tokenKeyPair;
        const tokenSecret = resData.tokenSecret;
        // const tokenPublicKey = resData.data.tokenPublicKey;
        const tokenCode = resData.data.code;
        // const tokenData = resData.data.data;
        // const imageUrlObj = resData.data.imageUrlObj;
        // console.log(keyPair);

        const result2 = await fetch(`${url  }/token-albedo/store-nft-data`, {
          // const result = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${  token}`,
          },
          body: JSON.stringify({
            ownerAccountId: accountId,
            // keyPair: keyPair,
            tokenSecret,
            // tokenPublicKey,
            code: tokenCode,
            data: resData,
            // imageUrlObj: imageUrlObj,
          }),
        });
    
        const resData2 = await result2.json();
        console.log(result2, resData2);


        if (result2.ok && resData2 && resData2.data) {

          const addedList = [resData2.data].concat(nftList);
          
          hDispatch('SET_NFTLIST', addedList);

          setShowIssueModal(false);
          dispatch(resetSendTxAction());

          hDispatch('SHOW_NOTIFICATION', {
            status: '',
            title: '',
            message: `NFT ${t('general.text30', 'created')}`,
          });
      
          setTimeout(() => {
            hDispatch('CLEAR_NOTIFICATION');
          }, 5000);

        } else {
          throw new Error('error-occured');
        }

        // resultObj.send = resData2;
        setIsLoading(false);

    } catch(err) {
      console.log(err);

      // await uploadUtils.deleteFile(
      //   url,
      //   token,
      //   resData.imageUrlObj,
      // );

      setSendResDataObj(null);

      setIsLoading(false);
    }
  };

  const storeNftData = async (url, token, resData, accountId) => {
    try {
      setIsLoading(true);
        /// / send nft to owner, store issue data in db ipfs
        // const keyPair = resData.data.tokenKeyPair;
        const tokenSecret = resData.tokenSecret;
        // const tokenPublicKey = resData.data.tokenPublicKey;
        const tokenCode = resData.data.code;
        // const tokenData = resData.data.data;
        // const imageUrlObj = resData.data.imageUrlObj;
        // console.log(keyPair);

        const result2 = await fetch(`${url  }/token-albedo/send-store-nft-data`, {
          // const result = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${  token}`,
          },
          body: JSON.stringify({
            ownerAccountId: accountId,
            // keyPair: keyPair,
            tokenSecret,
            // tokenPublicKey,
            code: tokenCode,
            data: resData,
            tokenKeypair: StellarSdk.Keypair.fromSecret(tokenSecret),
            // imageUrlObj: imageUrlObj,
          }),
        });
    
        const resData2 = await result2.json();
        console.log(result2, resData2);


        if (result2.ok && resData2 && resData2.data) {

          const addedList = [resData2.data].concat(nftList);
          
          hDispatch('SET_NFTLIST', addedList);

          setShowIssueModal(false);
          dispatch(resetSendTxAction());

          hDispatch('SHOW_NOTIFICATION', {
            status: '',
            title: '',
            message: `NFT ${t('general.text30', 'created')}`,
          });
      
          setTimeout(() => {
            hDispatch('CLEAR_NOTIFICATION');
          }, 5000);


          postCreatorUpdatePush(
            BACKEND_URL,
            localStorage.getItem('token'),
            resData2.data.uId,
            resData2.data.n_id,
            { type: 'nft-create' },
          );

        } else {
          throw new Error('error-occured');
        }

        // resultObj.send = resData2;
        setIsLoading(false);

    } catch(err) {
      console.log(err);

      // await uploadUtils.deleteFile(
      //   url,
      //   token,
      //   resData.imageUrlObj,
      // );

      setResDataObj(null);

      setIsLoading(false);
    }
  };


  const getIpfsBackendHandler = async (url, token) => {
    try {
      setIsLoading(true);
      setGetBackendError('');

      await uploadUtils.getIpfsBackend(url, token);
      setIsLoading(false);

    } catch(err) {
      console.log(err);

      setGetBackendError('Error occured not available now');
      setIsLoading(false);
    }
  };


  const fee = '100000';
  let useFee = fee;
  let enoughXLM = false;
  let requiredXLM = 0;

  // const initialIssuerXLM = 1.5;
  // const createTrustXLM = 0.5;

  // const issueFee = 0.1;


  if (feeStats) {
    const networkFee = feeStats.fee_charged.mode;

    useFee = (( Number(networkFee) * 1.1 + Number(fee) ) * 10).toFixed();

    console.log('fees', fee, networkFee, useFee);

    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    requiredXLM = Number(useFee)/10**7 + 
      txConfig.initialIssuerXLM + 
      txConfig.createTrustXLM + 
      txConfig.issueFee;

    if (availableXLM >= requiredXLM) {
      enoughXLM = true;
    }

  } else {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      requiredXLM = Number(useFee)/10**7 + 
      txConfig.initialIssuerXLM + 
      txConfig.createTrustXLM + 
      txConfig.issueFee;
  
      if (availableXLM >= requiredXLM) {
        enoughXLM = true;
      }
  }

  // console.log('txConfig', txConfig);

  let fileUploadBody;

  if (account.data) {
    fileUploadBody = (
      <div>
        <div className={classes.fileUploadInput}>
          <label>
            code ({t('CreateNFT.13', 'required')}, {t('CreateNFT.14', 'alphanumeric')} 5 - 12 {t('CreateNFT.15', 'character length')})
          </label>
          <Input 
            type='text'
            // id="code"
            name='nft-code' 
            value={codeInput}
            onChange={codeInputChangeHandler} 
            minLength="5"
            maxLength="12"
            placeholder='yourNFT'
          />

          {/* {codeInput && codeInput.length <=4 && '<=4'} */}
        </div>
        
        <div className={classes.fileUploadInput}>
          <label>{t('CreateNFT.16', 'name')}</label>
          <Input 
          type='text' 
          name='name' 
          // placeholder='name of NFT (up to 20 characters)'
          placeholder={`${t('CreateNFT.17', 'name of NFT (up to 20 characters)')}`}
          onChange={nameInputChangeHandler} 
          />
        </div>

        {/* <label>title-input</label>
        <input type='text' name='name' onChange={titleInputChangeHandler} />
        */}
        <div className={classes.fileUploadInput}>
          <label>{t('general.text2', 'description')}</label>
          <Input 
            type='text' 
            name='description' 
            // placeholder='description of NFT (up to 500 characters)'
            placeholder={`${t('CreateNFT.18', 'description of NFT (up to 500 characters)')}`}
            onChange={descriptionInputChangeHandler} 
          />
        </div>
        
        <div className={classes.fileUploadInput}>
          <label>
            {t('CreateNFT.19', 'amount (Integer)')}
          </label>
          <Input 
          type='text' 
          name='amount' 
          placeholder='issue amount (default 1)'
          onChange={amountInputChangeHandler} 
          />
        </div>

        {/* <div>
          <label>home-domain-input</label>
          <input type='text' name='home-domain' onChange={homeDomainInputChangeHandler} />
        </div>
        <div>
        <label>owner-secret-input</label>
          <input type='password' name='owner-secret' onChange={ownerSecretInputChangeHandler} />
        </div> */}
        <div className={classes.fileUploadInput}>
          <label>
            {t('CreateNFT.21', 'select file (required)')}
          </label>
          <Input 
            type="file" 
            name="file" 
            onChange={onChangeHandler} 
          />
          
          <div className={classes.fileUploadImageEditorLink}>
            <ImageEditorLink />
          </div>

          <FileUploadPreview 
            filePreviews={filePreviews}
            selectedFiles={selectedFiles}
          />
        </div>

        <div className={classes.fileUploadAvailableXlm}>
          <InfoBlock>
            <div>
              {t('CreateNFT.22', 'available')}
              {' '}
               XLM: {availableXLM && availableXLM.toFixed(7)} 
            </div>
            <div>
              {requiredXLM > 5 && (
                <span>
                  {t('CreateNFT.23', 'You need to have at least')}
                  {' '}
                  {requiredXLM.toFixed(7)} XLM
                  {' '}
                  ({`including issue fee ${txConfig.issueFee.toFixed(2)} XLM`})
                </span>
              )}
              {requiredXLM <= 5 && (
                <span>
                  {t('CreateNFT.23', 'You need to have at least')}
                  {' '}
                   {requiredXLM.toFixed(7)} XLM
                  {' '}
                  ({`including issue fee ${txConfig.issueFee.toFixed(2)} XLM`}),
                  {' '}
                  {t('CreateNFT.24', 'more than 5 XLM is recommended. ')}
                  
                </span>
              )}
            </div>
          </InfoBlock>
        </div>

        {account.data && (
          <Button 
            disabled={isLoading || !isValidInput() || !enoughXLM}
            isLoading={isLoading}
            onClick={() => {
              albedoNftIssueHandler(
                BACKEND_URL,
                localStorage.getItem('token'),
                selectedFiles,
                inputData,
                publicKey,
              );
          }}
          >
            {t('CreateNFT.01', 'Create New NFT')}
          </Button>
        )}
        
        {/* {isLoading && (
          <div>loading...loading...</div>
        )} */}
      </div>
    );
  }


  return (
    <Fragment>

      {/* <div>file-upload-content (file upload case)</div> */}
      {/* <div>{fileUploadBody}</div> */}

      {localStorage.getItem('email') && account && account.data && (
        <div>
          <div className={classes.fileUploadStartButton}>
            <Button
              // size="small"
              onClick={() => {
                // dispatch(resetSendTxAction());
                initInputs();
                setShowIssueModal(true); 
                getIpfsBackendHandler(BACKEND_URL, localStorage.getItem('token'));
              }}
            >
              {t('CreateNFT.01', 'Create New NFT')}
            </Button>
    
            <span
              title="about NFT issue"
              onClick={() => {
                setShowIssueDescription(!showIssueDescription);
              }}
            >
              <InfoIcon />
              {/* <DetailsTooltip 
                details="More information to display inside the tooltip"
              >
                Info element
              </DetailsTooltip> */}
            </span>
          </div>

          {showIssueDescription && (
            <IssueDescription />
          )}
        </div>
      )}

      {!isAuth && (
        <div className={classes.fileUploadAuth}>
          <div>
            {t('CreateNFT.02', 'Account Login is Required to issue NFT')}
          </div>
          
          <div className={classes.fileUploadAuthButtons}>
            <span>
              <a href={loginPageLink} >
                <Button 
                  variant="tertiary"
                >
                  {t('general.text18', 'Login')}
                </Button>
              </a>
            </span>
            <span> or </span>
            <span>
              <a href={signupPageLink} >
                <Button
                  variant="tertiary"
                >
                  {t('general.text19', 'signup')}
                </Button>
              </a>
            </span>
          </div>
        </div>
      )}

      {isAuth && (!account || !account.data) && (
        <div className={classes.fileUploadWalletLink}>
            <Link to="/wallet-login">
                <Button>
                    {t('singleNFT.16', 'connect with wallet')}
                </Button>
            </Link>

            <span
              title="about connect wallet"
              onClick={() => {
                setShowWalletConnectDescription(!showWalletConnectDescription);
              }}
            >
              <InfoIcon />
              {/* <DetailsTooltip 
                details="More information to display inside the tooltip"
              >
                Info element
              </DetailsTooltip> */}
            </span>
        </div>
      )}

      {showWalletConnectDescription && (
        <WalletConnectDescription />
      )}


      <Modal
        // visible={isSendTxModalVisible || isReceiveTxModalVisible}
        visible={showIssueModal}
        // onClose={resetModalStates}
        onClose={() => {
          setShowIssueModal(false);
          dispatch(resetSendTxAction());
        }}
      >
        {showIssueModal && !getBackendError && (
          <TxFlow
            onCancel={() => {
              // setIsSendTxModalVisible(true);
              // resetModalStates();
              setShowIssueModal(false);
              dispatch(resetSendTxAction());
            }}
            createTxBody={fileUploadBody}
            isLoading={isLoading}
          />
        )}

        {showIssueModal && getBackendError && (
          <div><strong>{getBackendError}</strong></div>
        )}
      </Modal>

      {showIssueModal && (
        <GetNetworkFee />
      )}

    </Fragment>
  );
}

export default FileUpload;
