import React, { useState, useEffect, Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, InfoBlock } from "@stellar/design-system";
import { useTranslation } from 'react-i18next';

import Loader from '../Loader/Loader.jsx';
import InfoIcon from '../UiComponent/Icons/InfoIcon.jsx';
import { useStore } from '../../../hook-store/store';


// import * as uploadUtils from '../utils/bucket-upload-util';
// import * as nftDataUtils from '../../../utils/nft/nft-data-util';
import * as userFollowerUtils from '../../../utils/follow/user-follower-util';
// import { getUserData } from '../../../utils/user';

import { BACKEND_URL } from '../../../urlLinks';
// import Backdrop from 'components/IssuerFrontend/Backdrop/Backdrop.jsx';

import classes from './FollowUserButton.module.css';

function FollowUserButton(props) {
  // console.log('FollowUserButton.js-props', props);
  const { creator } = props;

  const params = useParams();

  const [t] = useTranslation('translation');
  // console.log('params', params);

  const [hStore, hDispatch] = useStore();
  // const nftList = hStore.nftStore.nftList;
  // const isAuth = hStore.uiStore.isAuth;
  // const userData = hStore.uiStore.userData;

  const { isAuth, userData } = hStore.uiStore;

  // const [nftData, setNftData] = useState();
  // const [creatorUId, setCreatorUId] = useState('');
  const [isFollowUser, setIsFollowUser] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [isGetFollowers, setIsGetFolloers] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  // console.log(setIsLoading);


  useEffect(() => {
    // getNftFollowUsersHandler();
    if (creator) {
      getUserFollowersHandler();
    }
  },[creator]);
  // const pageListNum = 2;

  const getUserFollowersHandler = async () => {
    try {
      setIsLoading(true);

      const resData = await userFollowerUtils.getUserFollowers(
        BACKEND_URL,
        localStorage.getItem('token'),
        // nft.n_id,
      );

      console.log('follow resData', resData);
      

      if (resData.data) {
        if (resData.data.length > 0) {
          const isFollowing = resData.data.find(user => {
            return user.followUId === creator._id;
          });
  
          if (isFollowing) {
            setIsFollowUser(true);
          }
        }

        setIsGetFolloers(true);
      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const followUserHandler = async () => {
    try {
      setIsLoading(true);

      const resData = await userFollowerUtils.addUserFollower(
        BACKEND_URL,
        localStorage.getItem('token'),
        creator._id,
      );

      if (resData) {
        setIsFollowUser(true);
      }
      // console.log('follow resData', resData);

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const unFollowUserHandler = async () => {
    try {
      setIsLoading(true);

      const resData = await userFollowerUtils.deleteUserFollower(
        BACKEND_URL,
        localStorage.getItem('token'),
        userData._id,
        creator._id,
      );

      if (resData) {
        setIsFollowUser(false);
      }
      // console.log('follow resData', resData);

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  let followUserButtonBody;

  if (isAuth && userData && creator && isGetFollowers) {
    followUserButtonBody = (
      <div className={classes.followUserButton}
        tilte="After following NFT and enabled notification in user page, you will get notification when offer is created."
      >
        <span
          onClick={() => { 
            setShowInfo(!showInfo); 
          }}
        >
          <InfoIcon />
        </span>
        {isFollowUser && (
          <Button
            style={{border: "0.25px solid gray"}}
            variant="tertiary"
            size="small"
            disabled={isLoading}
            onClick={unFollowUserHandler}
          >
            {t('singleNFT.04', 'stop following')}
          </Button>
        )}

        {!isFollowUser && (
          <Button
            style={{border: "0.25px solid gray"}}
            variant="tertiary"
            size="small"
            disabled={isLoading}
            onClick={followUserHandler}
          >
            {t('singleNFT.03', 'start following this')}
          </Button>
        )}

        {isLoading && <Loader />}
      </div>
    );
  }


  return (
    <Fragment>
      <div>
        {followUserButtonBody}

        {showInfo && (
          <InfoBlock 
            // variant={InfoBlock.variant.error}
          >
            After following User and enabled notification in user page, you will get notification when user updated list.
          </InfoBlock>
        )}
      </div>

    </Fragment>
  );
}

export default FollowUserButton;
