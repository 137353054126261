export const generateBase64FromImage = imageFile => {
  const reader = new FileReader();
  const promise = new Promise((resolve, reject) => {
    reader.onload = e => {
      console.log(e.target);
      return resolve(e.target.result);
    };
    reader.onerror = err => 
      // console.log(err);
       reject(err)
    ;
  });

  reader.readAsDataURL(imageFile);
  return promise;
};

function fallbackCopyTextToClipboard(text) {
  // const textArea = document.createElement("textarea");
  // textArea.value = text;
  
  // // Avoid scrolling to bottom
  // textArea.style.top = "0";
  // textArea.style.left = "0";
  // textArea.style.position = "fixed";

  // document.body.appendChild(textArea);
  // textArea.focus();
  // textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log(`Fallback: Copying text command was ${  msg}`);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  // document.body.removeChild(textArea);
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    console.log('copy failed');
    // fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(() => {
    console.log('Async: Copying to clipboard was successful!');
  }, (err) => {
    console.error('Async: Could not copy text: ', err);
  });
}