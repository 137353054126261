import React, { useState, useEffect, Fragment } from 'react';
import { Button, InfoBlock } from '@stellar/design-system';
import { useTranslation } from 'react-i18next';

import { useRedux } from "hooks/useRedux";

import Loader from '../Loader/Loader.jsx';
import { useStore } from '../../../hook-store/store';

import * as uploadUtils from '../../../utils/nft/bucket-upload-util';
// import * as nftDataUtils from '../../utils/nft-data-util';

import { getAssetOffers } from '../../../utils/nft/offer-util.js';

import { BACKEND_URL } from '../../../urlLinks';

import classes from './DeleteFiles.module.css';

function DeleteFiles(props) {
  // console.log('FileUpadate-props', props);
  const { nft, showFilesDeleteHandler } = props;

  const [t] = useTranslation('translation');

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    // "memoRequiredAccounts",
    "settings",
    // "sendTx",
  );

  const publicKey = account.data ? account.data.id : '';

  const [hStore, hDispatch] = useStore();
  const nftList = hStore.nftStore.nftList;

  const [getBackendError, setGetBackendError] = useState('');

  const [hasSellOffer, setHasSellOffer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   getIpfsBackendHandler(BACKEND_URL, localStorage.getItem('token'));
  // },[]);
 
  useEffect(() => {
    getAssetSellOffersHandler(
      'credit_alphanum12', nft.code, nft.issuer,
    );
  },[]);

  const getAssetSellOffersHandler = async (assetType, code, issuer) => {
    try {
      setIsLoading(true);

      const assetOffers = await getAssetOffers(
        'sell', 
        assetType, 
        code, 
        issuer,
      );

      console.log('assetSellOffers', assetOffers);
      // console.log(accountOffers._embedded.records);

      if (assetOffers) {
        // setAssetSellOffers(assetOffers._embedded.records);
        const userHasSellOffer = assetOffers._embedded.records.find(offer => {
          return (offer.seller === publicKey &&
            offer.selling.asset_code === nft.code &&
            offer.selling.asset_issuer === nft.issuer
            );
        });

        if (userHasSellOffer) {
          setHasSellOffer(true);
        }
      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  const deleteTokenFilesHandler = async (nId, imageUrlObj, userAccountId) => {
    try {
      setIsLoading(true);

      await uploadUtils.getIpfsBackend(BACKEND_URL, localStorage.getItem('token'));

      // console.log(files, inputData);

      // console.log(nId, imageUrlObj, accountId);

      const result = await uploadUtils.postDeleteTokenFiles(
        BACKEND_URL,
        localStorage.getItem('token'),
        nId,
        imageUrlObj,
        userAccountId,
      );

      console.log(result);
      
      const nfts = nftList;

      if (result.data) {

        const updateIndex = nfts.findIndex(nft => (
          nft.n_id === result.data.n_id),
        );
        
        nfts[updateIndex] = result.data;
        
        console.log(updateIndex, nfts);
        hDispatch('SET_NFTLIST', nfts);

        hDispatch('SHOW_NOTIFICATION', {
          status: '',
          title: '',
          message: `delete files success`,
        });
    
        setTimeout(() => {
          hDispatch('CLEAR_NOTIFICATION');
        }, 5000);


        // postCreatorUpdatePush(
        //   BACKEND_URL,
        //   localStorage.getItem('token'),
        //   nft.uId,
        //   nft.n_id,
        //   { type: 'nft-update' },
        // );
      }

      showFilesDeleteHandler();
      setIsLoading(false);
      
    } catch(err) {
      console.log(err);
      setIsLoading(false);

      hDispatch('SHOW_NOTIFICATION', {
        status: '',
        title: '',
        message: `delete files failed`,
      });
    }

  };

  let imageUrls;

  if (nft) {
    imageUrls = {
      filePath: nft.filePath,
      smallImagePath: nft.smallImagePath,
      miniImagePath: nft.miniImagePath,
      trimMediaPath: nft.trimMediaPath,
    };
  }

  return (
    <Fragment>
      {/* {hasSellOffer ? 'has-sell-offer' : 'not-has-sell-offer'} */}
      {/* <div>--- update-token-info-content ---</div> */}
      {getBackendError && (
        <div>
          <strong>{getBackendError}</strong>
        </div>
      )}
      {!getBackendError && (
        <div>
          <InfoBlock>
            <div>Please make sure your wallet do not have trading sell offer of this NFT.</div>
            <div>File deletion is irreversible process. It is impossible to update with new file.</div>
            <div>After file deletion, NFT will disappear from NFT List.</div>
            <div>Is it no problem to delete file?</div>
          </InfoBlock>
          {hasSellOffer && (
            <div>
              <strong>You have trading sell offer.</strong>
            </div>
          )}
          <div className={classes.deleteFilesButtons}>
            <Button 
              style={{border: "0.25px solid gray"}}
              variant="tertiary"
              disabled={isLoading}  
              onClick={() => { 
                if (!isLoading) {
                  showFilesDeleteHandler();
                } 
              }}
            >
              cancel
            </Button> 
            <Button 
              style={{border: "0.25px solid gray"}}
              variant="tertiary"
              disabled={isLoading || hasSellOffer}  
              onClick={() => { 
                if (!isLoading) {
                  deleteTokenFilesHandler(
                    nft.n_id,
                    imageUrls,
                    publicKey,
                  ); 
                } 
              }}
            >
              delete file
            </Button> 
          </div>
       
        </div>
      )}

      {isLoading && <Loader />}

    </Fragment>
  );
}

export default DeleteFiles;
