import React, { useState, useEffect, Fragment } from 'react';
// import albedo from '@albedo-link/intent';
import * as StellarSdk from "@stellar/stellar-sdk";
import {
    Button,
    InfoBlock,
    TextLink,
    Modal,
    Icon,
    Identicon,
    Loader,
  } from "@stellar/design-system";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

import { useRedux } from "hooks/useRedux";

import { ActionStatus, AuthType } from "types/types";
import { sendTxAction, resetSendTxAction } from 'ducks/sendTx';
import { logEvent } from 'helpers/tracking';
import { getNetworkConfig } from 'helpers/getNetworkConfig';
import { fetchAccountAction } from 'ducks/account';

// import Backdrop from '../../Backdrop/Backdrop.jsx';
import GetAvailableXlm from '../../UiComponent/GetAvailableXlm.jsx';
import GetNetworkFee from '../../UiComponent/GetNetworkFee.jsx';

// import SuccessTx from '../../SuccessTx.jsx';
// import FailedTx from '../../FailedTx.jsx';
import TxFlow from '../../TxFlow/TxFlow.jsx';

import { 
  createCancelSellOfferXdr,
  getInstructionsMessage,
  makeCancelSellOfferXdr,
} from '../../../../utils/nft/offer-util';

import { useStore } from '../../../../hook-store/store';

import { BACKEND_URL } from '../../../../urlLinks';

import classes from './NftCancelBuyOffer.module.css';

function NftCancelBuyOffer(props) {
  const { 
    nft, 
    assetBuyOffers,
    getAssetBuyOffersHandler,
    sellAsset,
    offer,
    // isLoading
  } = props;

  const [t] = useTranslation('translation');

  const dispatch = useDispatch();

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    "memoRequiredAccounts",
    "settings",
    "sendTx",
  );
  const { status, errorString } = sendTx;
  const publicKey = account.data ? account.data.id : '';


  const [hStore, hDispatch] = useStore();
  const feeStats = hStore.nftStore.feeStats;
  const availableXLM = hStore.nftStore.availableXLM;
  const txXdr = hStore.nftStore.txXdr;

  const [showCancelBuyModal, setShowCancelBuyModal] = useState(false);
  const [selectedNftId, setSelectedNftId] = useState('');
  
  const [stateTxXdr, setStateTxXdr] = useState('');
  // const [txSequence, setTxSequence] = useState('');
  const [isLoading, setIsLoading] = useState(false);

 
  useEffect(() => {
    console.log('status', status, sendTx);

    if (txXdr && stateTxXdr && txXdr === stateTxXdr) {
      if ((sendTx.data && 
        // sendTx.data.source_account_sequence === txSequence && 
        status === ActionStatus.SUCCESS) || 
        status === ActionStatus.ERROR) {
          cancelResultHandler({ code: nft.code, issuer: nft.issuer });
        }
    }
  },[status]);

  useEffect(() => {
    // if (status === ActionStatus.SUCCESS || status === ActionStatus.ERROR) {
    //   openCancelBuyModalHandler();
    // }
    if (!status) {
      closeCancelBuyModalHandler();
    }
  },[status]);

  const openCancelBuyModalHandler = () => {
    setShowCancelBuyModal(true);
  };

  const closeCancelBuyModalHandler = () => {
    setShowCancelBuyModal(false);
    // dispatch(resetSendTxAction());
  };

  const cancelBuyOfferHandler = async (
    url, 
    token, 
    accountId,
    // eslint-disable-next-line @typescript-eslint/no-shadow, no-shadow
    sellAsset,
    buyAsset,
    offerId,
  ) => {
    try {
      setIsLoading(true);

      // const resData = await createCancelSellOfferXdr(
      //   url, 
      //   token, 
      //   accountId,
      //   sellAsset,
      //   buyAsset,
      //   offerId,
      // );

      const resData = await makeCancelSellOfferXdr(
        accountId,
        new StellarSdk.Asset(sellAsset.code, sellAsset.issuer),
        new StellarSdk.Asset(buyAsset.code, buyAsset.issuer),
        offerId,
        Number(useFee * 10**7).toFixed(),
        settings,
      );

      if (resData) {
        // console.log(resData);

        const xdrTx = resData.data;

        const transaction = new StellarSdk.Transaction(
            xdrTx,
            getNetworkConfig(settings.isTestnet).network,
        );
        console.log('transaction', transaction, xdrTx);
        
        setStateTxXdr(xdrTx);
        hDispatch('SET_TXXDR', xdrTx);
        // setTxSequence(transaction._sequence);

        dispatch(sendTxAction(transaction));
        logEvent("cancelBuyOffer: transactions", {
            txOperations: transaction._operations,
            // amount: formData.amount.toString(),
            //   "used federation address": !!formData.federationAddress,
            //   "used memo": !!formData.memoContent,
        });

      }

      // await getAssetBuyOffersHandler(
      //   'credit_alphanum12', 
      //   buyAsset.code, 
      //   buyAsset.issuer
      // );
      // // await setUpdateAccountInfo(accountId);

      // hDispatch('SHOW_NOTIFICATION', {
      //   status: '',
      //   title: '',
      //   message: 'buy offre canceled',
      // });

      // setTimeout(() => {
      //   hDispatch('CLEAR_NOTIFICATION');
      // }, 3000);

      // setIsLoading(false);
      
    } catch(err) {
      console.log(err);

      // if (!err.error.code) {
      //   hDispatch('SHOW_NOTIFICATION', {
      //     status: '',
      //     title: '',
      //     message: 'Error: buy offre cancel failed',
      //   });
      // }

      setIsLoading(false);

    }
  };

  const cancelResultHandler = async (buyAsset) => {
    if (status === ActionStatus.SUCCESS) {
      getAssetBuyOffersHandler(
        'credit_alphanum12', 
        buyAsset.code, 
        buyAsset.issuer,
      );

      dispatch(resetSendTxAction());
      // dispatch(fetchAccountAction(publicKey));

      hDispatch('SHOW_NOTIFICATION', {
        status: '',
        title: '',
        message: `${t('singleNFT.06', 'trading buy offers')} ${t('general.text32', 'canceled')}`,
      });

      setTimeout(() => {
        hDispatch('CLEAR_NOTIFICATION');
      }, 5000);


      setIsLoading(false);
    }

    if (status === ActionStatus.ERROR) {
      console.log('errorString', errorString);

      // if (!err.error.code) {
        // hDispatch('SHOW_NOTIFICATION', {
        //   status: '',
        //   title: '',
        //   message: `Error: buy offre cancel failed,
        //     ${sendTx.errorString}`,
        // });
      // }

      setIsLoading(false);
    }
  };

  const cancelStartBody = (      
    <Button 
      size="small"
      onClick={openCancelBuyModalHandler}
    >
      {t('singleNFT.08', 'cancel buy')}
    </Button>
  );


  let networkFee = 0;
  // let minXLM = 0;
  let enoughXLM = false;
  let useFee = 0;

  if (feeStats) {
    networkFee = Number(feeStats.fee_charged.mode);
    useFee = Number(networkFee)*1.1 / 10**7;
    useFee = Number(networkFee)*10 / 10**7;

    if (availableXLM >= useFee) {
      enoughXLM = true;
    }
  } 


 let nftCancelBuyOfferBody;

 // eslint-disable-next-line prefer-const
 nftCancelBuyOfferBody = (
  <div>
    <div className={classes.cancelBuyOfferConetent}>
      <div>OfferId</div>
      <div><strong>{offer.id}</strong></div>
    </div>

    <div className={classes.cancelBuyOfferAvailableXlm}>
      <InfoBlock>
        <div className={classes.cancelBuyOfferConetent}>
          {t('singleNFT.17', 'Confirm screen of wallet may show amount 0, price 1 with OfferId.')}
        </div>
        <div className={classes.cancelBuyOfferConetent}>
          {t('singleNFT.18', 'This is because inpnuts with amount 0 and some price are required to create transaction for canceling offer.')}
        </div>
        <div className={classes.cancelBuyOfferConetent}>
          {t('singleNFT.23', 'Please make sure OfferId is same as confirm screen to cancel offer.')}
        </div>

        <div className={classes.cancelBuyOfferConetent}>
          <div>
            {t('general.text25', 'available')}
            {' '}
            XLM: {availableXLM && availableXLM.toFixed(7)}</div>
          <div>
            {t('general.text26', 'required')}
            {' '}
            XLM: {useFee.toFixed(7)}</div>
        </div>

      </InfoBlock>
    </div>

   
    <Button 
      disabled={isLoading || !enoughXLM}
      isLoading={isLoading}
      onClick={() => {
        cancelBuyOfferHandler(
          BACKEND_URL, 
          localStorage.getItem('token'),
          publicKey,
          sellAsset, // { code: 'XLM', issuer: ''},
          { code: nft.code, issuer: nft.issuer },
          offer.id,
        );
        setSelectedNftId(nft.n_id);
        // hDispatch('SET_SHOWTXMODAL', true);
      }}

    >
      {t('singleNFT.08', 'cancel buy')}
    </Button>

    {/* {nft.n_id === selectedNftId && 
        settings.authType && settings.authType !== AuthType.PRIVATE_KEY && (
        <InfoBlock>
            number in confirm screen may be different from inputs, 
            because of ... for non-divisible... indivisible unit
        </InfoBlock>
    )} */}

    {nft.n_id === selectedNftId &&
        status === ActionStatus.PENDING &&
        settings.authType &&
        settings.authType !== AuthType.PRIVATE_KEY && (
            <InfoBlock>{getInstructionsMessage(settings.authType)}</InfoBlock>
    )}
  </div>
 );


  return (
    <Fragment>
      
      {/* {isLoading && (
        <Backdrop />
      )} */}

      {cancelStartBody}
      {/* {nftCancelBuyOfferBody} */}

      <Modal
        // visible={isSendTxModalVisible || isReceiveTxModalVisible}
        visible={showCancelBuyModal}
        // onClose={resetModalStates}
        onClose={() => {
            closeCancelBuyModalHandler();
            dispatch(resetSendTxAction());
        }}
      >
        {showCancelBuyModal && (
          <TxFlow
            onCancel={() => {
              // setIsSendTxModalVisible(true);
              // resetModalStates();
                closeCancelBuyModalHandler();
                dispatch(resetSendTxAction());
              
            }}
            createTxBody={nftCancelBuyOfferBody}
            isLoading={isLoading}
          />
        )}
        {/* {isReceiveTxModalVisible && <ReceiveTransaction />} */}
      </Modal>

      {/* <Modal 
        // visible={showCancelBuyModal}
        visible={false}
        onClose={() => {
          dispatch(resetSendTxAction());
          closeCancelBuyModalHandler();
          // hDispatch('SET_SHOWTXMODAL', false);
        }}
      >
        {status === ActionStatus.ERROR && (
          <FailedTx 
            onEditTransaction={() => {
              // dispatch(resetSendTxAction());
            }}
            onCancel={() => {
              dispatch(resetSendTxAction());
              closeCancelBuyModalHandler();
              // hDispatch('SET_SHOWTXMODAL', false);
            }} 
          />
        )}

        {status === ActionStatus.SUCCESS && (
          <SuccessTx
            // onRestartFlow={() => {
            //   console.log('onRestartFlow');
            // }}
            onCancel={() => {
              dispatch(resetSendTxAction());
              closeCancelBuyModalHandler();
              // hDispatch('SET_SHOWTXMODAL', false);
            }} 
        />
        )}
      </Modal> */}

      {showCancelBuyModal && (
        <div>
          <GetNetworkFee />
          <GetAvailableXlm />
        </div>
      )}

    </Fragment>
  );
}

export default NftCancelBuyOffer;
