import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

// import Backdrop from '../../Backdrop/Backdrop.jsx';
import NftBuyOfferListItem from './NftBuyOfferListItem.jsx';


// import { 
//   createCancelSellOfferXdr,
// } from '../../../../utils/nft/offer-util';

import { useStore } from '../../../../hook-store/store';

import { addUSDPriceInOffers } from '../../../../utils/nft/nft-data-util.js';

// import { BACKEND_URL, } from '../../../../App';
import { marks } from '../../../../images/icons/marks';

function NftBuyOfferList(props) {
  const { 
    nft, 
    assetBuyOffers,
    getAssetBuyOffersHandler,
    // isLoading
  } = props;

  const [t] = useTranslation('translation');

  // const dispatch = useDispatch();

  // const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
  //   "account",
  //   "memoRequiredAccounts",
  //   "settings",
  //   "sendTx",
  // );
  // const { status, errorString } = sendTx;

  const [hStore, hDispatch] = useStore();
  const {
    termAssetList,
  } = hStore.nftStore;

  // const [txSequence, setTxSequence] = useState('');
  // const [isLoading, setIsLoading] = useState(false);
  const [showBuyList, setShowBuyList] = useState(true);


// let hasAsset = false;
// let hasAmount;

// let isTrustlineExist;

// if (albedoAccountInfo && nft) {
//   isTrustlineExist = checkTrustLine(
//     albedoAccountInfo.balances, 
//     nft.code, nft.issuer
//   );
// }


//  let nftSellOfflerListBody = 'no-sell-offer';

//  if (assetSellOffers.length > 0) {
//   // console.log('assetSellOffers', assetSellOffers);
//   nftSellOfflerListBody = (
//     <ul>
//       {assetSellOffers.map(offer => {
        
// eslint-disable-next-line max-len
//         //// seller is login user case, show sell offer in CreateNftSellOffer.js
//         if (albedoPubkeyInfo && albedoPubkeyInfo.pubkey === offer.seller) {
//           return null;
//         }

//         return (
//           <div>
//             <NftSellOfferListItem 
//               nft={nft}
//               offer={offer}
//               // buyCode={buyCode}
//               buyNftHandler={buyNftHandler}
//               isLoading={isLoading}
//             />
//           </div>
//         );
//       })}
//     </ul>
//   )
//  }


 let nftBuyOfflerListBody = (
  <span>
    {t('singleNFT.19', 'no trading buy offer exist')}
  </span>
 );

 if (assetBuyOffers.length > 0) {
  // console.log('assetBuyOffers', assetBuyOffers);
  if (!showBuyList) {
    nftBuyOfflerListBody = null;
  } else {

    const sortedBuyOffers = _.orderBy(assetBuyOffers, `amount`, "desc");

    let displaBuyOffers = sortedBuyOffers;

    if (termAssetList) {
      
      const withUSDPriceBuyOffers = addUSDPriceInOffers(
          assetBuyOffers, 
          termAssetList.assets,
          true,
      );
      
      // console.log('withUSDPriceBuyOffers', withUSDPriceBuyOffers);
      displaBuyOffers = _.orderBy(withUSDPriceBuyOffers, 'offerPriceUSD', 'desc');
    }

    nftBuyOfflerListBody = (
      <ul>
        {displaBuyOffers.map(offer => (
          <div key={offer.id}>
            <NftBuyOfferListItem 
              nft={nft} 
              offer={offer}
              getAssetBuyOffersHandler={getAssetBuyOffersHandler}
              // cancelBuyOfferHandler={cancelBuyOfferHandler}
              // isLoading={isLoading}
            />
          </div>
          ))}
      </ul>
    );
  }
 }


  return (
    <Fragment>
      
      {/* {isLoading && (
        <Backdrop />
      )} */}

      <div 
      // style={{paddingTop:"1.5rem", paddingBottom:"1.5rem"}}
      >
        <div>
          {t('singleNFT.06', 'trading buy offers')}
        </div>

        {assetBuyOffers.length > 0 && !showBuyList && (
          <span onClick={() => { setShowBuyList(true); }}>
            {assetBuyOffers.length} buy-offer  {marks.triangleDown}
          </span>
        )}

        {/* {assetBuyOffers.length > 0 && showBuyList && (
          <span onClick={() => { setShowBuyList(false); }}>
            hide-buy-list {marks.triangle}
          </span>
        )} */}
        
        {nftBuyOfflerListBody}
      </div>

    </Fragment>
  );
}

export default NftBuyOfferList;
