import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { Layout, Identicon, CopyText } from "@stellar/design-system";
import { useTranslation } from 'react-i18next';

// import { resetStoreAction } from "config/store";
// import { stopAccountWatcherAction } from "ducks/account";
// import { stopTxHistoryWatcherAction } from "ducks/txHistory";
import { useRedux } from "hooks/useRedux";
// import { getUserThemeSettings } from "helpers/getUserThemeSettings";
// import { logEvent } from "helpers/tracking";


// import Backdrop from "./Backdrop/Backdrop.jsx";
// import ToggleNav from "./Navigation/ToggleNav/ToggleNav/ToggleNav.jsx";
import { useStore } from '../../../hook-store/store';

import classes from './IssuerFooter.module.css';

const IssuerFooter = () => {

  const [t] = useTranslation('translation');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { account } = useRedux("account");
  const { isAuthenticated } = account;

  const [hStore, hDispatch] = useStore();


  return (
    <Fragment>
      <div className={classes.issuerFooter}>
        {/* issuer-footer       */}
        <div className={classes.issuerFooterRow}>
          <Link to="/privacypolicy">
            {t('footer.01', 'Privacy policy')}
          </Link>
          {' '}
          <Link to="/termsofuse">
            {t('footer.02', 'Terms of use')}
            </Link>
        </div>
        <div className={classes.issuerFooterRow}>
          <Link to="/aboutpage">
            {t('footer.03', 'About this page')}
          </Link>
        </div>
        <div className={classes.issuerFooterRow}>
          <span className={classes.issuerFooterPageTitle}>
            Stellar NFT Place by SpaceEight
          </span>
        </div>
      </div>
    </Fragment>
  );
};

export default IssuerFooter;