import React, { Fragment, useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import { Button } from "@stellar/design-system";
import { useTranslation } from 'react-i18next';

import Loader from "../../Loader/Loader.jsx";
import UserFollowNftsItem from "./UserFollowNftsItem.jsx";

import { useStore } from "../../../../hook-store/store";
// import { getUserData } from "../../../../utils/user";

import { getUserFollowNfts } from '../../../../utils/follow/user-follow-nfts';

import { marks } from "../../../../images/icons/marks";

import { BACKEND_URL } from "../../../../urlLinks";

import classes from './UserFollowNfts.module.css';


const UserFollowNfts = (props) => {
  const { 
  } = props;

  const [t] = useTranslation('translation');

  const [hStore, hDispatch] = useStore();
  // const userData = hStore.uiStore.userData;
  // const isAdmin = store.uiStore.isAdmin;

  const [showFollowList, setShowFollowList] = useState(false);
  const [getListFinish, setGetListFinish] = useState(false);
  const [userFollowNfts, setUserFollowNfts] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  // const [userData, setUserData] = useState();

  useEffect(() => {

    if (showFollowList && !getListFinish) {
      getUserFollowNftsHandler(
        BACKEND_URL, 
        localStorage.getItem('token'),
      );
    }
    // getUserDataHandler(BACKEND_URL, localStorage.getItem('token'));
  }, [showFollowList]);


  const getUserFollowNftsHandler = async (url, token) => {
    try {
      setIsLoading(true);
      // setError('');
  
      const resData = await getUserFollowNfts(url, token);

      console.log('resData', resData);
      if (resData.data) {
        setUserFollowNfts(resData.data);
        setGetListFinish(true);
      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
    
  };


  let userFollowNftsBody = <div>no nft</div>;

  if (userFollowNfts.length > 0) {
    userFollowNftsBody = (
      <ul>
        {userFollowNfts.map(nft => {
          return (
            <div key={nft.n_id}
              style={{padding: "0.5rem 0"}}
            >
              <UserFollowNftsItem 
                nft={nft}
              />
            </div>
          );
        })}
      </ul>
    );
  }
 

  return <Fragment>
    <div>
      <span
        className={classes.userFollowNftsTitle}
        onClick={() => { 
          setShowFollowList(!showFollowList);
        }}
      >
        {t('userInfo.text26', 'your following NFTs')} {marks.triangleDown}
      </span>
    </div>

    {isLoading && <Loader />}

    {!isLoading && showFollowList && (
      <div>
        {userFollowNftsBody}
      </div>
    )}
  </Fragment>;
};

export default UserFollowNfts;
