/* eslint-disable max-len */
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import {
  Button,
  InfoBlock,
  TextLink,
  Modal,
  Icon,
  Identicon,
} from "@stellar/design-system";

import { useRedux } from "hooks/useRedux";

import { ActionStatus, AuthType } from "types/types";
import { sendTxAction, resetSendTxAction } from 'ducks/sendTx';

import NftCancelBuyOffer from './NftCancelBuyOffer.jsx';
import Loader from '../../Loader/Loader.jsx';
// import NftSellOfferListItem from './NftSellOfferListItem';
// import SuccessTx from '../../SuccessTx.jsx';
// import FailedTx from '../../FailedTx.jsx';

import { 
  getInstructionsMessage,
} from '../../../../utils/nft/offer-util';

import { useStore } from '../../../../hook-store/store';

import { BACKEND_URL } from '../../../../urlLinks';

import xlmLogo from "../../../../images/stellar-xlm-logo.png";
import classes from './NftBuyOfferListItem.module.css';

function NftBuyOfferListItem(props) {
  const { 
    nft, 
    offer,
    // cancelBuyOfferHandler,
    getAssetBuyOffersHandler,
    // isLoading,
  } = props;

  // console.log('NftBuyOfferListItem-props', props);
  const [t] = useTranslation('translation');

  const dispatch = useDispatch();

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    "memoRequiredAccounts",
    "settings",
    "sendTx",
  );

  const { status, errorString } = sendTx;


  const [hStore, hDispatch] = useStore();
  const { 
    forSellOfferAssets,
    termAssetList,
  } = hStore.nftStore;
  // const [showCancelBuyModal, setShowCancelBuyModal] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  const publicKey = account.data ? account.data.id : '';

  // useEffect(() => {
  //   if (status === ActionStatus.SUCCESS || status === ActionStatus.ERROR) {
  //     openCancelBuyModalHandler();
  //   }
  //   if (!status) {
  //     closeCancelBuyModalHandler();
  //   }
  // },[status]);


  // const openCancelBuyModalHandler = () => {
  //   setShowCancelBuyModal(true);
  // };

  // const closeCancelBuyModalHandler = () => {
  //   setShowCancelBuyModal(false);
  //   // dispatch(resetSendTxAction());
  // };

  // const cancelBuyOfferHandler = async (
  //   url, 
  //   token, 
  //   accountId,
  //   sellAsset,
  //   buyAsset,
  //   offerId,
  // ) => {
  //   try {
  //     setIsLoading(true);

  //     // const accountInfo = await getAccountInfo(accountId);

  //     // console.log(accountInfo);

  //     // if (accountInfo && accountInfo.balances.length > 0) {
  //     //   const hasSellAsset = accountInfo.balances.find(balance => {
  //     //     return (balance.asset_code === sellAsset.code && 
  //     //       balance.asset_issuer === sellAsset.issuer &&
  //     //       Number(balance.balance) > 0
  //     //     );
  //     //   });

  //     //   console.log(hasSellAsset);

  //     //   if (!hasSellAsset) {
  //     //     throw new Error('not-have-sell-asset');
  //     //   }
  //     // }

  //     // const sellOffers = await getAssetOffers(
  //     //   'sell', 
  //     //   'credit_alphanum12', 
  //     //   sellAsset.code, 
  //     //   sellAsset.issuer,
  //     // );

  //     // console.log(sellOffers, sellOffers._embedded.records);
      
  //     // if (sellOffers && sellOffers._embedded.records.length === 0) {
  //     //   throw new Error('sell-offer-not-exist');
  //     // }

  //     // if (sellOffers && sellOffers._embedded.records.length > 0) {
  //     //   const idOfferExist = sellOffers._embedded.records.find(offer => {
  //     //     return offer.id === offerId;
  //     //   });

  //     //   if (!idOfferExist) {
  //     //     throw new Error('offerId-sell-offer-not-exist');
  //     //   }
  //     // }


  //     const resData = await createCancelSellOfferXdr(
  //       url, 
  //       token, 
  //       accountId,
  //       sellAsset,
  //       buyAsset,
  //       offerId,
  //     );

  //     if (resData) {
  //       // console.log(resData);

  //       const albedoResult = await albedo.tx({
  //         xdr: resData.data,
  //         // description: 'this is description content',
  //         pubkey: accountId,
  //         submit: true,
  //       });

  //       console.log(albedoResult);
  //     }

  // eslint-disable-next-line max-len
  //     await getAssetBuyOffersHandler('credit_alphanum12', buyAsset.code, buyAsset.issuer);
  //     // await setUpdateAccountInfo(accountId);

  //     dispatch('SHOW_NOTIFICATION', {
  //       status: '',
  //       title: '',
  //       message: 'buy offre canceled',
  //     });

  //     setTimeout(() => {
  //       dispatch('CLEAR_NOTIFICATION')
  //     }, 3000);

  //     setIsLoading(false);
      
  //   } catch(err) {
  //     console.log(err);


  //     dispatch('SHOW_NOTIFICATION', {
  //       status: '',
  //       title: '',
  //       message: 'Error: buy offre cancel failed',
  //     });

  //     setIsLoading(false);

  //   }
  // };


// console.log('assetBuyOffer', offer);
// let buyCode;
let sellCode;
let sellAsset;
let dispAmount;
let dispPrice;

let sellLogoUrl;

 let nftBuyOfferListItemBody;

 if (offer) {

  let termAssetInfo;

  // if (offer.buying.asset_type === 'native') {
  //   // buyCode = 'XLM';
  // } else {
  //   // buyCode = offer.buying.asset_code;
  // }
  
  if (offer.selling.asset_type === 'native') {
    sellCode = 'XLM';
    sellAsset = { code: 'XLM', issuer: ''};
    sellLogoUrl = xlmLogo;

    if (termAssetList) {
      termAssetInfo = termAssetList.assets.find(ele => {
        return ele.code === 'XLM' && !ele.issuer;
      });
    }

  } else {
    sellCode = offer.selling.asset_code;
    sellAsset = { code: offer.selling.asset_code, issuer: offer.selling.asset_issuer };

    const assetForLogo = forSellOfferAssets.find(asset => {
      return asset.code === offer.selling.asset_code && 
        asset.issuer === offer.selling.asset_issuer;
    });

    if (assetForLogo && assetForLogo.tomlData) {
      sellLogoUrl = assetForLogo.tomlData.image;
    }

    if (termAssetList) {
      termAssetInfo = termAssetList.assets.find(ele => {
        return ele.code === offer.buying.asset_code && 
        ele.issuer === offer.buying.asset_issuer
      });
    }
  }
  
  dispAmount = Number(offer.amount)*Number(offer.price);
  dispPrice = Number(offer.amount);

  console.log('dispAmount, dispPrice offer', dispAmount, dispPrice, offer);

  const isUserOffer = publicKey && offer.seller === publicKey;

  nftBuyOfferListItemBody = (
    <div>
      {/* <div>amount: {offer.amount} price: {offer.price} {buyCode} offer-id: {offer.id}</div> */}
      <div>
        {t('singleNFT.25', 'amount')}: {(dispAmount*10000000).toFixed()} ({dispAmount.toFixed(7)}) 
        <br/>
        <span className={classes.nftBuyOfferItemPrice}>
          <span>
            {t('singleNFT.26', 'price')}: {dispPrice.toFixed(7)} {sellCode} 
          </span>
          <img
            className={classes.nftBuyOfferItemLogo} 
            src={sellLogoUrl} 
            alt="asset-logo"
          />
        </span>
        {offer.offerPriceUSD && (
          <span>( ~ {offer.offerPriceUSD.toFixed(2)} USD)</span>
        )}
        {/* <span> {termAssetInfo && termAssetInfo.price_USD} (USD)</span> */}
      
      {/* offer-id: {offer.id} */}
      </div>
      {/* <div>buyingId: {offer.seller}</div>
      <div>last-modified-time: {offer.last_modified_time}</div> */}
      {isUserOffer && (
        <div>
          {/* <button 
            disabled={isLoading}
            onClick={() => {
              cancelBuyOfferHandler(
                BACKEND_URL, 
                localStorage.getItem('token'),
                publicKey,
                sellAsset, // { code: 'XLM', issuer: ''},
                { code: nft.code, issuer: nft.issuer },
                offer.id,
              );
              // hDispatch('SET_SHOWTXMODAL', true);
            }}

          >
            cancel-buy-offer-nft
          </button> */}

          <NftCancelBuyOffer
            nft={nft} 
            getAssetBuyOffersHandler={getAssetBuyOffersHandler}
            sellAsset={sellAsset}
            offer={offer}
          />

        </div>
      )}

      {/* {isUserOffer && 
        settings.authType && settings.authType !== AuthType.PRIVATE_KEY && (
        <div>
          number in confirm screen may be different from inputs, 
            because of ... for non-divisible... indivisible unit
        </div>
      )}

      {isUserOffer && 
        status === ActionStatus.PENDING &&
        settings.authType &&
        settings.authType !== AuthType.PRIVATE_KEY && (
          <InfoBlock>{getInstructionsMessage(settings.authType)}</InfoBlock>
      )} */}
    </div>
  );
 }


  return (
    <Fragment>
      <div className={classes.nftBuyOfferItem}>
        {/* <div>nft-buy-offer-list-item</div> */}
        {nftBuyOfferListItemBody}

        {/* {isLoading && (
          <Loader />
        )} */}

      </div>
    </Fragment>
  );
}

export default NftBuyOfferListItem;
