import React from "react";
import { Button, TextLink, Modal } from "@stellar/design-system";
import { useTranslation } from 'react-i18next';

import { ErrorMessage } from "components/ErrorMessage";
import { useRedux } from "hooks/useRedux";
import { AuthType } from "types/types";
import { getNetworkConfig } from "helpers/getNetworkConfig";

const CreateTx = (props) => {
  const { 
    // onCancel, 
    // onEditTransaction, 
    // onContinue, 
    isLoading,
} = props;

  const [t] = useTranslation('translation');

  const { sendTx, settings } = useRedux("sendTx", "settings");

  return (
    <>
      {/* <Modal.Heading>Transaction failed</Modal.Heading> */}
      {props.children}

      {!isLoading && sendTx.errorString && (
        <div>
            <Modal.Body>
                <p>
                  {t('transaction.01', 'See details below for more information.')}
                </p>
                <ErrorMessage message={`Error: ${sendTx.errorString}`} />
                {settings.authType === AuthType.PRIVATE_KEY ? (
                <ErrorMessage
                    message="The attempted operation may not be supported on this wallet yet."
                    fontSize="var(--font-size-secondary)"
                />
                ) : null}
            </Modal.Body>
        </div>
      )}

    {/* {sendTx.data && (
        <div>
            <Modal.Heading>Transaction successfully completed</Modal.Heading>
            
            <Modal.Body>
            <p className="align--center">
                <TextLink
                href={`${getNetworkConfig(
                    settings.isTestnet).stellarExpertTxUrl}${sendTx.data.id
                }`}
                >
                See details on StellarExpert
                </TextLink>
            </p>
            </Modal.Body>
        </div>
    )} */}

      <Modal.Footer>
        {/* <Button onClick={onEditTransaction}>Edit Transaction</Button> */}
        {/* <Button onClick={onCancel} variant={Button.variant.secondary}>
          Close
        </Button>
        <Button onClick={onContinue}>
            continue-tx
        </Button> */}
      </Modal.Footer>
    </>
  );
};

export default CreateTx;