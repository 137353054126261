import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import {
  Button,
  InfoBlock,
  Input,
  TextLink,
  Modal,
  Icon,
  Identicon,
} from "@stellar/design-system";
import _ from 'lodash';
import { useTranslation } from 'react-i18next';


import { useRedux } from "hooks/useRedux";
import { ActionStatus, AuthType } from "types/types";
import { logEvent } from 'helpers/tracking';
import { fetchAccountAction } from 'ducks/account';
import { sendTxAction, resetSendTxAction } from 'ducks/sendTx';
import { getNetworkConfig } from 'helpers/getNetworkConfig';

import Loader from '../../Loader/Loader.jsx';
import WideAds from '../../Ads/WideAds.jsx';
import UserOffersCancel from './UserOffersCancel.jsx';

import { 
  getAccountOffers,
} from '../../../../utils/nft/offer-util';

import {
  getNftByAssetInfo,  
} from '../../../../utils/nft/nft-data-util';

// import * as uploadUtils from '../utils/bucket-upload-util';
import { useStore } from '../../../../hook-store/store';
import GetAssetsTomlData from 'components/IssuerFrontend/UiComponent/GetAssetsTomlData.jsx';

// import { txConfig } from '../../transactionConfig.ts';
import { BACKEND_URL, STELLAREXPERT_URL } from '../../../../urlLinks';

import classes from './UserOffers.module.css';

function UserOffers(props) {
  const { nft } = props;
  // console.log('CreateNftSellOffer.jsx-props', props);
  const initialShouNum = 5;

  const [t] = useTranslation('translation');

  const dispatch = useDispatch();

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    "memoRequiredAccounts",
    "settings",
    "sendTx",
  );
  const { status, errorString } = sendTx;

  const publicKey = account.data ? account.data.id : '';


  const [hStore, hDispatch] = useStore();
  const { forSellOfferAssets } = hStore.nftStore;
  // const txXdr = hStore.nftStore.txXdr;
  // const feeStats = hStore.nftStore.feeStats;

  const [userAccountOffers, setUserAccountOffers] = useState([]);
  const [offerNftList, setOfferNftList] = useState([]);
  const [showAll, setShowAll] = useState(false);

  // const [stateTxXdr, setStateTxXdr] = useState('');
  // const [showSellModal, setShowSellModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [accountData, setAccountData] = useState();
  // const [availableXLM, setAvailableXLM] = useState(0);


  useEffect(() => {
    if (publicKey) {
      getUserOffersHandler(publicKey);
    }

    if (publicKey && status === ActionStatus.SUCCESS) {
      getUserOffersHandler(publicKey);
    }
  }, [publicKey, status]);

  useEffect(() => {
    // console.log('userAccountOffers', userAccountOffers);
    if (userAccountOffers.length > 0 && forSellOfferAssets.length > 0) {
      setOfferNftListHandler(userAccountOffers);
    }
  },[userAccountOffers, forSellOfferAssets]);

  useEffect(() => {
    console.log('offer for list', offerNftList);
  },[offerNftList]);


  const getUserOffersHandler = async (pKey) => {
    try {
      setIsLoading(true);

      const resData = await getAccountOffers(pKey);
      // console.log('getUserOffersHandler resData', resData);
      if (resData._embedded) {
        setUserAccountOffers(resData._embedded.records);
      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  // const getNftByAssetHandler = async (url, token, code, issuer) => {
  //   try {
  //   const resData = await getNftByAssetInfo(url, token, code, issuer);
  //   // console.log('getUserOffersHandler resData', resData);
  //   console.log('offer for list resData', resData);
  //   return resData.data;

  //   } catch(err) {
  //     console.log(err);
  //   }
  // };

  const setOfferNftListHandler = (offerList) => {
    try {

      setIsLoading(true);

      const nftInOfferList = [];
      
      offerList.forEach(async (offer) => {
        console.log('offer for list', offer);
        if (offer.buying.asset_code && offer.buying.asset_issuer) {

          const buyAssetInSellOfferAssets = forSellOfferAssets.find(asset => {
            return asset.code === offer.buying.asset_code && asset.issuer === offer.buying.asset_issuer;
          });

          if (!buyAssetInSellOfferAssets) {
            const buyNft = await getNftByAssetInfo(
              BACKEND_URL,
              localStorage.getItem('token'),
              offer.buying.asset_code,
              offer.buying.asset_issuer,
            );

            nftInOfferList.push(buyNft.data);
          }
          
        }

        if (offer.selling.asset_code && offer.selling.asset_issuer) {
          const sellAssetInSellOfferAssets = forSellOfferAssets.find(asset => {
            return asset.code === offer.selling.asset_code && asset.issuer === offer.selling.asset_issuer;
          });

          if (!sellAssetInSellOfferAssets) {
            const sellNft = await getNftByAssetInfo(
              BACKEND_URL,
              localStorage.getItem('token'),
              offer.selling.asset_code,
              offer.selling.asset_issuer,
            );
            // console.log('offer for list sellNft', sellNft);
            nftInOfferList.push(sellNft.data);
          }
        }

        console.log('nftInOfferList, offerList', nftInOfferList, offerList);
        
        const uniqList = _.uniqBy(nftInOfferList, 'n_id');

        setOfferNftList(uniqList);

      });

      setIsLoading(false);
      // return nftInOfferList;

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  let userOffersTableBody;

  if (userAccountOffers.length > 0 && offerNftList.length > 0) {

    let displayList = userAccountOffers.slice(0, initialShouNum);

    if (showAll) {
      displayList = userAccountOffers;
    }

    userOffersTableBody = (
      <tbody>
        {displayList.map(offer => {

          const sellAsset = offer.selling;
          const buyAsset = offer.buying;

          console.log(sellAsset, buyAsset, offer, offerNftList);

          let sellCode = sellAsset.asset_code;
          let buyCode = buyAsset.asset_code;

          let sellNft;
          let buyNft;

          let dispPrice = (Number(offer.price)/10**7).toFixed(7);
          let dispAmount = (offer.amount * 10**7).toFixed(7);


          if (sellAsset.asset_type === 'native') {
            sellCode = 'XLM';
          } else {
            sellNft = offerNftList.find(nft => {
              return nft.code === sellAsset.asset_code && 
              nft.issuer === sellAsset.asset_issuer;
            });
          }

          if (buyAsset.asset_type === 'native') {
            buyCode = 'XLM';
          } else {
              buyNft = offerNftList.find(nft => {
                return nft.code === buyAsset.asset_code && 
                nft.issuer === buyAsset.asset_issuer;
              });
            }
          
          console.log('sell-buy', sellAsset, buyAsset, offer);
          console.log('sell-buy sellNft, buyNft', sellNft, buyNft);

          let buyLink;
          let sellLink;
          let buyEl = (<span>{buyCode}</span>);
          let sellEl = (<span>{sellCode}</span>);

          if (buyNft) {
            buyLink = `/${buyNft.n_id}`;
            buyEl = (
              <span>
                <Link to={buyLink}>
                  {buyCode}
                </Link>
              </span>
            );

            dispPrice = (Number(offer.amount)).toFixed(7);
            // dispAmount = offer.amount;
            dispAmount = (Number(offer.amount)*Number(offer.price)*10**7).toFixed();
          }

          if (sellNft) {
            sellLink = `/${sellNft.n_id}`;
            sellEl = (
              <span>
                <Link to={sellLink}>
                  {sellCode}
                </Link>
              </span>
            );
          }

          return (
            <tr>
              <td className={classes.userOffersId}>
                {offer.id}
              </td>
              <td className={classes.userOffersAsset}>
                {sellEl}
              </td>
              <td className={classes.userOffersAsset}>
                {buyEl}
              </td>
              <td className={classes.userOffersPrice}>
                {dispPrice}
              </td>
              <td className={classes.userOffersAmount}>
                {dispAmount}
              </td>
              <td className={classes.userOffersTime}>
                {new Date(offer.last_modified_time).toLocaleString()}
              </td>
              <td className={classes.userOffersAmount}>
                <UserOffersCancel 
                  offer={offer}
                  sellAsset={sellAsset}
                  buyAsset={buyAsset}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }


  let userOffersBody = (
    <div className={classes.userOffers}>
      <div className={classes.userOffersTitle}>
        {t('userOffer.01', 'Your Wallet Trading Offers')}
      </div>
      <div className={classes.userOffersTableContainer}>
        {t('userOffer.02', 'no trading offers')}
      </div>

      {/* {(!account || !account.data) && (
          <section className={classes.userOffersConnectWallet}>
            <Link 
              to={`/wallet-login?returnPath=useroffers`}
            >
              <Button 
                size="small" 
                // variant="secondary"
              >
                {t('singleNFT.16','Connect with wallet')}
              </Button>
            </Link>
          </section>
        )} */}
    </div>
  );

  if (userAccountOffers.length > 0) {
    userOffersBody = (
      <div>
        <div className={classes.userOffersTitle}>
          {t('userOffer.01', 'Your Wallet Trading Offers')}
        </div>
        <div className={classes.userOffersTableContainer}>
          <table>
            <thead>
              <tr>
                  {/* <th colspan="2">The table header</th> */}
                <th>offerId</th>
                <th>
                  {t('userOffer.03', 'selling')}
                </th>   
                <th>
                  {t('userOffer.04', 'buying')}
                </th>
                <th>
                  {t('userOffer.05', 'price')}
                </th>
                <th>
                  {t('userOffer.06', 'amount')}
                </th>
                <th className={classes.userOffersTimeHeader}>
                  {t('userOffer.07', 'time')}
                </th>
                <th>

                </th>
                {/* <th>other-el</th> */}

              </tr>
            </thead>
            {userOffersTableBody}
          </table>
        </div>

        <div className={classes.userOffersShowMore}>
          {userAccountOffers.length > initialShouNum && !showAll && (
            <Button
              size="small" 
              variant="tertiary"
              onClick={() => {
                setShowAll(true);
              }}
            >
              {t('userOffer.08', 'display more')}
            </Button>
          )}
          {userAccountOffers.length > initialShouNum && showAll && (
            <Button
              size="small" 
              variant="tertiary"
              onClick={() => {
                setShowAll(false);
              }}
            >
              {t('userOffer.09', 'display less')}
            </Button>
          )}
        </div>

        <div className={classes.userOffersDetilLink}>
          <span>
            {t('userOffer.10', 'Displaying recent trading offers')}
            {' '}
            {showAll && (
              <span>
              ({userAccountOffers.length})
              </span>
            )}
          </span>
          {' '}
          <a           
            href={`${STELLAREXPERT_URL}/account/${publicKey}?filter=offers`}
            target="_blank"
            rel="nofollow noopener noreferrer">
              {t('userOffer.11', 'More detail')}
          </a>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <div className={classes.userOffers}>
        {isLoading && <Loader />}
        {userOffersBody}
        
        <div className={classes.userOffersAds}>
          <WideAds />
        </div>
      </div>

      {forSellOfferAssets?.length === 0 && (
        <GetAssetsTomlData 
            // listForToml={buyInputAssetList} 
        />
      )}
    </Fragment>
  );
}

export default UserOffers;
