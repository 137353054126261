/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */

/* eslint-disable max-len */
import React, {Fragment, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import i18n from '../../../i18n';
import { resources } from '../../../translation/translation';

import "./LanguageList.css";

// import ImageEditorLink from "./ImageEditorLink.jsx";
// import "./TermsOfUse.css";
// import classes from './AboutPage.module.css';

function LanguageList(props) {
  // console.log('props-LanguageList.js', props);

  const { setShowLngModal } = props;

  const [t] = useTranslation('translation');

  // const [showLanguageList, setShowLanguageList] = useState(false);

  useEffect(() => {
    // findLanguageName(props.i18n.language);
  },[]);

  // const showLanguageListHandler = () => {
  //   setShowLanguageList(!showLanguageList);
  // };

  const changeLanguage = (input) => {
    i18n.changeLanguage(input);
  };

  const findLanguageName = (code) => {
    const languageInfo = resources[code];
    console.log(languageInfo);
  };

  const storeUserSelectLanguage = (code) => {
    localStorage.setItem('userSelectLng', code);
  };

  const languageNameList = [];

  for (const element in resources) {
    // console.log(element, resources[element].translations.LANGUAGE);
    languageNameList.push({
      code: element,
      LANGUAGE: resources[element].translation.LANGUAGE,
    });
  }
  // console.log(languageNameList);

  const sortedLanguageList = _.sortBy(languageNameList, 'LANGUAGE'); 
  const languageListBody = (
      <ul>
        {sortedLanguageList.map((ele, index) => (
            <div key={index} className="listElement">
              {/* {ele.code}  */}
              <span 
                onClick={() => {
                  changeLanguage(ele.code);
                  storeUserSelectLanguage(ele.code);

                  // props.showLanguageModalHandler();
                  setShowLngModal(false);
                  // props.getLanguageNameHandler(ele.LANGUAGE)
                }}
              >
                {ele.LANGUAGE}
              </span>
            </div>
            ))
        }
      </ul>

  );

  return (
    <Fragment>
      {languageListBody}
    </Fragment>

  );
};

export default LanguageList;
