import React, { useState, useEffect, Fragment } from 'react';
// import albedo from '@albedo-link/intent'
import * as StellarSdk from '@stellar/stellar-sdk';
import { useRedux } from "hooks/useRedux";
import { ActionStatus, AuthType } from "types/types";
import { getNetworkConfig } from 'helpers/getNetworkConfig';

import NftBuyOfferList from './NftBuyOfferList/NftBuyOfferList.jsx';
import NftSellOfferList from './NftSellOfferList/NftSellOfferList.jsx';

import classes from './NftOffer.module.css';

import { 
  createSellOfferXdr, 
  createCancelSellOfferXdr,
  getAccountInfo,
  getAssetOffers,
  getAccountOffers,
  createTrustlineXdr,
  checkTrustLine,
  createBuyOfferXdr,
} from '../../../utils/nft/offer-util';

import { getStellarTermAssetList } from '../../../utils/nft/nft-data-util';

import { useStore } from '../../../hook-store/store';

// import { BACKEND_URL, } from '../../../App';

function NftOffer(props) {
  const { 
    nft, 
    getTotalOfferNumHandler,
    // isLoading
  } = props;

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    "memoRequiredAccounts",
    "settings",
    "sendTx",
  );
  const { status, errorString } = sendTx;

  const publicKey = account.data ? account.data.id : '';


  const [hStore, hDispatch] = useStore();
  const {
    assetBuyOffers,
    assetSellOffers,
  } = hStore.nftStore;

  // const [assetSellOffers, setAssetSellOffers] = useState([]);
  // const [assetBuyOffers, setAssetBuyOffers] = useState([]);
  
  // const [isLoading, setIsLoading] = useState(false);

  
  // // initialize offers in hStore when left component 
  useEffect(() => {
    return () => {
      hDispatch('SET_ASSETSELLOFFERS', []);
      hDispatch('SET_ASSETBUYOFFERS', []);
    }
  },[]);
  
  useEffect(() => {
    if (assetBuyOffers.length > 0 || assetSellOffers.length > 0) {
      const totalOffers = assetSellOffers.length + assetBuyOffers.length;
      getTotalOfferNumHandler(totalOffers);
    }

    if (assetBuyOffers.length === 0 && assetSellOffers.length === 0) {
      getTotalOfferNumHandler(0);
    }

  },[assetSellOffers, assetBuyOffers]);

  useEffect(() => {
    if (nft) {
      getAssetSellOffersHandler('credit_alphanum12', nft.code, nft.issuer);
      getAssetBuyOffersHandler('credit_alphanum12', nft.code, nft.issuer);
    }
  },[nft]);


  // // update sell offer list when sell offer changed
  useEffect(() => {
    if (nft && status === ActionStatus.SUCCESS) {

      const transaction = new StellarSdk.Transaction(
        sendTx.data.envelope_xdr,
        getNetworkConfig(settings.isTestnet).network,
      );
      // console.log('transaction', transaction);
      const operation = transaction.operations[0];

      if (operation && operation.selling && 
            operation.selling.code === nft.code &&
            operation.selling.issuer === nft.issuer
      ) {
        getAssetSellOffersHandler('credit_alphanum12', nft.code, nft.issuer);
        getAssetBuyOffersHandler('credit_alphanum12', nft.code, nft.issuer);
      }


      // getAssetSellOffersHandler('credit_alphanum12', nft.code, nft.issuer);
      // getAssetBuyOffersHandler('credit_alphanum12', nft.code, nft.issuer);
    }
  },[nft, status]);


  useEffect(() => {
    // if (publicKey) {
    // }
    getTermAssetListHander();
  },[publicKey]);


  const getAssetSellOffersHandler = async (assetType, code, issuer) => {
    try {
      const assetOffers = await getAssetOffers(
        'sell', 
        assetType, 
        code, 
        issuer,
      );

      console.log('assetSellOffers', assetOffers);
      // console.log(accountOffers._embedded.records);

      if (assetOffers) {
        // setAssetSellOffers(assetOffers._embedded.records);
        hDispatch('SET_ASSETSELLOFFERS', assetOffers._embedded.records);
      }

    } catch(err) {
      console.log(err);
    }
  };

  const getAssetBuyOffersHandler = async (assetType, code, issuer) => {
    try {
      const assetBuyOffers = await getAssetOffers(
        'buy', 
        assetType, 
        code, 
        issuer,
      );

      console.log('assetBuyOffers', assetBuyOffers);
      // console.log(accountOffers._embedded.records);

      if (assetBuyOffers) {
        // setAssetBuyOffers(assetBuyOffers._embedded.records);
        hDispatch('SET_ASSETBUYOFFERS', assetBuyOffers._embedded.records);
      }

    } catch(err) {
      console.log(err);
    }
  };


  const getTermAssetListHander = async () => {
    try {
      const resData = await getStellarTermAssetList();

      if (resData) {
        hDispatch('SET_TERMASSETLIST', resData);
      }
    } catch(err) {
      console.log(err);
    }
  };


  return (
    <Fragment>
      <div className={classes.nftOffer}>
        {/* <div>nft-offer-component</div> */}
        <div className={classes.nftOfferListContainer}>
          <div className={classes.nftOfferList}>
            <NftSellOfferList 
              nft={nft}
              assetBuyOffers={assetBuyOffers}
              assetSellOffers={assetSellOffers}
              getAssetBuyOffersHandler={getAssetBuyOffersHandler}
              getAssetSellOffersHandler={getAssetSellOffersHandler}
            />
          </div>

          <div className={classes.nftOfferList}>
            <NftBuyOfferList 
              nft={nft}
              assetBuyOffers={assetBuyOffers}
              assetSellOffers={assetSellOffers}
              getAssetBuyOffersHandler={getAssetBuyOffersHandler}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default NftOffer;
