import React, { useState, useEffect, Fragment } from 'react';
import { Button, Input } from '@stellar/design-system';
import { useTranslation } from 'react-i18next';

import { useRedux } from "hooks/useRedux";

import Loader from '../Loader/Loader.jsx';
import { useStore } from '../../../hook-store/store';

import * as uploadUtils from '../../../utils/nft/bucket-upload-util';
// import * as nftDataUtils from '../../utils/nft-data-util';
import { postCreatorUpdatePush } from '../../../utils/pushNotification/push-notify';

import { BACKEND_URL } from '../../../urlLinks';

function FileUpdate(props) {
  // console.log('FileUpadate-props', props);
  const { nft, showUpdateHandler } = props;

  const [t] = useTranslation('translation');

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    // "memoRequiredAccounts",
    "settings",
    // "sendTx",
  );

  const [hStore, hDispatch] = useStore();
  const nftList = hStore.nftStore.nftList;

  // const [nftList, setNftList] = useState([]);
  const [nameInput, setNameInput] = useState(nft && nft.name);
  const [titleInput, setTitleInput] = useState('');
  // const [amountInput, setAmountInput] = useState(1);
  // const [creatorInput, setCreatorInput] = useState('');
  const [descriptionInput, setDescriptionInput] = useState(nft && nft.desc);
  // const [homeDomainInput, setHomeDomainInput] = useState('');

  const [cidInput, setCidInput] = useState('');
  const [nIdInput, setNIdInput] = useState(nft.n_id);

  // const [ownerSecretInput, setOwnerSecretInput] = useState('');

  // const [imageCidNameInput, setImageCidNameInput] = useState('');
  // const [imageCidUrlInput, setImageCidUrlInput] = useState('');
  // const [imageCidUrlFile, setImageCidUrlFile] = useState();

  const [getBackendError, setGetBackendError] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getIpfsBackendHandler(BACKEND_URL, localStorage.getItem('token'));
  },[]);
 
  const inputData = {
    name: nameInput,
    title: titleInput,
    // amount: amountInput,
    description: descriptionInput,
    // homeDomain: homeDomainInput,
    cid: cidInput,
    nId: nIdInput,

    userAccountId: account.data ? account.data.id : '', 
    // ownerSecret: ownerSecretInput,
    // imageCidUrl: imageCidUrlInput,
    // imageCidName: imageCidNameInput,
    // creator: creatorInput,
  };


  const getIpfsBackendHandler = async (url, token) => {
    try {
      setIsLoading(true);
      setGetBackendError('');

      await uploadUtils.getIpfsBackend(url, token);
      setIsLoading(false);

    } catch(err) {
      console.log(err);

      setGetBackendError('Error occured not available now');
      setIsLoading(false);
    }
  };

  const nameInputChangeHandler = (event) => {
    setNameInput(event.target.value); 
  };

  // const titleInputChangeHandler = (event) => {
  //   setTitleInput(event.target.value); 
  // }

  // const amountInputChangeHandler = (event) => {
  //   if (!isNaN(Number(event.target.value)) ) {
  //     setAmountInput(parseInt(event.target.value));
  //   }
  // }

  const descriptionInputChangeHandler = (event) => {
    setDescriptionInput(event.target.value); 
  };


  // const cidInputChangeHandler = (event) => {
  //   setCidInput(event.target.value);
  // };

  // const nIdInputChangeHandler = (event) => {
  //   setNIdInput(event.target.value);
  // };


  const updateTokenInfoHandler = async (files, inputData) => {
    try {
      setIsLoading(true);

      console.log(files, inputData);

      const result = await uploadUtils.updateTokenInfo(
        BACKEND_URL,
        localStorage.getItem('token'),
        [],
        inputData,
      );

      console.log(result);
      
      const nfts = nftList;

      if (result.data) {

        const updateIndex = nfts.findIndex(nft => (
          nft.n_id === result.data.n_id),
        );
        
        nfts[updateIndex] = result.data;
        
        console.log(updateIndex, nfts);
        hDispatch('SET_NFTLIST', nfts);

        hDispatch('SHOW_NOTIFICATION', {
          status: '',
          title: '',
          message: `update success`,
        });
    
        setTimeout(() => {
          hDispatch('CLEAR_NOTIFICATION');
        }, 5000);


        postCreatorUpdatePush(
          BACKEND_URL,
          localStorage.getItem('token'),
          nft.uId,
          nft.n_id,
          { type: 'nft-update' },
        );
      }

      showUpdateHandler();
      setIsLoading(false);
      
    } catch(err) {
      console.log(err);
      setIsLoading(false);

      hDispatch('SHOW_NOTIFICATION', {
        status: '',
        title: '',
        message: `update failed`,
      });
    }

  };

  return (
    <Fragment>
      {/* <div>--- update-token-info-content ---</div> */}
      {getBackendError && (
        <div>
          <strong>{getBackendError}</strong>
        </div>
      )}
      {!getBackendError && (
        <>
          <div>
            <label>
              {t('CreateNFT.17', 'name of NFT (up to 20 characters)')}
            </label>
            <Input
              type='text' 
              name='name' 
              onChange={nameInputChangeHandler} 
              defaultValue={nft && nft.name}
            />
          </div>
          <div>
            <label>
              {t('CreateNFT.18', 'description of NFT (up to 300 characters)')}
            </label>
            <textarea
              className="textareaBase"
              type='text' 
              name='description' 
              onChange={descriptionInputChangeHandler} 
              defaultValue={nft && nft.desc}
            />
          </div>
          <span></span>
          <div>
            <Button 
              style={{border: "0.25px solid gray"}}
              variant="tertiary"
              // type="button" 
              // className="btn btn-success btn-block"
              disabled={isLoading || 
                nameInput.length > 20 || 
                descriptionInput.length > 300
              }  
              onClick={() => { 
                if (!isLoading) {
                  updateTokenInfoHandler([], inputData); 
                } 
              }}
            >
              {t('userInfo.text2', 'update')}
            </Button> 
          </div>
        </>
      )}

      {isLoading && <Loader />}

    </Fragment>
  );
}

export default FileUpdate;
