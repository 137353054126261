import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
} from "@stellar/design-system";
import { useTranslation } from 'react-i18next';
import Linkify from 'react-linkify';
import { SecureLink } from "react-secure-link";
import numeral from 'numeral';
import _ from 'lodash';

import { useRedux } from "hooks/useRedux";
// import AlbedoLogin from '../Albedo/AlbedoLogin';

import StellarAddress from 'components/IssuerFrontend/UiComponent/StellarAddress.jsx';
import Backdrop from '../../Backdrop/Backdrop.jsx';
import FullSizeImage from './FullSizeImage.jsx';
import FollowNftButton from './FollowNftButton.jsx';
import NftComment from '../../NftComment/NftComment.jsx';
import NftHolders from './NftHolders.jsx';
import NftOffer from '../NftOffer.jsx';
import WideAds from '../../Ads/WideAds.jsx';

import { useStore } from '../../../../hook-store/store';
import { getLowestSellOffer } from '../../../../utils/nft/nft-data-util.js';
import { marks } from '../../../../images/icons/marks';
import classes from './SingleNftItem.module.css';

function SingleNftItem(props) {
  const { 
    nft, 
    creatorUId,
    isLoading,
  } = props;


  const [t] = useTranslation('translation');

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    // "memoRequiredAccounts",
    "settings",
    // "sendTx",
  );

  // const publicKey = account.data ? account.data.id : '';


  const [hStore, hDispatch] = useStore();
  // const albedoPubkeyInfo = hStore.uiStore.albedoPubkeyInfo;
  const albedoAccountInfo = hStore.uiStore.albedoAccountInfo;
  const {
    // assetBuyOffers,
    assetSellOffers,
    termAssetList,
  } = hStore.nftStore;

  const [showFullSize, setShowFullSize] = useState(false);
  const [holderOfferSelect, setHolderOfferSelect] = useState('holder');
  const [totalOfferNum, setTotalOfferNum] = useState(0);
  const [showDescDetail, setShowDescDetail] = useState(false);
  const [totalCommentNum, setTotalComentNum] = useState(0);

  const holderOfferSelectHandler = (type) => {
    setHolderOfferSelect(type);
  };

  const getTotalOfferNumHandler = (totalNum) => {
    setTotalOfferNum(totalNum);
  };

  const getTotalCommentNumHandler = (totalNum) => {
    setTotalComentNum(totalNum);
  };

  let mediaBody;

  if (nft.mimetype) {
    mediaBody = `${nft.name  } ${  nft.mimetype}`;

    if (nft.mimetype.split('/')[0] === 'image') {
      mediaBody = (        
        <img 
          onClick={() => { 
            setShowFullSize(true); 
          }}
          style={{maxWidth:"250px", maxHeight:"80vh",cursor:"pointer"}} 
          // id='image'
          // src={nft.image_cid ? nft.image_cid : nft.fileUrl} 
          // src={nft.smallImageUrl ? nft.smallImageUrl : nft.fileUrl} 
          src={nft.fileUrl} 
          alt="imagedata"
        />
      );
    }

    if (nft.mimetype.split('/')[0] === 'video') {
      mediaBody = (        
        <video style={{maxWidth: "100%", maxHeight: "80vh" }} 
          // src={nft.image_cid ? nft.image_cid : nft.fileUrl} 
          src={nft.fileUrl}
          alt="videodata" 
          muted 
          controls 
          autoPlay={false}
        />
      );
    }

    if (nft.mimetype.split('/')[0] === 'audio') {
      mediaBody = (        
        <audio style={{maxWidth:"100%", marginTop:"80px"}} 
          // src={nft.image_cid ? nft.image_cid : nft.fileUrl} 
          src={nft.smallImageUrl ? nft.smallImageUrl : nft.fileUrl}
          alt="audiodata" controls
        />
      );
    }
  } 


  let hasAsset;
  let hasAmount;
  /// / check have nft
  if (nft && albedoAccountInfo && albedoAccountInfo.balances.length > 0) {
    const hasSellAsset = albedoAccountInfo.balances.find(balance => (
      balance.asset_code === nft.code && 
        balance.asset_issuer === nft.issuer &&
        Number(balance.balance) > 0
      ));

    // console.log(hasSellAsset);

    if (hasSellAsset) {
      hasAsset = true;
      hasAmount = hasSellAsset.balance;
    }
  }


  let lowestSellOffer;
  let lowestSellPrice;
  let lowestSellCode;

  if (assetSellOffers && assetSellOffers.length > 0 && termAssetList) {

    const lowestSellOfferData = getLowestSellOffer(
      assetSellOffers, 
      termAssetList.assets,
    );

    lowestSellOffer = lowestSellOfferData.lowestSellOffer;
    lowestSellPrice = lowestSellOfferData.lowestSellPrice;
    // console.log('lowestSellOfferData', lowestSellOfferData);


    if (lowestSellOffer.buying.asset_type === 'native') {
      lowestSellCode = 'XLM';
    } else {
      lowestSellCode = lowestSellOffer.buying.asset_code;
    }
    // console.log('lowest', lowestSellOffer);
  }


  let singleNftItemBody;

  if (nft) {
    // let holderShowStyle = { display: 'block' };
    let offerShowStyle = { display: 'none' };
    let commentShowStyle = { display: 'none' };

    let holderTabStyle = { fontWeight: 'bold'};
    let offerTabStyle = null;
    let commentTabStyle = null;

    if (holderOfferSelect === 'offer') {
      // holderShowStyle = { display: 'none' };
      offerShowStyle = { display: 'block' };
      commentShowStyle = { display: 'none' };

      holderTabStyle = null;
      offerTabStyle = { fontWeight: 'bold'};
      commentTabStyle = null;
    }

    if (holderOfferSelect === 'comment') {
      // holderShowStyle = { display: 'none' };
      offerShowStyle = { display: 'none' };
      commentShowStyle = { display: 'block' };

      holderTabStyle = null;
      offerTabStyle = null;
      commentTabStyle = { fontWeight: 'bold' };
    }

    singleNftItemBody = (
      <div key={nft.cid}>
        <section className={classes.singleNftMediaContainer}>
          <div className={classes.singleNftMedia}>
            {mediaBody}

            {nft. fileDeleted && (
              <div>
                <strong>file deleted</strong>
              </div>
            )}
          </div>

          <div className={classes.singleNftDesc}>
            {/* <div>nId: {nft.n_id}</div> */}
            {/* <div>cid: {nft.cid}</div> */}
            <div>
              <div className={classes.singleNftDescTitle}>
                {t('general.text1', 'name')}
              </div>
              <div className={classes.singleNftDescContent}>
                {nft.name}
              </div>
            </div>
              <div>
                <div className={classes.singleNftDescTitle}>
                  {t('general.text2', 'description')}
                </div>
                <div className={classes.singleNftDescContent}>
                  <Linkify 
                      // eslint-disable-next-line max-len
                      componentDecorator={(decoratedHref, decoratedText, key) => {
                        // <a target="blank" href={decoratedHref} key={key}>
                        //     {decoratedText}
                        // </a>
                        let showText = decoratedText;

                        if (decoratedText.length > 30) {
                          showText = `${decoratedText.slice(0, 30)}....`;
                        }
                        return (
                        <SecureLink href={decoratedHref} key={key}>
                          {showText}
                        </SecureLink>);
                      }}
                  >
                    {nft.desc}
                  </Linkify>
                </div>
              </div>
              
              {lowestSellOffer && (
                <div>
                  <div className={classes.singleNftDescTitle}>
                    Price
                  </div>
                  <div className={classes.singleNftDescContent}>
                    {lowestSellPrice.toFixed(2)} {lowestSellCode}
                    {' '}
                    ( ~ {(lowestSellOffer.offerPriceUSD).toFixed(2)} USD)
                  </div>
                </div>
              )}
            <div>
              <div className={classes.singleNftDetailButoon}>
                <span
                  onClick={() => {
                    setShowDescDetail(!showDescDetail);
                  }}
                >
                  {t('general.text11', 'display detail')} {marks.triangleDown}
                </span>
              </div>

              {showDescDetail && (
                <div>
                  <div>
                    <div className={classes.singleNftDescTitle}>
                      code
                    </div>
                    <div className={classes.singleNftDescContent}> 
                      {nft.code}
                    </div>
                  </div>
                  <div>
                    <div className={classes.singleNftDescTitle}>
                      {t('general.text5','issuer')}
                    </div>
                    <div className={classes.singleNftDescContent}>
                      <StellarAddress stellarId={nft.issuer}/>
                    </div>
                  </div>
                  <div className={classes.singleNftDescTitle}>
                      total visit
                    </div>
                    <div className={classes.singleNftDescContent}> 
                      {nft.totalVisit >= 1000 && numeral(nft.totalVisit).format('0.0a')}
                      {nft.totalVisit < 1000 && nft.totalVisit}
                    </div>
                  <div>
                    <div className={classes.singleNftDescTitle}>
                      {t('general.text6','created date')}
                    </div>
                    <div className={classes.singleNftDescContent}>
                      {new Date(nft.createdAt).toLocaleDateString()}
                    </div>
                  </div>
                  <div>
                    <div className={classes.singleNftDescTitle}>
                      {t('general.text7','updated date')}
                    </div>
                    <div className={classes.singleNftDescContent}>
                      {new Date(nft.updatedAt).toLocaleDateString()}
                    </div>
                  </div>
                  {/* <div>creatorId: <StellarAddress stellarId={nft.initOwnId}/></div> */}
                </div>
              )}
            </div>
            {/* <div>userId: {nft.userId}</div> */}

            {hasAsset && (
              <div>
                <div>
                  {t('NFTlist.05','You have')} {(Number(hasAmount)*10000000).toFixed()}
                </div>
                {/* <CreateNftSellOffer 
                  nft={nft}
                /> */}
              </div>
            )}
          </div>

        </section>
        
        {showFullSize && (
          <div>

          <Backdrop />

          <FullSizeImage 
            nft={nft}
            setShowFullSize={setShowFullSize}
            showFullSize={showFullSize}
          />
          </div>
        )}

        <section>
          <WideAds />
        </section>

        <section>
          <FollowNftButton nft={nft}/>
        </section>

        {(!account || !account.data) && (
          <section className={classes.singleNftWalletLogin}>
            <Link 
              to={`/wallet-login?returnPath=${nft.n_id}`}
            >
              <Button 
                size="small" 
                // variant="secondary"
              >
                {t('singleNFT.16','Connect with wallet')}
              </Button>
            </Link>
          </section>
        )}
        
        
        <section>
          <div className={classes.singleNftTabs}>
            <button className={classes.singleNftTab}
              style={holderTabStyle}
              onClick={() => {
                holderOfferSelectHandler('holder'); 
              }}
            >
              {t('general.text8','holders')}
            </button>
            {' '}
            <button className={classes.singleNftTab}
              style={offerTabStyle}
              onClick={() => {
                holderOfferSelectHandler('offer'); 
              }}
            >
              {t('general.text9','trading offers')}
              {totalOfferNum > 0 && (
                <span> ({totalOfferNum}) </span>
              )}

            </button>
            <button className={classes.singleNftTab}
              style={commentTabStyle}
              onClick={() => {
                holderOfferSelectHandler('comment'); 
              }}
            >
              {t('general.text10','comments')}
              {' '}
              {totalCommentNum > 0 && (
                <span>({totalCommentNum})</span>
              )}
            </button>
          </div>
          
          {holderOfferSelect === 'holder' && (
            <div 
              // style={holderShowStyle}
            >
              <NftHolders nft={nft} />
            </div>
          )}

          <div style={offerShowStyle}>
            <NftOffer 
              nft={nft}
              getTotalOfferNumHandler={getTotalOfferNumHandler} 
            />
          </div>

          <div style={commentShowStyle}>
            <NftComment
              nft={nft}
              creatorUId={creatorUId}
              getTotalCommentNumHandler={getTotalCommentNumHandler}
            />
          </div>

        </section>


        {/* <section>
          <NftComment nft={nft} creatorUId={creatorUId} />
        </section> */}

      </div>
    );
  }


  return (
    <Fragment>
      <div>
        {singleNftItemBody}
      </div>
    </Fragment>
  );
}

export default SingleNftItem;
