import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Img from "react-cool-img";

import { useRedux } from "hooks/useRedux";
// import AlbedoLogin from '../Albedo/AlbedoLogin';

// eslint-disable-next-line max-len
// import CreateNftSellOffer from '../UserNftList/CreateNftSellOffer/CreateNftSellOffer.jsx';
import NftComment from '../NftComment/NftComment.jsx';
import CreateNftSellOffer from './CreateNftSellOffer/CreateNftSellOffer.jsx';
import NftOffer from './NftOffer.jsx';

import { 
  getAccountInfo,
  checkAssetPosition,
} from '../../../utils/nft/offer-util';

import { useStore } from '../../../hook-store/store';

// import * as uploadUtils from '../utils/bucket-upload-util';
import * as nftDataUtils from '../../../utils/nft/nft-data-util';

import {BACKEND_URL } from '../../../urlLinks';

import classes from './NftListItem.module.css';

function NftListItem(props) {
  const { 
    nft, 
    isLoading,
  } = props;

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    // "memoRequiredAccounts",
    "settings",
    // "sendTx",
  );

  // const publicKey = account.data ? account.data.id : '';


  const [hStore, hDispatch] = useStore();
  const { userList } = hStore.uiStore;
  const { sellOfferNftList } = hStore.nftStore;

  const [nftHolders, setNftHolders] = useState([]);
  const [accountData, setAccountData] = useState();
  const [creator, setCreator] = useState();

  useEffect(() => {
    if (nft) {
      getNftHoldrsHandler(
        // 'https://api.stellar.expert',
        `${BACKEND_URL  }/nft-data/nft-holders`,
        nft.code,
        nft.issuer,
      );
    }
  },[nft]);


  useEffect(() => {
    const getAccountDataHandler = async () => {
      try {
        const accountInfo = await getAccountInfo(account.data.id);
        console.log('accountInfo', accountInfo);
        if (accountInfo) {
          setAccountData(accountInfo);
        }
      } catch(err) {
        console.log(err);
      }
    };

    if (account.data) {
      getAccountDataHandler();
    }
  },[account.data]);


  useEffect(() => {
    if (nft && userList.length > 0 && !creator) {
      const creator = userList.find(user => (user._id === nft.uId));
      
      if (creator) {
        setCreator(creator);
      }
    }
  },[nft, userList]);


  const getNftHoldrsHandler = async(url, code, issuer) => {
    try {
      const result = await nftDataUtils.getNftHolders(url, code, issuer);
      
      console.log(result);
      if (result.data && result.data._embedded) {
        // console.log(result.data._embedded.records)
        setNftHolders(result.data._embedded.records);
      }

    } catch(err) {
      console.log(err);
    }
  };


  let mediaBody;

  if (nft.mimetype) {
    mediaBody = `${nft.name  } ${  nft.mimetype}`;

    if (nft.mimetype.split('/')[0] === 'image') {
      mediaBody = (        
        <Img style={{maxWidth:"100%", maxHeight:"200px"}} 
          // src={nft.image_cid ? nft.image_cid : nft.fileUrl} 
          src={nft.smallImageUrl ? nft.smallImageUrl : nft.fileUrl} 
          alt="imagedata"
        />
      );
    }

    if (nft.mimetype.split('/')[0] === 'video') {
      mediaBody = (        
        <video style={{maxWidth:"100%", height:"175px"}} 
          // src={nft.image_cid ? nft.image_cid : nft.fileUrl} 
          src={nft.trimMediaUrl ? nft.trimMediaUrl : nft.fileUrl} 
          alt="videodata" 
          muted 
          // controls 
          autoPlay={true}
          loop={true}
        />
      );
    }

    if (nft.mimetype.split('/')[0] === 'audio') {
      mediaBody = (        
        <audio style={{maxWidth:"100%", marginTop:"20px"}} 
          // src={nft.image_cid ? nft.image_cid : nft.fileUrl}  
          src={nft.trimMediaUrl ? nft.trimMediaUrl : nft.fileUrl} 
          alt="audiodata" controls
        />
      );
    }
  } 


  let hasAsset;
  let hasAmount;
  /// / check have nft
  if (nft && accountData && accountData.balances.length > 0) {
    const hasSellAsset = accountData.balances.find(balance => (
      balance.asset_code === nft.code && 
        balance.asset_issuer === nft.issuer &&
        Number(balance.balance) > 0
      ));

    // console.log(hasSellAsset);

    if (hasSellAsset) {
      hasAsset = true;
      hasAmount = hasSellAsset.balance;
    }
  }

  // let nftHoldersBody;
  // let holderCreateSellOfferBody;


  // if (nftHolders.length > 0) {
  //   nftHoldersBody = (
  //     <div>
  //       <div>nft-holders-body</div>
  //       <ul>
  //         {nftHolders.map(holder => (
  //           <div key={holder.account}>
  //             <div>holder-account: {holder.account}</div>
  //             <div>holder-balance: {holder.balance}</div>
  //           </div>
  //           ))}
  //       </ul>
  //     </div>
  //   );
  // }


  const nftSellOfferData = sellOfferNftList.find(sNft => {
    return sNft.n_id === nft.n_id;
  });

  let lowestSellOffer;
  let lowestSellPrice;
  let lowestSellCode;

  if (nftSellOfferData) {
    const lowestSellOfferData = nftSellOfferData.lowestSellOfferData;

    lowestSellOffer = lowestSellOfferData.lowestSellOffer;
    lowestSellPrice = lowestSellOfferData.lowestSellPrice;
    // console.log('lowestSellOfferData', lowestSellOfferData);


    if (lowestSellOffer.buying.asset_type === 'native') {
      lowestSellCode = 'XLM';
    } else {
      lowestSellCode = lowestSellOffer.buying.asset_code;
    }
  }


  let nftListItemBody;

  if (nft) {
    nftListItemBody = (
      <div className={classes.nftListItem}>
        <div className={classes.nftListItemMediaContainer}>
          <Link 
            // to={`/uploads/${nft.n_id}`} 
            to={`/${nft.n_id}`} 
            // target="_blank" rel="noopener noreferrer"
          >
            {mediaBody}
          </Link>
        </div>
        {/* <div>nId: {nft.n_id}</div> */}
        {/* <div>cid: {nft.cid}</div> */}
        <div>
          <span>
            <Link 
              // to={`/uploads/${nft.n_id}`} 
              to={`/${nft.n_id}`} 
              // target="_blank" rel="noopener noreferrer"
            >
            name: {nft.name}
            </Link>
          </span>
        </div>
        {/* <div>issuer: {nft.issuer}</div> */}
        <div>
          <span>
            Update Time: 
            {' '}
            {nft.updatedAt && new Date(nft.updatedAt).toLocaleDateString()}
          </span>
        </div>
        {creator && (
          <Link
            to={`/creator-nftlist?_id=${creator._id}`} 
            // target="_blank" rel="noopener noreferrer"
          >
            <div className={classes.nftListItemCreator}>
              <span>
                creator:
              </span>
              {creator.imageUrl && (
                <Img 
                className={classes.nftListItemCreatorImage}
                  src={creator.imageUrl}
                  alt='creator-img'
                />
              )}
              <span>
                {creator.name}
              </span>
            </div>
          </Link>
        )}
        {/* <div>userId: {nft.userId}</div> */}

        {hasAsset && (
          <div>
            <div>
              you have this {(Number(hasAmount)*10000000).toFixed()}
            </div>
            {/* <CreateNftSellOffer 
              nft={nft}
            /> */}
          </div>
        )}

        {nftSellOfferData && (
          <Link to={`/${nft.n_id}`}>
            <div>
              price:
              {' '}
              {lowestSellPrice.toFixed(2)} {lowestSellCode}
              {' '}
              ( ~ {(lowestSellOffer.offerPriceUSD).toFixed(2)} USD)
            </div>
          </Link>           
        )}


        {/* <NftOffer 
          nft={nft}
        /> */}
        
        {/* {!albedoPubkeyInfo && (
          <AlbedoLogin 
            noLogout='noLogout'
          />
        )} */}
        
        {/* <CreateNftSellOffer
          nft={nft} 
        /> */}

        {/* {account && account.data && (
          <div>{nftHoldersBody}</div>
        )} */}

        {/* <NftComment nft={nft} /> */}

        {/* <hr/> */}
        {/* <button 
          onClick={() => { getNftImageUrlHandler(nft.image_cid); }}
        >
          get-image-test
        </button> */}
      </div>
    );
  }


  return (
    <Fragment>
      <div>
        {nftListItemBody}
      </div>
    </Fragment>
  );
}

export default NftListItem;
