import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import Loader from 'components/IssuerFrontend/Loader/Loader.jsx';
import Notification from 'components/IssuerFrontend/Notification/Notification.jsx';
import SingleNftItem from './SingleNftItem.jsx';
import { useStore } from '../../../../hook-store/store';


// import * as uploadUtils from '../utils/bucket-upload-util';
import * as nftDataUtils from '../../../../utils/nft/nft-data-util';
import { getUserData } from '../../../../utils/user';

import { BACKEND_URL } from '../../../../urlLinks';
// import Backdrop from 'components/IssuerFrontend/Backdrop/Backdrop.jsx';

import classes from './SingleNft.module.css';

function SingleNft(props) {
  // console.log('SingleNft.js-props', props);
  const {xxx} = props;

  const params = useParams();
  const navigate = useNavigate();
  // console.log('params', params);

  const [hStore, hDispatch] = useStore();
  // const nftList = hStore.nftStore.nftList;
  const isAuth = hStore.uiStore.isAuth;
  const userData = hStore.uiStore.userData;

  const [nftData, setNftData] = useState();
  const [creatorUId, setCreatorUId] = useState('');
  const [notNftFound, setNotNftFound] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  // console.log(setIsLoading);

  // const pageListNum = 2;


  useEffect(() => {
    window.scrollTo(0, 0);

    getNftHandler(params.nId);

    // hDispatch('SHOW_NOTIFICATION', {
    //   status: '',
    //   title: '',
    //   message: `test notification`,
    // });
  },[]);


  useEffect(() => {
    if (isAuth && !userData) {
      getUserDataHandler(
        BACKEND_URL, 
        localStorage.getItem('token'),
      );
    }
  },[isAuth, userData]);


  const getNftHandler = async (nId) => {
    try {
      setIsLoading(true);

      const result = await nftDataUtils.getNft(
        BACKEND_URL,
        localStorage.getItem('token'),
        nId,
      );
      
      // setNftList(result.data);
      // hDispatch('SET_NFTLIST', result.data);
      if (result.data) {
        console.log(result.data);
        setNftData(result.data);

        if (result.data.uId) {
          setCreatorUId(result.data.uId);
        }
      }

      setIsLoading(false);
      // console.log(result.data[0].imageCid)
    } catch(err) {
      console.log(err);
      setIsLoading(false);

      if (err.message === 'nft-not-found') {
        // navigate("/uploads");
        setNotNftFound(true);
      }
    }
  };


  const getUserDataHandler = async(url, token) => {
    try {
      const result = await getUserData(url, token);

      console.log(result);

      if (result.data) {
        // setUserData(result.data);
        hDispatch('SET_USERDATA', result.data);
      }
    } catch(err) {
      console.log(err);
    }
  };

  // const getFileTypeNftsHandler = async (fileType) => {
  //   try {
  //     const result = await nftDataUtils.getFileTypeNfts(
  //       BACKEND_URL,
  //       localStorage.getItem('token'),
  //       fileType,
  //     );
      
  //     // setNftList(result.data);
  //     console.log(result.data);

  //     // console.log(result.data[0].imageCid)
  //   } catch(err) {
  //     console.log(err);
  //   }
  // };

  // const getMimetypeNftsHandler = async (mimetype) => {
  //   try {
  //     const result = await nftDataUtils.getMimetypeNfts(
  //       BACKEND_URL,
  //       localStorage.getItem('token'),
  //       mimetype,
  //     );
      
  //     // setNftList(result.data);
  //     console.log(result.data);

  //     // console.log(result.data[0].imageCid)
  //   } catch(err) {
  //     console.log(err);
  //   }
  // };


  // const getNftImageUrlHandler = async (imageCid) => {
  //   try {
  //     const result = await nftDataUtils.getNftImageUrl(
  //       BACKEND_URL,
  //       localStorage.getItem('token'),
  //       imageCid
  //     );

  //     console.log(result.data);
  //     window.open(result.data , "_blank", 'noreferrer');
  //     // window.open(result.data);
  //     // window.open("http://www.mozilla.org/");
      
  //     // console.log(result.data[0].imageCid)
  //   } catch(err) {
  //     console.log(err);
  //   }
  // };

  let signleNftBody;

  if (nftData) {
    signleNftBody = (
      <SingleNftItem
        nft={nftData}
        isLoading={isLoading}
        creatorUId={creatorUId}
    />
    );
  }

  if (notNftFound) {
    signleNftBody = (
      <div>
        <div>
          nft not found
        </div>
        <div>
          <Link to="/uploads">go to list page</Link>
        </div>
      </div>
    );
  }


  return (
    <Fragment>
      <div className={classes.singleNft}>
        {/* <div>single-nft-page</div> */}
        {/* <Link to={`/dashboard`}>back-to-dashboard</Link> */}
        
        {/* <Link to={`/uploads`}>
          Back to List Page
        </Link> */}

        {/* <Link to={`/wallet-login`}>back-to-/wallet-login</Link> */}
        {isLoading && <Loader />}
        {signleNftBody}
      </div>

      {hStore.uiStore.notification && (
          <div>
          <Notification 
              status={hStore.uiStore.notification.status}
              title={hStore.uiStore.notification.title}
              message={hStore.uiStore.notification.message}
          />
          </div>
      )}
    </Fragment>
  );
}

export default SingleNft;
