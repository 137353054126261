/* eslint-disable max-len */

export const postOfferPush = (
  url, 
  token, 
  nId, 
  sellOfferData, 
  buyOfferData,
  commentData,  
) => new Promise(
  (resolve, reject) => {
    fetch(`${url  }/push-notify/offer-push`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${  token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        nId,
        sellOfferData,
        buyOfferData,
        commentData,
      }),
    })
      .then((res) => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("test push failed!");
        }
        return res.json();
      })
      .then((resData) => {
        console.log(resData);

        resolve({ message: "push success", data: resData.data });
        // displayNotification(resData.data.pushContent);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
        // reject({ message: "test push failed", error: err });
      });
  });


  export const postCreatorUpdatePush = (
    url, 
    token, 
    creatorUId,
    nId,
    data, 
  ) => new Promise(
    (resolve, reject) => {
      fetch(`${url  }/push-notify/creator-update-push`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${  token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          creatorUId,
          nId,
          data,
        }),
      })
        .then((res) => {
          if (res.status !== 200 && res.status !== 201) {
            throw new Error("test push failed!");
          }
          return res.json();
        })
        .then((resData) => {
          console.log(resData);
  
          resolve({ message: "push success", data: resData.data });
          // displayNotification(resData.data.pushContent);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          // reject({ message: "test push failed", error: err });
        });
    });