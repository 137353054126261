import React, { Fragment, useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { Button, Input } from '@stellar/design-system';
import { useTranslation } from 'react-i18next';
import Linkify from 'react-linkify';
import { SecureLink } from "react-secure-link";

import i18n from '../../../../i18n';
import Backdrop from "components/IssuerFrontend/Backdrop/Backdrop.jsx";
import Loader from '../../Loader/Loader.jsx';
import UserImage from '../../User/UserImage.jsx';
import PushNotificationControl from '../../PushNotification/PushNotificationControl.jsx';
import UserFollowNfts from '../../UserFollow/UserFollowNfts/UserFollowNfts.jsx';
import FollowingUsers from '../../UserFollow/FollowingUsers/FollowingUsers.jsx';
import LanguageList from '../../LanguageList/LanguageList.jsx';
import ModalSimple from "components/IssuerFrontend/Modal/ModalSimple.jsx";

import { useStore } from "../../../../hook-store/store";
import { resources } from '../../../../translation/translation';
import { getUserData } from "../../../../utils/user";
import { 
  BACKEND_URL, 
  USERACCOUNTPAGE_URL, 
} from "../../../../urlLinks";

import { marks } from '../../../../images/icons/marks';
import classes from './UserInfo.module.css';

const UserInfo = (props) => {
  // const { } = props;

  console.log(i18n);
  const selectedLanguage = resources[localStorage.getItem('i18nextLng')].translation.LANGUAGE;

  const [t] = useTranslation('translation');

  const [hStore, hDispatch] = useStore();
  const { 
    userData,
    userNameData,
  } = hStore.uiStore;
  // const isAdmin = store.uiStore.isAdmin;

  // const [userData, setUserData] = useState();
  const [showNameUpdate, setShowNameUpdate] = useState(false);
  const [showDescriptionUpdate, setShowDescriptionUpdate] = useState(false);
  const [nameInput, setNameInput] = useState('');
  const [descriptionInput, setDescriptionInput] = useState('');
  const [showLngModal, setShowLngModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getUserDataHandler(BACKEND_URL, localStorage.getItem('token'));
  }, []);

  const getUserDataHandler = async(url, token) => {
    try {
      const result = await getUserData(url, token);

      console.log(result);

      if (result.data) {
        // setUserData(result.data);
        hDispatch('SET_USERDATA', result.data);
      }
    } catch(err) {
      console.log(err);
    }
  };

  const userNameUpdateHandler = async (name) => {
    try {
      setIsLoading(true);
      // console.log('name', name);
      // throw new Error('error-error');
      const result = await fetch(`${BACKEND_URL }/auth/name?userLocation=${localStorage.getItem('userLocation')}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
        }),
      });
  
      const resData = await result.json();
  
      if (!result.ok) {
        throw new Error('something worng');
      }
  
      console.log(result, resData);
      
      const updatedUser = {
        ...userData,
        name,
      };

      hDispatch('SET_USERDATA', updatedUser);

      setShowNameUpdate(false);
      setIsLoading(false);

      return resData;

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      throw err;
    }
  };


  const userDescriptionUpdateHandler = async (description) => {
    try {
      setIsLoading(true);

      const result = await fetch(`${BACKEND_URL }/auth/description?userLocation=${localStorage.getItem('userLocation')}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          description,
        }),
      });
  
      const resData = await result.json();
  
      if (!result.ok) {
        throw new Error('something worng');
      }
  
      console.log(result, resData);
  
      const updatedUser = {
        ...userData,
        description,
      };

      hDispatch('SET_USERDATA', updatedUser);

      setShowDescriptionUpdate(false);
      setIsLoading(false);

      return resData;

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      throw err;
    }
  };

  const userNameUpdateBody = (
    <div className={classes.userInfoNameUpdate}>
      <label>
        {t('userInfo.text1', 'user name')} ({t('userInfo.text17', 'up to 20 characters')})
      </label>
      <Input 
        type='text' 
        name='name' 
        onChange={(event) => { 
          setNameInput(event.target.value); 
        }}
        defaultValue={userData && userData.name}
      />
      <div className={classes.userInfoNameUpdateButton}>
        <Button 
          style={{border: "0.25px solid gray"}}
          variant="tertiary"
          // type="button" 
          // className="btn btn-success btn-block"
          disabled={isLoading || nameInput.length > 20}  
          onClick={() => { 
            userNameUpdateHandler(nameInput); 
          }}
        >
          {t('userInfo.text2', 'update')}
        </Button> 
      </div>

      {isLoading && <Loader />}
    </div>
  );

  const userDescriptionUpdateBody = (
    <div className={classes.userInfoNameUpdate}>
    <label>
      {t('userInfo.text3', 'user description')} ({t('userInfo.text18', 'up to 300 characters')})
    </label>
    <textarea
      className="textareaBase"
      type='text' 
      name='description' 
      rows='4'
      onChange={(event) => { 
        setDescriptionInput(event.target.value); 
      }} 
      defaultValue={userData && userData.description}
    />
    <div className={classes.userInfoNameUpdateButton}>
      <Button 
          style={{border: "0.25px solid gray"}}
          variant="tertiary"
          // type="button" 
          // className="btn btn-success btn-block"
          disabled={isLoading || descriptionInput.length > 300}  
          onClick={() => { 
            userDescriptionUpdateHandler(descriptionInput); 
          }}
        >
          {t('userInfo.text2', 'update')}
        </Button> 
    </div>

    {isLoading && <Loader />}
  </div>
  );

  let userInfoBody;

  if (userData) {
    userInfoBody = (
      <div className={classes.userInfoContent}>
        
        {/* {isAdmin && (
          <div>
            <div>admin-user</div>
            <div><Link to="/admin">admin-page</Link></div>
          </div>
        )} */}


        <div className={classes.userInfoItem}>
            <div>
              {t('userInfo.text1', 'user name')} (NFT Place)
            </div>
          <div className={classes.userInfoName}>
            <strong>{userData.name}</strong> 
            <Button
              style={{border: "0.25px solid gray"}}
              variant="tertiary"
              size="small"
              onClick={() => { 
                setShowNameUpdate(!showNameUpdate); 
              }}
            >
              {t('userInfo.text2', 'update')}
            </Button>
          </div>
        </div>

        {showNameUpdate && userNameUpdateBody}

        <div className={classes.userInfoItem}>
          {t('userInfo.text4', 'email address')}
          {' '} 
          <div className={classes.userInfoName}>
            <strong>{userData.email}</strong>
          </div>
        </div>



        <div className={classes.userInfoItem}>
          <div>
            {t('userInfo.text3', 'user description')} (NFT Place)
          </div>
          <div className={classes.userInfoName}>
            <strong className={classes.userInfoDescriptionContent}>
            <Linkify 
                      // eslint-disable-next-line max-len
                      componentDecorator={(decoratedHref, decoratedText, key) => {
                        // <a target="blank" href={decoratedHref} key={key}>
                        //     {decoratedText}
                        // </a>
                        let showText = decoratedText;

                        if (decoratedText.length > 30) {
                          showText = `${decoratedText.slice(0, 30)}....`;
                        }
                        return (
                        <SecureLink href={decoratedHref} key={key}>
                          {showText}
                        </SecureLink>);
                      }}
                  >
              {userData.description}
              </Linkify>
            </strong>
          </div>
          <Button
            style={{border: "0.25px solid gray"}}
            variant="tertiary"
            size="small"
            onClick={() => { 
              setShowDescriptionUpdate(!showDescriptionUpdate); 
            }}
          >
            {t('userInfo.text2', 'update')}
          </Button>
        </div>

        {showDescriptionUpdate && userDescriptionUpdateBody}
        
        <div className={classes.userInfoItem}>
          {t('userInfo.text5', 'creation time')}
          {' '}
          <div className={classes.userInfoName}>
            <strong>
              {new Date(userData.createdAt).toLocaleDateString()}
            </strong>          
          </div>
        </div>

        <div className={classes.userInfoItem}>
          <UserImage 
            userData={userData}
            getUserDataHandler={getUserDataHandler}
          />
        </div>

        <div className={classes.userInfoItem}>
          <PushNotificationControl />
        </div>

        <div className={classes.userInfoItem}>
          <UserFollowNfts />
        </div>

        <div className={classes.userInfoItem}>
          <FollowingUsers />
        </div>
        
        <div className={classes.userInfoItem}>
          <div className={classes.userInfoLanguage}>
            <span>
              {t('userInfo.text28', 'language')}:
            </span>
            <span >
              {selectedLanguage}
            </span>
            <Button
                style={{border: "0.25px solid gray"}}
                variant="tertiary"
                size="small"
                disabled={isLoading}
                onClick={() => { 
                  setShowLngModal(true); 
                }}
              >
                {t('userInfo.text29', 'change language')}
            </Button>
          </div>

          {showLngModal && (
            <div>
            <Backdrop 
              onCancel={() => { 
                setShowLngModal(false); 
                // zIndex={50}
              }}
            />
              <ModalSimple
                // modalStyle={{width: "40rem"}}
              >
              <div className={classes.userInfoLanguageClose}>
                <span
                  className={classes.userInfoLanguageCloseButton}
                  onClick={() => {
                    setShowLngModal(false); 
                  }}
                >
                  X
                </span>
              </div>
                <LanguageList 
                  setShowLngModal={setShowLngModal}
                />
              </ModalSimple>
            </div>
          )}

        </div>
        
        {/* <br/>
        <br/>
        <div>
          SpaceEight account user data
          {' '}
          <a
            href={`${USERACCOUNTPAGE_URL}?tokenForCasTGT=${localStorage.getItem('tokenForCasTGT')}&TGTexp=${localStorage.getItem('TGTexp')}`}
            target="_blank" rel="nofollow noopener noreferrer"
            title={'go to user account page for update'}
          >
            (user-page-link {marks.externalLinkMark})
          </a>
        </div>
        {userNameData && (
          <div className={classes.userInfoItem}>
            <div className={classes.userInfoName}>
              <div>
                name <strong>{userNameData.name}</strong> 
              </div>
    
              <a
                href={`${USERACCOUNTPAGE_URL}?tokenForCasTGT=${localStorage.getItem('tokenForCasTGT')}&TGTexp=${localStorage.getItem('TGTexp')}`}
                target="_blank" rel="nofollow noopener noreferrer"
                title={'go to user account page for update'}
              >
                <Button
                  style={{border: "0.25px solid gray"}}
                  variant="tertiary"
                  size="small"
                >
                  {t('userInfo.text2', 'update')} {marks.externalLinkMark}
                </Button>
              </a>
            </div>
          </div>
        )}

        <div className={classes.userInfoItem}>
            {t('userInfo.text4', 'email address')}
            {' '} 
            <strong>{userData.email}</strong>
          </div>

        {userNameData && (
          <div className={classes.userInfoItem}>
            <div className={classes.userInfoName}>
              <div>
                user image
              </div>
              {userNameData.imageUrl && (
                <img className={classes.userInfoUserImage}
                  src={userNameData.imageUrl}
                />
              )}
              {!userNameData.imageUrl && (
                <span className={classes.userInfoUserNoImage}>
                  {marks.userMrak}
                </span>
              )}
              <a
                href={`${USERACCOUNTPAGE_URL}?tokenForCasTGT=${localStorage.getItem('tokenForCasTGT')}&TGTexp=${localStorage.getItem('TGTexp')}`}
                target="_blank" rel="nofollow noopener noreferrer"
                title={'go to user account page for update'}
              >
                <Button
                  style={{border: "0.25px solid gray"}}
                  variant="tertiary"
                  size="small"
                  // onClick={() => { 
                  //   setShowNameUpdate(!showNameUpdate); 
                  // }}
                >
                  {t('userInfo.text2', 'update')} {marks.externalLinkMark}
                </Button>
              </a>
            </div>
          </div>
        )} */}

      </div>
    );
  }
 

  return <Fragment>
    <div className="pageContentContainer">
      {userInfoBody}
    </div>
  </Fragment>;
};

export default UserInfo;
