import React, { useState, useEffect, Fragment } from 'react';
// import albedo from '@albedo-link/intent'
// var toml = require('toml');
// const toml = toml || require('toml-j0.4');
import toml from 'toml-j0.4';

import { useRedux } from "hooks/useRedux";

import { getAccountInfo } from '../../../utils/nft/offer-util';
import { useStore } from '../../../hook-store/store';

import {
  postGetAccountAssetData,
  getHorizonAssetInfo,
  parseStellarToml,
  getAssetInfoFromToml,
  createListForGetToml,
  createListForGetToml2,
} from '../../../utils/nft/account-data-util';

// import * as uploadUtils from '../utils/nft/bucket-upload-util';
// import * as accountUtils from '../utils/account-util';

import { BACKEND_URL, HORIZON_URL } from '../../../urlLinks';


function GetAssetsTomlData(props) {
  const { listForToml } = props;

  const { account, settings, } = useRedux(
    "account",
    // "memoRequiredAccounts",
    "settings",
    // "sendTx",
  );

  const [hStore, hDispatch] = useStore();

  // const [assetTomlInfo, setAssetTomlInfo] = useState();
  // const [assetInfoList, setAssetInfoList] = useState([]);
  const [accountData, setAccountData] = useState();

  // const aList = [
  //   // {code: 'AQUAxxx', issuer: 'GBNZILSTVQZ4R7IKQDGHYGY2QXL5QOFJYQMXPKWRRM5PAV7Y4M67AQUA'},
  //   {code: 'AQUA', issuer: 'GBNZILSTVQZ4R7IKQDGHYGY2QXL5QOFJYQMXPKWRRM5PAV7Y4M67AQUA'},
  // ];


  let buyInputAssetList = [
    { code: "XLM", issuer: "" },
    // {code: "AQUA", issuer: "GBNZILSTVQZ4R7IKQDGHYGY2QXL5QOFJYQMXPKWRRM5PAV7Y4M67AQUA"},
    {code: "USDC", issuer: "GA5ZSEJYB37JRC5AVCIA5MOP4RHTM335X2KGX3IHOJAPP5RE34K4KZVN"},
    {code:'yETH', issuer: 'GDYQNEF2UWTK4L6HITMT53MZ6F5QWO3Q4UVE6SCGC4OMEQIZQQDERQFD'},
    {code:'yBTC', issuer: 'GBUVRNH4RW4VLHP4C5MOF46RRIRZLAVHYGX45MVSTKA2F6TMR7E7L6NW'},
    {code: 'yUSDC', issuer: 'GDGTVWSM4MGS4T7Z6W4RPWOCHE2I6RDFCIFZGS3DOA63LWQTRNZNTTFF'},
  ];

  if (settings.isTestnet) {
    buyInputAssetList = [
      { code: "XLM", issuer: "" },
      {
        code: "USDC",
        issuer: "GBBD47IF6LWK7P7MDEVSCWR7DPUWV3NY3DTQEVFL4NAT4AQH3ZLLFLA5",
      },
    ];
  }


  useEffect(() => {
    createAssetsTomlDataList(listForToml);

    if (!listForToml) {
      createAssetsTomlDataList(buyInputAssetList);
    }
  },[]);


const postGetAccountAssetDataHandler = async (url, token, assetList) => {
  try {
    const resData = await postGetAccountAssetData(
      BACKEND_URL,
      'token',
      assetList,
    );

    console.log('accounst asset info', resData.data);
    hDispatch('SET_ASSETINFOLIST', resData.data);
    
    const accountAssetData = {
      id: accountData.account_id,
      assetInfoList: resData.data,
      getTime: Date.now(),
    };

    // localStorage.setItem(`accountAssetData-${accountData.account_id}`, JSON.stringify(accountAssetData));
    localStorage.setItem('assetDataList', JSON.stringify(accountAssetData));
    return resData;
    
  } catch(err) {
    console.log(err);
  }
};


const createAssetInfoList = async (assetList) => {
  try {
    // const list = [{ code: 'XLM', issuer: '', tomlData: null }];
    const list = [];

    for (const asset of assetList) {
      const tomlData = await parseStellarToml(asset.code, asset.issuer);
      
      if (tomlData) {
        list.push({
          code: asset.code,
          issuer: asset.issuer,
          tomlData,
        });
      } else {
        list.push({
          code: asset.code,
          issuer: asset.issuer,
          tomlData: null,
        });
      }
    }
  
    console.log('toml assetInfoList', list);
    hDispatch('SET_ASSETINFOLIST', list);

    const accountAssetData = {
      id: accountData.account_id,
      assetInfoList: list,
      getTime: Date.now(),
    };

    // localStorage.setItem(`accountAssetData-${accountData.account_id}`, JSON.stringify(accountAssetData));

    localStorage.setItem('assetDataList', JSON.stringify(accountAssetData));
  
  } catch(err) {
    console.log(err);
  }
  
};


const createAssetsTomlDataList = async (assetList) => {
  try {
    // const list = [{ code: 'XLM', issuer: '', tomlData: null }];
    const list = [];

    for (const asset of assetList) {
      const tomlData = await parseStellarToml(asset.code, asset.issuer);
      
      if (tomlData) {
        list.push({
          code: asset.code,
          issuer: asset.issuer,
          tomlData,
        });
      } else {
        list.push({
          code: asset.code,
          issuer: asset.issuer,
          tomlData: null,
        });
      }
    }
    
    console.log('assetListToml', list);
    hDispatch('SET_FORSELLOFFER_ASSETS', list);
    return list;

  } catch(err) {
    console.log(err);
  }
  
};


// let testList = (
//   <ul>
//     {hStore.nftStore.assetInfoList.map((asset, index) => {
//       if (asset.tomlData) {
//         return <li key={index}>
//           <img style={{height:"20px"}} src={asset.tomlData.image} alt="logo"/> {asset.code}
//         </li>
//       } else {
//         return <li key={index}>{asset.code}</li>;
//       }
//     })}
//   </ul>
// );


  return (
    <Fragment>
      {/* <img src={assetTomlInfo && assetTomlInfo.image}/> */}
      {/* {testList} */}
    </Fragment>
  );
}

export default GetAssetsTomlData;
