// import React from 'react';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

import { useRedux } from "hooks/useRedux";
// import { useStore } from '../../../../hook-store/store';
// import { logoutHandler } from '../../../../utils/user/user';
// import { marks } from '../../../../utils/marks';

// import { loginPageLink, signupPageLink } from '../../../../App';
import { useStore } from '../../../../../hook-store/store';
import { loginPageLink, signupPageLink } from '../../../../../urlLinks';
import { logoutHandler } from '../../../../../utils/user';
import { appInstallHandler } from '../../../../../utils/ui-util';

import './ToggleNavItems.css';


const ToggleNavItems = props => {
  // console.log('ToggleNavItems-props', props);
  const { setIsMenuOpen } = props;

  const [t] = useTranslation('translation');

  const dispatch = useDispatch();

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    "memoRequiredAccounts",
    "settings",
    "sendTx",
  );
  const { status, errorString } = sendTx;

  const publicKey = account.data ? account.data.id : '';

  const [hStore, hDispatch] = useStore();
  // const isAuth = hStore.uiStore.isAuth;
  const { 
    isAuth,
    deferredPrompt,
    userData,
  } = hStore.uiStore;
  // const isMenuOpen = hStore.uiStore.isMenuOpen;


  const navItems = [
    { 
      id: 'list', 
      // text: 'NFT list', 
      text: `${t('nav.01', 'NFT list')}`,
      link: '/', 
      auth: false,
    },
    { 
      id: 'usernft', 
      // text: 'User NFT list', 
      text: `${t('NFTlist.02', 'User NFT List')}`,
      link: `/user-uploads?_id=${userData && userData._id}`, 
      auth: true,
    },
    { 
      id: 'userinfo', 
      // text: 'User info', 
      text: `${t('nav.02', 'User info')}`,
      link: '/userinfo', 
      auth: true,
    },
    { 
      id: 'useroffers', 
      // text: 'User offers', 
      text: `${t('nav.03', 'User trading offers')}`,
      link: '/useroffers', 
      auth: true,
    },
    { 
      id: 'install-app', 
      // text: 'Install App', 
      text: `${t('nav.09', 'install App')}`,
      link: '/', 
      auth: false,
    },
    { 
      id: 'login', 
      // text: 'Login', 
      text: `${t('general.text18', 'login')}`,
      link: '/feed/posts', 
      auth: false,
    },
    { 
      id: 'signup', 
      // text: 'Signup', 
      text: `${t('general.text19', 'signup')}`,
      link: '/feed/posts', 
      auth: false,
    },
    { 
      id: 'logout', 
      // text: 'Logout', 
      text: `${t('general.text21', 'logout')}`,
      link: '/feed/posts', 
      auth: true,
    },
  ];

  
  const logoutConfirm = () => {
    if (window.confirm("Is it no problem to logout?")) {
      // console.log('confirm true');
      // props.onLogout();

      hDispatch('SET_ISAUTH', false);
      hDispatch('SET_USERDATA', null);
      logoutHandler();

    }
  };

  let toggleNavItemsBody;

  toggleNavItemsBody = (
    <ul>
      {navItems.map(item => {
        if (!isAuth && item.id === 'login') {
          return (
            <div className="toggleNavItem">
              <span>
                <a href={loginPageLink} >
                  <span className='toggleNavItemIcon'>
                    {/* {marks.signInMrak} */}
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                </a>
              </span>
            </div>
          );
        }

        if (!isAuth && item.id === 'signup') {
          return (
            <div className="toggleNavItem">
              <span>
                <a href={signupPageLink} >
                <span className='toggleNavItemIcon'>
                  {/* {marks.userPlusMrak} */}
                </span> 
                <span className='toggleNavItemText'>
                  {item.text}
                </span>
                </a>
              </span>
            </div>
          );
        }

        if (item.id === 'list') {
          return (
              <div className="toggleNavItem">
                <Link to={item.link} 
                  onClick={() => {
                    // setIsMenuOpen(false);
                    hDispatch('SET_ISMENUOPEN', false);
                    // dispatch('SET_SHOWTOGGLENAV', false);
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {/* {marks.userMark}  */}
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                </Link>
              </div>
          );
        }

        if (isAuth && item.id === 'usernft') {
          return (
              <div className="toggleNavItem">
                <Link to={item.link} 
                  onClick={() => {
                    // setIsMenuOpen(false);
                    hDispatch('SET_ISMENUOPEN', false);
                    // dispatch('SET_SHOWTOGGLENAV', false);
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {/* {marks.userMark}  */}
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                </Link>
              </div>
          );
        }
        
        if (isAuth && publicKey && item.id === 'useroffers') {
          return (
              <div className="toggleNavItem">
                <Link to={item.link} 
                  onClick={() => {
                    // setIsMenuOpen(false);
                    hDispatch('SET_ISMENUOPEN', false);
                    // dispatch('SET_SHOWTOGGLENAV', false);
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {/* {marks.userMark}  */}
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                </Link>
              </div>
          );
        }


        if (isAuth && item.id === 'userinfo') {
          return (
              <div className="toggleNavItem">
                <Link to={item.link} 
                  onClick={() => {
                    hDispatch('SET_ISMENUOPEN', false);
                    // dispatch('SET_SHOWTOGGLENAV', false);
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {/* {marks.userMark}  */}
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                </Link>
              </div>
          );
        }

        if (deferredPrompt && item.id === 'install-app') {
          return (
              <div className="toggleNavItem">
                <Link to={item.link} 
                  onClick={() => {
                    // hDispatch('SET_ISMENUOPEN', false);
                    appInstallHandler(deferredPrompt);
                    // dispatch('SET_SHOWTOGGLENAV', false);
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {/* {marks.userMark}  */}
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                </Link>
              </div>
          );
        }

        if (isAuth && item.id === 'logout') {
          return (
              <div className="toggleNavItem">
                <span
                  onClick={() => { logoutConfirm(); }} 
                >
                <span className='toggleNavItemIcon'>
                  {/* {marks.signOutMrak}  */}
                </span> 
                <span className='toggleNavItemText'>
                  {item.text}
                </span>
                </span>
              </div>
          );
        }

        else {
          return null;
        }

        // return (
        //   <div  className="toggleNavItem">
        //     <span>
        //     <span className='toggleNavItemIcon'>
        //       {item.text}
        //       {/* {marks.userMark}  */}
        //       </span> 
        //     </span>
        //   </div>
        // );
      })}
    </ul>
  );

  const togleNavItemLinkBody = (
    <div className='toggleNavItemLinks'>
      <div className="toggleNavItem">
        <a className='toggleNavItemLinksLink'
          href='https://www.spaceeight.work'
          target="_blank"
          rel="nofollow noopener noreferrer"
          onClick={() => {
            hDispatch('SET_ISMENUOPEN', false);
            // dispatch('SET_SHOWTOGGLENAV', false);
          }}
        >
          <span className='toggleNavItemIcon'>
            {/* {marks.userMark}  */}
          </span> 
          <span className='toggleNavItemText'
            title='StellarSpace: Transaction Client for Blockchain based Stellar Network, Wallet Payment Decentralized Exchange'
          >
             
            {t('nav.04', 'Decentralized Exchange')}
            {' '}
            (DEX)
            <br />
            <sapn className="toggleNavItemLinkDesc">
              (StellarSpace)
            </sapn>
          </span>
        </a>
      </div>
      <div className="toggleNavItem">
        <a className='toggleNavItemLinksLink'
          href='https://deposit.spaceeight.work'
          target="_blank"
          rel="nofollow noopener noreferrer"
          onClick={() => {
            hDispatch('SET_ISMENUOPEN', false);
            // dispatch('SET_SHOWTOGGLENAV', false);
          }}
        >
          <span className='toggleNavItemIcon'>
            {/* {marks.userMark}  */}
          </span> 
          <span className='toggleNavItemText' 
            title='StellarSpace Deposit, deposit stellar XLM to your account using your credit card' 
          >
            {t('nav.05', 'Buy')}
            {' '}
            XLM
            <br />
            <sapn className="toggleNavItemLinkDesc">
              (StellarSpace Deposit)
            </sapn>
          </span>
        </a>
      </div>
      <div className="toggleNavItem">
        <a className='toggleNavItemLinksLink'
          href='https://news.spaceeight.work'
          target="_blank"
          rel="nofollow noopener noreferrer"
          onClick={() => {
            hDispatch('SET_ISMENUOPEN', false);
            // dispatch('SET_SHOWTOGGLENAV', false);
          }}
        >
          <span className='toggleNavItemIcon'>
            {/* {marks.userMark}  */}
          </span> 
          <span className='toggleNavItemText'
            title='News from various news sources about cryptocurrencies and markets and others'
          >
            {t('nav.06', 'News')}
            <br/>
            <sapn className="toggleNavItemLinkDesc">
              (StellarSpace News)
            </sapn>
          </span>
        </a>
      </div>
    </div>
  );

  return (
  <Fragment>
    {isAuth && (
      <div className='toggleNavItemEmail'>
        {localStorage.getItem('email')}
      </div>
    )}

    {toggleNavItemsBody}

    <div className="toggleNavItemSpacer"></div>

    {togleNavItemLinkBody}
  </Fragment>
  );

  }
export default ToggleNavItems;
