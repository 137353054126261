import React, { useState, useEffect, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import _ from 'lodash';
// import {
//   Pagination,
// } from "@stellar/design-system";
import { useRedux } from "hooks/useRedux";

import Loader from '../Loader/Loader.jsx';
import NftListItem from './NftListItem.jsx';
import Paginator from '../Paginator/Paginator.jsx';
import { useStore } from '../../../hook-store/store';


// import * as uploadUtils from '../utils/bucket-upload-util';
import * as nftDataUtils from '../../../utils/nft/nft-data-util';

import { BACKEND_URL } from '../../../urlLinks';

import classes from './NftList.module.css';

function NftList(props) {
  const {xxx} = props;

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    // "memoRequiredAccounts",
    "settings",
    // "sendTx",
  );

  const [hStore, hDispatch] = useStore();
  const { userList } = hStore.uiStore;
  const { 
    nftList, 
    termAssetList,
 } = hStore.nftStore;

  const [currentPage, setCurrentPage] = useState(1);
  const [pageListNum, setPageListNum] = useState(10);
  const [isMoreItem, setIsMoreItem] = useState(true);
  // const [sellOfferNftList, setSellOfferNftList] = useState([]);
  // const [getTimes, setGetTimes] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  // console.log(setIsLoading);

  // const pageListNum = 2;
  
  useEffect(() => {
    scrollTo(0, 0);
  },[]);
  
  useEffect(() => {
    getNftsHandler(nftList.length);
    // getUserNftsHandler(localStorage.getItem('userId'));
    
    // getFileTypeNftsHandler('image');
    // getFileTypeNftsHandler('video');
    // getMimetypeNftsHandler('application/pdf');

    // getMimetypeNftsHandler('text/plain');
    // getFileTypeNftsHandler('text');
  },[]);


  useEffect(() => {
    const lastPage = Math.ceil(nftList.length/pageListNum);

    if ((currentPage >= lastPage - 1) && isMoreItem) {
      getNftsHandler(nftList.length);
    }
 
  },[currentPage]);


  //// get termAssetList for price
  useEffect(() => {
    getTermAssetListHander();
  },[currentPage]);

  useEffect(() => {
    if (nftList.length > 0 && termAssetList) {
      const pageStart = (currentPage - 1) * pageListNum;
      const pageEnd = (currentPage) * pageListNum;
      
      const pageNftList = nftList.slice(pageStart, pageEnd);

      // createListWithSellOffer(pageNftList, termAssetList.assets);
      createSellOfferNftsHandler(pageNftList, termAssetList.assets);
    }

  },[currentPage, nftList, termAssetList]);


  const getNftsHandler = async (feNum) => {
    try {
      setIsLoading(true);
      const result = await nftDataUtils.getNfts(
        BACKEND_URL,
        localStorage.getItem('token'),
        feNum,
      );
      
      // setNftList(result.data);
      if (result.data) {
        const notDeletedList = result.data.list.filter(nft => !nft.fileDeleted);
        hDispatch('SET_NFTLIST', notDeletedList);
        
        // hDispatch('SET_NFTLIST', result.data.list);
        setIsMoreItem(result.data.isMoreItem);
        console.log('get list result', result.data);
      
        if (result.data.creatorUserList?.length > 0) {
          const addedList = userList.concat(result.data.creatorUserList);
          const uniqList = _.uniqBy(addedList, '_id');
          hDispatch(
            'SET_USERLIST',
            uniqList,
          );
        }
      }

      setIsLoading(false);
      // console.log(result.data[0].imageCid)
    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const getFileTypeNftsHandler = async (fileType) => {
    try {
      const result = await nftDataUtils.getFileTypeNfts(
        BACKEND_URL,
        localStorage.getItem('token'),
        fileType,
      );
      
      // setNftList(result.data);
      console.log(result.data);

      // console.log(result.data[0].imageCid)
    } catch(err) {
      console.log(err);
    }
  };

  const getMimetypeNftsHandler = async (mimetype) => {
    try {
      const result = await nftDataUtils.getMimetypeNfts(
        BACKEND_URL,
        localStorage.getItem('token'),
        mimetype,
      );
      
      // setNftList(result.data);
      console.log(result.data);

      // console.log(result.data[0].imageCid)
    } catch(err) {
      console.log(err);
    }
  };


  const createSellOfferNftsHandler = async (pageNftList, assetsInTermData) => {
    const withSellOfferList = await nftDataUtils.createListWithSellOffer(
      pageNftList, 
      assetsInTermData,
    );
    
    if (withSellOfferList && withSellOfferList.length > 0) {
      // console.log('withSellOfferList', withSellOfferList);
      hDispatch('SET_SELLOFFERNFTLIST', withSellOfferList);
      // setSellOfferNftList(withSellOfferList);
    }
    
  };


  const getTermAssetListHander = async () => {
    try {
      const resData = await nftDataUtils.getStellarTermAssetList();

      if (resData) {
        hDispatch('SET_TERMASSETLIST', resData);
      }
    } catch(err) {
      console.log(err);
    }
  };


  // const getNftImageUrlHandler = async (imageCid) => {
  //   try {
  //     const result = await nftDataUtils.getNftImageUrl(
  //       BACKEND_URL,
  //       localStorage.getItem('token'),
  //       imageCid
  //     );

  //     console.log(result.data);
  //     window.open(result.data , "_blank", 'noreferrer');
  //     // window.open(result.data);
  //     // window.open("http://www.mozilla.org/");
      
  //     // console.log(result.data[0].imageCid)
  //   } catch(err) {
  //     console.log(err);
  //   }
  // };

  let nftListBody;

  const pageStart = (currentPage - 1) * pageListNum;
  const pageEnd = (currentPage) * pageListNum;

  if (nftList.length > 0) {
    nftListBody = (
      <ul className={classes.NftListList}>
        {nftList.slice(pageStart, pageEnd).map(nft => {
          return (
            <div key={nft._id}
              className={classes.NftListListContent}
              // style={{    
              //   whiteSpace: 'nowrap',
              //   flexBasis: '50%',
              //   flexGrow: 1,
              // }}
            >
              <NftListItem 
                nft={nft}
                isLoading={isLoading}
              />
            </div>
          );
        })}
      </ul>
    );
  }

  return (
    <Fragment>
      {isLoading && <Loader />}
      {!isLoading && (
        <div>
          {nftListBody}
          
          <Paginator 
            currentPage={currentPage}
            // lastPage={Math.ceil(nftList.length/pageListNum)}
            lastPage={isMoreItem 
              ? Math.ceil(nftList.length/pageListNum)+1 
              : Math.ceil(nftList.length/pageListNum)
            }
            // lastPage={Math.ceil(nftList.length/pageListNum)+1}
            onPrevious={() => {
              setCurrentPage(currentPage - 1);
              }}
            onNext={() => {
              setCurrentPage(currentPage + 1);
              }}
          />
        </div>
      )}
    </Fragment>
  );
}

export default NftList;
