import React, { useState, useEffect, Fragment } from 'react';
import { Button } from "@stellar/design-system";
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import Loader from '../Loader/Loader.jsx';
import NftCommentInput from './NftCommentInput.jsx';
import NftCommentItem from './NftCommentItem.jsx';

import { useStore } from '../../../hook-store/store';

import { getNftComment, createNftComment, deleteNftComment } from '../../../utils/nft/nft-comment-util';

import { BACKEND_URL } from '../../../urlLinks';

import classes from './NftComment.module.css';


function NftComment(props) {
  // console.log('NftCommet.jsx-props', props);
  const { 
    nft, 
    creatorUId,
    getTotalCommentNumHandler,
  } = props;

  const [t] = useTranslation('translation');

  const [hStore, hDispatch] = useStore();
  const isAuth = hStore.uiStore.isAuth;
  const userList = hStore.uiStore.userList;
  const userData = hStore.uiStore.userData;

  const [nftCommentList, setNftCommentList] = useState([]);
  const [showMoreList, setShowMoreList] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  // const nftList = store.nftStore.nftList;
  
  const initialListNum = 3;

  useEffect(() => {
    if (nft) {
      getNftCommentHandler(
        BACKEND_URL, 
        localStorage.getItem('token'),
        nft.n_id,
      );
    }
  },[nft]);

  // useEffect(() => {
  //   if (nftCommentList.length > initialListNum) {
  //     setShowMoreList(false);
  //   }

  //   // if (nftCommentList.length <= initialListNum) {
  //   //   setShowMoreList(true);
  //   // }
  // },[nftCommentList]);


  const getNftCommentHandler = async (url, token, nid) => {
    try {
      setIsLoading(true);

      const result = await getNftComment(url, token, nid);

      if (result && result.data) {
        setNftCommentList(result.data.comments);
        
        if (result.data.comments.length > 0) {
          getTotalCommentNumHandler(result.data.comments.length);
        }

        // console.log('comments result', result);
        if (result.data.creatorUserList?.length > 0) {
          const addedList = userList.concat(result.data.creatorUserList);
          const uniqList = _.uniqBy(addedList, '_id');
          hDispatch(
            'SET_USERLIST',
            uniqList,
          );

          // console.log('comment addedlist', addedList, uniqList);
        }
      }
      
      setIsLoading(false);
    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  let nftCommentsBody = (
      <div>
        {t('comment.01', 'no comments')}
      </div>
    );

  if (nft && nftCommentList.length > 0) {

  //   let showList = nftCommentList.slice(0, initialListNum);

  //   if (showMoreList) {
  //     showList = nftCommentList;
  //   }

    nftCommentsBody = (
      <div>
        <ul>
          {nftCommentList.map(comment => {
          
            let canDelete = false;

            // console.log('comment', comment, userData);
            if (userData && userData._id === comment.uId) {
              canDelete = true;
            }

            // if (creatorUId && creatorUId === comment.uId) {
            //   canDelete = true;
            // }

            // canDelete = true;


            return (
              <div key={comment._id}>
                <NftCommentItem 
                  nft={nft}
                  comment={comment} 
                  canDelete={canDelete}
                  nftCommentList={nftCommentList}
                  setNftCommentList={setNftCommentList}
                  // deleteNftCommentHandler={deleteNftCommentHandler}
                  isLoading={isLoading}
                />
              </div>
            );
          })}
        </ul>
      </div>

    );
  }

  return (
    <Fragment>
      <div>
        <div className={classes.nftComment}>
          <div>
            {t('comment.02', 'comments')}
          </div>

          <NftCommentInput 
            nft={nft}
            nftCommentList={nftCommentList}
            setNftCommentList={setNftCommentList}
          />

          {isLoading && <Loader />}

          {nftCommentsBody}

          {/* <div className={classes.nftCommentMoreButton}>
            {!showMoreList && nftCommentList.length > initialListNum && (
              <Button 
                variant="tertiary"
                size="small"
                onClick={() => {
                  setShowMoreList(true);
                }}
              >
                show-all-comments
              </Button>
            )}
            {showMoreList && nftCommentList.length > initialListNum && (
              <Button 
                variant="tertiary"
                size="small"
                onClick={() => {
                  setShowMoreList(false);
                }}
              >
                show-less-comments
              </Button>
            )}
          </div> */}

        </div>
      </div>
    </Fragment>
  );
}

export default NftComment;
