/* eslint-disable max-len */
// const React = window.React = require('react');
import React from 'react';

import { useTranslation } from 'react-i18next';
// const [t] = useTranslation('translation');
// {t('termsOfUse.text1', 'terms of use')}

import './TermsOfUse.css';

const TermsOfUse = props =>  {

  const [t] = useTranslation('translation');

    return <div>
      {/* <Generic> */}
        <h4 style={{fontSize:""}} className="Session__welcomeTitle">
          {/* */}
          {t('termsOfUse.text1', 'Terms of use ')}
          </h4>
        <div style={{fontSize:""}}>

          <h4 style={{fontSize:""}}>
            {/*  */}
            {t('termsOfUse.text2', 'Privacy')}
            </h4>
            {/*   */}
            {t('termsOfUse.text3', 'Your privacy is important to us. Please read the')}
            <a href="/privacypolicy" className="terms__textLink">
              {/* <span>  </span> */}
              <span> {t('termsOfUse.text4', 'privacy policy')} </span>
            </a> 
            {/*  */}
            {t('termsOfUse.text5', 'for more information.')}
          <br />
          <h4 style={{fontSize:""}}>
            {/*  */}
            {t('termsOfUse.text6', 'Your own responsibilities')}
            </h4>
            {/*  */}
            {t('termsOfUse.text7', 'You, the user, are solely responsible for ensuring your own compliance with laws and taxes in your jurisdiction. You are solely responsible for your own security.')}
          {/* <br /> */}
          <br />
          <h4 style={{fontSize:""}}>
            {/*  */}
            {t('termsOfUse.text8', 'Disclaimer of warranty')}
            </h4>

            {/*  */}
            {t('termsOfUse.text9', 'This site is provided on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND.')}
          <br />
          <br />
          {props.accept ? <div>
            By pressing "
            {t('termsOfUse.text21')}
            <strong>
              Accept and Continue
              {t('termsOfUse.text22')}
              </strong>
            ", you acknowledge that you have read this document and agree to these terms of use.
            {t('termsOfUse.text23')}
            <div className="Session__tos__next">
              <button className="s-button" onClick={props.accept}>
                Accept and Continue
                {t('termsOfUse.text24')}
                </button>
            </div>
          </div> : null}
        </div>
      {/* </Generic> */}
    </div>;
  
};
export default TermsOfUse;
