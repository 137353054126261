import React, { useEffect } from "react";
import _ from "lodash";

import { useStore } from "../../../hook-store/store";

const UpdateLsUserList = () => {
  const [hStore, hDispatch] = useStore();
  const { userList } = hStore.uiStore;

  useEffect(() => {
    if (userList?.length > 0) {
      updateLsUserList(userList, null);
    }
  },[userList]);

  const updateLsUserList = (addList, userData) => {
    let lsUserList = localStorage.getItem('lsUserList');
  
    if (lsUserList) {
      lsUserList = JSON.parse(lsUserList);
    } else {
      lsUserList = [];
    }
  
    let useAddList = addList;
  
    if (userData) {
      useAddList = addList.concat(userData);
    }
    
    const addedList = useAddList.concat(lsUserList);
    const uniqList = _.uniqBy(addedList, '_id');
    const storeList = uniqList.slice(0, 100000);
    // console.log('userList', addedList, uniqList, storeList, lsUserList);
  
    localStorage.setItem('lsUserList', JSON.stringify(storeList));
  };
  return (
    <>
    </>
  );
};

export default UpdateLsUserList;