import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from "react-redux";
import * as StellarSdk from '@stellar/stellar-sdk';
import {
  Button,
  InfoBlock,
  Input,
  TextLink,
  Modal,
  Icon,
  Identicon,
} from "@stellar/design-system";
import { useTranslation } from 'react-i18next';

import { useRedux } from "hooks/useRedux";
import { ActionStatus, AuthType } from "types/types";
import { logEvent } from 'helpers/tracking';
import { fetchAccountAction } from 'ducks/account';
import { sendTxAction, resetSendTxAction } from 'ducks/sendTx';
import { getNetworkConfig } from 'helpers/getNetworkConfig';

import CreateNftSellOfferInputs from './CreateNftSellOfferInputs.jsx';
import TxFlow from 'components/IssuerFrontend/TxFlow/TxFlow.jsx';

import { 
  createSellOfferXdr, 
  createCancelSellOfferXdr,
  getAccountInfo,
  getAssetOffers,
  getAccountOffers,
  checkAssetPosition,
  makeSellOfferXdr,
} from '../../../../utils/nft/offer-util';

import { postOfferPush } from '../../../../utils/pushNotification/push-notify';

// import * as uploadUtils from '../utils/bucket-upload-util';
import { useStore } from '../../../../hook-store/store';

import { BACKEND_URL } from '../../../../urlLinks';

import classes from './CreateNftSellOffer.module.css';


function CreateNftSellOffer(props) {
  const { 
    nft, 
    offer,
    assetSellOffers,
    accountData,
    hasAsset,
    hasAmount,
    hasSellOffer,
    // hasBuyOffer,
    getAssetSellOffersHandler={getAssetSellOffersHandler},
   } = props;
  // console.log('CreateNftSellOffer.jsx-props', props);

  const [t] = useTranslation('translation');

  const dispatch = useDispatch();

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    "memoRequiredAccounts",
    "settings",
    "sendTx",
  );
  const { status, errorString } = sendTx;

  const publicKey = account.data ? account.data.id : '';


  const [hStore, hDispatch] = useStore();
  const txXdr = hStore.nftStore.txXdr;
  const feeStats = hStore.nftStore.feeStats;


  const [priceInput, setPriceInput] = useState('');

  const [buyAssetInput, setBuyAssetInput] = useState('');
  const [sellAmountInput, setSellAmountInput] = useState('');

  const [inputXlmAmount, setInputXlmAmount] = useState('');
  const [accountOffers, setAccountOffers] = useState([]);
  // const [assetSellOffers, setAssetSellOffers] = useState([]);
  const [showCreateOffer, setShowCreateOffer] = useState(false);

  const [stateTxXdr, setStateTxXdr] = useState('');
  const [showSellModal, setShowSellModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  // const [accountData, setAccountData] = useState();
  // const [availableXLM, setAvailableXLM] = useState(0);


  // useEffect(() => {
  //   getAssetSellOffersHandler();
  // },[]);

  // useEffect(() => {
  //   const getAccountDataHandler = async () => {
  //     try {
  //       const accountInfo = await getAccountInfo(account.data.id);
  //       console.log('accountInfo', accountInfo);
  //       if (accountInfo) {
  //         setAccountData(accountInfo);
  //       }
  //     } catch(err) {
  //       console.log(err);
  //     }
  //   };

  //   if (account.data) {
  //     getAccountDataHandler();
  //   }
  // },[account.data]);

  useEffect(() => {
    if (publicKey) {
      dispatch(fetchAccountAction(publicKey));
    }
  },[publicKey]);

  useEffect(() => {
    console.log('status', status, sendTx);
    if (txXdr && stateTxXdr && txXdr === stateTxXdr) {
      if ((sendTx.data && 
          // sendTx.data.source_account_sequence === txSequence && 
          status === ActionStatus.SUCCESS) || 
          status === ActionStatus.ERROR
      ) {

        if (sendTx.data && sendTx.data.envelope_xdr) {

          console.log('status resultXDR', JSON.stringify(StellarSdk.xdr.TransactionResult.fromXDR(sendTx.data.result_xdr, 'base64')) );

          const txData = new StellarSdk.Transaction(
            sendTx.data.envelope_xdr,
            getNetworkConfig(settings.isTestnet).network,
          );
  
          sellOfferResultHandler(
            { code: nft.code, issuer: nft.issuer },
            txData._operations[0],
            // sendTx.data.offerResults[0].currentOffer,
          );

        } else {
          sellOfferResultHandler(
            { code: nft.code, issuer: nft.issuer },
            null,
            // sendTx.data.offerResults[0].currentOffer,
          );
        }
        
      }
    }
  },[status]);

  useEffect(() => {
    if (!status) {
      setShowSellModal(false);
      // closeBuyModalHandler();
    }
  },[status]);


  const setSelectOptionHandler = (select) => {
    console.log(select.value);
    // setSelectedOption(select.value);
    setBuyAssetInput(select.value);
  };


  // const priceInputChangeHandler = (event) => {
  //   console.log(event.target.value)
  //   const roundNum = Math.floor(Number(event.target.value));
  //   setPriceInput(roundNum.toString());

  //   // setInputXlmAmount()
  //   console.log( (Number(event.target.value)*0.0000001).toFixed(7) );
  //   // console.log(event.target.value);
  // };


  const inputXlmAmountChangeHandler = (event) => {
    console.log(event.target.value);
    console.log( (Number(event.target.value)*10000000).toFixed(7) );

    const roundNum = Math.floor(Number(event.target.value));
    setPriceInput( (Number(event.target.value)*10000000).toFixed(7) );

    // setInputXlmAmount()
    // console.log( (Number(event.target.value)*0.0000001).toFixed(7) );
    // console.log(event.target.value);
  };

  const sellAmountInputChangeHandler = (event) => {
    setSellAmountInput(event.target.value);
  };

  // const buyAssetInputChangeHandler = (event) => {
  //   // console.log(event.target.value);
  //   // console.log(buyInputAssetList[event.target.value]);
  //   setBuyAssetInput(buyInputAssetList[event.target.value]);
  // }

  const showCreateOfferHandler = (value) => {
    setShowCreateOffer(value);
    setPriceInput('');
    setInputXlmAmount('');
  };


  // const getAssetSellOffersHandler = async () => {
  //   try {
  //     const assetOffers = await getAssetOffers(
  //       'sell', 
  //       'credit_alphanum12', 
  //       nft.code, 
  //       nft.issuer,
  //     );

  //     console.log('assetOffers', assetOffers);
  //     // console.log(accountOffers._embedded.records);

  //     if (assetOffers) {
  //       setAssetSellOffers(assetOffers._embedded.records);
  //     }

  //   } catch(err) {
  //     console.log(err);
  //   }
  // };


  const createNftSellOfferHandler = async (
    url, 
    token, 
    accountId,
    sellAsset,
    buyAsset,
    price,
    amount,
    fee,
  ) => {
    try {
      setIsLoading(true);

      const accountInfo = await getAccountInfo(accountId);

      console.log(accountInfo);

      if (accountInfo && accountInfo.balances.length > 0) {
        // eslint-disable-next-line max-len
        const hasSellAsset = accountInfo.balances.find(balance => (balance.asset_code === sellAsset.code && 
            balance.asset_issuer === sellAsset.issuer &&
            Number(balance.balance) > 0
          ));

        console.log(hasSellAsset);

        if (!hasSellAsset) {
          throw new Error('not-have-sell-asset');
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-shadow
      const sellOffers = await getAssetOffers(
        'sell', 
        'credit_alphanum12', 
        sellAsset.code, 
        sellAsset.issuer,
      );

      console.log(sellOffers, sellOffers._embedded.records);
      
      // if (sellOffers && sellOffers._embedded.records.length > 0) {
      //   throw new Error('sell-asset-is-already-in-sell-offer');
      // }


      // const resData = await createSellOfferXdr(
      //   url, 
      //   token, 
      //   accountId,
      //   sellAsset,
      //   buyAsset,
      //   price,
      //   amount,
      // );

      console.log('sellAsset', sellAsset);

      const resData = await makeSellOfferXdr(
        accountId,
        new StellarSdk.Asset(sellAsset.code, sellAsset.issuer),
        new StellarSdk.Asset(buyAsset.code, buyAsset.issuer),
        price,amount,
        fee,
        settings,
      );

      console.log('resData', resData);


      if (resData) {
        // console.log(resData);

        const xdrTx = resData.data;

        const transaction = new StellarSdk.Transaction(
            xdrTx,
            getNetworkConfig(settings.isTestnet).network,
        );
        console.log('transaction', transaction);
        
        // setTxSequence(transaction._sequence);
        setStateTxXdr(xdrTx);
        hDispatch('SET_TXXDR', xdrTx);

        dispatch(sendTxAction(transaction));
        logEvent("createBuyNftOffer: transactions", {
            txOperations: transaction._operations,
            // amount: formData.amount.toString(),
            //   "used federation address": !!formData.federationAddress,
            //   "used memo": !!formData.memoContent,
        });

      }

      // await getAssetSellOffersHandler();
      // showCreateOfferHandler(false);

      // await setUpdateAccountInfo(accountId);

      // setIsLoading(false);
    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  // const createCancelNftSellOfferHandler = async (
  //   url, 
  //   token, 
  //   accountId,
  //   sellAsset,
  //   buyAsset,
  //   offerId,
  // ) => {
  //   try {
  //     setIsLoading(true);

  //     const accountInfo = await getAccountInfo(accountId);

  //     console.log(accountInfo);

  //     if (accountInfo && accountInfo.balances.length > 0) {
  //       // eslint-disable-next-line max-len
  //       const hasSellAsset = accountInfo.balances.find(balance => (balance.asset_code === sellAsset.code && 
  //           balance.asset_issuer === sellAsset.issuer &&
  //           Number(balance.balance) > 0
  //         ));

  //       console.log(hasSellAsset);

  //       if (!hasSellAsset) {
  //         throw new Error('not-have-sell-asset');
  //       }
  //     }

  //     // eslint-disable-next-line @typescript-eslint/no-shadow
  //     const sellOffers = await getAssetOffers(
  //       'sell', 
  //       'credit_alphanum12', 
  //       sellAsset.code, 
  //       sellAsset.issuer,
  //     );

  //     console.log(sellOffers, sellOffers._embedded.records);
      
  //     if (sellOffers && sellOffers._embedded.records.length === 0) {
  //       throw new Error('sell-offer-not-exist');
  //     }

  //     if (sellOffers && sellOffers._embedded.records.length > 0) {
  //       // eslint-disable-next-line max-len
  //       const idOfferExist = sellOffers._embedded.records.find(offer => offer.id === offerId);

  //       if (!idOfferExist) {
  //         throw new Error('offerId-sell-offer-not-exist');
  //       }
  //     }


  //     const resData = await createCancelSellOfferXdr(
  //       url, 
  //       token, 
  //       accountId,
  //       sellAsset,
  //       buyAsset,
  //       offerId,
  //     );

  //     if (resData) {
  //       // console.log(resData);

  //       // const albedoResult = await albedo.tx({
  //       //   xdr: resData.data,
  //       //   // description: 'this is description content',
  //       //   pubkey: accountId,
  //       //   submit: true,
  //       // });

  //       // console.log(albedoResult);
  //     }

  //     await getAssetSellOffersHandler();
  //     showCreateOfferHandler(false);
  //     // await setUpdateAccountInfo(accountId);

  //     setIsLoading(false);
  //   } catch(err) {
  //     console.log(err);
  //     setIsLoading(false);
  //   }
  // };


  const sellOfferResultHandler = async (buyAsset, offerData) => {
    if (status === ActionStatus.SUCCESS) {
      // console.log('status sellOfferResutHandler', status);

      // setBuyAmountInput('');
      // setBuyPriceInput('');
      getAssetSellOffersHandler();
      showCreateOfferHandler(false);
  
      dispatch(resetSendTxAction());
      setShowSellModal(false);
      // dispatch(fetchAccountAction(publicKey));

      hDispatch('SHOW_NOTIFICATION', {
        status: '',
        title: '',
        message: `${t('singleNFT.05','trading sell offers')} ${t('general.text30','created')}`,
      });
  
      setTimeout(() => {
        hDispatch('CLEAR_NOTIFICATION');
      }, 5000);

      setIsLoading(false);


      // postOfferPush(
      //   BACKEND_URL, 
      //   localStorage.getItem('token'), 
      //   nft.n_id,
      //   offerData,
      //   null,
      // );
    }

    if (status === ActionStatus.ERROR) {
      console.log('errorString', errorString);
      setIsLoading(false);

      // if (!err.error.code) {
      //   hDispatch('SHOW_NOTIFICATION', {
      //     status: '',
      //     title: '',
      //     message: `Error: buy offre failed,
      //       ${sendTx.errorString}`,
      // });
    }
  };


  let createNftSellOfferBody;

  if (hasAsset && nft && account.data && accountData) {

    if (!hasSellOffer && !showCreateOffer) {
      createNftSellOfferBody = (
        <div>
          <Button 
            size="small"
            onClick={() => { 
              showCreateOfferHandler(true); 
              setShowSellModal(true);
            }}
          >
            {t('singleNFT.09','create trading sell offer')}
          </Button>
        </div>
      );
    }

    if (showCreateOffer) {
      createNftSellOfferBody = (
        <div>
          <CreateNftSellOfferInputs 
            nft={nft}
            buyAssetInput={buyAssetInput}
            inputXlmAmountChangeHandler={inputXlmAmountChangeHandler}
            sellAmountInput={sellAmountInput}
            sellAmountInputChangeHandler={sellAmountInputChangeHandler}
            showCreateOfferHandler={showCreateOfferHandler}
            setSelectOptionHandler={setSelectOptionHandler}
            createNftSellOfferHandler={createNftSellOfferHandler}
            priceInput={priceInput}
            // enoughXLM={enoughXLM}
            isLoading={isLoading}
          />
        </div>
      );
    }

    if (!showSellModal) {
      createNftSellOfferBody = (
        <div>
          <Button 
            size="small"
            onClick={() => { 
              showCreateOfferHandler(true); 
              setShowSellModal(true);
            }}
          >
            {t('singleNFT.09','create trading sell offer')}
          </Button>
        </div>
      );
    }

  }


  return (
    <Fragment>
      {/* <img src={xlmLogo} /> */}

      <div 
        // style={{paddingTop:"1.5rem", paddingBottom:"1.5rem"}}
      >
        {hasAmount && (
          <div>
            {/* <div>create-nft-sell-offer-component</div> */}
            {/* <div>hasAmount: {(Number(hasAmount)*10000000).toFixed()}</div> */}
          </div>
        )}

        {!showSellModal && createNftSellOfferBody}

        <Modal
          visible={showSellModal}
          // onClose={resetModalStates}
          onClose={() => {
            setShowSellModal(false);
            dispatch(resetSendTxAction());
          }}
        >
          {showSellModal && (
            <TxFlow
              onCancel={() => {
                setShowSellModal(false);
                dispatch(resetSendTxAction());
              }}
              createTxBody={createNftSellOfferBody}
              isLoading={isLoading}
            />
          )}
          {/* {isReceiveTxModalVisible && <ReceiveTransaction />} */}
        </Modal>
      </div>
    </Fragment>
  );
}

export default CreateNftSellOffer;
