import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "@stellar/design-system";
import Img from "react-cool-img";
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import Loader from "../../Loader/Loader.jsx";

import { useStore } from "../../../../hook-store/store";

import { getUserFollowers } from '../../../../utils/follow/user-follower-util';

import { marks } from "../../../../images/icons/marks";

import { BACKEND_URL } from "../../../../urlLinks";

import classes from './FollowingUsers.module.css';


const FollowingUsers = (props) => {
  // const { 
  // } = props;

  const [t] = useTranslation('translation');

  const [hStore, hDispatch] = useStore();

  const { 
    userList,
    userData,
   } = hStore.uiStore;
  // const userData = hStore.uiStore.userData;
  // const isAdmin = store.uiStore.isAdmin;

  const [showFollowList, setShowFollowList] = useState(false);
  const [getListFinish, setGetListFinish] = useState(false);
  const [followingUIdList, setFollowingUIdList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  // const [userData, setUserData] = useState();

  useEffect(() => {
    if (showFollowList && !getListFinish) {
      getUserFollowersHandler(
        BACKEND_URL, 
        localStorage.getItem('token'),
      );
    }
    // getUserDataHandler(BACKEND_URL, localStorage.getItem('token'));
  }, [showFollowList]);


  const getUserFollowersHandler = async (url, token) => {
    try {
      setIsLoading(true);
      // setError('');
  
      const resData = await getUserFollowers(url, token);

      console.log('resData', resData);

      if (resData.data) {
        const followUIdList = [];

        if (resData.data.length > 0) {

          resData.data.forEach(ele => {
            followUIdList.push(ele.followUId);
          });
  
          setFollowingUIdList(followUIdList);
        }

        setGetListFinish(true);
      }

      if (resData.followUserList?.length > 0) {
        const addedList = userList.concat(resData.followUserList);
        const uniqList = _.uniqBy(addedList, '_id');
        hDispatch(
          'SET_USERLIST',
          uniqList,
        );
      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
    
  };


  let followingUsersBody = <div>no user</div>;

  if (followingUIdList.length > 0) {

    followingUsersBody = (
      <ul>
        {followingUIdList.map((uId) => {
          // console.log('nft', nft);

          const followUser = userList.find(user => user._id === uId);

          if (followUser?._id !== userData?._id) {
            return (
              <div key={uId}>
                <span className={classes.followingUserName}>
                  <Link 
                    to={`/creator-nftlist?_id=${uId}`}
                  >
                    <Img className={classes.followingUserImage}
                      src={followUser?.imageUrl} 
                    />
                  </Link>
                  <Link 
                    to={`/creator-nftlist?_id=${uId}`}
                  >
                    {followUser?.name} 
                  </Link>
                </span>
              </div>
            );
          }
          
        })}
      </ul>
    );
  }
 

  return (
    <Fragment>
      <div >
        <span
          className={classes.followingUsersTitle}
          onClick={() => { 
            setShowFollowList(!showFollowList); 
          }}
        >
          {t('userInfo.text27', 'your following Users')} {marks.triangleDown}
        </span>
      </div>

      {isLoading && <Loader />}

      {!isLoading && showFollowList && (
        <div>
          {followingUsersBody}
        </div>
      )}

    </Fragment>
  );
};

export default FollowingUsers;
