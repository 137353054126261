import ReactDOM from "react-dom";
import { App } from "./App";

// Import global CSS from Stellar Design System
import "@stellar/design-system/build/styles.min.css";

import './i18n';

import configureUiStore from './hook-store/ui-store';
import configureNftStore from './hook-store/nft-store';
import configureNftSearchStore from './hook-store/nft-search-store';

configureUiStore();
configureNftStore();
configureNftSearchStore();

ReactDOM.render(<App />, document.getElementById("root"));


// Check that service workers are supported
if ('serviceWorker' in navigator) {
	// eslint-disable-next-line no-console
	// console.log('serviceWorker is there');

	// Use the window load event to keep the page load performant
	window.addEventListener('load', () => {
		navigator.serviceWorker.register('/service-worker.js')
			.then((res) => {
				// eslint-disable-next-line no-console
				console.log(res);
			})
			.catch((err) => {
				// eslint-disable-next-line no-console
				console.log(err);
			});
	});
}