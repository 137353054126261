/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable require-await */
/* eslint-disable prefer-arrow-callback */

import _ from 'lodash';
import axios from 'axios';
import { DataProvider, Types } from "@stellar/wallet-sdk";
import { getNetworkConfig } from "helpers/getNetworkConfig";

import { getAssetOffers } from './offer-util';

export const getNfts = async (url, token, feNum, perPage, getTimes) => {
  try {

    const result = await fetch(`${url  }/nft-data/nfts?feNum=${feNum}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } 
      throw new Error('something wrong');
    

  } catch(err) {
    console.log(err);
    throw err;
  }
};


export const getFileTypeNfts = async (url, token, fileType) => {
  try {

    const result = await fetch(`${url  }/nft-data/file-type-nfts?fileType=${fileType}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } 
      throw new Error('something wrong');
    

  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const getMimetypeNfts = async (url, token, mimetype) => {
  try {

    const result = await fetch(`${url  }/nft-data/mimetype-nfts?mimetype=${mimetype}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } 
      throw new Error('something wrong');
    

  } catch(err) {
    console.log(err);
    throw err;
  }
};


export const getNftImageUrl = async (url, token, imageCid) => {
  try {

    const result = await fetch(`${url  }/nft-data/nft-image-url?imageCid=${imageCid}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } 
      throw new Error('something wrong');
    

  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const getNft = async (url, token, nId) => {
  try {

    const result = await fetch(`${url  }/nft-data/nft?nId=${nId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } 

    if (result.status === 404) {
      throw new Error('nft-not-found');
    }
    
    throw new Error('something wrong');
    

  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const getUserNfts = async (url, token, _id) => {
  try {

    const result = await fetch(`${url  }/nft-data/user-nfts?_id=${_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } 
      throw new Error('something wrong');
    

  } catch(err) {
    console.log(err);
    throw err;
  }
};


export const getNftHolders = async (url, code, issuer) => {
  try{
      const result = await fetch(`${url  }?code=${code}&issuer=${issuer}`, {
      // const result = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: 'Bearer ' + '',
        // "Access-Control-Allow-Origin": "*",
        // "mode":"no-cors",
      },
    });

    const resData = await result.json();
    console.log(result, resData);


    // const isTestnet = false;
    // // eslint-disable-next-line no-useless-concat
    // // const expertUrl = 'https://api.stellar.expert' + `/explorer/${isTestnet ? 'testnet' : 'public'}/asset/${code}-${issuer}/holders`;
    // const expertUrl = `https://api.stellar.expert/explorer/${isTestnet ? 'testnet' : 'public'}/asset/yXLM-GARDNV3Q7YGT4AKSDF25LT32YSCCW4EV22Y2TV3I2PU2MMXJTEDL5T55/holders`;
    // const result2 = await axios.get(expertUrl);
    // console.log('axholders', result2);

    if (result.ok) {
      return resData;
    } 
      throw new Error('something wrong');


  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const fetchAccountDetails = async (settings, publicKey) => {
  try {
    const isTestnet = settings.isTestnet;
  
    const dataProvider = new DataProvider({
      serverUrl: getNetworkConfig(isTestnet).url,
      accountOrKey: publicKey,
      networkPassphrase: getNetworkConfig(isTestnet).network,
    });
  
    const stellarAccount = await dataProvider.fetchAccountDetails();
    return stellarAccount;

  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const checkIsCreator = async (url, token, nId) => {
  try{
      const result = await fetch(`${url  }/nft-data/check-is-creator?nId=${nId}`, {
      // const result = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        // "Access-Control-Allow-Origin": "*",
        // "mode":"no-cors",
      },
    });

    const resData = await result.json();
    console.log(result, resData);

    if (!result.ok) {
      throw new Error('something wrong');
    } 

    return resData;

  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const getNftByAssetInfo = async (url, token, code, issuer) => {
  try {

    const result = await fetch(`${url  }/nft-data/nft-by-asset-info?code=${code}&issuer=${issuer}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } 
      throw new Error('something wrong');
    

  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const createShortDisplayId = (id) => {
  const displayId = `${id.slice(0,5)}....${id.slice(-5)}`;
  return displayId;
};

export const getStellarTermAssetList = async () => {
  try {
    const result = await fetch('https://api.stellarterm.com/v1/ticker.json');
  
    const resData = await result.json();
  
    console.log(result, resData, 'testget');
    
    if (!result.ok) {
      throw new Error('error occured'); 
    }

    return resData;

  } catch(err) {
    console.log(err);
    throw err;
  }
};


export const addUSDPriceInOffers = (txOffers, assetsInTermData, isBuyOffer) => {


    const priceAddOffers = txOffers.map(offer => {
      let assetInfo;

      // // sell offer case
      if (!isBuyOffer) {
        if (offer.buying.asset_type === 'native') {
          assetInfo = assetsInTermData.find(tasset => {
            return tasset.code === 'XLM' && !tasset.issuer;
          });
  
          return {
            ...offer,
            price_USD: assetInfo.price_USD,
            offerPriceUSD: assetInfo.price_USD * (Number(offer.price)/10**7),
            totalPriceUSD: assetInfo.price_USD * Number(offer.amount)*10**7 * 
              (Number(offer.price)/10**7),
          };
        } 
  
        assetInfo = assetsInTermData.find(tasset => {
          return tasset.code === offer.buying.asset_code && 
          tasset.issuer === offer.buying.asset_issuer;
        });
  
        return  {
          ...offer,
          price_USD: assetInfo.price_USD,
          offerPriceUSD: assetInfo.price_USD * (Number(offer.price)/10**7),
          totalPriceUSD: assetInfo.price_USD * (Number(offer.amount)*10**7) * 
            (Number(offer.price)/10**7),
        };

      }


      // // buy offer case
      if (offer.selling.asset_type === 'native') {
        assetInfo = assetsInTermData.find(tasset => {
          return tasset.code === 'XLM' && !tasset.issuer;
        });

        return {
          ...offer,
          price_USD: assetInfo.price_USD,
          offerPriceUSD: assetInfo.price_USD * (Number(offer.amount)),
          totalPriceUSD: assetInfo.price_USD * 
          Number(offer.amount)*Number(offer.price)*10**7 * 
          (Number(offer.amount)),
        };
      } 

      assetInfo = assetsInTermData.find(tasset => {
        return tasset.code === offer.selling.asset_code && 
        tasset.issuer === offer.selling.asset_issuer;
      });

      return {
        ...offer,
        price_USD: assetInfo.price_USD,
        offerPriceUSD: assetInfo.price_USD * (Number(offer.amount)),
        totalPriceUSD: assetInfo.price_USD * 
        Number(offer.amount)*Number(offer.price)*10**7 * 
        (Number(offer.amount)),
      };

      
    });

    return priceAddOffers;

};


export const getLowestSellOffer = (assetSellOffers, assetsInTermData) => {
  
  const withUSDPriceSellOffers = addUSDPriceInOffers(
    assetSellOffers, 
    assetsInTermData,
  );

  
  const lowestSellOffer = _.minBy(
    withUSDPriceSellOffers, function(o) { 
      if (o.offerPriceUSD) {
        return Number(o.offerPriceUSD); 
      } 
      
      return Number(o.price);
      
    });

  console.log(lowestSellOffer, withUSDPriceSellOffers);
  
  const lowestSellPrice = (Number(lowestSellOffer.price)/10**7);
  
  const isNativeAsset = lowestSellOffer.buying.asset_type === 'native';

  return {
    lowestSellOffer,
    lowestSellPrice,
    isNativeAsset,
  };
  
};


// eslint-disable-next-line max-len
export const createListWithSellOffer = async (
  pageNftList, 
  assetsInTermData,
// eslint-disable-next-line @typescript-eslint/require-await
) => {
  
  const withSellOffersList = [];

  try {

    console.log('pageItems', pageNftList);

    // eslint-disable-next-line no-restricted-syntax
    for (const nft of pageNftList) {
      const assetSellOffers = await getAssetOffers(
        'sell', 
        'credit_alphanum12', 
        nft.code, 
        nft.issuer,
      );

      if (assetSellOffers && assetSellOffers._embedded.records.length > 0) {

        const lowestSellOfferData = getLowestSellOffer(
          assetSellOffers._embedded.records,
          assetsInTermData,
        );

        // console.log('lowestSellOffer', lowestSellOfferData);
        withSellOffersList.push({
          n_id: nft.n_id,
          nftData: nft,
          assetSellOffers,
          lowestSellOfferData,
        });

    }
  }

    // console.log('withSellOfferList', withSellOffersList);
    return withSellOffersList;
    
  } catch(err) {
    console.log(err);
  }

};