import * as StellarSdk from '@stellar/stellar-sdk';
import { Horizon } from '@stellar/stellar-sdk';
import { AuthType } from "types/types";
import { lumensFromStroops } from 'helpers/stroopConversion';
import { getNetworkConfig } from 'helpers/getNetworkConfig';
import { HORIZON_URL, FEE_ACCOUNTID } from "../../urlLinks";
import { txConfig } from '../../components/IssuerFrontend/transactionConfig';


export const createBuyOfferXdr = async (
  url, 
  token, 
  accountId,
  sellAsset,
  buyAsset,
  price,
  buyAmount,
  feeInput,
) => {
  try {
    const result = await fetch(`${url  }/token-tx/create-buy-offer-xdr`, {
    // const result = await fetch(url + '/token-tx/create-sell-offer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
      body: JSON.stringify({
        accountId,
        sellAsset,
        buyAsset,
        buyAmount,
        price,
        feeInput,
      }),
    });

    const resData = await result.json();
    
    console.log(result, resData);

    // const albedoResult = await albedo.tx({
    //   xdr: resData.data,
    //   // description: 'this is description content',
    //   pubkey: accountId,
    //   submit: true,
    // });

    // console.log(albedoResult);
    
    if (!result.ok) {
      throw new Error('error-occured');
    }

    return resData;

  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const createSellOfferXdr = async (
  url, 
  token, 
  accountId,
  sellAsset,
  buyAsset,
  price,
  amount,
) => {
  try {
    const result = await fetch(`${url  }/token-tx/create-sell-offer-xdr`, {
    // const result = await fetch(url + '/token-tx/create-sell-offer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
      body: JSON.stringify({
        accountId,
        sellAsset,
        buyAsset,
        amount,
        price,
      }),
    });

    const resData = await result.json();
    
    console.log(result, resData);

    // const albedoResult = await albedo.tx({
    //   xdr: resData.data,
    //   // description: 'this is description content',
    //   pubkey: accountId,
    //   submit: true,
    // });

    // console.log(albedoResult);
    
    if (!result.ok) {
      throw new Error('error-occured');
    }

    return resData;

  } catch(err) {
    console.log(err);
    throw err;
  }
};


export const createCancelSellOfferXdr = async (
  url, 
  token, 
  accountId,
  sellAsset,
  buyAsset,
  offerId,
) => {
  try {

    const result = await fetch(`${url  }/token-tx/create-cancel-sell-offer-xdr`, {
    // const result = await fetch(url + '/token-tx/create-sell-offer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
      body: JSON.stringify({
        accountId,
        sellAsset,
        buyAsset,
        offerId,
      }),
    });

    const resData = await result.json();
    
    console.log(result, resData);

    // const albedoResult = await albedo.tx({
    //   xdr: resData.data,
    //   // description: 'this is description content',
    //   pubkey: accountId,
    //   submit: true,
    // });

    // console.log(albedoResult);
    

    if (!result.ok) {
      throw new Error('error-occured');
    }

    return resData;

  } catch(err) {
    console.log(err);
    throw err;
  }
};


export const createTrustlineXdr = async (
  url, 
  token, 
  accountId,
  code,
  issuer,
) => {
  try {

    const result = await fetch(`${url  }/token-tx/create-trustline-xdr`, {
    // const result = await fetch(url + '/token-tx/create-sell-offer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
      body: JSON.stringify({
        accountId,
        code,
        issuer,
      }),
    });

    const resData = await result.json();
    
    console.log(result, resData);

    if (!result.ok) {
      throw new Error('error-occured');
    }

    return resData;

  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const checkTrustLine = (balances, code, issuer) => {
  // eslint-disable-next-line max-len
  const hasTrust = balances.find(asset => asset.asset_code === code && asset.asset_issuer === issuer);

  if (hasTrust) {
    return true;
  }

  return false;
};

export const getAccountInfo = async (pubkey) => {
  try {
    const result = await fetch(`${HORIZON_URL  }/accounts/${pubkey}`);

    const resData = await result.json();

    console.log(result, resData);

    if (!result.ok) {
      throw new Error('error-occured');
    }

    // setAlbedoAccountInfo(resData);

    return resData;

  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const getAssetOffers = async (type, assetType, code, issuer, limit) => {
  try {
    // console.log(pubkey);
    let result;

    if (type === 'sell') {
      result = await fetch(`${HORIZON_URL  }/offers?selling_asset_type=${assetType}&selling_asset_issuer=${issuer}&selling_asset_code=${code}&limit=${limit || 200}`);
    }

    if (type === 'buy') {
      result = await fetch(`${HORIZON_URL  }/offers?buying_asset_type=${assetType}&buying_asset_issuer=${issuer}&buying_asset_code=${code}&limit=${limit || 200}`);
    }

    const resData = await result.json();

    console.log(result, resData);

    if (!result.ok) {
      throw new Error('error-occured');
    }

    // setAlbedoAccountInfo(resData);

    return resData;

  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const getAccountOffers = async (pubkey) => {
  try {
    console.log(pubkey);
    const result = await fetch(`${HORIZON_URL  }/accounts/${pubkey}/offers?order=desc&limit=200`);
    const resData = await result.json();

    console.log(result, resData);

    if (!result.ok) {
      throw new Error('error-occured');
    }

    // setAlbedoAccountInfo(resData);

    return resData;

  } catch(err) {
    console.log(err);
    throw err;
  }
};


export const checkAssetPosition = (code, issuer, balances) => {
  const hasSellAsset = balances.find(balance => (balance.asset_code === code && 
      balance.asset_issuer === issuer &&
      Number(balance.balance) > 0
    ));

  if (hasSellAsset) {
    return {
      // hasAsset: true,
      amount: hasSellAsset.balance,
    };
  }

  return null;
};

export   const getInstructionsMessage = (authType) => {
  switch (authType) {
    case AuthType.ALBEDO:
      return `Review the transaction on the Albedo popup.`;
    case AuthType.LEDGER:
      return `Review the transaction on your Ledger wallet device.`;
    case AuthType.FREIGHTER:
      return `Review the transaction on the Freighter popup.`;
    case AuthType.TREZOR:
      return `Follow the instructions on the Trezor popup.`;
    default:
      return `Follow the instructions in the popup.`;
  }
};

export const fetchNetworkFee = async (settings) => {
  const server = new Horizon.Server(
    getNetworkConfig(settings.isTestnet).url,
  );
  try {
    const feeStats = await server.feeStats();
    const networkFee = lumensFromStroops(
      feeStats.fee_charged.mode,
    ).toString();
    // setRecommendedFee(networkFee);
    // setMaxFee(networkFee);
    // if (
    //   feeStats.ledger_capacity_usage > 0.5 &&
    //   feeStats.ledger_capacity_usage <= 0.75
    // ) {
    //   // setNetworkCongestion(NetworkCongestion.MEDIUM);
    // } else if (feeStats.ledger_capacity_usage > 0.75) {
    //   // setNetworkCongestion(NetworkCongestion.HIGH);
    // }

    console.log('feeStats', feeStats);
    return {
      ...feeStats,
      networkFeeXLM: networkFee,
      getTime: Date.now(),
    };
  } catch (err) {
    // use default values

    return null;
  }
};


export const makeSellOfferXdr = (
  accountId,
  sellAsset,
  buyAsset,
  price,
  amount,
  fee,
  settings,
) => {
  return new Promise((resolve, reject) => {
    // console.log(networkPassphrase);
    console.log(sellAsset, buyAsset, price, amount, fee)
    // Create an object to represent the new asset
    // var astroDollar = new StellarSdk.Asset(name, issuingKeys.publicKey());

    const server = new Horizon.Server(HORIZON_URL);

    server
      // .loadAccount(receivingKeys.publicKey())
      .loadAccount(accountId)
      .then(function (receiver) {
        // console.log('receiver', receiver);

        const hasTrust = receiver.balances.find(balance => {
          return balance.asset_code === buyAsset.code 
          && balance.asset_issuer === buyAsset.issuer;
        })

        console.log('hasTrust', hasTrust);

        let transaction;

        if (hasTrust || (buyAsset.code === 'XLM' && !buyAsset.issuer)) {
          transaction = new StellarSdk.TransactionBuilder(receiver, {
            fee: fee,
            // fee: '1000000',
            networkPassphrase: getNetworkConfig(settings.isTestnet).network,
            // networkPassphrase: networkPassphrase,
            // networkPassphrase: 'Public Global Stellar Network ; September 2015'
          })
            .addOperation(
              StellarSdk.Operation.manageSellOffer({
                buying: buyAsset,
                selling: sellAsset,
                amount: amount,
                price: price,
                offerId: 0, // 0 for new offer
              })
            )
            // // send tx create fee to fee-collect accountId
            .addOperation(
              StellarSdk.Operation.payment({
                // destination: process.env.FEE_ACCOUNTID,
                destination: FEE_ACCOUNTID,
                asset: StellarSdk.Asset.native(),
                // amount: "0.0000001",
                // amount: Number(process.env.TX_FEE).toFixed(7),
                amount: Number(txConfig.txFee).toFixed(7),
              })
            )
            // setTimeout is required for a transaction
            .setTimeout(100)
            .build();
        }
        else {
          transaction = new StellarSdk.TransactionBuilder(receiver, {
            fee: fee,
            // fee: '1000000',
            networkPassphrase: getNetworkConfig(settings.isTestnet).network,
            // networkPassphrase: 'Public Global Stellar Network ; September 2015'
          })
            .addOperation(
              StellarSdk.Operation.changeTrust({
                asset: new StellarSdk.Asset(buyAsset.code, buyAsset.issuer),
                //   limit: "1000",
              })
            )
            .addOperation(
              StellarSdk.Operation.manageSellOffer({
                buying: buyAsset,
                selling: sellAsset,
                amount: amount,
                price: price,
                offerId: 0, // 0 for new offer
              })
            )
            // // send tx create fee to fee-collect accountId
            .addOperation(
              StellarSdk.Operation.payment({
                // destination: process.env.FEE_ACCOUNTID,
                destination: FEE_ACCOUNTID,
                asset: StellarSdk.Asset.native(),
                // amount: "0.0000001",
                // amount: Number(process.env.TX_FEE).toFixed(7),
                amount: Number(txConfig.txFee).toFixed(7),
              })
            )
            // setTimeout is required for a transaction
            .setTimeout(100)
            .build();
        }


          // console.log('transaction', transaction);
          // console.log('transaction.toXDR()', transaction.toXDR());
        
          // resolve(transaction);
        resolve({ data: transaction.toXDR() });
        // transaction.sign(keyPair);
        // return server.submitTransaction(transaction);
      })
      // .then((res) => {
      //   console.log("tx id: ", res.id);
      //   // resolve(res.id);
      // })
      .catch(function (error) {
        console.error("Error!", error);
        reject(error);
      });
  });
};


export const makeCancelSellOfferXdr = (
  accountId,
  sellAsset,
  buyAsset,
  // price,
  // amount,
  offerId,
  fee,
  settings,
) => {
  return new Promise((resolve, reject) => {
    // Create an object to represent the new asset

    const server = new Horizon.Server(HORIZON_URL);

    console.log(buyAsset, sellAsset, offerId);
    server
      // .loadAccount(receivingKeys.publicKey())
      .loadAccount(accountId)
      .then(function (receiver) {
        var transaction = new StellarSdk.TransactionBuilder(receiver, {
          fee: fee.toString(),
          // fee: '1000000',
          // networkPassphrase: networkPassphrase,
          networkPassphrase: getNetworkConfig(settings.isTestnet).network,
          // networkPassphrase: 'Public Global Stellar Network ; September 2015'
        })
          .addOperation(
            StellarSdk.Operation.manageSellOffer({
              buying: buyAsset,
              selling: sellAsset,
              amount: '0',
              price: '1',
              offerId: offerId, // 0 for new offer
            })
          )
          // setTimeout is required for a transaction
          .setTimeout(100)
          .build();

          // console.log('transaction', transaction);
          // console.log('transaction.toXDR()', transaction.toXDR());
          
        resolve({ data: transaction.toXDR() });
        // transaction.sign(keyPair);
        // return server.submitTransaction(transaction);
      })
      // .then((res) => {
      //   console.log("tx id: ", res.id);
      //   // resolve(res.id);
      // })
      .catch(function (error) {
        console.error("Error!", error);
        reject(error);
      });
  });
};


export const makeBuyOfferXdr = (
  accountId,
  sellAsset,
  buyAsset,
  price,
  buyAmount,
  fee,
  settings,
) => {
  return new Promise((resolve, reject) => {

    const server = new Horizon.Server(HORIZON_URL);

    server
      // .loadAccount(receivingKeys.publicKey())
      .loadAccount(accountId)
      .then(function (receiver) {
          console.log('buyAsset sellAsset', buyAsset, sellAsset);
          
          const hasTrust = receiver.balances.find(balance => {
            return balance.asset_code === buyAsset.code 
            && balance.asset_issuer === buyAsset.issuer;
          })

          console.log('hasTrust', hasTrust);
          
          if (hasTrust) {
            var transaction = new StellarSdk.TransactionBuilder(receiver, {
              fee: fee,
              // fee: '1000000',
              // networkPassphrase: networkPassphrase,
              networkPassphrase: getNetworkConfig(settings.isTestnet).network,
              // networkPassphrase: 'Public Global Stellar Network ; September 2015'
            })
            .addOperation(
              StellarSdk.Operation.manageBuyOffer({
                buying: buyAsset,
                selling: sellAsset,
                buyAmount: buyAmount,
                price: price,
                offerId: 0, // 0 for new offer
              })
            )
            // // send tx create fee to fee-collect accountId
            .addOperation(
              StellarSdk.Operation.payment({
                destination: FEE_ACCOUNTID,
                asset: StellarSdk.Asset.native(),
                // amount: "0.0000001",
                amount: Number(txConfig.txFee).toFixed(7),
              })
            )
            // setTimeout is required for a transaction
            .setTimeout(100)
            .build();
  
            // console.log('transaction', transaction);
            // console.log('transaction.toXDR()', transaction.toXDR());
            
            resolve({ data: transaction.toXDR() });
          }
          else {
            var transaction = new StellarSdk.TransactionBuilder(receiver, {
              fee: fee,
              // fee: '1000000',
              // networkPassphrase: networkPassphrase,
              networkPassphrase: getNetworkConfig(settings.isTestnet).network,
              // networkPassphrase: 'Public Global Stellar Network ; September 2015'
            })
            .addOperation(
              StellarSdk.Operation.changeTrust({
                asset: new StellarSdk.Asset(buyAsset.code, buyAsset.issuer),
                //   limit: "1000",
              })
            )
            .addOperation(
              StellarSdk.Operation.manageBuyOffer({
                buying: buyAsset,
                selling: sellAsset,
                buyAmount: buyAmount,
                price: price,
                offerId: 0, // 0 for new offer
              })
            )
            // // send tx create fee to fee-collect accountId
            .addOperation(
              StellarSdk.Operation.payment({
                destination: FEE_ACCOUNTID,
                asset: StellarSdk.Asset.native(),
                // amount: "0.0000001",
                amount: Number(txConfig.txFee).toFixed(7),
              })
            )
            // setTimeout is required for a transaction
            .setTimeout(100)
            .build();
  
            // console.log('transaction', transaction);
            // console.log('transaction.toXDR()', transaction.toXDR());
            
            resolve({ data: transaction.toXDR() });
            // transaction.sign(keyPair);
            // return server.submitTransaction(transaction);
          }

      })
      // .then((res) => {
      //   console.log("tx id: ", res.id);
      //   // resolve(res.id);
      // })
      .catch(function (error) {
        console.error("Error!", error);
        reject(error);
      });
  });
};
