import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useRedux } from "hooks/useRedux";
// import AlbedoLogin from '../Albedo/AlbedoLogin';

import Loader from '../../Loader/Loader.jsx';
import StellarAddress from '../../UiComponent/StellarAddress.jsx';

import { useStore } from '../../../../hook-store/store';

// import * as uploadUtils from '../utils/bucket-upload-util';
import * as nftDataUtils from '../../../../utils/nft/nft-data-util';
import { BACKEND_URL } from '../../../../urlLinks.js';

import classes from './NftHolders.module.css';

function NftHolders(props) {
  const { 
    nft, 
  } = props;

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    // "memoRequiredAccounts",
    "settings",
    // "sendTx",
  );

  // const publicKey = account.data ? account.data.id : '';


  const [hStore, hDispatch] = useStore();

  const [nftHolders, setNftHolders] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  useEffect(() => {
    if (nft) {
      getNftHoldrsHandler(
        // 'https://api.stellar.expert',
        `${BACKEND_URL  }/nft-data/nft-holders`,
        nft.code,
        nft.issuer,
      );
    }
  },[nft]);

  const getNftHoldrsHandler = async(url, code, issuer) => {
    try {
      setIsLoading(true);

      const result = await nftDataUtils.getNftHolders(url, code, issuer);
      
      console.log(result);
      if (result.data && result.data._embedded) {
        // console.log(result.data._embedded.records)
        setNftHolders(result.data._embedded.records);
      }

      setIsLoading(false);
    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  let nftHoldersBody;

  if (nftHolders.length > 0) {
    nftHoldersBody = (
      <div className={classes.nftHolder}>
        {/* <div>nft-holders-body</div> */}
        <ul>
          {nftHolders.map((holder, index) => (
            <div key={index}>
              <StellarAddress 
                stellarId={holder.account}
              />
              {/* <div>holder-account: {holder.account}</div> */}
              <div className={classes.nftHolderBalance}>
                Balance 
                {' '}
                <strong>{holder.balance}</strong>
              </div>
            </div>
            ))}
        </ul>
      </div>
    );
  }


  return (
    <Fragment>
      <div>
        {isLoading && <Loader />}
        {nftHoldersBody}
      </div>
    </Fragment>
  );
}

export default NftHolders;
