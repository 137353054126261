import { initStore } from "./store";

// const configureStore = () => {
function configureStore() {
  const actions = {
    SET_SEARCHNFTLIST: (state, payload) => (
      { 
        nftSearchStore : {
          ...state.nftSearchStore,
          searchNftList: payload, 
        },
      }
    ),
    SET_SEARCHUSERLIST: (state, payload) => (
      { 
        nftSearchStore : {
          ...state.nftSearchStore,
          searchUserList: payload, 
        },
      }
    ),
  };

  initStore(actions, {
    nftSearchStore: {
      searchNftList: [],
      searchUserList: [],
    },
  });
}

export default configureStore;
