import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import * as StellarSdk from '@stellar/stellar-sdk';
import {
  Button,
  InfoBlock,
  Input,
  TextLink,
  Modal,
  Icon,
  Identicon,
  TxFlow,
} from "@stellar/design-system";
import _ from 'lodash';
import { useTranslation } from 'react-i18next';


import { useRedux } from "hooks/useRedux";
import { ActionStatus, AuthType } from "types/types";
import { logEvent } from 'helpers/tracking';
import { fetchAccountAction } from 'ducks/account';
import { sendTxAction, resetSendTxAction } from 'ducks/sendTx';
import { getNetworkConfig } from 'helpers/getNetworkConfig';

import Loader from '../../Loader/Loader.jsx';
// import WideAds from '../../Ads/WideAds.jsx';
import GetAvailableXlm from '../../UiComponent/GetAvailableXlm.jsx';
import GetNetworkFee from 'components/IssuerFrontend/UiComponent/GetNetworkFee.jsx';

import { 
  makeCancelSellOfferXdr,
  fetchNetworkFee,
} from '../../../../utils/nft/offer-util';

import {
  getNftByAssetInfo,  
} from '../../../../utils/nft/nft-data-util';

// import * as uploadUtils from '../utils/bucket-upload-util';
import { useStore } from '../../../../hook-store/store';

// import { txConfig } from '../../transactionConfig.ts';
import { BACKEND_URL, STELLAREXPERT_URL } from '../../../../urlLinks';

import classes from './UserOffers.module.css';

function UserOffersCancel(props) {
  const { offer, sellAsset, buyAsset } = props;
  console.log('UserOfferCancel.jsx-props', props);

  const [t] = useTranslation('translation');

  const dispatch = useDispatch();

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    "memoRequiredAccounts",
    "settings",
    "sendTx",
  );
  const { status, errorString } = sendTx;

  const publicKey = account.data ? account.data.id : '';


  const [hStore, hDispatch] = useStore();
  // const txXdr = hStore.nftStore.txXdr;
  // const feeStats = hStore.nftStore.feeStats;
  const { 
    txXdr, 
    feeStats, 
    availableXLM,
  } = hStore.nftStore;


  const [stateTxXdr, setStateTxXdr] = useState('');
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    const fetchNetworkFeeHandler = async () => {
        const getStats = await fetchNetworkFee(settings);

        if (getStats) {
            hDispatch('SET_FEESTATS', getStats);
            // setTxFeeInput( 
            //   (Number(getStats.networkFeeXLM * 1.1) * 10**7).toFixed() );
        }
    };

    if (!feeStats || feeStats.getTime < Date.now() - 1000*60*1 ||
          status === ActionStatus.ERROR) {
      fetchNetworkFeeHandler();
    }
  },[status]);

  useEffect(() => {
    console.log('status', status, sendTx);

    if (txXdr && stateTxXdr && txXdr === stateTxXdr) {
      if ((sendTx.data && 
        // sendTx.data.source_account_sequence === txSequence && 
        status === ActionStatus.SUCCESS) || 
        status === ActionStatus.ERROR) {
          // cancelResultHandler({ code: nft.code, issuer: nft.issuer });
          cancelResultHandler({ 
            code: buyAsset.asset_code, 
            issuer: buyAsset.asset_issuer,
          });
        }
    }
  },[status]);


  const cancelBuyOfferHandler = async (
    url, 
    token, 
    accountId,
    // eslint-disable-next-line @typescript-eslint/no-shadow, no-shadow
    sellAsset,
    buyAsset,
    offerId,
  ) => {
    try {
      setIsLoading(true);

      // const resData = await createCancelSellOfferXdr(
      //   url, 
      //   token, 
      //   accountId,
      //   sellAsset,
      //   buyAsset,
      //   offerId,
      // );

      const resData = await makeCancelSellOfferXdr(
        accountId,
        new StellarSdk.Asset(sellAsset.code, sellAsset.issuer),
        new StellarSdk.Asset(buyAsset.code, buyAsset.issuer),
        offerId,
        Number(useFee * 10**7).toFixed(),
        settings,
      );

      if (resData) {
        // console.log(resData);

        const xdrTx = resData.data;

        const transaction = new StellarSdk.Transaction(
            xdrTx,
            getNetworkConfig(settings.isTestnet).network,
        );
        console.log('transaction', transaction, xdrTx);
        
        setStateTxXdr(xdrTx);
        hDispatch('SET_TXXDR', xdrTx);
        // setTxSequence(transaction._sequence);

        dispatch(sendTxAction(transaction));
        logEvent("cancelBuyOffer: transactions", {
            txOperations: transaction._operations,
            // amount: formData.amount.toString(),
            //   "used federation address": !!formData.federationAddress,
            //   "used memo": !!formData.memoContent,
        });

      }

      // await getAssetBuyOffersHandler(
      //   'credit_alphanum12', 
      //   buyAsset.code, 
      //   buyAsset.issuer
      // );
      // // await setUpdateAccountInfo(accountId);

      // hDispatch('SHOW_NOTIFICATION', {
      //   status: '',
      //   title: '',
      //   message: 'buy offre canceled',
      // });

      // setTimeout(() => {
      //   hDispatch('CLEAR_NOTIFICATION');
      // }, 3000);

      // setIsLoading(false);
      
    } catch(err) {
      console.log(err);

      // if (!err.error.code) {
      //   hDispatch('SHOW_NOTIFICATION', {
      //     status: '',
      //     title: '',
      //     message: 'Error: buy offre cancel failed',
      //   });
      // }

      setIsLoading(false);

    }
  };

  const cancelResultHandler = async (buyAsset) => {
    if (status === ActionStatus.SUCCESS) {
      // getAssetBuyOffersHandler(
      //   'credit_alphanum12', 
      //   buyAsset.code, 
      //   buyAsset.issuer,
      // );

      dispatch(resetSendTxAction());
      // dispatch(fetchAccountAction(publicKey));

      hDispatch('SHOW_NOTIFICATION', {
        status: '',
        title: '',
        message: `${t('singleNFT.06', 'trading buy offers')} ${t('general.text32', 'canceled')}`,
      });

      setTimeout(() => {
        hDispatch('CLEAR_NOTIFICATION');
      }, 5000);


      setIsLoading(false);
    }

    if (status === ActionStatus.ERROR) {
      console.log('errorString', errorString);

      // if (!err.error.code) {
        // hDispatch('SHOW_NOTIFICATION', {
        //   status: '',
        //   title: '',
        //   message: `Error: buy offre cancel failed,
        //     ${sendTx.errorString}`,
        // });
      // }

      setIsLoading(false);
    }
  };


  let networkFee = 0;
  // let minXLM = 0;
  let enoughXLM = false;
  let useFee = 0;

  let buyAssetInfo;
  let sellAssetInfo;

  if (feeStats) {
    networkFee = Number(feeStats.fee_charged.mode);
    useFee = Number(networkFee)*1.1 / 10**7;
    useFee = Number(networkFee)*10 / 10**7;

    if (availableXLM >= useFee) {
      enoughXLM = true;
    }
  } 

  if (buyAsset.asset_type === 'native') {
    buyAssetInfo = { code: 'XLM', issuer: ''};

  } else {
    buyAssetInfo = { 
      code: buyAsset.asset_code, 
      issuer: buyAsset.asset_issuer, 
    };
  }

  if (sellAsset.asset_type === 'native') {
    sellAssetInfo = { code: 'XLM', issuer: ''};

  } else {
    sellAssetInfo = { 
      code: sellAsset.asset_code, 
      issuer: sellAsset.asset_issuer,
    };
  }

  // console.log(buyAssetInfo, sellAssetInfo)
  

  let userOfferCancelModalBody;

  if (showCancelModal) {
    userOfferCancelModalBody = (
      <div>
        {/* <div>user-sell-offers-length: {sellOffers.length}</div> */}
        <div className={classes.userOfferCancelConetent}>
          <div>OfferId</div>
          <div><strong>{offer.id}</strong></div>
        </div>

        <div className={classes.userOfferCancelAvailableXlm}>
          <InfoBlock>
            <div className={classes.sellOfferCancelConetent}>
              {t('singleNFT.17', 'Confirm screen of wallet may show amount 0, price 1 with OfferId.')}
            </div>
            <div className={classes.sellOfferCancelConetent}>
              {t('singleNFT.18', 'This is because inpnuts with amount 0 and some price are required to create transaction for canceling offer.')}
            </div>
            <div className={classes.sellOfferCancelConetent}>
              {t('singleNFT.23', 'Please make sure OfferId is same as confirm screen to cancel offer.')}      
            </div>

            <div className={classes.sellOfferCancelConetent}>
              <div>
                {t('general.text25', 'available')}
                {' '} 
                XLM: {availableXLM && availableXLM.toFixed(7)}</div>
              <div>
                {t('general.text26', 'required')}
                {' '}
                XLM: {useFee.toFixed(7)}</div>
            </div>
          </InfoBlock>
        </div>

        <Button 
          disabled={isLoading || !enoughXLM}
          isLoading={isLoading}
          onClick={() => {
            // createCancelNftSellOfferHandler(
            //   BACKEND_URL, 
            //   localStorage.getItem('token'),
            //   publicKey,
            //   { code: nft.code, issuer: nft.issuer },
            //   buyAssetInfo,
            //   offer.id,
            // );
            cancelBuyOfferHandler(
              BACKEND_URL, 
              localStorage.getItem('token'),
              publicKey,
              sellAssetInfo,
              buyAssetInfo,
              offer.id,
            );
          }}
        >
          Cancel offer
        </Button>
      </div>
    );
  }

  let userOffersCancelBody;

  if (offer) {
    userOffersCancelBody = (
      <Button
        style={{ fontSize: "0.8rem" }}
        size="small"
        onClick={() => {
          // cancelBuyOfferHandler(
          //   BACKEND_URL, 
          //   localStorage.getItem('token'),
          //   publicKey,
          //   sellAssetInfo,
          //   buyAssetInfo,
          //   offer.id,
          // );
          setShowCancelModal(true);
        }}
      >
        cancel
      </Button>
    );
  }

  return (
    <Fragment>
      {isLoading && <Loader />}
      {userOffersCancelBody}

      {showCancelModal && (
        <Modal
          visible={showCancelModal}
          // onClose={resetModalStates}
          onClose={() => {
            setShowCancelModal(false);
            dispatch(resetSendTxAction());
          }}
        >
            {/* // <TxFlow
            //   onCancel={() => {
            //     setShowCancelModal(false);
            //     // dispatch(resetSendTxAction());
            //   }}
            //   createTxBody={userOfferCancelModalBody}
            //   isLoading={isLoading}
            // /> */}
            <div>
              {userOfferCancelModalBody}
              <GetAvailableXlm />
              <GetNetworkFee />
            </div>
          {/* {isReceiveTxModalVisible && <ReceiveTransaction />} */}
        </Modal>
      )}
      
    </Fragment>
  );
}

export default UserOffersCancel;
