import React, { useState, useEffect, Fragment } from 'react';
// import albedo from '@albedo-link/intent'
// var toml = require('toml');
// const toml = toml || require('toml-j0.4');
import toml from 'toml-j0.4';

import { useRedux } from "hooks/useRedux";

import { getAccountInfo } from '../../../utils/nft/offer-util';
import { useStore } from '../../../hook-store/store';

import {
  postGetAccountAssetData,
  // getHorizonAssetInfo,
  parseStellarToml,
  // getAssetInfoFromToml,
  // createListForGetToml,
  createListForGetToml2,
} from '../../../utils/nft/account-data-util';

// import * as uploadUtils from '../utils/nft/bucket-upload-util';
// import * as accountUtils from '../utils/account-util';

import { BACKEND_URL, HORIZON_URL } from '../../../urlLinks';


function GetAccountAssetInfo(props) {

  const { account, settings } = useRedux(
    "account",
    // "memoRequiredAccounts",
    "settings",
    // "sendTx",
  );

  const [hStore, hDispatch] = useStore();
  // const albedoPubkeyInfo = hStore.uiStore.albedoPubkeyInfo;
  // const albedoAccountInfo = hStore.uiStore.albedoAccountInfo;

  // const [assetTomlInfo, setAssetTomlInfo] = useState();
  // const [assetInfoList, setAssetInfoList] = useState([]);
  const [accountData, setAccountData] = useState();

  let aList = [
    // {code: 'AQUAxxx', issuer: 'GBNZILSTVQZ4R7IKQDGHYGY2QXL5QOFJYQMXPKWRRM5PAV7Y4M67AQUA'},
    {code: 'AQUA', issuer: 'GBNZILSTVQZ4R7IKQDGHYGY2QXL5QOFJYQMXPKWRRM5PAV7Y4M67AQUA'},
    {code:'USDC', issuer: 'GA5ZSEJYB37JRC5AVCIA5MOP4RHTM335X2KGX3IHOJAPP5RE34K4KZVN'},
    {code:'yETH', issuer: 'GDYQNEF2UWTK4L6HITMT53MZ6F5QWO3Q4UVE6SCGC4OMEQIZQQDERQFD'},
    {code:'yBTC', issuer: 'GBUVRNH4RW4VLHP4C5MOF46RRIRZLAVHYGX45MVSTKA2F6TMR7E7L6NW'},
    {code: 'yUSDC', issuer: 'GDGTVWSM4MGS4T7Z6W4RPWOCHE2I6RDFCIFZGS3DOA63LWQTRNZNTTFF'},
  ];

  if (settings.isTestnet) {
    aList = [
      {code: 'USDC', issuer: 'GBBD47IF6LWK7P7MDEVSCWR7DPUWV3NY3DTQEVFL4NAT4AQH3ZLLFLA5'},
    ];
  }


  useEffect(() => {
    const getAccountDataHandler = async () => {
      try {
        const accountInfo = await getAccountInfo(account.data.id);
        console.log('accountInfo in GetAccountAssetInfo', accountInfo);
        if (accountInfo) {
          setAccountData(accountInfo);
        }
      } catch(err) {
        console.log(err);
      }
    };

    if (account.data && !accountData) {
      getAccountDataHandler();
    }

    if (account.data && accountData &&
      account.data.id !== accountData.account_id) {
      getAccountDataHandler();
    }
  },[settings]);


  // // initialize asset info list when logout
  // useEffect(() => {
  //   if (!account || !account.data) {
  //     hDispatch('SET_ASSETINFOLIST', []);
  //   }
  // },[account]);


  // // add assetInfoList to store when exist in localStrage
  useEffect (() => {

    // if (accountData && accountData.balances.length > 0) {

    //   // const listForGetToml = aList;
    //   // const lsAccountAssetData = localStorage.getItem(`accountAssetData-${accountData.account_id}`);
    //   const lsAssetDataList = localStorage.getItem('assetDataList');
      
    //   let parsedList;

    //   if (lsAssetDataList) {
    //     parsedList = JSON.parse(lsAssetDataList);

    //     hDispatch('SET_ASSETINFOLIST', parsedList.assetInfoList);
    //   } 
    // }

   
    const lsAssetDataList = localStorage.getItem('assetDataList');
    let parsedList;

    if (lsAssetDataList) {
      parsedList = JSON.parse(lsAssetDataList);

      hDispatch('SET_ASSETINFOLIST', parsedList.assetInfoList);
    } 
    
  },[accountData]);


  // // fetch toml data from backend
  // useEffect(() => {
  //   if (accountData && accountData.balances.length > 0) {

  //     // const listForGetToml = aList;
  //     // const lsAccountAssetData = localStorage.getItem(`accountAssetData-${accountData.account_id}`);
  //     const lsAssetDataList = localStorage.getItem('assetDataList');

  //     let parsedList;

  //     if (lsAssetDataList) {
  //       parsedList = JSON.parse(lsAssetDataList);
  //     }
      
  //     if (!parsedList || parsedList.getTime < Date.now() - 1000*60*60*12) {
  //       // if (!parsedList || parsedList.getTime < Date.now() - 1000) {

  //       // const listForGetToml = createListForGetToml(accountData, aList);
  //       const listForGetToml = createListForGetToml2(accountData, aList);

  //       // console.log('listForGetToml',listForGetToml)
  //       postGetAccountAssetDataHandler(
  //         BACKEND_URL,
  //         'token',
  //         listForGetToml,
  //       );
  //     }

  //   }    
  // },[accountData]);


  // // fetch from frontend
  useEffect(() => {
    if (accountData && accountData.balances.length > 0) {
      
      // console.log('before listForGetToml', listForGetToml);

      // const listForGetToml = createListForGetToml(accountData, aList);
      const listForGetToml = createListForGetToml2(accountData, aList);

      // console.log('listForGetToml', listForGetToml);
      createAssetInfoList(listForGetToml);
    }
  },[accountData]);


const postGetAccountAssetDataHandler = async (url, token, assetList) => {
  try {
    const resData = await postGetAccountAssetData(
      BACKEND_URL,
      'token',
      assetList,
    );

    console.log('accounst asset info', resData.data);
    hDispatch('SET_ASSETINFOLIST', resData.data);
    
    const accountAssetData = {
      id: accountData.account_id,
      assetInfoList: resData.data,
      getTime: Date.now(),
    };

    // localStorage.setItem(`accountAssetData-${accountData.account_id}`, JSON.stringify(accountAssetData));
    localStorage.setItem('assetDataList', JSON.stringify(accountAssetData));
    return resData;
    
  } catch(err) {
    console.log(err);
  }
};


const createAssetInfoList = async (assetList) => {
  try {
    // const list = [{ code: 'XLM', issuer: '', tomlData: null }];
    const list = [];

    for (const asset of assetList) {
      const tomlData = await parseStellarToml(asset.code, asset.issuer);
      
      if (tomlData) {
        list.push({
          code: asset.code,
          issuer: asset.issuer,
          tomlData,
        });
      } else {
        list.push({
          code: asset.code,
          issuer: asset.issuer,
          tomlData: null,
        });
      }
    }
  
    console.log('toml assetInfoList', list);
    hDispatch('SET_ASSETINFOLIST', list);

    const accountAssetData = {
      id: accountData.account_id,
      assetInfoList: list,
      getTime: Date.now(),
    };

    // localStorage.setItem(`accountAssetData-${accountData.account_id}`, JSON.stringify(accountAssetData));

    localStorage.setItem('assetDataList', JSON.stringify(accountAssetData));
  
  } catch(err) {
    console.log(err);
  }
  
};


let testList = (
  <ul>
    {hStore.nftStore.assetInfoList.map((asset, index) => {
      if (asset.tomlData) {
        return <li key={index}>
          <img style={{height:"20px"}} src={asset.tomlData.image} alt="logo"/> {asset.code}
        </li>
      } else {
        return <li key={index}>{asset.code}</li>;
      }
    })}
  </ul>
);


  return (
    <Fragment>
      {/* <img src={assetTomlInfo && assetTomlInfo.image}/> */}
      
      {/* <div>tset-list</div>
      {testList} */}
    </Fragment>
  );
}

export default GetAccountAssetInfo;
