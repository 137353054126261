import { initStore } from "./store";

// const configureStore = () => {
function configureStore() {
  const actions = {
    SET_NFTLIST: (state, payload) => (
      { 
        nftStore : {
          ...state.nftStore,
          nftList: payload, 
        },
      }
    ),
    SET_ASSETINFOLIST : (state, payload) => (
      { 
        nftStore : {
          ...state.nftStore,
          assetInfoList: payload, 
        },
      }
    ),
    SET_FEESTATS : (state, payload) => (
      { 
        nftStore : {
          ...state.nftStore,
          feeStats: payload, 
        },
      }
    ),
    SET_SHOWTXMODAL : (state, payload) => (
      { 
        nftStore : {
          ...state.nftStore,
          showTxModal: payload, 
        },
      }
    ),
    SET_TXXDR : (state, payload) => (
      { 
        nftStore : {
          ...state.nftStore,
          txXdr: payload, 
        },
      }
    ),
    SET_FORSELLOFFER_ASSETS : (state, payload) => (
      { 
        nftStore : {
          ...state.nftStore,
          forSellOfferAssets: payload, 
        },
      }
    ),
    SET_AVAILABLEXLM : (state, payload) => (
      { 
        nftStore : {
          ...state.nftStore,
          availableXLM: payload,
        },
      }
    ),
    SET_TERMASSETLIST : (state, payload) => (
      { 
        nftStore : {
          ...state.nftStore,
          termAssetList: payload,
        },
      }
    ),
    SET_ASSETSELLOFFERS : (state, payload) => (
      { 
        nftStore : {
          ...state.nftStore,
          assetSellOffers: payload,
        },
      }
    ),
    SET_ASSETBUYOFFERS : (state, payload) => (
      { 
        nftStore : {
          ...state.nftStore,
          assetBuyOffers: payload,
        },
      }
    ),
    SET_SELLOFFERNFTLIST : (state, payload) => (
      { 
        nftStore : {
          ...state.nftStore,
          sellOfferNftList: payload,
        },
      }
    ),
  };

  initStore(actions, {
    nftStore: {
      nftList: [],
      assetInfoList: [],
      feeStats: null,
      showTxModal: false,
      txXdr: '',
      forSellOfferAssets: [],
      availableXLM: 0,
      termAssetList: null,
      assetSellOffers: [],
      assetBuyOffers: [],
      sellOfferNftList: [],
    },
  });
}

export default configureStore;
