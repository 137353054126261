import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Input, InfoBlock } from '@stellar/design-system';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { useRedux } from "hooks/useRedux";

import Loader from '../../Loader/Loader.jsx';
// import NftListItem from './NftListItem.jsx';
// import Paginator from '../Paginator/Paginator.jsx';
import { useStore } from '../../../../hook-store/store';


// import * as uploadUtils from '../utils/bucket-upload-util';
// import * as nftDataUtils from '../../../utils/nft/nft-data-util';

import { getNftSearchResult } from '../../../../utils/nft/nft-search-util';

import { BACKEND_URL } from '../../../../urlLinks';

import classes from './NftSearch.module.css';

function NftSearch(props) {
  // console.log('NFTSearch-props', props);
  const { 
    // inputValue,
  } = props;

  const [t] = useTranslation('translation');

  const navigate = useNavigate();

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    // "memoRequiredAccounts",
    "settings",
    // "sendTx",
  );

  const [hStore, hDispatch] = useStore();
  // const nftList = hStore.nftStore.nftList;
  const { searchNftList, searchUserList } = hStore.nftSearchStore;
  const { userList } =hStore.uiStore;
  // const [getTimes, setGetTimes] = useState(0);

  const [inputValue, setInputValue] = useState('');
  const [showResults, setShowResults] = useState(false);

  // const [searchNftList, setSearchNftList] = useState([]);
  // const [searchUserList, setSearchUserList] = useState([]);
  
  const [isLoading, setIsLoading] = useState(false);
  // console.log(setIsLoading);


  // useEffect(() => {

  // },[]);

  const setInputValueHandler = (event) => {
    setInputValue(event.target.value);
    hDispatch('SET_SEARCHNFTLIST', []);
    hDispatch('SET_SEARCHUSERLIST', []);
    setShowResults(false);
  };

  const getNftSearchHandler = async (input) => {
    try {
      if (!input) {
        return;
        // throw new Error('no input entered');
      }

      setIsLoading(true);

      const result = await getNftSearchResult(
        BACKEND_URL,
        localStorage.getItem('token'),
        input,
      );

      console.log(result);
      
      if (result.data && result.data.length > 0) {
        // setSearchNftList(result.data);
        hDispatch('SET_SEARCHNFTLIST', result.data);
        

        const nameDataList = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const element of result.data) {
          if (!element.n_id && element._id) {
            nameDataList.push(element);
          }
        }

        const addedList2 = userList.concat(nameDataList);
        // console.log('userList addedList', addedList);
        hDispatch(
          'SET_USERLIST',
          _.uniqBy(addedList2, '_id'),
        );
      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const setUserSearchHandler = (input) => {

    let searchList = [];
    // console.log(userList);

    if (input && input.length < 4) {
      searchList = userList.filter(user => user.name === input);
    } 

    if (input && input.length >= 4 && input.length <= 20) {
      searchList = userList.filter(user => user.name.includes(input));
    } 

    console.log(searchList);
    // setSearchUserList(searchList);
    hDispatch('SET_SEARCHUSERLIST', searchList);
    // return searchList;
  };


  let resultList;

  if (showResults && 
        (searchNftList.length > 0 || searchUserList.length > 0)
  ) {

    const combinedList = searchUserList.slice(0, 10)
      .concat(searchNftList.slice(0, 10));

    const displayList = _.uniqBy(combinedList, '_id');

    resultList = (
      <div className={classes.nftSearchResultBox}>
        <div>
          <ul>
            {displayList.map((item, index) => {

                let creatorName;

                if (item.n_id) {
                  const creator = userList.find(user => user._id === item.uId);
                  if (creator) {
                    creatorName = (
                      <span>
                        ({' '}{creator.name}
                        {' '}
                        {creator.imageUrl && (
                          <img className={classes.nftSearchResultItemUserImage}
                          src={creator.imageUrl}
                          /> 
                        )}
                        {' '})
                      </span>
                    );
                  }
                }

                if (item.n_id) {
                  return (
                    <div key={index}
                      className={classes.nftSearchResultItem}
                    >
                      {item.n_id && (
                        <span
                          onClick={() => {
                            navigate(`/${item.n_id}`);
                            // setShowResults(false);
                          }}
                        >
                          <span className={classes.nftSearchResultItemRow}>
                            <span>(NFT) {item.name}</span>
                            {item.image && (
                              <img className={classes.nftSearchResultItemNftImage}
                              src={item.image}
                              />
                            )}
                            {creatorName && (
                              <span className={classes.nftSearchResultNftCreator}>
                                {creatorName}
                              </span>
                            )}
                          </span>
                        </span>
                      )}
                    </div>
                  );
                } 
                
                return (
                    <div key={index}
                      className={classes.nftSearchResultItem}
                    >
                      {!item.n_id && (
                        <span
                          onClick={() => {
                            navigate(`/creator-nftlist?_id=${item._id}`);
                            // setShowResults(false);
                          }}
                        >
                          <span className={classes.nftSearchResultItemRow}>
                            (Creator) {item.name} 
                            {item.imageUrl && (
                              <img className={classes.nftSearchResultItemUserImage}
                              src={item.imageUrl}
                              />
                            )}
                          </span>
                        </span>
                      )}
                    </div>
                  );
                

              })
            }
          </ul>
        </div>
      </div>
  );
  }

  
  const nftSearchBody = (
    <div>
      <div style={{position:"relative"}}>
        <div className={classes.nftSearchInputContainer}>
          <Input 
            style={{border:"1px solid gray", borderRadius:"4px"}}
            type='text'
            onChange={setInputValueHandler}
            // value={inputValue}
            // placeholder='search creator or NFT...'
            placeholder={`${t('nav.07', 'search creator or NFT')}...`}
            // placeholder={`search NFT...`}
            onKeyUp={(event) => { 
              // console.log(event.key);
              if (!isLoading && event.key === 'Enter') {
                getNftSearchHandler(inputValue.trim());
                setUserSearchHandler(inputValue.trim());
                setShowResults(true);
              }
            }}
          />
          <Button
            style={{border: "1px solid gray"}}
            variant="tertiary"
            size="small"
            disabled={isLoading}
            onClick={() => {
              getNftSearchHandler(inputValue.trim());
              setUserSearchHandler(inputValue.trim());
              setShowResults(true);
            }}
          >
            {t('nav.08', 'search')}
          </Button>
        </div>

      <div>{resultList}</div>

      </div>

    </div>
  );


  return (
    <Fragment>

      <div className={classes.nftSearch}>
        {nftSearchBody}
      </div>
      {isLoading && (<Loader />)}
    </Fragment>
  );
}

export default NftSearch;
