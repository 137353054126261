import React, { useState, useEffect, Fragment } from "react";

import Select from "react-select";

import { useRedux } from "hooks/useRedux";
// import * as uploadUtils from '../utils/bucket-upload-util';

import GetAssetsTomlData from "components/IssuerFrontend/UiComponent/GetAssetsTomlData.jsx";
import { useStore } from "../../../../hook-store/store";

import xlmLogo from "../../../../images/stellar-xlm-logo.png";

function CreateNftSellOfferSelect(props) {
  const { setSelectOptionHandler, isLoading } = props;

  const { account, settings } = useRedux(
    "account",
    "settings",
  );

  const [hStore, hDispatch] = useStore();
  const forSellOfferAssets = hStore.nftStore.forSellOfferAssets;
  // const assetInfoList = hStore.nftStore.assetInfoList;

  // const [isLoading, setIsLoading] = useState(false);

  // let buyInputAssetList = [
  //   { code: "XLM", issuer: "" },
  //   {
  //     code: "AQUA",
  //     issuer: "GBNZILSTVQZ4R7IKQDGHYGY2QXL5QOFJYQMXPKWRRM5PAV7Y4M67AQUA",
  //   },
  //   {
  //     code: "USDC",
  //     issuer: "GA5ZSEJYB37JRC5AVCIA5MOP4RHTM335X2KGX3IHOJAPP5RE34K4KZVN",
  //   },
  //   {code:'yETH', issuer: 'GDYQNEF2UWTK4L6HITMT53MZ6F5QWO3Q4UVE6SCGC4OMEQIZQQDERQFD'},
  //   {code:'yBTC', issuer: 'GBUVRNH4RW4VLHP4C5MOF46RRIRZLAVHYGX45MVSTKA2F6TMR7E7L6NW'},
  // ];

  // if (settings.isTestnet) {
  //   buyInputAssetList = [
  //     { code: "XLM", issuer: "" },
  //     {
  //       code: "USDC",
  //       issuer: "GBBD47IF6LWK7P7MDEVSCWR7DPUWV3NY3DTQEVFL4NAT4AQH3ZLLFLA5",
  //     },
  //   ];
  // }


  // let logoUrl;

  // if (assetInfoList && assetInfoList.length > 0) {
  //   // const aquaData = assetInfoList.find((asset) => (
  //   //   asset.code === "AQUA" && asset.issuer === "GBNZILSTVQZ4R7IKQDGHYGY2QXL5QOFJYQMXPKWRRM5PAV7Y4M67AQUA"));
  //   // if (aquaData && aquaData.tomlData) {
  //   //   logoUrl = aquaData.tomlData.image;
  //   // }
  //   // console.log('aquaData', aquaData);
  // }

  const options2 = [];

  if (forSellOfferAssets.length > 0) {
    // for (const asset of forSellOfferAssets) {
    forSellOfferAssets.forEach((asset) => {
      if (asset.code === "XLM" && !asset.issuer) {
        options2.push({
          value: { code: "XLM", issuer: "" },
          label: (
            <span>
              <img
                style={{ height: "15px" }}
                src={xlmLogo}
                alt="select log xlm"
              />{" "}
              <span style={{ color: "gray" }}>XLM</span>
            </span>
          ),
        });
      } else {
        const issuerSub = `${asset.issuer.slice(0, 5)}....${asset.issuer.slice(-5)}`;

        if (asset.tomlData) {
          options2.push({
            value: { code: asset.code, issuer: asset.issuer },
            label: (
              <span>
                <img
                  style={{ height: "15px" }}
                  src={asset.tomlData.image}
                  // src=""
                  alt=""
                />{" "}
                <span style={{ color: "gray" }}>
                  {asset.code} ({issuerSub})
                </span>
              </span>
            ),
          });
        }
        if (!asset.tomlData) {
  
          // listForOptions.push(assetInfoListData);
          options2.push({
            value: { code: asset.code, issuer: asset.issuer },
            label: (
              <span>
                <img style={{ height: "15px" }} src="" alt="" /> {asset.code} (
                {issuerSub})
              </span>
            ),
          });
        }
      }

    });
  }

  // console.log(options2);

  const createNftSellOfferSelectBody = (
    <div>
      <Select
        defaultValue={null}
        onChange={setSelectOptionHandler}
        options={options2}
      />
    </div>
  );

  return (
    <Fragment>
      {createNftSellOfferSelectBody}

      <GetAssetsTomlData 
        // listForToml={buyInputAssetList} 
      />

    </Fragment>
  );
}

export default CreateNftSellOfferSelect;
