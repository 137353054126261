export const getNftFollowUsers = async (url, token, nId) => {
  try {

    const result = await fetch(`${url  }/nft-follow-users?nId=${nId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } 
      throw new Error('something wrong');
    

  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const addNftFollowUser = async (url, token, nId, uId) => {
  try {

    const result = await fetch(`${url  }/nft-follow-users/add-nft-follow-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
      body: JSON.stringify({
        nId,
        uId,
      }),
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } 
      throw new Error('something wrong');
    

  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const deleteNftFollowUser = async (url, token, nId) => {
  try {

    const result = await fetch(`${url  }/nft-follow-users/delete-nft-follow-user?nId=${nId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
      // body: JSON.stringify({
      //   nId,
      //   uId,
      // }),
    });

    const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } 
      throw new Error('something wrong');
    

  } catch(err) {
    console.log(err);
    throw err;
  }
};