import { initStore } from "./store";

// const configureStore = () => {
function configureStore() {
  const actions = {
    SHOW_NOTIFICATION: (state, payload) => {
      const notifyContent = {
        status: payload.status,
        title: payload.title,
        message: payload.message,
      };
      
      return {
        uiStore : {
          ...state.uiStore,
          notification: notifyContent, 
        },
      };
    },
    CLEAR_NOTIFICATION: (state) => (
      {
          uiStore : {
            ...state.uiStore,
            notification: null, 
        },
      }
    ),
    SET_GLOADING: (state, payload) => (
      { 
        uiStore : {
          ...state.uiStore,
          gLoading: payload, 
        },
      }
    ),
    SET_WINDOW_VALUES: (state, payload) => (      
      { 
        uiStore : {
          ...state.uiStore,
          windowValues: payload, 
        },
      }
    ),
    SET_ISAUTH: (state, payload) => (
      {
        uiStore : {
          ...state.uiStore,
          isAuth: payload,
        },
      }
    ),
    SET_ALBEDO_PUBKEYINFO: (state, payload) => (
      {
        uiStore : {
          ...state.uiStore,
          albedoPubkeyInfo: payload,
        },
      }
    ),
    SET_ALBEDO_ACCOUNTINFO: (state, payload) => (
      {
        uiStore : {
          ...state.uiStore,
          albedoAccountInfo: payload,
        },
      }
    ),
    SET_GETBACKEND: (state, payload) => (
      {
        uiStore : {
          ...state.uiStore,
          getBackend: payload,
        },
      }
    ),
    SET_USERDATA: (state, payload) => (
      {
        uiStore : {
          ...state.uiStore,
          userData: payload,
        },
      }
    ),
    SET_USERLIST: (state, payload) => (
      {
        uiStore : {
          ...state.uiStore,
          userList: payload,
        },
      }
    ),
    SET_ISMENUOPEN: (state, payload) => (
      {
        uiStore : {
          ...state.uiStore,
          isMenuOpen: payload,
        },
      }
    ),
    SET_DEFERREDPROMPT: (state, payload) => (
      {
        uiStore : {
          ...state.uiStore,
          deferredPrompt: payload,
        },
      }
    ),
    SET_USERNAMEDATA: (state, payload) => ({ 
        // ...state,
        uiStore : {
          ...state.uiStore,
          userNameData: payload, 
        },
      }),

  };

  initStore(actions, {
    uiStore: {
      notification: null,
      gLoading: false,
      windowValues: null,

      isAuth: false,
      albedoPubkeyInfo: null,
      albedoAccountInfo: null,

      getBackend: false,
      userData: null,
      userList: [],

      isMenuOpen: false,
      deferredPrompt: null,

      userNameData: null,
    },
    // lastPage: 0,
    // notification: {
    //   status: null,
    //   title: null,
    //   message: null,
    // },
    // i18n : undefined
  });
}

export default configureStore;
