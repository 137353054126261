import React, { useState, useEffect, Fragment } from 'react';
// import axios from 'axios';
import { useDispatch } from "react-redux";
import {
  Button,
  InfoBlock,
} from "@stellar/design-system";
import { useTranslation } from 'react-i18next';

import { useRedux } from "hooks/useRedux";


import { useStore } from '../../../hook-store/store';


import classes from './IssueDescription.module.css';

function WalletConnectDescription(props) {

  const [t] = useTranslation('translation');

  const dispatch = useDispatch();

  const { account, memoRequiredAccounts, settings, sendTx } = useRedux(
    "account",
    "memoRequiredAccounts",
    "settings",
    "sendTx",
  );
  const { status, errorString } = sendTx;

  const publicKey = account.data ? account.data.id : '';


  const [hStore, hDispatch] = useStore();
  // const nftList = hStore.nftStore.nftList;
  // const txXdr = hStore.nftStore.txXdr;
  // const feeStats = hStore.nftStore.feeStats;


  const walletConnectDescriptionBody =(
    <div className={classes.issueDescription}>
      <InfoBlock>
        {/* <br/> */}
        {/* <div className={classes.issueDescriptionTitle}>
          Wallet-Connect-description
        </div> */}
        <div className={classes.issueDescriptionContent}>
          {t('CreateNFT.04', 'To issue NFT or Trade NFT in Stellar NFT Place, it is required to connect a wallet.')}
        </div>
        <div className={classes.issueDescriptionContent}>
          {t('CreateNFT.05', 'You can use several wallets including Albedo and Freighter.')}
        </div>
        <div className={classes.issueDescriptionContent}>
          {t('CreateNFT.06', 'Instructions and links will be shown after click button.')}
        </div>
        <div className={classes.issueDescriptionContent}>
          {t('CreateNFT.07', ' Stellar NFT Place does not store your keys to connect wallet. They are stored in your browser or wallet.')}
        </div>
      </InfoBlock>
    </div>
  );


  return (
    <Fragment>
      {walletConnectDescriptionBody}
    </Fragment>
  );
}

export default WalletConnectDescription;
