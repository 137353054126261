import React, { useState, useEffect, Fragment } from 'react';
import { Modal, Button } from "@stellar/design-system";
import Img from "react-cool-img";
import { useTranslation } from 'react-i18next';

// import NftListItem from './NftListItem';
import Loader from '../Loader/Loader.jsx';
import Backdrop from '../Backdrop/Backdrop.jsx';
import ModalSimple from '../Modal/ModalSimple.jsx';

import { useStore } from '../../../hook-store/store';

// import * as uploadUtils from '../utils/bucket-upload-util';
// import * as nftDataUtils from '../../../utils/nft-data-util';
import { deleteNftComment } from '../../../utils/nft/nft-comment-util';

import { BACKEND_URL } from '../../../urlLinks';

import classes from './NftCommentItem.module.css';


function NftCommentItem(props) {
  // console.log('NftCommet.jsx-props', props);
  const { 
    nft, 
    comment,
    nftCommentList,
    canDelete,
    // deleteNftCommentHandler,
    setNftCommentList,
    // isLoading,
  } = props;

  const [t] = useTranslation('translation');

  const [hStore, hDispatch] = useStore();
  // const isAuth = hStore.uiStore.isAuth;
  const userList = hStore.uiStore.userList;
  // const userData = hStore.uiStore.userData;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  // useEffect(() => {
  //   setShowDeleteModal(false);
  // },[nftCommentList]);

  const deleteNftCommentHandler = async (url, token, nId, commentId) => {
    try {
      setIsLoading(true);
  
      const result = await deleteNftComment(url, token, nId, commentId);

      const deletedList = nftCommentList.filter(comment => (
        comment._id !== commentId));

      setNftCommentList(deletedList);

      setIsLoading(false);
      setShowDeleteModal(false);

      hDispatch('SHOW_NOTIFICATION', {
        status: '',
        title: '',
        message: `${t('comment.06', 'comment')} ${t('general.text31', 'deleted')}`,
      });
  
      setTimeout(() => {
        hDispatch('CLEAR_NOTIFICATION');
      }, 3000);

    } catch(err) {
      console.log(err);
      setIsLoading(false);

      hDispatch('SHOW_NOTIFICATION', {
        status: '',
        title: '',
        message: `${t('comment.05', 'delete comment')} ${t('general.text29', 'failed')}`,
      });
    }
  };

  const commentUser = userList.find(user => user._id === comment.uId);

  let nftCommentItemBody;

  if (nft && comment) {
    nftCommentItemBody = (
      <div className={classes.nftCommentItem}>
        <div className={classes.nftCommentContent}>
          {comment.comment}
        </div>

        {commentUser && (
          <div className={classes.nftCommentAuthor}>
            {commentUser.imageUrl && (
              <Img 
                className={classes.nftCommentAuthorImage}
                src={commentUser.imageUrl}
                alt="comment-author-img"
              />
            )}
            <span>
              {commentUser.name}
            </span>
            <span title={new Date(comment.time).toLocaleString()}>
              ({new Date(comment.time).toLocaleDateString()})
            </span>
          </div>
        )}

        {canDelete && (
          <div className={classes.nftCommentDeleteButton}>
            <Button
              // className="buttonBaseSmall"
              // style={{border:"1px solid gray", fontSize:"0.9rem"}}
              variant="tertiary"
              size="small"
              onClick={() => {
                setShowDeleteModal(true);
              }}
            >
              {t('comment.05', 'delete comment')}
            </Button>
          </div>
        )}

        {showDeleteModal && (
          <div>
            <Backdrop 
              onCancel={() => { 
                setShowDeleteModal(false); 
              }}
            />
            <ModalSimple
              modalStyle={{width: "40rem"}}
            >
              <div className={classes.nftCommentClose}>
                <span
                  className={classes.nftCommentCloseButton}
                  onClick={() => {
                    setShowDeleteModal(false); 
                  }}
                >
                  X
                </span>
              </div>
              <div>
                {t('comment.05', 'delete comment')}?
              </div>
              <div>
                <Button
                  variant="tertiary"
                  disabled={isLoading}
                  isLoading={isLoading}
                  onClick={() => {
                    deleteNftCommentHandler(
                      BACKEND_URL,
                      localStorage.getItem('token'),
                      nft.n_id,
                      comment._id,
                    );
                  }}
                >
                  {t('comment.05', 'delete comment')}
                </Button>

                {/* {isLoading && (
                  <div><Loader /></div>
                )} */}

              </div>
            </ModalSimple>
          </div>
        )}
      </div>
    );
  }

  return (
    <Fragment>
      {nftCommentItemBody}
    </Fragment>
  );
}

export default NftCommentItem;
