
import React, {Fragment} from 'react';
import classes from './ModalSimple.module.css';

function ModalSimple(props) {
  const { modalStyle } = props;
  
  const mStyle = modalStyle || null; 
  return (
    <Fragment>
      <div 
        className={classes.modalSimple}
        style={mStyle || null}
      >
        {/* <p>Are you sure?</p> */}
        <span>{props.children}</span>

      </div>
    </Fragment>
  );
}

export default ModalSimple;