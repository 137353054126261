import React, { useState, useEffect, Fragment } from 'react';
import { Button, Textarea } from "@stellar/design-system";
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
// import NftListItem from './NftListItem';
import Loader from '../Loader/Loader.jsx';
import NftCommentItem from './NftCommentItem.jsx';

import { useStore } from '../../../hook-store/store';

import { getNftComment, createNftComment, deleteNftComment } from '../../../utils/nft/nft-comment-util';
import { postOfferPush } from '../../../utils/pushNotification/push-notify';

import { BACKEND_URL } from '../../../urlLinks';

import classes from './NftCommentInput.module.css';


function NftCommentInput(props) {
  // console.log('NftCommet.jsx-props', props);
  const { 
    nft, 
    nftCommentList,
    setNftCommentList,
  } = props;

  const [t] = useTranslation('translation');

  const [hStore, hDispatch] = useStore();
  const isAuth = hStore.uiStore.isAuth;
  const userList = hStore.uiStore.userList;
  const userData = hStore.uiStore.userData;

  // const [nftCommentList, setNftCommentList] = useState([]);
  const [commentInput, setCommentInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const nftList = store.nftStore.nftList;
  

  const commentInputChangeHandler = (event) => {
    setCommentInput(event.target.value);
  };

  const createNftCommentHandler = async (url, token, nId, comment) => {
    try {
      setIsLoading(true);

      const result = await createNftComment(url, token, nId, comment);

      if (result && result.data) {
        const addedList = nftCommentList.concat(result.data);
        setNftCommentList(addedList);
        setCommentInput('');

        hDispatch('SHOW_NOTIFICATION', {
          status: '',
          title: '',
          message: `${t('comment.03', 'write comment')} ${t('general.text28', 'success')}`,
        });
    
        setTimeout(() => {
          hDispatch('CLEAR_NOTIFICATION');
        }, 3000);

        postOfferPush(
          BACKEND_URL, 
          localStorage.getItem('token'), 
          nft.n_id,
          null,
          null,
          result.data,
        );

      } else {
        throw new Error('error occured');
      }

      if (result.creatorUser) {
        const addedList2 = userList.concat(result.creatorUser);
        // console.log('userList addedList', addedList);
        hDispatch(
          'SET_USERLIST',
          _.uniqBy(addedList2, '_id'),
        );
      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);

      hDispatch('SHOW_NOTIFICATION', {
        status: '',
        title: '',
        message: `${t('comment.03', 'write comment')} ${t('general.text29', 'failed')}`,
      });
    }
  };


  let nftCommentInputBody; 

  if (nft && isAuth) {
    nftCommentInputBody = (
      <div className={classes.nftCommentInput}>
        <div>
          <textarea
            className="textareaBase"
            rows="3"
            maxLength={500}
            minLength={1}
            // placeholder={`write comment here (up to 500 characters)`}
            placeholder={`${t('comment.04', 'write comment here (up to 500 characters)')}`}
            onChange={commentInputChangeHandler}
            value={commentInput}
          />
        </div>
        <div>
          <Button
            style={{border: "0.25px solid gray"}}
            variant="tertiary"
            size="small"
            disabled={isLoading  || !commentInput.trim()}
            isLoading={isLoading}
            onClick={() => {
              createNftCommentHandler(
                BACKEND_URL,
                localStorage.getItem('token'),
                nft.n_id,
                commentInput,
              );
            }}
          >
            {t('comment.03', 'write comment')}
          </Button>
        </div>
      </div>
    );
  }
  return (
    <Fragment>
      {nftCommentInputBody}
      {/* {isLoading && <div><Loader /></div>} */}
    </Fragment>
  );
}

export default NftCommentInput;
