/* eslint-disable max-len */


// //// for local dev
// export const BACKEND_URL = process.env.REACT_APP_DEV_BACKEND_URL;
// export const HORIZON_URL = 'https://horizon-testnet.stellar.org';
// export const loginPageLink = `${process.env.REACT_APP_DEV_AUTHPAGE_URL  }/login?fromUrl=${encodeURIComponent(window.location.origin)}`;
// export const signupPageLink = `${process.env.REACT_APP_DEV_AUTHPAGE_URL  }/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;
// export const TOML_HOST = process.env.REACT_APP_DEV_TOML_HOST;
// export const FEE_ACCOUNTID = process.env.REACT_APP_DEV_FEE_ACCOUNTID;
// export const STELLAREXPERT_URL = process.env.REACT_APP_DEV_STELLAREXPERT_URL;
// export const USERACCOUNTPAGE_URL = 'http://localhost:8506';


// //// for test deploy
// export const BACKEND_URL = process.env.REACT_APP_TEST_BACKEND_URL;
// export const HORIZON_URL = 'https://horizon.stellar.org';
// export const loginPageLink = `${process.env.REACT_APP_AUTHPAGE_URL  }/login?fromUrl=${encodeURIComponent(window.location.origin)}`;
// export const signupPageLink = `${process.env.REACT_APP_AUTHPAGE_URL  }/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;
// export const FEE_ACCOUNTID = process.env.REACT_APP_FEE_ACCOUNTID;
// export const STELLAREXPERT_URL = process.env.REACT_APP_STELLAREXPERT_URL;
// export const USERACCOUNTPAGE_URL = 'https://casauthsso-userpage-test.web.app';


// //// for deploy
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const HORIZON_URL = 'https://horizon.stellar.org';
export const loginPageLink = `${process.env.REACT_APP_AUTHPAGE_URL  }/login?fromUrl=${encodeURIComponent(window.location.origin)}`;
export const signupPageLink = `${process.env.REACT_APP_AUTHPAGE_URL  }/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;
export const FEE_ACCOUNTID = process.env.REACT_APP_FEE_ACCOUNTID;
export const STELLAREXPERT_URL = process.env.REACT_APP_STELLAREXPERT_URL;
export const USERACCOUNTPAGE_URL = 'https://useraccount.spaceeight.net';
