import toml from 'toml-j0.4';

import { HORIZON_URL, BACKEND_URL, TOML_HOST } from '../../urlLinks';

export const postGetAccountAssetData = async (url, token, assetList) => {
  try {
    const result = await fetch(`${url  }/nft-data/account-asset-info`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  token}`,
      },
      body: JSON.stringify({
        assetList,
      }),
    });

    const resData = await result.json();
    console.log(result, resData);
    
    if (!result.ok) {
      throw new Error('something wrong');
    } 

    return resData;
    
  } catch(err) {
    console.log(err);
    throw err;
  }
};

  
export const getHorizonAssetInfo = async (code, issuer) => {
    try {
      const result = await fetch(`${HORIZON_URL  }/assets?asset_code=${code}&asset_issuer=${issuer}`);
    
      const resData = await result.json();
  
      // console.log('getHorizonAssetInfo resData', result, resData);
  
      if (!result.ok) {
        throw new Error('error-occured');
      }
  
      const record = resData._embedded.records[0];
      const tomlUrl = record._links.toml.href;
  
      console.log(tomlUrl);
  
      return resData;
    } catch (err) {
        // do something
        console.log(err);
        return null;
        // throw err;
    }
  };
  
  
// eslint-disable-next-line consistent-return
export const parseStellarToml = async (code, issuer) => {
    try {
      const assetInfo = await getHorizonAssetInfo(code, issuer);
      
      console.log('assetInfo', assetInfo);
      if (assetInfo) {
        
        const record = assetInfo._embedded.records[0];
        // const tomlUrl = record._links.toml.href;
        
        let tomlUrl = record._links.toml.href;

        // console.log('tomlUrl', tomlUrl);

        if (tomlUrl.startsWith('https://localhost:')) {
          tomlUrl = `http://loalhost:4001/.well-known/stellar.toml`;
        }

        if (tomlUrl.endsWith('.sni.example.com/.well-known/stellar.toml')) {
          const splitList = tomlUrl.split('//')[1].split('.');
          const localSubdomain = `${splitList[0]  }.${  splitList[1]}`;
          tomlUrl = `http://${localSubdomain}.localhost:4001/subdomain-toml`;
          
          // console.log('splitList tomlUrl', splitList);
          // console.log('tomlUrl local subdomain', tomlUrl);
        }
        
        const result = await fetch(tomlUrl);
      
        if (result) {
          const tomlText = await result.text();
      
          console.log('toml result', result);
          const paresedToml = toml.parse(tomlText);
          
          console.log('toml parsedToml', paresedToml);
    
          const tomlInfo = getAssetInfoFromToml(code, issuer, paresedToml);
    
          // setAssetTomlInfo(tomlInfo);
    
          return tomlInfo;
        }
        
          return null;
        
      }
    } catch (err) {
      console.log(err);
      return null;
      // throw err;
    }
  };
  
export  const getAssetInfoFromToml = (code, issuer, parsedTomlData) => {
    const assetInfo = parsedTomlData.CURRENCIES.find(currency => (
      currency.code === code && currency.issuer === issuer));
  
    console.log('assetinfo from toml', assetInfo);
  
    return assetInfo;
  };


// eslint-disable-next-line consistent-return
export const createListForGetToml = (accountData, aList) => {
  if (accountData && accountData.balances.length > 0) {
    const listForGetToml = aList;

    // eslint-disable-next-line no-restricted-syntax
    for (const asset of accountData.balances) {
      const isInList = aList.find(as => as.code === asset.asset_code && 
        as.issuer === asset.asset_issuer);

      if (!isInList && 
          (asset.asset_type === "credit_alphanum12" || 
            asset.asset_type === "credit_alphanum4") 
      ) {
        listForGetToml.push({
          code: asset.asset_code,
          issuer: asset.asset_issuer,
        });
      }
    }

    return listForGetToml;
  }
};


export const createListForGetToml2 = (accountData, aList) => {

    const listForGetToml = aList;

    // eslint-disable-next-line no-restricted-syntax
    for (const asset of accountData.balances) {
      const isInList = aList.find(as => as.code === asset.asset_code && 
        as.issuer === asset.asset_issuer);

      if (!isInList && 
          (asset.asset_type === "credit_alphanum12" || 
            asset.asset_type === "credit_alphanum4") 
      ) {
        listForGetToml.push({
          code: asset.asset_code,
          issuer: asset.asset_issuer,
        });
      }
    }

    const lsAllData = localStorage.getItem('assetDataList');
    if (lsAllData) {
      JSON.parse(lsAllData).assetInfoList.forEach(asset => {
        const isInList = listForGetToml.find(ele => (
          ele.code === asset.code && ele.issuer === asset.issuer));
        
          const isNative = listForGetToml.find(ele => (
            ele.code === 'XLM' && !ele.issuer));
          
        if (!isInList && !isNative) {
          listForGetToml.push({
            code: asset.code,
            issuer: asset.issuer,
          });
        }
      });
    }

    console.log('listForGetToml', listForGetToml);

    return listForGetToml;
};


export const addNewAssetData = async (code, issuer) => {

  const lsAllData = localStorage.getItem('assetDataList');
  if (lsAllData) {
    const lsInfoList = JSON.parse(lsAllData).assetInfoList;
    const isInLsData = lsInfoList.find(asset => (
        code === asset.code && issuer === asset.issuer));
    // console.log('addNewAssetData', isInLsData);

    if (!isInLsData) {
      const newAssetTomlData = await parseStellarToml(code, issuer);
      // console.log('addNewAssetData', newAssetTomlData);

      const addedList = lsInfoList.concat({
        code,
        issuer,
        tomlData: newAssetTomlData || null,
      });

      const newAssetDataObj = {
        ...JSON.parse(lsAllData),
        assetInfoList: addedList,
      };
      
      // localStorage.setItem('assetDataList', JSON.stringify(newAssetDataObj));
      return newAssetDataObj;
    
    } 
      return null;
    
  } 
    return null;
  
};